import { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const menuContainer: any = css`
background-color: ${colors.green};
display: flex;
justify-content: center;
align-items: center;
border-radius: 4px;
width: 42px;
height: 33px;
margin-right: 14px;
position: absolute;
cursor: pointer;
margin-top: 64px;
@media (${BREAKPOINTS_DM.silver_768})
{
    display: none;
}
`;

export const menu: any = css`
  width: 22px;
  height: 17px;
  position: relative;
  cursor: pointer;

    @media (${BREAKPOINTS_DM.silver_768})
    {
        display: none;
    }
`;

const line2: any = css`
  width: 100%;
  height: 3px;
  position: absolute;
  background-color: white;
  border-radius: 3px;
`;

export const line: any = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: background-color ease 0.2s .15s;
  ${line2}
  &:before, &:after {
    content: '';
    transition: transform ease .5s, background-color ease .3s .2s, top ease .5s;
    ${line2}
  }
  &:before {
    top: -6.5px
  }
  &:after {
    top: 7px
  }
`;
