import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
  BREAKPOINTS_DM
} from "src/common/constants/mediaquerys";
import { css } from "styled-components";

import colors from "../../../constants/colors";

const Default = css`
  width: 79%;
  margin: 0 auto;
  .c-empty{
    width: 80%;
    display: block;
  }
  h1 {
    span{
      font-family: Roboto,sans-serif;
      color: ${colors.dark_gray};
      font-size: 16px;
      font-weight: 800;
      letter-spacing: -1.3px;
      @media (${BREAKPOINTS_DM.silver_768}) {
        font-size: 35px;
      }
      @media (${BREAKPOINTS_DM.gold_1024}) {
        font-size: 40px;
      }
    }
  }
  h2 {
    > a > span {
      letter-spacing: -.7px
    }
    
  }
  h3 {
    ${({ theme }) => theme?.textStyles?.h3}
  }

  a {
    color: ${({ theme }) => theme.palette.green};
  }
  svg {
    path {
      fill: ${({ theme }) => theme.palette.green};
    }
  }
  li, ul{
    display: block;
  }
  ul {
    padding: 0px 0px 0px 40px;
    list-style: disc;
    margin-bottom: 10px;
  }
  li{
    strong{
      height: 21px
    }
    list-style: disc;
    line-height: 21px;
    display: list-item;
  }

  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }

  tr{
    td{
      border: 1px solid black;
      width: 143px
    }
    border: 1px solid black;
    strong{
      font-weight: 500;
    }
    p{
      padding: 5px 10px;
      font-weight: 400;
      margin: 0;
    }
  }
  
  span {
    font-size: 15.5px;
    line-height: 1.6em;
    font-weight: 400;
    font-family: Roboto,sans-serif;
    color: #111;
    margin-top: 0;
    margin-bottom: 10px;

  }
  p {
    font-size: 15.5px;
    line-height: 1.6em;
    font-weight: 400;
    font-family: Roboto,sans-serif;
    color: #111;
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const Silver = css`
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SRichTextGeneral = css`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SRichTextGeneral;
