import React, { FC } from "react";
import { IPhotoGallery } from "src/common/childTypes";

import { SComparisonPhotoGallery } from "./styles/SComparisonPhotoGallery";
import { SComparisonPhotoGalleryItem } from "./styles/SComparisonPhotoGalleryItem";
import { SComparisonPhotoGalleryImageWrapper } from "./styles/SComparisonPhotoGalleryImageWrapper";
import { SComparisonPhotoGalleryItemWrapper } from "./styles/SComparisonPhotoGalleryItemWrapper";
import ChevronLeftIcon from "src/common/brand-area/icons/ChevronLeftIcon";
import ChevronRightIcon from "src/common/brand-area/icons/ChevronRightIcon";
import { FancyImageWithModal } from "src/common/BaseComponents/FancyImageWithModal/FancyImageWithModal";
import { IGenAsset } from "src/common/types_dealer_gen";

export const responsiveFactor = { bronze: 1, silver: 1, platinum: 1 } as const;

const ComparisonPhotoGallery: FC<{ item: IPhotoGallery }> = ({ item }) => {
    if (!item.elements) return null;

    const chunks: Array<IGenAsset[]> = [];
    for (let x = 0; x < item.elements.length; x = x + 2) {
        const startIndex = x;
        const endIndex = startIndex + 2;
        chunks.push(item.elements.slice(startIndex, endIndex));
    }

    return (
        <SComparisonPhotoGallery data-gallery-comparison>
            {chunks?.map((chunk, i) => {
                const vorherAsset = chunk?.[0];
                const nachherAsset = chunk?.[1];

                return (
                    <SComparisonPhotoGalleryItemWrapper key={`gallery-image-${i}`} data-image-comparison-item-wrapper>
                        <SComparisonPhotoGalleryItem data-image-comparison-item aspectRatioFactor={responsiveFactor}>
                            {vorherAsset?.src && (
                                <div data-image-left-trigger>
                                    <SComparisonPhotoGalleryImageWrapper data-image>
                                        <label className="before">vorher</label>
                                        <span className="before-icon icon">
                                            <ChevronRightIcon />
                                        </span>
                                        <FancyImageWithModal
                                            img={vorherAsset}
                                            responsiveAspectRatio={{
                                                bronze: 277 / 235,
                                                silver: 490 / 405,
                                                platinum: 810 / 683,
                                            }}
                                            responsiveFactor={responsiveFactor}
                                            responsiveMaxWidth={{ platinum: 1366 }}
                                        />
                                    </SComparisonPhotoGalleryImageWrapper>
                                </div>
                            )}
                            {nachherAsset?.src && (
                                <div data-image-right-trigger>
                                    <SComparisonPhotoGalleryImageWrapper data-image>
                                        <label className="after">nachher</label>
                                        <span className="after-icon icon">
                                            <ChevronLeftIcon />
                                        </span>
                                        <FancyImageWithModal
                                            img={nachherAsset}
                                            responsiveAspectRatio={{
                                                bronze: 277 / 235,
                                                silver: 490 / 405,
                                                platinum: 810 / 683,
                                            }}
                                            responsiveFactor={responsiveFactor}
                                            responsiveMaxWidth={{ platinum: 1366 }}
                                        />
                                    </SComparisonPhotoGalleryImageWrapper>
                                </div>
                            )}
                        </SComparisonPhotoGalleryItem>
                    </SComparisonPhotoGalleryItemWrapper>
                );
            })}
        </SComparisonPhotoGallery>
    );
};

export default ComparisonPhotoGallery;
