import styled, { css } from "styled-components";

import { GUTTER, SCREEN_TYPES } from "../../../constants/grid";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const bottomMarginGeneration = (type, cols) => css`
    > div > div > div {
        :not(:nth-last-child(-n + ${cols})) {
            margin-bottom: ${GUTTER[type]}px;
        }
    }
`;

const Default = css`
    ${(props: any) => bottomMarginGeneration(SCREEN_TYPES.BRONZE, props.bronze)};
`;

const Silver = css`
    ${(props: any) => bottomMarginGeneration(SCREEN_TYPES.SILVER, props.silver)};
`;

const Gold = css`
    ${(props: any) => bottomMarginGeneration(SCREEN_TYPES.GOLD, props.gold)};
`;

const Platinum = css`
    ${(props: any) => bottomMarginGeneration(SCREEN_TYPES.PLATINUM, props.platinum)};
`;

const Diamond = css`
    ${(props: any) => bottomMarginGeneration(SCREEN_TYPES.DIAMOND, props.diamond)};
`;

const SGallery = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
    ${(props) => (props.overwriteStyle ? props.overwriteStyle : "")};
`;
export default SGallery;
