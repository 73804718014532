import React, { FC } from "react";
import { Img } from "src/common";
import { IPhotoGallery } from "src/common/childTypes";

import {
  PhotoGalleryWrapper,
  PhotoGalleryItem,
  PhotoGalleryImage,
} from "./ComparisonPhotoGallery.style";

const ComparisonPhotoGallery: FC<{ item: IPhotoGallery }> = ({ item }) => {
  if (!item.elements) return null;
  const chunks = [];
  for (let x = 0; x < item.elements.length; x = x + 2) {
    const startIndex = x;
    const endIndex = startIndex + 2;
    chunks.push(item.elements.slice(startIndex, endIndex));
  }

  return (
    <PhotoGalleryWrapper data-gallery-comparison>
      {chunks?.map((chunk, i) => {
        const vorherAsset = chunk?.[0];
        const nachherAsset = chunk?.[1];

        return (
          <PhotoGalleryItem
            key={`gallery-image-${i}`}
            data-image-comparison-item
          >
            {vorherAsset?.src ? (
              <div data-image-left-trigger>
                <PhotoGalleryImage data-image>
                  <label>vorher</label>
                  <Img src={vorherAsset?.src} quality={1000} />
                  {vorherAsset?.description && (
                    <div className="caption-comparison">
                      {vorherAsset.description}
                    </div>
                  )}
                </PhotoGalleryImage>
              </div>
            ) : null}
            {nachherAsset?.src ? (
              <div data-image-right-trigger>
                <PhotoGalleryImage data-image>
                  <label>nachher</label>
                  <Img src={nachherAsset?.src} quality={1000} />
                  {nachherAsset?.description && (
                    <div className="caption-comparison">
                      {nachherAsset.description}
                    </div>
                  )}
                </PhotoGalleryImage>
              </div>
            ) : null}
          </PhotoGalleryItem>
        );
      })}
    </PhotoGalleryWrapper>
  );
};

export default ComparisonPhotoGallery;
