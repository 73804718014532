
import React, { FC, useContext } from "react";
import { Context, getHeaderNavItemProps, IContext } from "src/common";

import { HeaderContainer, Logo, NavigationContainer } from "./Header.styles";
import Navigation from "./Navigation/Navigation";

const Header: FC<{ disableLogo?: boolean }> = ({ disableLogo }) => {
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  return (
    <HeaderContainer>
      <NavigationContainer>
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} />
      </NavigationContainer>
      {logo && !disableLogo && <a href="/"><Logo src={logo.src} /></a>}
    </HeaderContainer>
  );
};

export default Header;
