import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
  background-color: white;
  margin-bottom: 0 !important;
`;

export const imagesWrapper: any = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  > img {
    width: auto;
    height: 10vw;
    max-height: 180px;
  }
`;
export const social: any = css`
  a {
    > span {
      display: none;
    }
    width: 30px;
    height: 30px;
    padding-left: 10px;
  }
  svg {
    fill: ${(props) => props.theme.secondary};
    fill: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.secondary};
    width: 30px;
    height: 30px;
  }
`;

export const footerContent: any = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-wrap: wrap;
  }
`;

export const copyright: any = css`
  margin-right: 50px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 100%;
    margin-right: 0;
  }
`;

export const links: any = css`
  display: flex;
  margin: 10px 30px 10px 0;
  align-items: center;
  a {
    color: inherit;
    margin: 0 12px;
    &:first-child {
      margin-left: 0;
    }
    &:nth-child(2) {
      margin-right: 0;
    }
  }
`;

export const nachOben: any = css`
  display: none;
  color: ${(props) => props.theme.primary};
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: inline-block;
  }
`;
