import styled from "styled-components";

import SSlickBase from "src/common/utils/slick/SSlickBase";
import SSlickTheme from "src/common/utils/slick/SSlickTheme";

export const Container: any = styled.div`
  width: 100%;
  height: 475px;
  border-top: 2px solid #fff;
  overflow: hidden;
`;

export const Wrapper: any = styled.div<{fullHeight: boolean}>`
  height: 475px;
  ${SSlickBase};
  ${SSlickTheme};
  background: #fff;
  width: 100%;
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255,255,255,.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255,255,255,.5);
    }
  }

  .slick-next {
    right: 0 !important;
  }

  .slick-prev {
    left: 0 !important;
    :before {
      transform: rotate(180deg);
    }
  }
`;
