import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const navContainer: any = css`
    width: 100%;
    background: linear-gradient(#414141 0,#000 55%);
    border-bottom: 3px solid white;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(50,50,50,.5);
    height: 85px;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: 100%;
        background: linear-gradient(#414141 0,#000 55%);
        height: 81px;
        border-bottom: 3px solid white;
    }
`;

export const companyLogo: any = css`
    position: absolute;
    left: 0;
    top: 10px;
    padding-left: 20px;
    width: 100%;
    max-width: 400px;
    @media (${BREAKPOINTS_DM.bronze_375})
    {
        top: 0;
    }

    @media (${BREAKPOINTS_DM.gold_1024})
    {
        padding-left: 0;
    }
`;

export const menuDesktop: any = css`
    @media (${BREAKPOINTS_DM.gold_1024})
    {
          display: flex;
          max-width: ${({ theme }) => theme.maxWidth}px;
          width: 100%;
          margin: auto;
          position: relative;
          justify-content: flex-end;
          z-index: 1;
    }
`;

export const ulMenuDesktop: any = css`
    display: none;
    span {
        width: 100%; 
        white-space: normal; 
        text-align: center;
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        display: flex;
    }
`;

export const menuResponsive: any = css`
      position: fixed;
      z-index: 9999;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      transition: transform ease .5s;
      overflow-y: auto;
      display: flex;
      &::-webkit-scrollbar {
        display: none;
      }
      @media (${BREAKPOINTS_DM.gold_1024})
      {
          display: none;
      }
`;

export const ulMenuResponsive: any = css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 80px;
      width: 70%;
      background-color: ${props => props.theme.palette.prime};
      box-shadow: -2px -2px 5px rgba(50,50,50,.5);
      min-height: 100%;
      box-sizing: border-box;
      margin-bottom: auto;
      @media (${BREAKPOINTS_DM.gold_1024})
      {
          display: none;
      }
`;

export const backDrop: any = css`
      width: 30%;
      height: 100%;
      opacity: 0;
`;

export const openSubMenuButton: any = css`
    color: white;
    display: inline-block;
    margin-left: 10px;
    background-image: url("/images/sprite-s82c5aa31b4.png");
    background-repeat: no-repeat;
    background-position: 0 -75px;
    height: 15px;
    transform: translateY(2px);
    width: 15px;
`;

export const active: any = css`
    color: ${props => props.theme.palette.prime} !important;
`;

export const activeImage: any = css`
    position: absolute;
    top: -111px;
`;

// Mapped navitem Styles

export const navItem: any = css`
      height: 111px;
      overflow: hidden;
      position: relative;
      width: 95px;
      img {
          height: 222px;
      }
      &:hover {
        img {
          position: absolute;
          top: -111px;
        }
        span {
          color: ${(props) => props.theme.palette.prime};
        }
      }
`;

export const link: any = css`
    height: 111px;
    width: 95px;
    span {
        position: absolute;
        color: white;
        top: 25%;
        left: 50%;
        transform: translateY(-25%) translateX(-50%);
        white-space: nowrap;
        font-weight: 300;
    }
`;

export const navItemResponsive: any = css`
    background-color: black;
    width: 99%;
    margin-bottom: 2px;
    border-radius: 10px 0 0 10px;
    border-bottom: 1px solid white;
    position: relative;
    &:hover {
    background-color: #D30032;
    }
`;

export const linkResponsive: any = css`
      padding: 22px;
      display: block;
      color: white;
      font-weight: 700;
      font-size: 18px;
      z-index: 9999999;
`;

export const subNavItemResponsive: any = css`
   background-color: ${props => props.theme.palette.bg1};
   width: 100%;
   margin-bottom: 2px;
   border-radius: 10px 0 0 10px;
   color: white;
   padding: 10px;
   display: block;
   box-sizing: border-box;
   &:hover {
    background-color: ${props => props.theme.palette.red};
   }
`;
