import React, { FC } from "react";
import { LinksWrapper, Link } from './Footer.style';

const Navigation: FC<{ items: any[] }> = ({ items }) => (
  <LinksWrapper>
    {items?.map((linkItem, i) => (
      <Link key={i} isBold={linkItem.isBold} href={linkItem?.link}>
        {linkItem?.title}
      </Link>
    ))}
  </LinksWrapper>
);

export default Navigation;