import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";
import { ISpacer } from "../Spacer";


const generateCss = (val) => {
    return val ? `height: ${val}px;` : "";
};

const Default = css<any>`
    width: 100%;
    ${(props: ISpacer) => generateCss(props.bronze)};
`;

const Silver = css<any>`
    ${(props: ISpacer) => generateCss(props.silver)};
`;

const Gold = css<any>`
    ${(props: ISpacer) => generateCss(props.gold)};
`;

const Platinum = css<any>`
    ${(props: ISpacer) => generateCss(props.platinum)};
`;

const Diamond = css<any>`
    ${(props: ISpacer) => generateCss(props.diamond)};
`;

const SCol = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SCol;
