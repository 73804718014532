import React, { Fragment } from "react";

interface IUniversalComponentRendererProps 
{
  items: any[];
}

const UniversalComponentRenderer = ({ items }: IUniversalComponentRendererProps): React.ReactElement => 
{
  let wasFirstCtaFound = false;

  return (
    <Fragment>
      {items && items.map((item, key) => 
      {
        if(`${item?.__typename}` === "undefined") 
        {
          return null;
        }
        if(`${item?.__typename}`.includes("UnknownSlice"))
        {
          return null;
        }
        try 
        {
          const MountedComponent = require(`../../components/elements/${item?.__typename}/${item?.__typename}`).default;
          let isCtaFirstChildOfItsType = false;

          if(item?.__typename === "CallToAction") 
          {
            if(!wasFirstCtaFound) 
            {
              isCtaFirstChildOfItsType = true;
            }
            wasFirstCtaFound = true;
          }

          return (
            <React.Fragment key={key}>
              <MountedComponent {...item} isFirstCTA={isCtaFirstChildOfItsType} index={key} />
            </React.Fragment>
          );
        }
        catch(e) 
        {
          console.error(item?.__typename, e);
          return null;
        }
      })}
    </Fragment>
  );
};

export default UniversalComponentRenderer;
