import React, { useState } from "react";
import SGuestbookStars from "./Styles/SGuestbookStars";

const GuestbookStars = (props: { changeable: boolean, amountOfStars: number, starSize: number, onChange}): any => {
    /* changeable indicates whether the amount of full 
        and half stars should change when clicking on it and whether stars should be hoverable.
        amountOfStars are the selected stars.
    */ 
    const [hoveredStars, setHoveredStars] = useState<number>(0);
    let fullStarsToRender: number;

    if (props.amountOfStars >= hoveredStars) {
        fullStarsToRender = props.amountOfStars;
    } else {
        fullStarsToRender = hoveredStars;
    }

    const halfStarsToRender = 5 - fullStarsToRender;
    const stars: React.ReactElement[] = [];
    
    for (let i = 0; i < fullStarsToRender; i++) {
        stars.push(
            <svg
                onMouseLeave={props.changeable ? () => setHoveredStars(0) : undefined}
                onClick={props.changeable ? () => props.onChange(i + 1) : undefined}
                key={i}
                xmlns="http://www.w3.org/2000/svg"
                width={props.starSize}
                height={props.starSize}
                viewBox="0 0 24 24"
            >
                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
            </svg>
        );
        
    }
    for (let i = 0; i < halfStarsToRender; i++) {
        stars.push(
            <svg
                onMouseEnter={props.changeable ? () => setHoveredStars(fullStarsToRender + i + 1) : undefined}
                onMouseLeave={props.changeable ? () => setHoveredStars(0) : undefined}
                key={fullStarsToRender + i}
                width={props.starSize}
                height={props.starSize}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                <path d="M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z" />
            </svg>
        );
    }

    return (
        <SGuestbookStars className={props.changeable && "changeable"}>{stars}</SGuestbookStars>
    );
};

GuestbookStars.defaultProps = {
    starSize: 24,
    onChange: undefined
}

GuestbookStars.displayName = "GuestbookStars";
export { GuestbookStars };
