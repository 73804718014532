import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const MicroWrapper: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  iframe {
    width: 100%;
    height: 100vh;
    min-height: 1000px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 13.5px 90px 0;
  }
`;

export const Heading: any = styled.div`
  padding: 27px 0 54px 27px;
  h1 {
    color: ${props => props.theme.primary};
    font-family: Exo, Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.6px;
    line-height: 42px;
    margin-top: 12px;
    margin-bottom: 13.5px;
    text-transform: uppercase;
  }
`;
