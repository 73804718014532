import React, { FC } from "react";
import { FullscreenImage, Img } from "src/common";
import { FullscreenSlider } from "src/common";
import { IPhotoGallery } from "src/common/childTypes";

import {
  PhotoGalleryWrapper,
  PhotoGalleryItem,
} from "./MosaicPhotoGallery.style";
import { ModalImageHide } from "../../ModalImageHide";
const MAX_COLS = 4;

const MODAL_MAP = {
  slider: FullscreenSlider,
  image: FullscreenImage,
  hide: ModalImageHide,
};

const getImageProps = (item: IPhotoGallery): any =>
  item?.modal === "slider" ? { elements: item.elements } : null;

// function isOdd(num) { return num % 2;}

const MosaicPhotoGallery: FC<{ item: IPhotoGallery; columns?: number }> = ({
  item,
  columns,
}) => {
  if (!item.elements) return null;

  let cols = columns || MAX_COLS;

  if (cols != 4 && cols != 2) {
    cols = MAX_COLS;
  }

  const assets = item.elements.filter((asset) => asset?.src);

  // console.log(` assets`, assets);
  // const chunkSize = Math.round(assets.length / cols);
  // const chunks = [];
  // for (let x = 0; x < cols; x++) {
  //   const startIndex = x * chunkSize;
  //   const endIndex = startIndex + chunkSize;
  //   chunks.push(assets.slice(startIndex, endIndex));
  // }

  const getCol = (index) => {
    if (cols == 4) {
      if (index % cols == 0) {
        return 1;
      }
      if (index % cols == 1) {
        return 3;
      }
      if (index % cols == 2) {
        return 0;
      }
      if (index % cols == 3) {
        return 2;
      }
    }
    
    if (cols == 2) {
      return (index + 1) % cols;
    }

    return index % cols;
  };

  const chunks = [];
  for (let x = 0; x < assets.length; x++) {
    const colIndex = getCol(x);

    if (!chunks[colIndex]) {
      chunks[colIndex] = [];
    }
    chunks[colIndex].push(assets[x]);
  }

  // const getDesc = (chunkIndex, itemIndex )=> {
  //   let targetChunk: any = 999;
  //   let targetItem: any = 999;
  //   if (isOdd(chunkIndex)) {
  //     targetChunk = chunkIndex - 1;
  //     targetItem = itemIndex;
  //   }else {
  //     targetChunk = chunkIndex + 1;
  //     targetItem = itemIndex;
  //   }
  //   return chunks[targetChunk]?.[targetItem]?.description;
  // }

  const Image = MODAL_MAP[item.modal || "image"] || MODAL_MAP["image"];

  // return null;
  return (
    <PhotoGalleryWrapper data-gallery="mosaicimage">
      {chunks?.map(
        (chunk, i) =>
          (
            <PhotoGalleryItem key={`gallery-image-${i}`} column={i}>
              {chunk?.map(
                (asset, x) =>
                  (
                    <div key={`gallery-image-item-${x}`}>
                      <div
                        data-image
                        style={
                          item.modal == "hide" ? { pointerEvents: "none" } : {}
                        }
                      >
                        {asset && (
                          <Image
                            img={[asset]}
                            subtitle={asset?.description ?? null}
                            lazyload={false}
                            {...getImageProps(item)}
                          />
                        )}
                      </div>
                      <div
                        data-caption
                        style={
                          item.modal == "hide" ? { pointerEvents: "none" } : {}
                        }
                      >
                        {asset?.description ?? null}
                      </div>
                    </div>
                  ) ?? null
              )}
            </PhotoGalleryItem>
          ) ?? null
      )}
    </PhotoGalleryWrapper>
  );
};

export default MosaicPhotoGallery;
