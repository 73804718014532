import React, { FC } from "react";
import { IAsset, ISlider, Img, IPage } from "src/common";
import {
    IPageGuestbook,
    IPageRepairInquiry,
    IPageDateInquiry,
    IPageContact,
    IPageBrand,
    IPageBrandOverview,
    IPageLanding,
    IPageImprint,
    IPageNews,
    IPageCourse,
} from "src/common/childTypes";
import FadeSlider from "src/common/components/FadeSlider/Slider";
import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import { HeroContainer, HeroContainerDesktop, HeroContainerMobile } from "./Hero.style";

const OVERRIDE_STYLE = `
  ${SSlickBase}
  ${SSlickTheme}
`;

const SLIDER_OPTIONS = {
    arrows: true,
    dots: false,
    autoplaySpeed: 4000,
    fade: true,
};

const Hero: FC<
    React.PropsWithChildren<{
        page:
            | IPageGuestbook
            | IPage
            | IPageRepairInquiry
            | IPageDateInquiry
            | IPageContact
            | IPageBrand
            | IPageBrandOverview
            | IPageLanding
            | IPageImprint
            | IPageNews
            | IPageCourse;
        isDealerSearchPage?: boolean;
    }>
> = ({ page, isDealerSearchPage }) => {
    // @ts-ignore
    const images = page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
    // @ts-ignore
    const slider = page?.elements?.filter((e) => e?.__typename === "Slider") as ISlider[];

    const slug = (page as any)?.slug;

    let component = null;
    if (!slug) {
        component = (
            <>
                <HeroContainerMobile>
                    <Img
                        alt={images?.[0]?.title}
                        src={images?.[0]?.src}
                        placeholderHeight="480px"
                        quality={768}
                        lazyload={false}
                    />
                </HeroContainerMobile>
                <HeroContainerDesktop>
                    <Img
                        alt={images?.[0]?.title}
                        src={images?.[0]?.src}
                        placeholderHeight="580px"
                        quality={1140}
                        lazyload={false}
                    />
                </HeroContainerDesktop>
            </>
        );
    } else if (slider && slider?.[0]?.elements) {
        component = (
            <FadeSlider overrideStyle={OVERRIDE_STYLE} elements={slider?.[0]?.elements} options={SLIDER_OPTIONS} />
        );
    } else if (images?.[0]) {
        if (images.length > 1) {
            component = (
                <FadeSlider
                    overrideStyle={OVERRIDE_STYLE}
                    elements={[...images?.map((f) => f)]}
                    options={SLIDER_OPTIONS}
                />
            );
        } else if (images?.length > 1) {
            component = <FadeSlider overrideStyle={OVERRIDE_STYLE} elements={images} options={SLIDER_OPTIONS} />;
        } else {
            component = (
                <Img alt={images?.[0]?.title} src={images} placeholderHeight="580px" quality={1140} lazyload={false} />
            );
        }
    }

    return (
        <HeroContainer empty={!component} isDealerSearchPage={isDealerSearchPage} slug={slug}>
            {component}
        </HeroContainer>
    );
};

export default Hero;
