import React, { FunctionComponent } from "react";

const ArrowLinkIcon: FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17" fill="none">
            <path d="M1 0.160645V9.16064H19" stroke="currentColor" strokeWidth="2" />
            <path d="M13.4375 15.6606L19.875 9.22314L13.4375 2.78564" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
};

export default ArrowLinkIcon;
