import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 10px;
      background-color: ${props => props.theme.palette.bg1};
      position: fixed;
      bottom: 0;
      z-index: 70;
      height: 30px;
      box-sizing: content-box;
      box-shadow: 2px 2px 5px rgba(50,50,50,.5);
      @media (min-width: 1080px)
      {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: 30px;
            bottom: initial;
            right: 0;
            top: 130px;
            height: initial;
      }
      a {
            color: ${props => props.theme.palette.highlight};
      }
      @media (${BREAKPOINTS_DM.gold_1024}) and (-ms-high-contrast: none), (-ms-high-contrast: active)
      {
          min-height: 150px;
      }
`;
