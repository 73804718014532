import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import { cutCorner } from "../../../constants/layout";
import * as styles from "./Asset.style";

export interface IAssetProps {
  file?: IAsset[];
  subtitle?: string;
}

const Asset: FunctionComponent<IAssetProps> = (props) => {
  return (
    <>
      {props?.file?.length === 1 ? (
        <div
          css={`
            ${cutCorner}${styles.wrapper}
          `}
        >
          <div>
            <Img
              quality={1080}
              src={props.file?.[0]?.src}
              alt={props.subtitle}
            />
          </div>
        </div>
      ) : (
        <>
          {!!props?.file ? (
            <>
              {props?.file?.map((file, i) => (
                <div
                  key={i}
                  css={`
                    ${cutCorner}${styles.wrapper}
                  `}
                >
                  <div>
                    <Img quality={1080} src={file?.src} alt={file.title} />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div
              css={`
                ${cutCorner}${styles.wrapper}
              `}
            >
              <div>
                <Img
                  quality={1080}
                  src={(props as any)?.src}
                  alt={(props as any).description}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Asset;
