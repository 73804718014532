import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const NavigationContainer: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 81px;
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
  z-index: 999;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 70px;
  }
`;

export const HeaderContainer: any = styled.div`
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 9999999999;
`;

export const Logo: any = styled.img`
  position: absolute;
  top: 10px;
  width: 45%;
  z-index: 9999;
  margin-left: 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    position: initial;
    width: calc(100% - 97px);
    max-width: 170px;
    width: unset;
    margin-left: 0px;
  }
`;

export const Head: any = styled.div`
  display: none;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    align-items: center;
    background-color: #1A1A1A;
    color: #A8A8A8;
    width: 100%;
    height: 42px;
    font-size: 13px;
  }
`;

export const HeadContent: any = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
  font-size: 13px;
  div.contact-data {
    display: flex;
    justify-content: space-between;
    a {
      padding: 13px 10px;
      display: flex;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        color: #E67C19;
      }
    }
    span {
      font-size: 13px;
      font-weight: 300;
      margin-left: 5px;
    }
  }
`;
