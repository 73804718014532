import React, { FC, useEffect, useState } from "react";

import { AffixWrapper } from "./Affix.style";

const Affix: FC<{ className?: string; offset: number; fixed?: boolean }> = ({ className = "", offset, fixed, children, ...props }) => {
  const [affix, setAffix] = useState(!!fixed);

  useEffect(() => {
    const handleScroll = (): void => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (!affix && scrollTop >= offset) {
        setAffix(true);
      }

      if (!fixed && affix && scrollTop < offset) {
        setAffix(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, [fixed, offset, affix, setAffix]);

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { affix });
    }
    return child;
  });

  return (
    <AffixWrapper {...props} className={`${className} ${affix ? "affix" : ""}`}>
      {childrenWithProps}
    </AffixWrapper>
  );
};

export default Affix;
