import {
  Context,
  IContext,
  IPageDateInquiry,
  ITextElement,
  IPageRepairInquiry,
} from "src/common";
import React, { FunctionComponent, useContext } from "react";
import Img from "../../../../BaseComponents/Img/Img";
import RichText from "../../../../BaseComponents/RichText/RichText";
import { cutCorner } from "../../../../constants/layout";
import * as styles from "./TopBar.style";
import { DachseiteSearch } from "../../dachseiteSearch/DachseiteSearch";
import { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const TopBar: FunctionComponent = () => {
  const caisyProps = useContext<IContext>(Context);

  const companyInformationPublic = caisyProps?.CompanyInformationPublic;

  const pageRepairInquiry = caisyProps.PageRepairInquiry as IPageRepairInquiry;
  const pageRepairInquiryContent = pageRepairInquiry?.elements?.find(
    (element) => element?.__typename === "TextElement"
  ) as ITextElement;

  const pageDateInquiry = caisyProps?.PageDateInquiry as IPageDateInquiry;
  const pageDateInquiryContent = pageDateInquiry?.elements?.find(
    (element) => element?.__typename === "TextElement"
  ) as ITextElement;

  let serviceNumberWidth;

  if (!pageRepairInquiry && !pageDateInquiry) {
    serviceNumberWidth = "100%";
  } else if (
    (!pageRepairInquiry && pageDateInquiry) ||
    (pageRepairInquiry && !pageDateInquiry)
  ) {
    serviceNumberWidth = "calc(100% - 310px)";
  } else {
    serviceNumberWidth = "calc(100% - 620px)";
  }

  const displayEitherPhoneOrServiceNumber = () => {
    if (!!companyInformationPublic?.serviceNumber) {
      return (
        companyInformationPublic?.serviceNumber && (
          <p>{companyInformationPublic?.serviceNumber}</p>
        )
      );
    } else {
      return (
        companyInformationPublic?.phone && (
          <p>{companyInformationPublic?.phone}</p>
        )
      );
    }
  };
  return (
    <div
      css={`
        ${styles.topBarWrapper}
      `}
    >
      {(caisyProps?.dealer as any)?.dachseite ? (
        <div
          css={css`
            width: ${serviceNumberWidth};
            @media (${BREAKPOINTS_DM.platinum_1366}) {
              width: 100%;
            }
          `}
        >
          <DachseiteSearch />
        </div>
      ) : (
        <div
          css={`
            ${cutCorner}${styles.serviceNummer}
          `}
          style={{ width: serviceNumberWidth }}
        >
          <p>UNSERE SERVICENUMMER</p>
          <div>
            <Img quality={30} src="/images/phone.svg" alt="phone icon" />
            {!!companyInformationPublic?.serviceNumber ? (
              <p>{companyInformationPublic?.serviceNumber}</p>
            ) : (
              <>
                {companyInformationPublic?.phone && (
                  <p>{companyInformationPublic?.phone}</p>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {(pageRepairInquiry || pageDateInquiry) && (
        <div css={styles.inquiriesWrapper}>
          {pageDateInquiry && (
            <div
              style={
                !pageRepairInquiry ? { width: "100%", marginRight: 0 } : null
              }
              css={`
                ${cutCorner}${styles.inquiryElement}${styles.pageDateInquiry}
              `}
              onClick={
                pageDateInquiry?.slug
                  ? () => window.open(pageDateInquiry.slug)
                  : null
              }
            >
              <div>
                {pageDateInquiryContent?.headline && (
                  <h3>{pageDateInquiryContent.headline}</h3>
                )}
                {pageDateInquiryContent?.text && (
                  <RichText
                    textContent={pageDateInquiryContent.text}
                    overwriteStyle={styles.inquiryElementRichtext}
                  ></RichText>
                )}
              </div>
            </div>
          )}
          {pageRepairInquiry && (
            <div
              style={!pageDateInquiry ? { width: "100%", marginLeft: 0 } : null}
              css={`
                ${cutCorner}${styles.inquiryElement}${styles.pageRepairInquiry}
              `}
              onClick={
                pageRepairInquiry?.slug
                  ? () => window.open(pageRepairInquiry.slug, "_self")
                  : null
              }
            >
              <div>
                {pageRepairInquiryContent?.headline && (
                  <h3>{pageRepairInquiryContent.headline}</h3>
                )}
                {pageRepairInquiryContent?.text && (
                  <RichText
                    textContent={pageRepairInquiryContent.text}
                    overwriteStyle={styles.inquiryElementRichtext}
                  ></RichText>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
