import React, { FC } from "react";
import { BREAKPOINTS_DM, BREAKPOINTS } from "src/common/constants/mediaquerys";
import styled from "styled-components";
import { Container } from "../Grid";

const HEIGHT = "130px";
const LOGO_WIDTH = "320px";

export const BurguerIcon: any = styled.div`
  height: 57px;
  width: 57px;
  background-image: url("/images/bars.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 16px;
  cursor: pointer;
  z-index: 999;
  left: 10px;
`;

export const HaderContainer = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.white};
  height: ${HEIGHT};
  position: fixed;
  z-index: 999;

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;

    ${Container} {
      position: relative;
      align-items: center;
    }
  }
`;

export const Logo = styled.img`
  display: block;
  margin-left: 10px;
  max-height: 75px;
  width: auto;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-left: unset;
    max-height: 132px;
  }
`;

export const SubNavigationLinks: any = styled.div<{ active: boolean }>`
  display: ${({ active }) => active ? "block" : "none"};
  z-index: 999999;

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
    position: absolute;
    top: 34px;
    left: 11px;
    background-color: #FFF;
    padding: 10.5px;
    width: 200px;
  }

  > a {
    display: flex;
    width: 100%;
    padding-left: 21px;
    font-size: 14px;
    margin-bottom: 1px;

    @media (${BREAKPOINTS_DM.silver_768}) {
      padding: 0;
      > span {
        color: ${({ theme }) => theme.palette.textColorHeadings};
        opacity: .6;
        :hover {
          opacity: 1;
          text-decoration: underline;
        }
      }

      :not(:last-child) {
        border-bottom: 1px solid;
      }
    }
  }
`;

export const NavigationLink: any = styled.li<{ active: boolean }>`
  :last-child {
    border-right: none;
  }

  padding: 0;

  > a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    color: ${({ active, theme }) => active ? theme.palette.primary : theme.palette.textColor};
    font-size: 14px;
    font-weight: 300;
    line-height: 31px;
    border-bottom: 1px solid;
    padding-left: 10.5px;

    span {
      padding: 0px 10px;
      background-color: #fff;
      i {
        border: solid #000;
        border-width: 0 3px 3px 0;
        display: inline-block;
        height: 9px;
        width: 9px;
        padding: 3px;

        ${({ active }) => active && "transform: rotate(45deg);"}
      }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      text-transform: uppercase;
      line-height: unset;
      height: unset;
      padding-left: 21px;
      padding-top: 4px;
      padding-bottom: 4px;

      background-color: transparent;
      wdith: unset;
      border-bottom: unset;

      > span {
        display: none;
      }
    }

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${BREAKPOINTS.SILVER}px) {
    ${({ active }) => active && `
      ${SubNavigationLinks} {
        display: block;
      }
    `}
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;
    :hover {
      ${SubNavigationLinks} {
        display: block;
      }
    }
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks = styled.div`
  position: absolute;

  background-color: ${({ theme }) => theme.palette.white};
  display: block;
  flex-wrap: wrap;
  align-items: center;
  padding: 80px 0 0 5px;

  transform: translate3d(75vw,0,0);
  transition: transform .8s ease;
  width: 75vw;
  height: 100vh;
  box-shadow: -2px -2px 5px rgba(50,50,50,.5);

  ${BurguerIcon} {
    display: none;
    left: 0;
  }

  ul {
    list-style: none;
    overflow-y: auto;
    height: calc(100vh - 80px);
    margin-left: -5px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    transform: translate3d(0,0,0);
    right: 0;
    width: unset;
    height: unset;
    padding: .5em 0;
    box-shadow: none;
    background-color: transparent;

    ${NavigationLink} {
      display: flex;
    }

    ${BurguerIcon} {
      display: none !important;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      height: unset;
      margin-top: 4px;
      padding-left: 12.5px;
      padding-right: 12.5px;
      overflow-y: visible;
    }
  }
`;

export const NavigationContainer: any = styled.div<{ active: boolean }>`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.palette.white};

  ${({ active }) => active
    ? `
      width: 75vw;
      ${NavigationLinks} {
        ${BurguerIcon} {
          display: block;
        }
        transform: translate3d(0,0,0);
      }

      ${BurguerIcon} {
        display: none;
      }
    `
    : `
      width: 77px;
    `
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
    top: 24px;
    ${BurguerIcon} {
      display: none;
    }
  }
`;
