import React, { FunctionComponent } from "react";
import { IPhotoGallery } from "src/common/childTypes";
import { IGenAsset } from "src/common/types_dealer_gen";
import { SOffsetGallery } from "./styles/SOffsetGallery";
import { SOffsetGalleryColumn } from "./styles/SOffsetGalleryColumn";
import { OffsetGalleryItem } from "./OffsetGalleryItem";
import { SSliverWrapper } from "src/common/constants/SSliverWrapper";
import { SBronzeWrapper } from "src/common/constants/SBronzeWrapper";
import { SOffsetGalleryChunkWrapper } from "./styles/SOffsetGalleryChunkWrapper";

const convertElementsIntoRows = ({ item }: { item: IPhotoGallery }) => {
    const CHUNK_SIZE = 4;
    const chunks: Array<{ leftColumn: IGenAsset[]; rightColumn: IGenAsset[] }> = [];

    for (let x = 0; x < item.elements.length; x += CHUNK_SIZE) {
        const startIndex = x;
        const endIndex = startIndex + CHUNK_SIZE;
        const chunk = item.elements.slice(startIndex, endIndex);

        const leftColumn: IGenAsset[] = [];
        const rightColumn: IGenAsset[] = [];

        chunk.forEach((element, index) => {
            if (index % 2 === 0) {
                leftColumn.push(element);
            } else {
                rightColumn.push(element);
            }
        });

        chunks.push({ leftColumn, rightColumn });
    }

    return chunks;
};

const OffsetGallery: FunctionComponent<{ item: IPhotoGallery }> = ({ item }) => {
    if (!item.elements) return null;

    const galleryChunks = convertElementsIntoRows({ item });

    const leftColumnImageResponsiveAspectRatio = { bronze: 312 / 225, silver: 294 / 274, platinum: 442 / 435 };
    const rightColumnImageResponsiveAspectRatio = { bronze: 312 / 167, silver: 348 / 186, platinum: 587 / 315 };

    const MobileGallery = () => (
        <SBronzeWrapper data-mobile-wrapper>
            {item.elements?.map((image, index) => {
                return (
                    <OffsetGalleryItem
                        key={`left-row-${index}`}
                        image={image}
                        item={item}
                        responsiveAspectRatio={
                            (index + 1) % 2 === 0
                                ? rightColumnImageResponsiveAspectRatio
                                : leftColumnImageResponsiveAspectRatio
                        }
                    />
                );
            })}
        </SBronzeWrapper>
    );

    const DesktopGallery = () => (
        <SSliverWrapper data-desktop-wrapper>
            {galleryChunks?.map(({ leftColumn, rightColumn }, index) => (
                <SOffsetGalleryChunkWrapper key={index} data-gallery-offset-item-wrapper>
                    <SOffsetGalleryColumn data-gallery-offset-left-row>
                        {leftColumn?.map((image, index) => (
                            <OffsetGalleryItem
                                key={`left-row-${index}`}
                                image={image}
                                item={item}
                                responsiveAspectRatio={leftColumnImageResponsiveAspectRatio}
                            />
                        ))}
                    </SOffsetGalleryColumn>
                    <SOffsetGalleryColumn data-gallery-offset-right-row>
                        {rightColumn?.map((image, index) => (
                            <OffsetGalleryItem
                                key={`right-row-${index}`}
                                image={image}
                                item={item}
                                responsiveAspectRatio={rightColumnImageResponsiveAspectRatio}
                            />
                        ))}
                    </SOffsetGalleryColumn>
                </SOffsetGalleryChunkWrapper>
            ))}
        </SSliverWrapper>
    );

    return (
        <SOffsetGallery data-gallery-offset>
            <MobileGallery />
            <DesktopGallery />
        </SOffsetGallery>
    );
};

export default OffsetGallery;
