import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import { LogoLinkWrapper } from "./LogoLink.style";

interface ILogoLink 
{
  href?: string;
  logoRight: boolean;
  logoCenter: boolean;
  mobileSwapBurger: boolean;
}

const LogoLink: FunctionComponent<ILogoLink> = ({ children, href, logoCenter, logoRight, mobileSwapBurger }) => 
{
  const props = useContext(Context);
  const logoSettings = props?.Settings;

  return (
    <LogoLinkWrapper 
      href={`${href ? href : "/"}`}
      logoRight={logoRight}
      mobileSwapBurger={mobileSwapBurger}
      logoCenter={logoCenter}
      logoSizeDesktop={logoSettings.desktopLogoSizePercent}
      logoSizeMobile={logoSettings.mobileLogoSizePercent}
      offsetY={logoSettings.desktopLogoOffsetY}
      offsetX={logoSettings.desktopLogoOffsetX}
      mobileOffsetX={logoSettings.mobileLogoOffsetX}
      mobileOffsetY={logoSettings.mobileLogoOffsetY}
      desktoVisualization={logoSettings.desktopMenuVisualization}
      mobileVisualization={logoSettings.mobileMenuVisualization}
    >
      {children}
    </LogoLinkWrapper>
  );
};

export default LogoLink;

