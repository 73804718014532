import styled, { keyframes, css } from "styled-components";

const jump = keyframes`
       0% {
            transform: translate(0,0);
        }
        25% {
        
            transform: translate(0,-3px);
        }
        50% {
        
            transform: translate(0,0);
        }
        75% {
        
            transform: translate(0,3px);
        }
        100% {
            
            transform: translate(0,0);
        }
`;

export const SScrollIndicatorCircle = styled.div<any>`
    text-shadow: 2px 2px 5px rgba(50, 50, 50, 0.2);
    animation: ${jump} 3s infinite linear;
    margin-left: 14px;
    padding-top: 9px;
    display: inline-flex;
    justify-content: center;
`;
