import { P_768_Regular } from "src/common/brand-area/constantsOverwrites/typography";
import styled, { css } from "styled-components";

const Default = css`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    color: var(--back-2);
    ${P_768_Regular}
`;

export const SModalSubtitleWrapper = styled.div`
    ${Default}
`;
