import React, { FunctionComponent } from "react";
import { ThemedCssFunction, DefaultTheme, FlattenSimpleInterpolation } from "styled-components";

import { COL_AMOUNT } from "../../constants/grid";
import Col from "../Col/Col";
import Row from "../Row/Row";
import SGallery from "./Styles/SGallery";

interface IGalleryProps 
{
  children?: any;
  bronze?: number; //* * Items per row in size bornze => default: 1 */
  silver?: number; //* * Items per row in size silver => default: bornze */
  gold?: number; //* * Items per row in size gold => default: silver */
  platinum?: number; //* * Items per row in size platinum => default: gold */
  diamond?: number; //* * Items per row in size diamond => default: platinum */
  fullWidth?: boolean; //* * Remove padding left and right */
  overwriteStyle?: ThemedCssFunction<DefaultTheme> | FlattenSimpleInterpolation; //* * Optinoal to overwrite styles */
}

const Gallery: FunctionComponent<IGalleryProps> = ({
  children,
  bronze = 1,
  silver = bronze,
  gold = silver,
  platinum = gold,
  diamond = platinum,
  fullWidth,
  overwriteStyle,
}) => 
{
  const numberToColPattern = (val, index, colsMax) => 
  {
    const sizeOfACols = colsMax / val;
    const left = ((sizeOfACols * index) % colsMax) + 1;
    const right = left + sizeOfACols - 1;
    const rowNumber = Math.floor((sizeOfACols * index) / colsMax) + 1;
    return [left, right === 0 ? colsMax : right, rowNumber];
  };
  if(!children) 
  {
    console.warn("We have a empty Gallery Component");
    return <div />;
  }
  if(!Array.isArray(children)) 
  {
    children = [children];
  }
  return (
    <SGallery
      bronze={bronze}
      silver={silver}
      gold={gold}
      platinum={platinum}
      diamond={diamond}
      overwriteStyle={overwriteStyle}
      galleryChildrenLength={children ? children.length : 0}
    >
      <Row fullWidth={fullWidth}>
        {children.map((child, index) => (
          <Col
            key={index}
            bronze={numberToColPattern(bronze, index, COL_AMOUNT.BRONZE)}
            silver={numberToColPattern(silver, index, COL_AMOUNT.SILVER)}
            gold={numberToColPattern(gold, index, COL_AMOUNT.GOLD)}
          >
            {child}
          </Col>
        ))}
      </Row>
    </SGallery>
  );
};

export default Gallery;
