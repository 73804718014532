import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../../constants/mediaquerys";
import {
    C_1366_Bold,
    C_360_Bold,
    C_768_Bold,
    P_1366_Bold,
    P_1366_Regular,
    P_360_Bold,
    P_360_Regular,
    P_768_Bold,
    P_768_Regular,
} from "src/templates/t27/constants/typography";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

const Default = css`
    width: 100%;
    padding: 24px;
    color: var(--back-1);

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;

    &.title {
        background-color: var(--front-2);
    }

    &.price {
        background-color: var(--primary-100);
    }

    > h2 {
        ${P_360_Bold}
    }

    > p {
        ${P_360_Regular}
        align-self: flex-start;
    }

    > a {
        align-self: flex-end;
        margin-left: auto;
        ${C_360_Bold}

        ${CSSWithOpacityHoverAnimation}

        display: flex;
        align-items: center;
        gap: 6px;

        > svg {
            margin-top: 6px;
            width: 22px;
            height: 17px;
        }
    }
`;

const Silver = css`
    > h2 {
        ${P_768_Bold}
    }

    > p {
        ${P_768_Regular}
    }

    > a {
        ${C_768_Bold}

        gap: 10px;
    }
`;

const Gold = css``;

const Platinum = css`
    padding: 38px;
    gap: 38px;

    > h2 {
        ${P_1366_Bold}
    }

    > p {
        ${P_1366_Regular}
    }

    > a {
        ${C_1366_Bold}

        > svg {
            width: 29px;
            height: 21px;
        }
    }
`;

const Diamond = css``;

export const SProductItemT27Card = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
