import React, { FC, Fragment } from "react";
import { ICompanyInformationPublic } from "../../../../../common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";
import { AddressWrapper } from "./Footer.style";

const LinksSeparator: FC = () => <span>|</span>;

const Address: FC<{ companyInformation: ICompanyInformationPublic; items: any[]; impressum: string; contact: string}> = ({ companyInformation, items, impressum, contact }) => (
  <AddressWrapper>
    <div className="managingDirectorWrapper">
      <p><strong>{getContactCompanyNameShort()}</strong></p>
    </div>
    <p>{companyInformation?.street}</p>
    <p>
      {companyInformation?.postcode} {companyInformation?.place}
    </p>
    <div className="addressLinksWrapper">
      Telefon: {companyInformation?.phone} -
      <a style={{ paddingLeft: "5px" }} href={`mailto:${companyInformation?.email}`}> E&#8209;Mail</a>
      <LinksSeparator />
      <a href={`/${impressum || "imprint-datenschutz"}`}>Impressum</a><LinksSeparator />
      <a href={`/${impressum || "imprint-datenschutz"}#Datenschutzerklärung`}>Datenschutzerklärung</a><LinksSeparator />
      <a href={`/${contact}`}>Anfahrt</a>
    </div>
  </AddressWrapper>
);

export default Address;