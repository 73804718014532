import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "./mediaquerys";

const Default = css`
display: none;
`;

const Silver = css`
display: block;
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SSliverWrapper = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
