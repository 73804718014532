// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container } from "../Grid";

export const FooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.textColor};
  color: #aaa;
  width: 100%;
  padding: 42px 10.5px;
  margin: auto;

  ${Container} {
    flex-direction: column;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${Container} {
      flex-direction: row;
    }
  }
`;


export const Link: any = styled.a`
  display: block;
  line-height: 21px;
  padding-bottom: 10px;
`;

export const AddressWrapper: any = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 42px;
  order: 2;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 3 * 2);
    text-align: left;

    display: flex;
    flex-wrap: wrap;

    p {
      width: 50%;

      :last-child {
        padding-bottom: 10.5px;
        display: flex;
        width: 100%;

        a {
          padding-right: 25px;
        }
      }
    }
  }

  p, div, a {
    line-height: 21px;
  }

  p {
    :last-child {
      margin-top: 18px;
      a {
        display: block;
        font-size: 12px;
        line-height: 29px;

        &:hover {
          opacity: .7;
        }
      }
    }
  }

  & > .managingDirectorWrapper {
    display: flex;
    align-items: center;
    p {
      margin-top: 0;
    }
  }

  & > .addressLinksWrapper {
    & > span {
      margin: 0 4px;
    }
  }
`;

export const Award: any = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 42px;
  order: 1;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 3);
    text-align: left;

    display: flex;
    justify-content: flex-end;
    order: 2;

    img {
      height: 80px;
    }
  }
`;

