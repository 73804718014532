import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

const padding = 17;

export const wrapper: any = css`
  max-width: 100%;
  background-color: #000;
  top: 0;
  right: 0;
  height: 0;
  visibility: hidden;
  transition: transform ease-in .45s, box-shadow ease .45s;
  transform: translateY(-100%);
  box-shadow: none;
`;

export const active: any = css`
    transition: transform ease-in-out .45s, box-shadow ease .1s;
    transform: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    visibility: visible;
    height: 100%;
`;

export const burgerMenuWrapper: any = css`
    padding: 10px;
    margin-top: 60px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: ${padding}px;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        padding: 0px 0px 0px ${padding}px;
        margin-top: 0px;
    }
`;
