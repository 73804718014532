import React, { FunctionComponent, useContext, useEffect, useState, Fragment } from "react";
import { SButton } from "./Styles/SButton";
import { SSlider } from "./Styles/SSlider";

export const TheOnlySlider: FunctionComponent<any> = (props) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [activeButton, setActiveButton] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSlide((activeSlide) => (activeSlide < props.children.length - 1 ? activeSlide + 1 : 0));
            setActiveButton((activeButton) => (activeButton < props.children.length - 1 ? activeButton + 1 : 0));
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const handleClick = (activate: number) => {
        setActiveSlide(activate);
        setActiveButton(activate);
    }

    return (
        <SSlider style={props.style}>
            <ul>
                {React.Children.map(props.children, (child, i) => (
                    <Fragment key={i} >
                        <li className={`${i === activeSlide ? "active" : ""}`}>
                            {child}
                        </li>
                    </Fragment>
                ))}
            </ul>
            {
                !props.noDots && <SButton>
                    {React.Children.map(props.children, (child, i) => (
                        <button key={i} className={`${i === activeButton ? "active" : ""}`} onClick={() => handleClick(i)}></button>
                    ))}
                </SButton>
            }
        </SSlider>
    );
};
