import React, { FC, Fragment } from "react";

import { AddressWrapper } from "./Footer.style";

const LinksSeparator: FC = () => <span style={{ fontSize: "20px" }}>|</span>;

const Address: FC<{ items: any[] }> = ({ items }) => (
  <AddressWrapper>
    <LinksSeparator />
    &nbsp;
    {items &&
      items.map((item, i) => {
        const slug = item?.slug;
        const nameInNavigation = item?.nameInNavigation;

        if (!slug || !nameInNavigation) {
          return null;
        }

        return (
          <Fragment key={i}>
            <a href={slug}>{nameInNavigation}</a>
            {i !== items.length - 1 && (
              <>
                &nbsp;
                <LinksSeparator />
                &nbsp;
              </>
            )}
          </Fragment>
        );
      })}
  </AddressWrapper>
);

export default Address;
