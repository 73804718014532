import React, { FC } from "react";
import UniversalComponentsRenderer from "src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import { IPhotoGallery } from "src/common/childTypes";
import { getAnimation } from "src/common/constants/animations";

import EffectPhotoGallery from "./EffectPhotoGallery";
import MasonryPhotoGallery from "./MasonryPhotoGallery";
import MosaicPhotoGallery from "./MosaicPhotoGallery";
import SliderPhotoGallery, { getSlider } from "./SliderPhotoGallery";
import ComparisonPhotoGallery from "./ComparisonPhotoGallery";


export enum IPhotoGalleryVisualization {
  Standard = 'standard',
  Slideshowwithoutthumbnails = 'slideshowwithoutthumbnails',
  Gallerywiththumbnails = 'gallerywiththumbnails',
  Gallerybeforeafter = 'gallerybeforeafter',
  Gallerywithimagedescriptionundermainimages = 'gallerywithimagedescriptionundermainimages',
  Riverimage = 'riverimage',
  Mosaicimage = 'mosaicimage',
  Fadeeffect = 'fadeeffect',
  Flipeffect = 'flipeffect',
  Zoomeffect = 'zoomeffect',
  Offset = 'offset'
}

const getVisualization = (item: any) => {
  switch (true) {
    case (item.visualization === IPhotoGalleryVisualization.Standard || (item.visualization as any) === "Standard") || (item.visualization as any) === "Standard":
      return "standard";
    case (item.visualization === IPhotoGalleryVisualization.Slideshowwithoutthumbnails || (item.visualization as any) === "Slideshowwithoutthumbnails") || (item.visualization as any) === "Slideshow ohne Thumbnails":
      return "slideshowwithoutthumbnails";
    case (item.visualization === IPhotoGalleryVisualization.Gallerywiththumbnails || (item.visualization as any) === "Gallerywiththumbnails") || (item.visualization as any) === "Galerie mit Thumbnails":
      return "gallerywiththumbnails";
    case (item.visualization === IPhotoGalleryVisualization.Gallerybeforeafter || (item.visualization as any) === "Gallerybeforeafter") || (item.visualization as any) === "Galerie vorher/nachher":
      return "gallerybeforeafter";
    case (item.visualization === IPhotoGalleryVisualization.Gallerywithimagedescriptionundermainimages || (item.visualization as any) === "Gallerywithimagedescriptionundermainimages") || (item.visualization as any) === "Galerie mit Bildbeschreibung unter Hauptbildern":
      return "gallerywithimagedescriptionundermainimages";
    case (item.visualization === IPhotoGalleryVisualization.Riverimage || (item.visualization as any) === "Riverimage") || (item.visualization as any) === "Flussbild":
      return "riverimage";
    case (item.visualization === IPhotoGalleryVisualization.Mosaicimage || (item.visualization as any) === "Mosaicimage"  || (item.visualization as any) === "Mosaikbild"):
      return "mosaicimage";
    case (item.visualization === IPhotoGalleryVisualization.Fadeeffect || (item.visualization as any) === "Fadeeffect") || (item.visualization as any) === "Fade-Effekt":
      return "fadeeffect";
    case (item.visualization === IPhotoGalleryVisualization.Flipeffect || (item.visualization as any) === "Flipeffect") || (item.visualization as any) === "Flip-Effekt":
      return "flipeffect";
    case (item.visualization === IPhotoGalleryVisualization.Zoomeffect || (item.visualization as any) === "Zoomeffect") || (item.visualization as any) === "Zoom-Effekt":
      return "zoomeffect";
    case (item.visualization === IPhotoGalleryVisualization.Offset || (item.visualization as any) === "Offset") || (item.visualization as any) === "Versatz":
      return "offset";
    default:
      return "standard";
  }
};

const PhotoGallery: FC<{ item: IPhotoGallery; columns?: number }> = ({
  item,
  columns,
  children,
  ...rest
}) => {
  // !! this is causing a recusive render loop and will cause a OOM crash
  // if ((rest as any).__typename === "PhotoGallery"){
  //   item = rest;
  //   columns = (rest as any).maxCountPerRow || 1;
  // }
  
  if (!item) {
    return null;
  }
  const visualization = getVisualization(item);
  (item.visualization as string) = visualization;

  const animation = getAnimation(visualization);
  if (animation) {
    return <EffectPhotoGallery item={item} columns={columns} />;
  }

  const slider = getSlider(visualization);
  if (slider) {
    return <SliderPhotoGallery item={item} />;
  }

  if (visualization === "mosaicimage") {
    return <MosaicPhotoGallery item={item} columns={columns} />;
  }

  if (visualization === "riverimage") {
    return <MasonryPhotoGallery item={item} columns={columns} />;
  }

  if (visualization === "gallerybeforeafter") {
    return <ComparisonPhotoGallery item={item} />;
  }
  // !! this preventing from endless rerender of UniversalComponentsRenderer
  if((item as any).__typename === "PhotoGallery"){
    return null;
  }

  return (
    <UniversalComponentsRenderer
      items={[item]}
      key="Universal-renderer-photogallery"
    />
  );
};

PhotoGallery.displayName = "PhotoGallery";

export default PhotoGallery;
