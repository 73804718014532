
import React, { FunctionComponent, useContext, useState, useEffect } from "react";
import { Context, IContext } from "src/common";

import { HeaderContainer, Logo, NavigationContainer} from "./Header.styles";
import Navigation from "./Navigation/Navigation";

const Header: FunctionComponent = () => 
{
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  const [isScrolling, toogleScrolling] = useState(false);

  useEffect(() => 
  {
    window.onscroll = () => handleAnimation();
  });

  const handleAnimation: any = () => 
  {
    if(document.documentElement.scrollTop < 50)
    {
      toogleScrolling(false);
    }
    if(document.documentElement.scrollTop > 50)
    {
      toogleScrolling(true);
    }
  };
  return (
    <>
      <HeaderContainer isScrolling={isScrolling}>
        <NavigationContainer>
          {logo && <a href="/"><Logo src={logo.src} /></a>}
          <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} isScrolling={isScrolling}/>
        </NavigationContainer>
      </HeaderContainer>
    </>
  );
};

export default Header;
