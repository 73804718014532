import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import Img from "../../../BaseComponents/Img/Img";
import { Context, IAsset } from "src/common";
import { TheOnlySlider } from "src/common/components/TheOnlySlider";
import { SlickSlider } from "../../common/SlickSlider/Slider";
import { SSlickWrapper } from "./Styles/SSlickWrapper";
import { SSliderWrapper } from "./Styles/SSliderWrapper";
export interface ISlider {
    elements: IAsset[];
    index: number;
}

const Slider: FunctionComponent<ISlider> = (props) => {
    const c = useContext(Context)
    const isNotLanding = !!c.slug;

    const els = props?.elements.filter((e) => !!e?.src);
    return (
        // <SSlickWrapper>
        //     <SlickSlider elements={els}>

        //     </SlickSlider>
        // </SSlickWrapper>
        <SSliderWrapper style={{ minHeight: isNotLanding ? "500px" : undefined, borderBottom: (!isNotLanding && props.index == 0) ? 'none' : undefined,  marginBottom: (!isNotLanding && props.index == 0) ? '0' : undefined  }}>
            <TheOnlySlider noDots={true} style={{ minHeight: isNotLanding ? "500px" : undefined, borderBottom: (!isNotLanding && props.index == 0) ? 'none' : undefined}}>
                {els.map((element, i) => (
                    <div key={`sldele${i}`}>
                        <Img quality={900} src={element?.src} alt={element.description ?? "image"} />
                    </div>
                ))}
            </TheOnlySlider>
        </SSliderWrapper>
    );
};

Slider.displayName = "Slider";

export default Slider;
