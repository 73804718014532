import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Context, getPage, ITextElement, RichText } from "src/common";
import { convertHex, rgba2hex } from "../../../../../common/utils/colorHelper";
import { richToPlainText } from "../../../../../common/utils/text/plaintext";
import { Snowparticles } from "src/common/components/Snowparticles";
import {
  LandingEvent,
  eLandingEventLocation,
} from "../../../components/pages/PageLanding/landingEvent/LandingEvent";
import { centeredContent } from "../../../constants/layout";
import ScrollIndicator from "../ScrollIndicator/ScrollIndicator";
import * as styles from "./LandingImage.style";
import { Heading } from "./LandingImage.style";
interface IProps {
  img: string;
}

const LandingImage: FunctionComponent<IProps> = (props) => {
  const [hideOnScroll, setHideOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
      }
    },
    [hideOnScroll],
    null,
    false,
    50
  );

  const p = useContext(Context);

  const landingEvents = p?.PageLanding?.events;
  const palette: any = p?.Settings;
  const headlineWithoutBackground = !!palette?.headlineWithoutBackground;
  const isKw: any = p?.CompanyInformationPrivate.line == "kw";

  let background: any = "transparent";

  if (palette.themeColorPrimary) {
    try {
      if (isKw) {
        background = palette.themeColorPrimary;
      } else {
        let c = palette.themeColorPrimary;
        if (c.indexOf("rgb") != -1) {
          c = "#" + rgba2hex(c);
        }
        // console.log(` palette.themeColorPrimary`, c);
        background = convertHex(c.toUpperCase(), 0.5);
      }
    } catch (err) {
      console.log(`hexToRgba err`, err);
    }
  }

  // @ts-ignore
  const textElements = (!p.slug ? p.PageLanding : getPage(p))?.elements?.filter(
    (e) => e?.__typename === "TextElement"
  ) as ITextElement[];

  return (
    <div css={styles.wrapper} className="t3-landing-header-area">
      <div>
        <ScrollIndicator active={hideOnScroll} />
      </div>
      <div
        className="t3-landing-image"
        id={
          p?.slug == null && p.Settings?.snowOnHomeImage
            ? `snowflakes-container`
            : undefined
        }
        css={styles.img}
        style={{ backgroundImage: `url(${props?.img})` }}
      />
      {p?.slug == null && p.Settings?.snowOnHomeImage ? (
        <Snowparticles />
      ) : null}
      <div
        css={`
          ${centeredContent}${styles.contentWrapper}
        `}
      >
        <Heading
          headlineWithoutBackground={headlineWithoutBackground}
          themeColorSecondary={palette.themeColorSecondary}
          textColor={palette.themeTextColourIsland}
          background={background}
          isKw={isKw}
        >
          {textElements?.[0]?.headline && (
            <h1>{textElements?.[0]?.headline}</h1>
          )}
          {!!textElements?.[0]?.text &&
          richToPlainText(textElements?.[0]?.text)?.trim() != "" ? (
            <RichText textContent={textElements?.[0]?.text}></RichText>
          ) : null}
        </Heading>
        <div>
          <LandingEvent
            className="landingevent"
            event={landingEvents?.[0]}
            type={eLandingEventLocation.landingImage}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingImage;
