import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css``;

const Silver = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
`;

const Gold = css``;

const Platinum = css`
    gap: 76px;
`;

const Diamond = css``;

export const SOffsetGalleryColumn = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
