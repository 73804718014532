import React, { FC } from "react";

import * as styles from "./ModalImageHide.style";
import { IAsset, Img } from "src/common";

interface IProps {
  img: IAsset[];
  subtitle: string;
  quality?: any;
  lazyload?: boolean;
  noWrapper?: boolean;
  id?: string;
}

const Inner: FC<IProps> = (props) => {
  const imgClass = `img_${props.id}`;

  return (
    <>
      <Img
        // @ts-ignore
        className={imgClass}
        src={props.img}
        quality={props?.quality || 1920}
        alt={props.subtitle}
        lazyload={props.lazyload}
      />
    </>
  );
};

export const ModalImageHide: FC<IProps> = (props) => {
  if (props.noWrapper) return <Inner key={props.id} {...props} />;

  return (
    <div css={!props.noWrapper ? styles.img : null} data-hidemodal-image>
      <Inner key={props.id} {...props} />
    </div>
  );
};
