import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/mediaquerys";
import { IPropsCol } from "../Col";

const generateColCss = (val) => {
  const row =
    val && !isNaN(val[2])
      ? css`
          -ms-grid-row: ${val[2]};
          grid-row: ${val[2]};
        `
      : css`
          -ms-grid-row: 1;
          grid-row: 1;
        `;
  if (Array.isArray(val)) {
    val = [val[0], val[1]];
    return css`
      -ms-grid-column: ${val[0]};
      -ms-grid-column-span: ${val[1] + 1 - val[0]};
      display: block;
      grid-column: ${val[0]} / ${val[1] + 1};
      ${row};
    `;
  }
  if (val && !isNaN(val)) {
    return css`
      ${val ? `grid-column: ${1}/${val + 1}` : ""};
      -ms-grid-column: ${1};
      -ms-grid-column-span: ${val};
      ${row};
      display: block;
    `;
  }
  if (val === 0) {
    return css`
      display: none;
    `;
  }
};
const generateOrder = (order) =>
  css`
    ${order ? `order: ${order}; ms-order: ${order};` : ""}
  `;

const Default = css`
  ${(props: IPropsCol) => generateColCss(props.bronze)};
  ${(props: IPropsCol) => generateOrder(props.orderBronze)};
`;

const Silver = css`
  ${(props: IPropsCol) => generateColCss(props.silver)};
  ${(props: IPropsCol) => generateOrder(props.orderSilver)};
`;

const Gold = css`
  ${(props: IPropsCol) => generateColCss(props.gold)};
  ${(props: IPropsCol) => generateOrder(props.orderGold)};
`;

const Platinum = css`
  ${(props: IPropsCol) => generateColCss(props.platinum)};
  ${(props: IPropsCol) => generateOrder(props.orderPlatinum)};
`;

const Diamond = css`
  ${(props: IPropsCol) => generateColCss(props.diamond)};
  ${(props: IPropsCol) => generateOrder(props.orderDiamond)};
`;

const SCol = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SCol;
