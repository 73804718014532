import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Container: any = styled.div<{ rows: boolean }>`
  display: flex;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto;
  padding: 0 10px;;

  ${({ rows }) => rows && "flex-direction: column;"}

  @media (${BREAKPOINTS_DM.silver_768}){
  }
`;

export const ContainerLarge: any = styled(Container)`

  @media (${BREAKPOINTS_DM.silver_768}){
    ${Container} {
      display: block;
    }
  }
`;

export const Section: any = styled.section`
  width: 100vw;
  padding-top: 130px;
  @media (${BREAKPOINTS_DM.silver_768}){
    padding-top: 70px;
  }
`;
