import React, { AnchorHTMLAttributes, FunctionComponent } from "react";
import { SPageItemT27 } from "./styles/SPageItemT27";
import {
    IGenAsset,
    IGenContinuingPageGalleryItem,
    IGenExternalLink,
    IGenPage,
    IGenTextElement,
} from "src/common/types_dealer_gen";
import { SPageItemT27Title } from "./styles/SPageItemT27Title";
import { SPageItemT27ImageWrapper } from "./styles/SPageItemT27ImageWrapper";
import { FancyImage } from "src/common/BaseComponents/FancyImage/FancyImage";
import { SPageItemT27TextWrapper } from "./styles/SPageItemT27TextWrapper";
import { RichText } from "src/common/components/RichText";
import { truncateText } from "src/common";
import Link from "next/link";
import ArrowLinkIcon from "src/common/icons/ArrowLinkIcon";
import { SSliverWrapper } from "src/common/constants/SSliverWrapper";
import { SBronzeWrapper } from "src/common/constants/SBronzeWrapper";
import { SPageItemT27Link } from "./styles/SPageItemT27Link";

const LinkComponent = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <SPageItemT27Link {...props}>
        Mehr&nbsp;
        <ArrowLinkIcon />
    </SPageItemT27Link>
);

const PageItemT27: FunctionComponent<{ item: IGenContinuingPageGalleryItem; itemsPerRow: number }> = ({
    item,
    itemsPerRow,
}) => {
    const link = item?.link?.[0] as IGenPage | IGenExternalLink;
    const image = item?.thumbnail?.[0] as IGenAsset;
    const firstRichTextAtPage =
        link?.__typename !== "ExternalLink" &&
        (link?.elements?.find((e) => e?.__typename === "TextElement") as IGenTextElement)?.text;
    const linkName = link?.__typename !== "ExternalLink" ? link?.nameInNavigation : link?.externalLinkName;

    const Title = (item.title || linkName) && <SPageItemT27Title>{item.title || linkName || ""}</SPageItemT27Title>;

    if (!item) return null;

    const Content = (
        <SPageItemT27>
            {!image?.src ? Title : <SSliverWrapper>{Title}</SSliverWrapper>}
            {image?.src && (
                <SPageItemT27ImageWrapper>
                    <FancyImage
                        img={image}
                        responsiveAspectRatio={{ bronze: 312 / 234 }}
                        responsiveFactor={{ bronze: 1, silver: 1 / itemsPerRow }}
                        responsiveMaxWidth={{ platinum: 1366 / itemsPerRow }}
                    />
                    <SBronzeWrapper>{Title}</SBronzeWrapper>
                </SPageItemT27ImageWrapper>
            )}
            {(item.text || firstRichTextAtPage) && (
                <SPageItemT27TextWrapper>
                    {item.text ? (
                        <RichText textContent={item.text} fragment />
                    ) : (
                        <p>{truncateText(firstRichTextAtPage, 150)}</p>
                    )}
                </SPageItemT27TextWrapper>
            )}
            {link?.__typename !== "ExternalLink" ? (
                link?.slug && (
                    <Link href={`${link.slug}`} passHref>
                        <LinkComponent />
                    </Link>
                )
            ) : (
                <LinkComponent href={`${link.externalLinkAddress}`} target="_blank" rel="noopener noreferrer" />
            )}
        </SPageItemT27>
    );

    if (!link) return <>{Content}</>;
    
    return (
        <>
            {link.__typename !== "ExternalLink" ? (
                <Link href={`${link.slug}`} passHref>
                    <a>{Content}</a>
                </Link>
            ) : (
                <a href={`${link.externalLinkAddress}`} target="_blank" rel="noopener noreferrer">
                    {Content}
                </a>
            )}
        </>
    );
};

export default PageItemT27;
