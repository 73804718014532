import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css`
    padding-bottom: 48px;
`;

const Silver = css`
    max-width: calc(((100vh - 82px) * 1.7777777777777778) - 72px - 72px - 40px);
    margin: 0 auto;
`;

const Gold = css``;

const Platinum = css`
    padding-bottom: 76px;
`;

const Diamond = css``;

export const SSliderWithFocalPoint = styled.div<any>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
