import React, { FunctionComponent, Fragment } from "react";
import { Img } from "src/common";
import { IAsset } from "src/common/rootTypes";

import RichText from "../../../BaseComponents/RichText/RichText";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import { bigHeading } from "../../../constants/textStyles";
import * as styles from "./TextElement.style";

export interface ITextElement 
{
  text?: string;
  headline?: string;
  index: number;
  icon: IAsset[];
}

const TextElement: FunctionComponent<ITextElement> = props => 
{
  if(!props.headline && !props.text) 
  {
    return null;
  }

  let cssClass;

  if(props.index === 0) 
  {
    cssClass = styles.firstChild;
  }
  else if(props.index % 2 === 1) 
  {
    cssClass = styles.odd;
  }
  else 
  {
    cssClass = styles.even;
  }

  return (
    <div css={`${styles.wrapper}${contentWrapper}${cssClass}`}>
      <div css={centeredContent}>
        {props.headline && <h2 css={`${bigHeading}${styles.headline}`}>{props.headline}</h2>}
        {props?.text && (
          <RichText textContent={props.text} overwriteStyle={styles.richtext} />
        )}
        {props?.icon?.[0] && (
          <div css={styles.imageInBox}>
            {props?.icon.map((f, i) => 
              <Fragment key={`fi${i}`}>
                <figure css={styles.figureWrapper}>
                  <Img
                    quality={700}
                    src={f?.src}
                    alt={f?.description || "icon"}
                  />
                  {f.title && <figcaption>{f.title}</figcaption>}
                </figure>
              </Fragment>)}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextElement;
