import Head from 'next/head'
import S404 from 'src/common/components/404/Styles/S404'

export default function Custom404(props: any) {
    return (
        <S404>
            <Head>
                <title> 404 | Die Seite konnte nicht gefunden werden </title>
            </Head>
            <h1>
                404 | Die Seite konnte nicht gefunden werden
            </h1>
        </S404>
    )
}