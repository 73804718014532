import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";
import { P_1366_Regular, P_360_Regular, P_768_Regular } from "src/common/brand-area/constantsOverwrites/typography";

const Default = css`
    width: 100%;
    padding-bottom: 48px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    .thumbnail__image-wrapper{
        float: left;
    }

    .thumbnail__image-wrapper__inner {
        width: calc((100vw - 24px - 24px - 60px) / 6);
        height: calc((100vw - 24px - 24px - 60px) / 6);
        overflow: hidden;
    }

    .slider {
        .slider__image-wrapper {
            .image-wrapper__description {
                margin-top: 12px;
                ${P_360_Regular}
                color: var(--front-2);
                word-wrap: break-word;
            }
        }
    }

    .thumbnail {
        .slick-track {
            display: flex;
            > div:not(:last-child) {
                margin-right: 12px;
            }

            > div:not(.slick-current) {
                cursor: pointer;
                .thumbnail__image-wrapper__inner {
                    position: relative;
                    &::before {
                        content: "";
                        display: block;
                        width: 100%;
                        position: absolute;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: rgba(217, 217, 217, 0.6);
                        z-index: 3;
                        transition: background 0.3s ease;
                    }

                    &:hover {
                        &::before {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
`;

const Silver = css`
    max-width: calc(((100vh - 82px) * 1.7777777777777778) - 72px - 72px - 40px);
    margin: 0 auto;
    .slider {
        .slider__image-wrapper {
            .image-wrapper__description {
                ${P_768_Regular}
            }
        }
    }

    .thumbnail__image-wrapper__inner {
        width: calc((100vw - 24px - 24px - 60px) / 6);
        height: calc((100vw - 24px - 24px - 60px) / 6);
        overflow: hidden;
    }

`;

const Gold = css``;

const Platinum = css`
    padding-bottom: 76px;
    gap: 18px;

    .slider {
        .slider__image-wrapper {
            .image-wrapper__description {
                margin-top: 18px;
                ${P_1366_Regular}
            }
        }
    }

    .thumbnail {
        .slick-track {
            > div:not(:last-child) {
                margin-right: 18px;
            }
        }
    }

    .thumbnail__image-wrapper__inner {
        width: calc((min(100vw, 1366px) - 90px) / 6);
        height: calc((min(100vw, 1366px) - 90px) / 6);
        overflow: hidden;
    }

`;

const Diamond = css``;

export const SThumbnailSlider = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
