import React, { useState, useEffect, useRef, FC } from "react";
import Slider, { Settings } from "react-slick";

import { BREAKPOINTS } from "src/common/constants/mediaquerys";
import SSlickBase from "src/common/BaseComponents/SliderWithFocalPoint/styles/SSlickBase";
import SSlickTheme from "src/common/BaseComponents/SliderWithFocalPoint/styles/SSlickTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { IGenAsset } from "src/common/types_dealer_gen";
import { FancyImage } from "src/common/BaseComponents/FancyImage/FancyImage";
import { SThumbnailSlider } from "./SThumbnailSlider";

interface IThumbnailSliderProps {
    elements?: IGenAsset[];
    shouldHideThumbnailSlider?: boolean;
}

const ThumbnailSlider: FC<IThumbnailSliderProps> = ({ elements, shouldHideThumbnailSlider }) => {
    const [sliderEl, setSliderEl] = useState<Slider | null>(null);
    const [thumbnailEl, setThumbnailEl] = useState<Slider | null>(null);

    const sliderRef = useRef<Slider>(null);
    const thumbnailRef = useRef<Slider>(null);

    useEffect(() => {
        setSliderEl(sliderRef.current);
        setThumbnailEl(thumbnailRef.current);
    }, []);

    const STANDARD_SETTINGS: Settings = {
        dots: false,
        nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
        prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        autoplay: true,
        responsive: [
            {
                breakpoint: BREAKPOINTS.SILVER - 1,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    const bronzeSlidesToShow = elements?.length >= 6 ? 6 : elements?.length;
    const silverSlidesToShow = elements?.length >= 9 ? 9 : elements?.length;

    const THUMB_SETTINGS: Settings = {
        slidesToShow: silverSlidesToShow,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        focusOnSelect: true,
        draggable: true,
        responsive: [
            {
                breakpoint: BREAKPOINTS.SILVER - 1,
                settings: {
                    slidesToShow: bronzeSlidesToShow,
                },
            },
        ],
    };

    return (
        <SThumbnailSlider className="thumbnail-slider">
            <div
                css={`
                    ${SSlickBase}${SSlickTheme}
                `}
                className="slider"
            >
                <Slider {...STANDARD_SETTINGS} asNavFor={thumbnailEl} ref={sliderRef}>
                    {elements?.map((element, i) => (
                        <div key={`image-${i}`} className="slider__image-wrapper">
                            <FancyImage
                                img={element}
                                responsiveMaxWidth={{ platinum: 1366 }}
                                responsiveAspectRatio={{ bronze: 360 / 312, silver: 720 / 405, platinum: 1214 / 683 }}
                            />
                            {element?.description && (
                                <p className="image-wrapper__description">{element.description}</p>
                            )}
                        </div>
                    ))}
                </Slider>
            </div>
            {!shouldHideThumbnailSlider && (
                <div
                    css={`
                        ${SSlickBase}
                        ${SSlickTheme}
                    `}
                    className="thumbnail"
                >
                    <Slider {...THUMB_SETTINGS} asNavFor={sliderEl} ref={thumbnailRef}>
                        {elements?.map((element, i) => {
                            if (!elements[i]){
                                return null;
                            }
                            return (
                                <div key={`thumnail-image-${i}`} className="thumbnail__image-wrapper">
                                    <div className="thumbnail__image-wrapper__inner">
                                        <FancyImage
                                            img={element}
                                            responsiveMaxWidth={{ platinum: 1366 / silverSlidesToShow }}
                                            responsiveAspectRatio={{ bronze: 1 }}
                                            responsiveFactor={{
                                                bronze: 1 / bronzeSlidesToShow,
                                                silver: 1 / silverSlidesToShow,
                                            }}
                                        />
                                    </div>
                                </div>
                            )})}
                    </Slider>
                </div>
            )}
        </SThumbnailSlider>
    );
};

export default ThumbnailSlider;
