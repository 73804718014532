export const GEDK_LINES: {
  short: string;
  domain: string;
  name: string;
  id: number;
  legacyId?: number;
  cobraId: number;
}[] = [
  {
      cobraId: 101,
      short: "none",
      domain: "gedk.de",
      name: "GEDK",
      id: 10,
  },
  {
      cobraId: 2,
      short: "dk",
      domain: "dk-das-kuechenhaus.de",
      name: "dk das küchenhaus",
      id: 1,
      legacyId: 1,
  },
  {
      cobraId: 3,
      short: "gelectra",
      domain: "gelectra.com",
      name: "Gelectra",
      id: 2,
      legacyId: 0,
  },
  {
      cobraId: 9,
      short: "gk",
      domain: "gk-gute-kuechen.de",
      name: "GK Gute Küchen",
      id: 3,
      legacyId: 6,
  },
  {
      cobraId: 10,
      short: "ihs",
      domain: "ihr-hausgeraetespezialist.de",
      name: "Ihr Hausgerätespezialist",
      id: 4,
      legacyId: 7,
  },
  {
      cobraId: 4,
      short: "kg",
      domain: "kuechengilde.de",
      name: "Küchengilde",
      id: 5,
      legacyId: 5,
  },
  {
      cobraId: 11,
      short: "kgp",
      domain: "kuechen-geraete-partner.de",
      name: "Küchen.Geräte.Partner.",
      id: 6,
      legacyId: 7,
  },
  {
      cobraId: 5,
      short: "kw",
      domain: "kuechenwerkstatt.de",
      name: "kw küchenwerkstatt",
      id: 7,
      legacyId: 4,
  },
  {
      cobraId: 12,
      short: "pk",
      domain: "perfekte-kuechen.de",
      name: "Perfekte Küchen",
      id: 8,
      legacyId: 9,
  },
  {
      cobraId: 13,
      short: "kuechenwelten",
      domain: "www.kuechen-welten.de",
      name: "Kuechen Welten",
      id: 9,
  },
  {
      cobraId: 15,
      short: "gsp",
      domain: "geraete-servicepartner.de",
      name: "GSP Geräte Servicepartner",
      id: 24,
  },
  {
      cobraId: 14,
      short: "ksp",
      domain: "kuechen-servicepartner.de",
      name: "KSP Küchen Servicepartner",
      id: 23,
  },
  {
      cobraId: 51489136,
      short: "kd",
      domain: "kd-kuechen-digital.de",
      name: "Küchen Digital",
      id: 11,
  },
];
