import * as React from "react";
import { FunctionComponent } from "react";

import SLink from "./SLink";

interface IProps
{
  href: string | undefined;
  className: string;
  target?: string;
}

export const BannerLink: FunctionComponent<IProps> = props => <SLink target={props?.target} href={props?.href} isVisible={!!props?.href} className={props?.className}>{props.children ?? null}</SLink>;
