import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";
import { P_1366_Regular, P_360_Regular, P_768_Regular } from "src/common/brand-area/constantsOverwrites/typography";

interface IProps {}

const Default = css<IProps>`
    width: 100%;
    max-height: calc(100vh - 70px);

    .slider {
        &__image-wrapper {
            > div {
                max-height: calc(100vh - 100px);
                max-width: calc(100% - 110px);
                margin: 0 auto;

                .layer-z1 {
                    display: none;
                }

                img {
                    object-fit: contain;
                }
            }

            .image-wrapper__description {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 12px;
                min-height: 28px;
                ${P_360_Regular}
                color: #f9f9f9;
                word-wrap: break-word;
            }
        }
    }
`;

const Silver = css`
    .slider {
        &__image-wrapper {
            .image-wrapper__description {
                ${P_768_Regular}
            }
        }
    }
`;

const Gold = css``;

const Platinum = css`
    max-height: calc(100vh - 125px);

    .slider {
        &__image-wrapper {
            > div {
                max-height: calc(100vh - 175px);

                img {
                    object-fit: contain;
                }
            }

            .image-wrapper__description {
                min-height: 50px;
                margin-top: 18px;
                ${P_1366_Regular}
                font-size: 18px;
            }
        }
    }
`;

const Diamond = css``;

export const SFullScreenSliderWithFancyImage = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
