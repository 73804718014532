import React, { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

import { Container } from "../Grid";

const HEIGHT = "130px";
const LOGO_WIDTH = "135px";

export const BurguerIcon: any = styled.div`
  height: 36px;
  width: 57px;
  background-image: url("/images/sprite-s29f1189ba8.png");
  background-position: 0px -146px;
  background-repeat: no-repeat;
  position: absolute;
  top: calc(${HEIGHT} / 2 - 36px / 2);
  cursor: pointer;
  z-index: 999;
  left: 10px;
`;

export const HaderContainer = styled.div`
  top: 0;
  left: 0;
  width: calc(100vw - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 999;

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: absolute;

    ${Container} {
      padding: 0 90px;
      position: relative;
      align-items: center;
    }
  }
`;

export const LogoLink: any = styled.a`
  display: block;
  position: absolute;
  top: 10px;
  width: 70px;
  height: 70px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    top: 70px;
    left: 150px;
    width: 100px;
    height: 100px;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 135px;
    height: 135px;
  }
`;

export const Logo = styled.img`
  display: block;
  margin-left: 10px;
  width: 100%;
  height: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-left: unset;
  }
`;

export const LogoText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.palette.white};
  padding-top: 10px;
  font-size: 15px;
  line-height: 16px;
  margin-left: 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    font-size: 19px;
    line-height: 20px;
    margin-left: unset;
  }
`;

export const NavigationLink: any = styled.li<{ active: boolean }>`
  border-bottom: 1px solid white;

  > a {
    color: ${({ active, theme }) =>
      active ? theme.palette.textColor : theme.palette.white};
    display: flex;
    font-weight: 700;
    font-size: 18px;
    padding-left: 10.5px;
    background-color: ${({ theme }) => theme.palette.primary};
    line-height: calc(${HEIGHT} / 2);
    width: 100%;
    margin-bottom: 2px;

    @media (${BREAKPOINTS_DM.silver_768}) {
      font-size: 15px;
      line-height: unset;
      padding-left: 10.5px;
      padding-right: 11.5px;
      padding-top: 2px;
      padding-bottom: 2px;

      background-color: transparent;
      wdith: unset;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    border-bottom: none;
    border-right: 1px solid white;

    :first-child {
      border-left: 1px solid white;
    }
  }
`;

export const Icon: any = styled.img``;

export const NavigationLinks = styled.div`
  // position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: ${HEIGHT} 0 0 5px;

  transform: translate3d(70vw, 0, 0);
  transition: transform 0.8s ease;
  width: 70vw;
  height: 100vh;
  box-shadow: -2px -2px 5px rgba(50, 50, 50, 0.5);
  background-color: ${({ theme }) => theme.palette.primary};

  ${BurguerIcon} {
    display: none;
    left: 0;
  }

  ul {
    list-style: none;
    overflow-y: auto;
    height: calc(100vh - ${HEIGHT});
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;
    display: flex;
    transform: translate3d(0, 0, 0);
    width: 100%;
    left: 5%;
    top: -1px;
    height: unset;
    padding: 0.5em 0;
    box-shadow: none;
    align-items: center;
    ${NavigationLink} {
      display: flex;
    }

    ${BurguerIcon} {
      display: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      height: unset;
    }
  }
`;

export const NavigationContainer: any = styled.div<{ active: boolean }>`
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  max-height: 120px;
  z-index: 9999;
  ${({ active }) =>
    active
      ? `
      width: 70vw;
      ${NavigationLinks} {
        ${BurguerIcon} {
          display: block;
        }
        transform: translate3d(0,0,0);
      }

      ${BurguerIcon} {
        display: none;
      }
    `
      : `
      width: 77px;
    `}

  @media (${BREAKPOINTS_DM.silver_768}) {
    background-color: ${({ theme }) => theme.palette.primary};
    position: fixed;
    z-index: 9999;
    width: 960px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 60px;
    ${BurguerIcon} {
      display: none;
    }
  }
`;

export const SubNavigationLinks: any = styled.div<{ active: boolean }>`
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }

  > a {
    color: ${({ theme }) => theme.palette.white};
    display: flex;
    font-weight: 700;
    font-size: 15px;
    padding-left: 10.5px;
    background-color: ${({ active, theme }) =>
      active ? theme.palette.primary : theme.palette.bgInverted};
    line-height: 31px;
    width: 100%;
    margin-bottom: 2px;
  }
`;
