import React, { FunctionComponent, useContext } from "react";
import { Context, LogoBannerHorizontal } from "src/common";
import { LineLogo } from "../../../../../common/components/LineLogo";

import FurtherAwards from "../FurtherAwards";
// import { LogoBannerHorizontal } from "../LogoBannerHorizontal";
import Address from "./Address";
import { FooterWrapper, ContentSide, ContentHead, Title, FooterBannerWrapper } from "./Footer.style";

interface IFooterLinkInColumnList 
{
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => 
{
  const props = useContext(Context);
  const allFooterLinksForUpperWrapper: IFooterLinkInColumnList[] = [];
  const companyInformation = props.CompanyInformationPublic;
  const footerNavItems = props.Footer?.Navigationspunkte || [];
  const impressum = props.PageImprint || {};

  for(const item of props.PrimaryNavigation?.items || []) 
  {
    if(item?.label && item?.link?.[0]?.slug) 
    {
      allFooterLinksForUpperWrapper.push({
        title: item.label || item?.link?.[0]?.nameInNavigation,
        link: item?.link?.[0]?.slug,
        isBold: true
      });
    }
  }

  return (
    <>
      <FurtherAwards />
      <FooterWrapper>
        {/* footer top */}
        <ContentHead>
          {/* left side */}
          <ContentSide>
            <div className="content">
              <ContentSide withOutPaddingTop>
                <div className="column">
                  <Title><h3>Empfohlen von</h3></Title>
                  <img src={"/images/kompass_inverted.png"} alt={"Küchenkompass"} />
                </div>
              </ContentSide>
              <ContentSide withOutPaddingTop>
                <div className="column">
                  <Title><h3>Partner von</h3></Title>
                 <LineLogo/>
                </div>
              </ContentSide>
            </div>
          </ContentSide>

          {/* right side */}
          <ContentSide>
            <Title><h3>Unsere Marken</h3></Title>
            <FooterBannerWrapper>
            <LogoBannerHorizontal/>
            </FooterBannerWrapper>
          </ContentSide>
        </ContentHead>
      
        {/* footer bottom */}
        <Address companyInformation={companyInformation} items={footerNavItems} impressum={impressum}/>
      </FooterWrapper>
    </>
  );
};

export default Footer;
