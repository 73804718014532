import React, { FC, Fragment } from "react";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";
import { AddressWrapper } from "./Footer.style";

const LinksSeparator: FC = () => <span>|</span>;

const Address: FC<{ companyInformation: any }> = ({ companyInformation }) => (
  <AddressWrapper>
    <h4>Kontakt</h4>
    <p>{getContactCompanyNameShort()}</p>
    <p>{companyInformation?.street}</p>
    <p>
      {companyInformation?.postcode} {companyInformation?.place}
    </p>
    <br />
    <p>
      Telefon: {companyInformation?.phone}
    </p>
    <p>
      E&#8209;Mail: <a href={`mailto:${companyInformation?.email}`}>{companyInformation?.email}</a>
    </p>
    <br />
    <p>
      <a href="/imprint-datenschutz">Impressum </a> <br/>
      <a href="/imprint-datenschutz#dataprivacy">Datenschutzerklärung</a>
    </p>
  </AddressWrapper>
);

export default Address;