import React, { FC, Fragment } from "react";
import { HtmlEmbedding } from "../../components/HtmlEmbedding/HtmlEmbedding";
export type IElementFactory = (string) => FC;

interface IUniversalComponentsRendererProps {
    items: any[];
    children?: any;
    factory?: IElementFactory;
    shouldRenderNewPhotoGallery?: boolean;
}

const factoryDefault: IElementFactory = (typename: string) => null;
const internalFactory = (factory: IElementFactory, typename: string, fallback: FC): FC => factory(typename) || fallback;

const UniversalComponentsRenderer = ({
    items,
    children,
    factory = factoryDefault,
    shouldRenderNewPhotoGallery,
}: IUniversalComponentsRendererProps): React.ReactElement => (
    <Fragment>
        {items &&
            items.map((item, key) => {
                try {
                    if (`${item?.__typename}`.includes("UnknownSlice")) {
                        return null;
                    }
                    if (`${item?.__typename}` == "HtmlEmbedding") {
                        return <HtmlEmbedding key={key} {...item} />;
                    }

                    if (item?.__typename == "PhotoGallery" && (item as any)?.visualization == "riverimage") {
                        shouldRenderNewPhotoGallery = true;
                    }

                    const MountedComponent =
                        item?.__typename == "PhotoGallery"
                            ? internalFactory(
                                  factory,
                                  item?.__typename,
                                  require(
                                      `src/common/components/${shouldRenderNewPhotoGallery ? "PhotoGallery2024/PhotoGallery2024" : "PhotoGalleryVisualization/PhotoGallery"}`
                                  ).default
                              )
                            : internalFactory(
                                  factory,
                                  item?.__typename,
                                  require(`../../components/elements/${item?.__typename}/${item?.__typename}`).default
                              );

                    let isStickedToComponentAbove = false;
                    if (key >= 1 && items[key - 1]?.__typename === "Asset") {
                        isStickedToComponentAbove = true;
                    }
                    if (key >= 1 && items[key - 1]?.__typename === "Slider") {
                        isStickedToComponentAbove = true;
                    }

                    return (
                        <MountedComponent
                            key={`${key}-${item?.__typename}`}
                            item={item}
                            {...item}
                            isStickedToComponentAbove={isStickedToComponentAbove}
                            index={key}
                        >
                            {children}
                        </MountedComponent>
                    );
                } catch (e) {
                    console.error(item?.__typename, e);
                    return null;
                }
            })}
    </Fragment>
);

export default UniversalComponentsRenderer;
