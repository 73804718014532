import { css } from "styled-components";

export const imageWrapper: any = css`
    width: 40%;
    float: left;
    padding-right: 30px;

    div > img{
      width: 100%;
      height: auto;
    }
    p{
      font-style: italic;
    }
`;
export const wrapper: any = css`
    width: 100%;
    padding: 0 !important;
    &:before {
      border-bottom-color: ${props => props.theme.secondary};;
    }
    > div {
      padding: 27px;
      border: solid 1px ${props => props.theme.secondary};
    }
`;

export const isStickedToComponentAbove: any = css`
    > div {
      border-top: none !important;
    }
    margin-top: -27px;
    &:before {
      display: none;
    }
`;

export const headlineWrapper: any = css`
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    > div {
      width: 80px;
      min-width: 80px;
      height: 80px;
      min-height: 80px;
      margin-right: 16px;
      img {
        object-fit: contain !important;
      }
    }
    
    h2 {
      color: ${props => props.theme.primary};
      font-size: 30px;
      /* max-width: 100%;
      &.withIcon{
        max-width: calc(100% - 80px);
      } */
      word-break: break-word;
    }
`;

export const richText: any = css`
    em {
      font-style: italic;
    }
    a {
      color: ${props => props.theme.primary};
      &:hover {
        text-decoration: underline;
      }
    }
    a p {
      color: ${props => props.theme.primary};
      &:hover {
        text-decoration: underline;
      }
    }
    p{
      min-height: 8px;
    }
    h2, h3, h4 {
      color: ${props => props.theme.primary};
      font-size: 20px;
    }
    p {
      width: auto;
    }
    iframe {
      max-width: 100%;
    }
`;

export const clearFix: any = css`
  clear: both;
`;
