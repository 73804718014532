import { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const sidebarWrapper: any = css`
    @media (${BREAKPOINTS_DM.platinum_1366})
    {
       width: 100% !important;
       margin-left: 0 !important;
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: 92% !important;
        margin: 0 auto !important;
    }
`;

export const sidebarItemWrapper: any = css`
  width: 100%;
  padding: 0 !important;
  &:before {
    border-color: ${props => props.theme.secondary} !important;
  }
`;

export const content: any = css`
  border: solid 1px ${props => props.theme.secondary};
  background-color: ${colors.lightMint};
  img {
    width: 100%;
    height: auto;
  }
`;

export const contentBgTransparent: any = css`
  border: solid 1px ${props => props.theme.secondary};
  img {
    width: 100%;
    height: auto;
  }
`;

export const markenContent: any = css`
  > div> img {
    @media (${BREAKPOINTS_DM.platinum_1366})
    {
      display: none;
    }
  }
`;

export const innerContentWrapper: any = css`
  padding: 27px;
  h3 {
    font-size: 30px;
    color: ${props => props.theme.primary};
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 5px;
  }
`;

export const brands: any = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 52px;
    > div {
      margin: 0 5px 13px;
      max-width: 90%;
      height: 20px;
      width: auto;
      display: inline-block;
      img {
        object-fit: scale-down !important;
        width: auto !important;
      }
    }
`;

export const rezensionImgWrapper: any = css`
    padding: 27px;
`;
