import { IGenSliderComp } from "src/common/__generated/root/types";
import { BREAKPOINTS } from "src/common/constants/mediaquerys";

import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import SlickSlider, { Settings } from "react-slick";
import { css } from "styled-components";

import { BrandAreaContainer } from "../../brand-area/components/brand-area-container/BrandAreaContainer";
import SSlickBase from "./styles/SSlickBase";
import SSlickTheme from "./styles/SSlickTheme";
import { SSliderWithFocalPoint } from "./styles/SSliderWithFocalPoint";
import { FancyImage, IFancyImage } from "../FancyImage/FancyImage";

interface ISliderWithFocalPoint extends IGenSliderComp {
    /** if false then T27Container padding will be removed */
    hasContainerPaddingWrapper?: boolean;
    /** responsiveFactor: The responsiveFactor is the amount of space used on the screen in percent - default is 1 for full width image */
    responsiveFactor?: IFancyImage["responsiveFactor"];
    /** responsiveAspectRatio: overwrite the aspect ratio for each breakpoint (width/height)  - default is to use the aspect ratio of the image */
    responsiveAspectRatio?: IFancyImage["responsiveAspectRatio"];
    /** responsiveMaxWidth: The responsiveMaxWidth is the max width of the image in px - default the is no limit and will scale to max of current breakpoint */
    responsiveMaxWidth?: IFancyImage["responsiveMaxWidth"];
    shouldShowSliderDots?: boolean;
}

const SliderWithFocalPoint: FunctionComponent<ISliderWithFocalPoint> = ({
    elements,
    hasContainerPaddingWrapper = true,
    responsiveAspectRatio,
    responsiveFactor,
    responsiveMaxWidth,
    shouldShowSliderDots = true,
}) => {
    const sliderSettings: Settings = {
        dots: shouldShowSliderDots,
        infinite: true,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        autoplay: true,
        nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
        prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
        responsive: [
            {
                breakpoint: BREAKPOINTS.SILVER,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    const bronzeWrapperOverwriteStyle = css`
        @media (max-width: ${BREAKPOINTS.SILVER / 16}em) {
            padding: 0;
        }
    `;

    if (elements?.length < 1) return null;

    const Content = (
        <SSliderWithFocalPoint>
            <div
                css={`
                    ${SSlickBase} ${SSlickTheme}
                `}
            >
                <SlickSlider {...sliderSettings}>
                    {elements.map((element, i) => (
                        <div key={`SlickSlider${i}`}>
                            <FancyImage
                                img={element}
                                responsiveMaxWidth={responsiveMaxWidth ?? { platinum: 1366 }}
                                responsiveAspectRatio={
                                    responsiveAspectRatio ?? {
                                        bronze: 360 / 312,
                                        silver: 720 / 405,
                                        platinum: 1214 / 683,
                                    }
                                }
                                responsiveFactor={responsiveFactor}
                            />
                        </div>
                    ))}
                </SlickSlider>
            </div>
        </SSliderWithFocalPoint>
    );

    return hasContainerPaddingWrapper ? (
        <BrandAreaContainer overwriteStyle={bronzeWrapperOverwriteStyle}>{Content}</BrandAreaContainer>
    ) : (
        Content
    );
};

export default SliderWithFocalPoint;
