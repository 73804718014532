import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND
} from "src/common/constants/mediaquerys";

const Default = css`
  .c-empty{
    min-height: 21px;
    width: 100%;
    display: block;
  }
  h1 {
    ${({ theme }) => theme?.textStyles?.h1}
  }
  h2 {
    ${({ theme }) => theme?.textStyles?.h2}
  }
  h3 {
    ${({ theme }) => theme?.textStyles?.h3}
  }

  a {
    color: ${({ theme }) => theme.palette.green};
  }
  p{
    line-height: 1.6rem;
    margin-bottom: 1.25rem;
    white-space: break-spaces;
    text-align: left;
    hyphens: none;
  }
  svg {
    path {
      fill: ${({ theme }) => theme.palette.green};
    }
  }
  li, ul{
    display: block;
    list-style: inside;
  }
  ul {
    list-style: disc;
  }
  li{
    strong{
      height: 21px
    }
    list-style: inside;
    line-height: 21px;
    display: list-item;
  }

  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }

  tr{
    font-size: 12px;
    font-weight: 300;
    p{
      margin-bottom: 10px;
      padding-right: 10px;
    }
  }
  strong {
    font-weight: 700;
  }
`;

const Silver = css`
  p{
    line-height: 30px;
    margin-bottom: 1.25rem;
  }
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SRichTextGeneral = css`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

export const RichTextGeneralWrapper = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

export default SRichTextGeneral;
