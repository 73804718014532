import React, { FunctionComponent, useContext, useState } from "react";
import {
  Context,
  getCurrentNavItem,
  IPage,
} from "src/common";
import { css } from "styled-components";

import * as styles from "./NavItem.style";
import { SubItem } from "./NavItem.style";

export enum eNavItemType {
  header,
  sidebar,
}

interface IProps {
  link: string;
  headline?: string;
  title: string;
  subItems: IPage[];
  type: eNavItemType;
}

const NavItem: FunctionComponent<IProps> = (props) => {
  const isInSidebar = props?.type === eNavItemType.sidebar;
  const p = useContext(Context);
  const palette = p?.Settings;
  const { link } = props;
  const currentMainNavItem = getCurrentNavItem(
    p?.PrimaryNavigation?.items,
    link
  );
  const currentMainNavSubItem = currentMainNavItem?.subitems?.find(
    (i) => i?.slug === p.slug
  );
  const active = p.slug === link || currentMainNavSubItem;

  const [isExpanded, setIsExpanded] = useState(false);
  const expandSubItems = (e): void => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const isExternal = props?.link?.startsWith("http");

  return (
    <div css={isInSidebar ? styles.sidebar_wrapper : styles.wrapper}>
      <a
        className={`nav-item nav-item-t3`+ (isInSidebar ? ` nav-item-t3-sidebar` : ` nav-item-t3-main`)}
        href={(isExternal ? "" : "/") + props?.link}
        target={isExternal ? "_blank" : undefined}
        css={
          active
            ? css`
                color: ${palette.themeColorPrimary ||
                palette.themeTextColourIsland};
              `
            : css`
                color: ${palette.themeTextColor};
              `
        }
        title={props?.headline}
      >
        {props?.title}
        {isInSidebar && !!props?.subItems?.find((s) => s.slug) && (
          <svg
            style={isExpanded ? { transform: "rotate(90deg)" } : {}}
            css={styles.sidebarExpandItemArrow}
            color={palette.themeColorPrimary}
            onClick={expandSubItems}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={`${palette.themeBackgroundColor}`}
          >
            <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
          </svg>
        )}
      </a>
      {!!props?.subItems?.find((s) => s.slug) && (
        <div
          css={
            isInSidebar
              ? isExpanded
                ? styles.sidebar_SubItemsWrapperExpanded
                : styles.sidebar_SubItemsWrapper
              : styles.dropdownWrapper
          }
          style={{ backgroundColor: palette.themeBackgroundColor || "white" }}
        >
          {props?.subItems.map((subItem: any, i) => {
            

            const externLink =
            subItem?.__typename == "ExternalLink" ? subItem?.externalLinkAddress : null;
        
            if(externLink){
              return (
                <SubItem
                  key={i}
                  isInSidebar={isInSidebar}
                  color={palette.themeTextColor}
                  target="_blank"
                  href={"https://" +
                  `${externLink}`.replace("http:", "https:").replace("https://", "")}
                >
                  {subItem?.externalLinkName || subItem?.__typename}
                </SubItem>
              );
            }

            if (!subItem?.slug) {
              return null;
            }
            
            const activeSub = subItem.slug === p?.slug;
            return (
              <SubItem
                key={i}
                isInSidebar={isInSidebar}
                color={palette.themeTextColor}
                href={"/" + subItem?.slug}
                css={
                  activeSub
                    ? css`
                        color: ${palette.themeColorPrimary ||
                        palette.themeTextColourIsland};
                      `
                    : ""
                }
              >
                {subItem?.nameInNavigation || subItem?.__typename}
              </SubItem>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavItem;
