import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { C_1366_Regular, C_360_Regular, C_768_Regular } from "src/common/brand-area/constantsOverwrites/typography";

const Default = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > [data-caption] {
        text-align: center;
        color: var(--front-2);
        ${C_360_Regular}
        transition: color 0.3s ease-in-out;
    }

    &:hover {
        > [data-caption] {
            color: var(--primary-100);
        }
    }
`;

const Silver = css`
    > [data-caption] {
        ${C_768_Regular}
    }
`;

const Gold = css``;

const Platinum = css`
    gap: 22px;

    > [data-caption] {
        ${C_1366_Regular}
    }
`;

const Diamond = css``;

export const SOffsetGalleryItem = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
