import { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const wrapper: any = css`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.blue};
  transition: height .3s linear;
  box-shadow: none;
  z-index: 999;
  overflow: hidden;
  min-height: 48px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  @media (${BREAKPOINTS_DM.gold_1024})
  {
      display: flex;
  }
`;

export const burgerMenuWrapper: any = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 48px;
`;
