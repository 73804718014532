import styled, { css } from "styled-components";

const Default = css<{ adjustedWidth?: number }>`
    display: grid;
    position: relative;
    height: 100%;
    width: ${(props) => (props.adjustedWidth ? `${props.adjustedWidth}px` : "100%")};
`;

export const SModalFlexContainer = styled.div<{ adjustedWidth?: number }>`
    ${Default}
`;
