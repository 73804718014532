import styled, { css } from "styled-components";

const Default = css`
    width: 100%;
    height: 100%;
    position: relative;
    > div{
        width: 100%;
    }
`;

const SModalImageWrapper = styled.div`
    ${Default}
`;

export default SModalImageWrapper;
