import React, { FunctionComponent, useState } from "react";

import Header from "../Header";
import { Content } from "./ContentWrapper.style";

const ContentWrapper: FunctionComponent<any> = (props) => 
{
  const [isShowMenu, toogleShowMenu] = useState(false);
  return (
    <Content isShowMenu={isShowMenu}>
      <Header onShowMenu={toogleShowMenu} isShowMenu={isShowMenu}/>
      {props.children}
    </Content>
  );
};
  
export default ContentWrapper;
