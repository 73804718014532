import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { P_1366_Bold, P_360_Bold, P_768_Bold } from "src/common/brand-area/constantsOverwrites/typography";

const Default = css`
    position: relative;

    &,
    > div {
        width: 100%;
        height: 100%;
    }

    label {
        position: absolute;
        padding: 10px;
        top: 0;
        z-index: 4;
        text-align: center;
        ${P_360_Bold}
        opacity: 0;
        transition: opacity 0.15s linear 0.01s, color 0.15s linear 0.01s, background-color 0.15s linear 0.01s;
        color: var(--front-3);
        background-color: var(--back-3);

        &.before {
            left: 0;
        }

        &.after {
            right: 0;
        }
    }

    > .icon {
        position: absolute;
        top: 50%;
        z-index: 4;
        color: var(--back-3);
        transition: opacity 0.15s linear 0.01s, left 0.15s linear 0.01s, right 0.15s linear 0.01s;

        &,
        svg {
            width: 14px;
            height: 21px;
        }

        &.before-icon {
            left: 6%;
        }

        &.after-icon {
            right: 3.1%;
            opacity: 0;
        }
    }
`;

const Silver = css`
    label {
        padding: 16px;
        ${P_768_Bold}
    }

    > .icon {
        &,
        svg {
            width: 15px;
            height: 23px;
        }

        &.before-icon {
            left: 26.5%;
        }

        &.after-icon {
            right: 23%;
        }
    }
`;

const Gold = css``;

const Platinum = css`
    label {
        padding: 24px;
        ${P_1366_Bold}
    }

    > .icon {
        &,
        svg {
            width: 18px;
            height: 27px;
        }

        &.before-icon {
            left: 28.7%;
        }

        &.after-icon {
            right: 25.9%;
        }
    }
`;

const Diamond = css``;

export const SComparisonPhotoGalleryImageWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
