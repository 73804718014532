import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "src/common/constants/mediaquerys";
import { BREAKPOINTS_DM } from "src/templates/t8/constants/mediaquerys";
import styled, { css } from "styled-components";

const Default = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0px;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SProductItemT17 = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

export const Title: any = styled.div`
    background-color: rgba(128, 199, 220, 0.8);
    color: ${props => props.theme.palette.white};
    font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
    line-height 40px;
    margin: 0 0px 8px;
    font-weight: 300;
    font-size: 21px;
    padding-left: 1px;
}
`;

export const TextWrapper: any = styled.div`
  width: 100%;
  div.bg-pink {
    background-color: #ED1E79;
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  };
`;

export const TextPrice: any = styled.span`
font-size: 0.875em;
line-height: 1.42857;
font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
`;

export const TextSecondary: any = styled.div`
display: flex;
flex-direction: row;
p {
  font-size: 0.875em;
  line-height: 1.42857;
  font-family: sofia_pro_lightregular, Helvetica, Arial, sans-serif;
}
`;
export const Icon: any = styled.div`
  width: 18px;
 `; 
