import React, { FC, useContext } from "react";
import { Context } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import { BrandBannerHorizontalWrapper } from "./BrandBannerHorizontal.style";

const BrandBannerHorizontal: FC = () => {
  const props = useContext(Context);
  const allBrands = props.AllBrand;

  if (allBrands.length <= 1) {
    return null;
  }

  const companies = allBrands.map((company, i) => {
           if (!company?.logo || company.logo.length === 0 || !company.logo?.[0]?.src) {
      return null;
    }
    return (
      <a key={i}>
        <Img
          src={company?.logo}
          lazyload={false}
          placeholderHeight={"31px"}
          quality={200}
          alt={company?.name || ""}
        />
      </a>
    );
  });

  return (
    <BrandBannerHorizontalWrapper>
      {companies}
    </BrandBannerHorizontalWrapper>
  );
};

export default BrandBannerHorizontal;
