import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context } from "src/common";

import Address from "./Address";
import { FooterWrapper } from "./Footer.style";
import Navigation from "./Navigation";
import SocialShare from "./SocialShare";

interface IFooterLinkInColumnList 
{
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => 
{
  const props = useContext(Context);
  const allFooterLinksForUpperWrapper: IFooterLinkInColumnList[] = [];
  const companyInformation = props.CompanyInformationPublic;
  const footerNavItems = props.Footer?.Navigationspunkte || [];
  const impressum = props.PageImprint || {};

  for(const item of props.PrimaryNavigation?.items || []) 
  {
    if(item?.label && item?.link?.[0]?.slug) 
    {
      allFooterLinksForUpperWrapper.push({
        title: item.label || item?.link?.[0]?.nameInNavigation,
        link: item?.link?.[0]?.slug,
        isBold: true
      });
    }

    if(item?.subitems) 
    {
      for(const subItem of item.subitems) 
      {
        if(subItem?.nameInNavigation && subItem?.slug) 
        {
          allFooterLinksForUpperWrapper.push({
            title: subItem?.nameInNavigation,
            link: subItem?.slug,
            isBold: false
          });
        }
      }
    }
  }

  return (
    <FooterWrapper>
      <Navigation items={allFooterLinksForUpperWrapper} />
      <Address companyInformation={companyInformation} items={footerNavItems} impressum={impressum}/>
      <SocialShare companyInformation={companyInformation}/>
    </FooterWrapper>
  );
};

export default Footer;
