import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { css } from "styled-components";

export const wrapper: any = css`
  width: 100%;
  height: 100%;
  ul {
    position: relative;
    height: calc(50vw + 250px) !important;
    overflow: hidden;
  }
  div > div {
    width: fit-content;
    margin: auto;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    ul {
      height: 330px !important;
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 100%;
    height: 100%;
    ul {
      position: relative;
      height: 230px !important;
      overflow: hidden;
    }
    div > div {
      margin: 0 auto 0 0;
    }
  }
`;
