import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface IProps {
    template: string;
    itemsPerRow: number;
}

const Default = css<IProps>`
    span {
        display: block;
    }
    a {
        cursor: pointer;
    }

    svg {
        width: 14px;
        height: 14px;
    }
    .cpg-price {
        padding: 10px;
        color: ${(props) => props.theme.palette?.productPrime ? `${props.theme.palette.productPrime}` : "var(--primary-100)"};
        background: ${(props) => props.theme.palette?.productBgLight100 ? `${props.theme.palette.productBgLight100}` : "var(--back-1)"}; 
    }
    .cpg-price-text {
        height: 21px;
        width: 100%;
    }
    .cpg-price-number {
        width: 50%;
        display: inline-block;
        font-weight: 700;
        text-align: left;
    }
    .cpg-price-next {
        width: 50%;
        display: inline-block;
        text-align: right;
    }

    ${({ template }) =>
        template === "t27" &&
        css`
            .row {
                grid-gap: 24px;
            }

            padding-bottom: 48px;
        `}
`;

const Silver = css<IProps>`
    ${({ template, itemsPerRow }) =>
        template === "t27" &&
        css`
            .row {
                grid-gap: ${itemsPerRow === 2 ? "24px" : "16px"};
            }
        `}
`;

const Gold = css``;

const Platinum = css<IProps>`
    ${({ template }) =>
        template === "t27" &&
        css`
            .row {
                grid-gap: 38px;
                padding-bottom: 76px;
            }
        `}
`;

const Diamond = css``;

const SContinuingPageGallery = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SContinuingPageGallery;
