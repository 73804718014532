import React, { FC, useCallback, useState } from "react";
import { getCurrentNavItem, INavigationItem, INavigationItem_Subitems, ISubNavigationItem } from "src/common";

import { NavigationContainer, NavigationHeader, NavigationLinks, NavigationLink, SubNavigationLinks, Icon, BurguerIconWrapper } from './Header.styles';

interface IHeaderItemProps 
{
  title: string;
  link: string;
  headline?: string;
  external?: boolean;
}
export const getHeaderNavItemProps = (item: INavigationItem | ISubNavigationItem): IHeaderItemProps | false => {

  const linkItem = item?.link?.[0];

  if((linkItem as any)?.__typename === "ExternalLink") 
  {
    return {
      link: `${(linkItem as any).externalLinkAddress}`,
      title: `${(item as any).label ?? (linkItem as any).externalLinkName}`,
      external: true,
    };
  }
  if((linkItem as any)?.__typename === "PageMicro")
  {
    return {
      link: `${(linkItem as any).slug}`,
      title: `${(item as any).label ?? (linkItem as any).externalLinkName}`,
    };
  }

  const link = linkItem?.slug || (item as any)?.slug;
  const title = (linkItem as any)?.nameInNavigation || (item as any)?.nameInNavigation;
  let headline;

  if(!link || !title)
  {
    return false;
  }

  if(linkItem?.__typename === "Page" || linkItem?.__typename === "PageNews" || linkItem?.__typename === "PageGuestbook") 
  {
    headline = linkItem.elements?.find(e => e?.__typename === "TextElement")?.[0]?.headline;
  }

  return {
    link,
    title,
    headline
  };
};
export const BurguerIcon: FC<{ open: boolean; onClick?: (boolean) => void }> = ({ open, onClick }) => (
  <BurguerIconWrapper open={open} onClick={onClick}>
    <span />
    <span />
    <span />
  </BurguerIconWrapper>
);

const SubNavitation: FC<{ subitems: INavigationItem_Subitems[], slug: string }> = ({ subitems, slug }) => (
  <>
    {subitems && (
      <SubNavigationLinks>
        {subitems.map((subItem) => (
          <a key={subItem.slug} href={subItem.slug} data-active={slug === subItem.slug}>
            {subItem.nameInNavigation}
          </a>
        ))}
      </SubNavigationLinks>
    )}
  </>
);

const Navigation: FC<{ items: INavigationItem[]; slug: string; affix?: boolean }> = ({ items, slug, affix }) => {
  const [isMenuActive, toggleMenuActive] = useState(false);
  const [currentLinkItem, toggleShowSubItems] = useState(slug);
  const onToggleShowSubItems = useCallback((link: string) => toggleShowSubItems(
    link === currentLinkItem
      ? null
      : link
    ), [currentLinkItem, toggleShowSubItems]);

  const currentMainNavItem: any = getCurrentNavItem(items, slug);
  return (
    <NavigationContainer active={isMenuActive} affix={affix}>

      <NavigationHeader onClick={() => toggleMenuActive(!isMenuActive)}>Menü <BurguerIcon open={isMenuActive} /> </NavigationHeader>
      <NavigationLinks>
        <ul>
          {items.map((item, index) => {
            const itemProps = getHeaderNavItemProps(item);
            if (!itemProps) return;

            const active =
              !!slug && (`${slug}` === `${itemProps.link}` || currentMainNavItem?.link?.[0]?.slug === `${itemProps.link}`);

            return (
              <NavigationLink key={index} active={active}>
                <a href={`/${itemProps.link}`} onClick={() => onToggleShowSubItems(itemProps.link)} target={itemProps.external && "_blank"}>
                  {item.icon && item.icon[0] && (
                    <Icon
                      src={item.icon?.[0]?.src}
                      alt={"hoverIcon"}
                    />
                  )}

                  {itemProps.title}
                </a>

                {currentLinkItem === itemProps.link && (
                  <SubNavitation subitems={item.subitems} slug={slug} />
                )}
              </NavigationLink>
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;