import React, { useContext } from "react";
import { Context } from "src/common";

import { cutCorner } from "../../../constants/layout";
import * as styles from "../mainAreaWrapper/sidebarRight/SidebarRight.style";
import { MicroWrapper, Heading } from "./Micro.style";

const Micro: React.FC<any> = () => {
  const props = useContext(Context);
  return (
    <MicroWrapper>
      {props?.PageMicro?.nameInNavigation ? (
        <div
          css={`
            ${cutCorner}${styles.sidebarItemWrapper}
          `}
        >
          <div
            css={`
              ${styles.contentBgTransparent}
            `}
          >
            <Heading>
              <h1>{props?.PageMicro?.nameInNavigation}</h1>
            </Heading>
          </div>
        </div>
      ) : null}
      <iframe src={props?.PageMicro?.externalLinkAddress} />
    </MicroWrapper>
  );
};

export default Micro;
