import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    padding: 48px 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--back-3);
    }

    &:last-child {
        padding-bottom: 0;
    }
    &:first-child {
        padding-top: 0;
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    padding: 76px 0;
`;

const Diamond = css``;

export const SComparisonPhotoGalleryItemWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
