import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
  BREAKPOINTS_DM,
} from "../../../../constants/mediaquerys";

const Default = css`
  display: flex;
  background-color: white;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 1;
    border-bottom: 1px solid ${(props) => props.theme.primary};
    background: white;
    width: 40px;
    height: 40px;
    transform: rotate(-45deg);
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    &:before {
      display: none;
    }
  }

  input {
    color: #333;
    border: 0;
    padding: 19px 0 18px 8px;
    font-size: 1rem;
    height: 100%;
    line-height: 100%;
    outline: 0;
  }
  svg {
    width: 15px;
    height: 15px;
    margin-left: 4px;
  }
  div:first-child {
    width: 60%;
    input {
      width: 100%;
    }
    border-top: 1px solid ${(props) => props.theme.primary};
    border-left: 1px solid ${(props) => props.theme.primary};
  }
  div:last-child {
    ::before {
      content: "";
      display: block;
      width: 1px;
      height: 50px;
      background: #d2d2d2;
      top: 7px;
      position: absolute;
      left: 0;
    }
    width: 40%;
    float: right;
    background-color: #fff;
    padding: 21px 13.5px;
    border-top: 1px solid ${(props) => props.theme.primary};
    border-right: 1px solid ${(props) => props.theme.primary};
    font-size: 1.2rem;
    position: relative;
    text-align: center;
    button {
      width: 100%;
      color: ${(props) => props.theme.primary};
      text-align: center;
    }
  }
`;

const Silver = css`
input {
    color: #333;
    border: 0;
    padding: 19px 0 18px 40.5px;
    font-size: 1.4rem;
    line-height: 30px;
    outline: 0;
  }
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SDealerSearchTop = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
