import styled, { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";
import { centeredContent } from "../../../constants/layout";

export const Wrapper: any = styled.div<{background: string; textColor:string}>`
    background-color: ${({ background }) => background ? background : colors.gray};
    width: 100%;
    z-index: 1;
    position: relative;
    padding: 30px 0 100px;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        padding-bottom: 50px;
    }
    color: ${({ textColor }) => textColor};
    a {
    color: ${({ textColor }) => textColor};
    }
`;

export const LineLogoWrapper: any = styled.div`
    img{
      width: auto;
      object-fit: contain;
      height: 30px;
    }
`;

export const Columns: any = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    > * {
        width: 100%;
        margin-bottom: 15px;
    }
    * {
        line-height: 140%;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
        > * {
            width: 25%;
            margin-bottom: 0;
        }
        > *:not(:first-child) {
            padding-left: 30px;
        }
    }
`;

export const NavLinks: any = styled.ul`
    display: none;
    a {
        font-size: 14px;
        &:hover {
            opacity: 0.6;
        }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        display: block;
    }
`;

export const ShareOnSocialMediaWrapper: any = styled.div`
    a {
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 7px 8px;
        height: 32px;
        width: 100%;
        margin-top: 6px;
        span {
            font-size: 13px;
            margin-left: 10px;
            color: white;
        }
        svg {
            height: 100%;
            fill: white;
        }
    }

    .facebook {
        background-color: #3b5998;
        &:hover {
            background-color: #486dbc;
        }
    }  
    .twitter {
        background-color: #55acee;
        color: white;
        &:hover {
            background-color: #78c2ff;
        }
    }
    .pintrest {
        background-color: #bd081c;
        &:hover {
            background-color: #f10c23;
        }
    }
    .mail {
        background-color: #999;
        &:hover {
            background-color: #c1c1c1;
        }
    }
    .instagram {
        background-color: #D72C78;
        &:hover {
            opacity: .6;
        }
    }
`;

export const Address: any = styled.div`
    * {
        /* font-size: .85714rem; */
        /* line-height: 2.83333; */
        font-size: 12px;
        line-height: 180% !important;
    }
`;

export const LinksInAddress: any = styled.p`
    display: flex;
    flex-wrap: wrap;
`;

export const NavLinkInAddress: any = styled.a`
    margin-left: 4px;
    &:before {
        content: " | ";
    }
    &:first-child {
        &:before {
            content: "";
        }
    }
`;

export const LogoBannerHorizontalWrapper: any = styled.div<{background: string}>`
    height: 50px;
    background: ${({ background }) => background ? background : "rgba(227, 227, 227, 0.6)"};
    /* -webkit-box-shadow: inset 0 0 23px 8px #666; */
    /* box-shadow: inset 0 0 23px 8px #666; */
    img {
        height: 31px;
        filter: grayscale(1);
    }
    div:last-child {
        height: 50px;
        margin: 0;
        div {
            img {
                margin-top: 10px;
                height: 31px;
            }
            height: 50px;
        }
    }
`;

export const CenteredContent: any = styled.div`
    ${centeredContent}
`;
