import React, { FC } from "react";
import { Img, IProduct, RichText } from "src/common";
import IconAngle from "src/common/components/ProductLandingBanner/Icons/IconAngle";

import {
  ItemDefaultWrapper,
  ImageWrapper,
  RichTextWrapper,
  BoxBorderBottom,
  BoxBorderLeft,
  BoxBorderMiddle,
  BoxBorderRight,
  BoxBorderTop,
  BordersWrapper,
  TitleWrapper,
  BoxContainer,
} from "./ItemDefault.style";

const ItemDefault: FC<{ item: any }> = ({ item }) => {
  if (!item) {
    return null;
  }

  return (
    <ItemDefaultWrapper>
      {item.title && <TitleWrapper><p>{item.title}</p></TitleWrapper>}
      {item.thumbnail?.length > 0 && (
        <>
          <BoxContainer>
            <BordersWrapper>
              <BoxBorderTop />
              <BoxBorderLeft />
              <BoxBorderMiddle />
              <BoxBorderBottom />
              <BoxBorderRight />
            </BordersWrapper>
          </BoxContainer>
          <ImageWrapper>
            {item.thumbnail.map((thumbnail, i) => (
              <Img src={thumbnail.src} key={`image-${i}`} />
            ))}
          </ImageWrapper>
        </>
      )}
      {item.text?.content && (
        <RichTextWrapper>
          <RichText fragment={true} textContent={item.text} />
        </RichTextWrapper>
      )}
    </ItemDefaultWrapper>
  );
};

export default ItemDefault;
