import styled from "styled-components";

export const BrandBannerHorizontalWrapper = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10.5px;

  > a {
    display: block;
    width: 25%;
    padding: 21px 0;
    img {
      height: 31px;
      object-fit: contain;
      filter: grayscale(1);
    }
  }
`;
