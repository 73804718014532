import React, { FC } from "react";
import { RichText as RichTextCommon } from "src/common";

import SRichTextGeneral from "./RichText.style";

export const RichTextGeneral: FC<{ textContent?: any; css?: any; fragment?: boolean }> = (props) => (
  <RichTextCommon overwriteStyle={SRichTextGeneral} {...props} />
);

export { SRichTextGeneral, RichTextGeneral as default };
