import React, { Fragment, Component } from "react";
import Slider, { Settings } from "react-slick";

import { IAsset, Img } from "src/common";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import SSlickBase from "../../../utils/slick/SSlickBase";
import { LazyLoadTypes } from "react-slick";
import { BREAKPOINTS } from "src/common/constants/mediaquerys";

const STANDARD_SETTINGS = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 6000,
  autoplay: true,
  lazyLoad: "progressive" as LazyLoadTypes,
};

const THUMB_SETTINGS = {
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
  focusOnSelect: true,
  draggable: true,
  responsive: [
    {
      breakpoint: BREAKPOINTS.SILVER,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

class ThumbnailSlider extends Component<
  {
    elements: (IAsset | null)[] | null;
    overrideMainSliderSettings?: Settings;
    overrideNavSliderSettings?: Settings;
  },
  {
    nav1: any;
    nav2: any;
  }
> {
  private slider1: any;
  private slider2: any;

  public constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  public componentDidMount(): void {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  public render() {
    const { elements } = this.props;

    return (
      <Fragment>
        <div
          css={`
            ${SSlickBase}
            ${SSlickTheme}
          `}
          data-gallery-slider
        >
          <Slider
            {...STANDARD_SETTINGS}
            asNavFor={this.state.nav2}
            ref={(slider) => (this.slider1 = slider)}
          >
            {elements.map((element, i) => (
              <div key={`image-${i}`} data-image>
                <Img
                  quality={1200}
                  key={`sliderimage${i}`}
                  src={element?.src}
                  alt={element.description ?? "image"}
                />
                {element?.description && (
                  <div className="caption-description">
                    {element.description}
                  </div>
                )}
                {/* add this line to reflect the description in each image */}
              </div>
            ))}
          </Slider>
        </div>
        <div
          css={`
            ${SSlickBase}
            ${SSlickTheme}
          `}
          data-gallery-slider-tracker
        >
          <Slider
            {...THUMB_SETTINGS}
            slidesToShow={
              THUMB_SETTINGS.slidesToShow > elements.length
                ? elements.length
                : THUMB_SETTINGS.slidesToShow
            }
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
          >
            {elements.map((element, i) => (
              <div key={`thumnail-image-${i}`} data-thumnail-image>
                <Img
                  quality={1200}
                  key={`sliderimage${i}`}
                  src={element?.src}
                  alt={element.description ?? "image"}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Fragment>
    );
  }
}

export default ThumbnailSlider;
