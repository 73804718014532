import React, { FC } from "react";
import Masonry from "react-responsive-masonry";
import { IPhotoGallery } from "src/common/childTypes";

import { SMasonryPhotoGallery } from "./SMasonryPhotoGallery";
import { FancyImageWithModal } from "src/common/BaseComponents/FancyImageWithModal/FancyImageWithModal";
import { useWindowDimensions } from "src/common/utils/useWindowDimensions";
import { BREAKPOINTS } from "src/common/constants/mediaquerys";
import FullScreenSliderWithFancyImage from "../../FullScreenSliderWithFancyImage/FullScreenSliderWithFancyImage";

const MasonryPhotoGallery: FC<{ item: IPhotoGallery }> = ({ item }) => {
    if (!item?.elements?.length) return null;
    

    const count = item?.maxCountPerRow;
    const maxCountPerRow = Math.max(count || 4, 1);
    const { windowWidth } = useWindowDimensions();
    const isBiggerThanBronze = windowWidth > BREAKPOINTS.SILVER - 1;
    const itemsPerRow = isBiggerThanBronze ? Math.min(4, maxCountPerRow) : Math.min(2, maxCountPerRow);

    const responsiveMaxWidth = 1366 / (itemsPerRow);
    const responsiveFactor = 1 / (itemsPerRow * 0.9);


    return (
        <SMasonryPhotoGallery data-gallery>
            <Masonry columnsCount={itemsPerRow}>
                {item.elements?.map((asset, i) => (
                    <div key={i} data-image>
                        {item.modal === "slider" ? (
                            <FullScreenSliderWithFancyImage
                                elements={item.elements}
                                img={asset}
                                responsiveMaxWidth={{ platinum: responsiveMaxWidth }}
                                responsiveFactor={{ bronze: responsiveFactor, silver: responsiveFactor }}
                            />
                        ) : (
                            <FancyImageWithModal
                                img={asset}
                                responsiveMaxWidth={{ platinum: responsiveMaxWidth }}
                                responsiveFactor={{ bronze: responsiveFactor, silver: responsiveFactor }}
                            />
                        )}
                    </div>
                ))}
            </Masonry>
        </SMasonryPhotoGallery>
    );
};

export default MasonryPhotoGallery;
