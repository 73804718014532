import styled from "styled-components";

export const SSlickWrapper = styled.div`
    .slick-slider{
        max-width: 60vw;
        .slick-next:before,.slick-prev:before {
            content: '→';
        }
        .slick-prev:before{
            content: '←';
        }
        .slick-next:before,
        .slick-prev:before{
            color: #D40046;
        }
    }
`;
