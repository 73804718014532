import React, { forwardRef } from "react";

import SCol from "./Styles/SCol";

export interface IPropsCol
{
    children?: any;
    bronze?: number[] | number;
    silver?: number[] | number;
    gold?: number[] | number;
    platinum?: number[] | number;
    diamond?: number[] | number;
    orderBronze?: any;
    orderSilver?: any;
    orderGold?: any;
    orderPlatinum?: any;
    orderDiamond?: any;
}

const Col = forwardRef<HTMLDivElement ,IPropsCol>((props, ref) => <SCol className="col" ref={ref as any}  {...props}>{props.children}</SCol>);
// [leftCol, rightCol, row]
// [leftCol, rightCol]
// rightCol
export default Col;
