import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

const wrapper = css`
    background-color: rgba(11, 11, 11, 0.8);
    position: fixed;
    z-index: 9999999999999;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.6s ease, height 0s linear 0.6s, width 0s linear 0.6s;
    pointer-events: none;

    padding: 24px;
    @media (${BREAKPOINTS_DM.platinum_1366}) {
        padding: 48px;
    }
`;

export const wrapperActive: any = css`
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.6s ease, height 0s linear 0s, width 0s linear 0s;
`;

const Default = css<{ isActive?: boolean }>`
    ${wrapper}
    ${(props) => (props.isActive ? wrapperActive : "")}
`;

export const SModalOverlay = styled.div<{ isActive?: boolean }>`
    ${Default}
`;
