import { Context, IContext } from "src/common";
import React, { FunctionComponent, useContext } from "react";

import { centeredContent, contentWrapper } from "../../../constants/layout";
import * as styles from "./Footer.style";
import { CopyrightNotice } from "src/common/components/CopyrightNotice/CopyrightNotice";
import { addHttpsToLinks } from "../../../../../common/utils/addHttpsToLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTiktok,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

interface IProps {
  showLogos: boolean;
}

export const Footer: FunctionComponent<IProps> = ({ showLogos }) => {
  const props = useContext<IContext>(Context);
  const companyInformation = props.CompanyInformationPublic;
  const navItems = props.Footer?.Navigationspunkte;

  const items = [
    {
      className: "fb",
      target: "_blank",
      href:
        companyInformation?.facebook &&
        addHttpsToLinks(companyInformation?.facebook),
    },
    {
      className: "insta",
      target: "_blank",
      href:
        companyInformation?.instagram &&
        addHttpsToLinks(companyInformation?.instagram),
    },
    {
      className: "youtube",
      target: "_blank",
      href:
        companyInformation?.youtube &&
        addHttpsToLinks(companyInformation?.youtube),
    },
    {
      className: "twitter",
      target: "_blank",
      href:
        companyInformation?.twitter &&
        addHttpsToLinks(companyInformation?.twitter),
    },
    {
      className: "pintrest",
      target: "_blank",
      href:
        companyInformation?.pintrest &&
        addHttpsToLinks(companyInformation?.pintrest),
    },
    {
      className: "whatsapp",
      target: "_blank",
      href:
        companyInformation?.whatsapp &&
        addHttpsToLinks(companyInformation?.whatsapp),
    },
    {
      className: "tiktok",
      target: "_blank",
      href:
        companyInformation?.tiktok &&
        addHttpsToLinks(companyInformation?.tiktok),
    },
  ].filter((item) => item.href);

  return (
    <div
      css={`
        ${styles.wrapper}
      `}
    >
      <div css={centeredContent}>
        {showLogos && (
          <div css={contentWrapper}>
            <div css={styles.imagesWrapper}>
              {props.CompanyInformationPrivate
                ?.householdApplianceMakerPartner && (
                <img
                  src={"/images/reparaturpartner.svg"}
                  alt={"reparatur partner"}
                />
              )}
              {props.CompanyInformationPrivate?.kitchensSalePartner && (
                <img src={"/images/toppartner.svg"} alt={"top partner"} />
              )}
              {props.CompanyInformationPrivate?.showInKitchenCompass && (
                <img
                  src={"/images/planungspartner.svg"}
                  alt={"planungs partner"}
                />
              )}
            </div>
          </div>
        )}
        <div css={styles.footerContent}>
          <p css={styles.copyright}>
            <CopyrightNotice />
          </p>
          <div css={styles.links}>
            {navItems?.map((navItem, i) => {
              if (!navItem?.slug) {
                return null;
              }
              return (
                <a href={`/` + navItem.slug} key={i}>
                  {navItem.nameInNavigation}
                </a>
              );
            })}
          </div>
          <div css={styles.social}>
            {items.map(({ className, href, target }, index) => (
              <a
                key={index}
                href={href}
                className={className + (target == "_blank" ? " boxicon" : "")}
                target={target}
              >
                {className === "fb" ? (
                  <FontAwesomeIcon icon={faFacebook} />
                ) : null}
                {className === "insta" ? (
                  <FontAwesomeIcon icon={faInstagram} />
                ) : null}
                {className === "youtube" ? (
                  <FontAwesomeIcon icon={faYoutube} />
                ) : null}
                {className === "tiktok" ? (
                  <FontAwesomeIcon icon={faTiktok} />
                ) : null}
                {className === "whatsapp" ? (
                  <FontAwesomeIcon icon={faWhatsapp} />
                ) : null}
                {className === "twitter" ? (
                  <FontAwesomeIcon icon={faTwitter} />
                ) : null}
                {className === "pintrest" ? (
                  <FontAwesomeIcon icon={faPinterest} />
                ) : null}
              </a>
            ))}
          </div>
          <a css={styles.nachOben} href="#top">
            nach oben
          </a>
        </div>
      </div>
    </div>
  );
};
