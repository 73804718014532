import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import * as styles from "./Asset.style";

export interface IAssetProps 
{
  file?: IAsset[];
  subtitle?: string;
}

const Asset: FunctionComponent<IAsset> = props => 
{
  if(!props?.src)
  {
    return null;
  }
  return (
    <div css={styles.wrapper}>
      <Img quality={1600} src={props?.src} alt={props.title} />
    </div>
  );
};
export default Asset;
