// @ts-nocheck
import styled, { css } from "styled-components";

const Default = css`
    svg {
        margin-right: 12px;
        margin-top: 8px;
        transform: scale(1.2);
        transform-origin: top left;
    }

    &.changeable svg {
      cursor: pointer;
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SGuestbookStars = styled.div`
  ${Default}
`;
export default SGuestbookStars;
