import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
padding-left: 22px;
padding-right: 22px;
`;

const Silver = css`
  padding-left: 24px;
  padding-right: 24px;
`;

const Gold = css``;

const Platinum = css`
  padding-left: 76px;
  padding-right: 76px;
`;

const Diamond = css``;

export const SBrandAreaContainer = styled.div<any>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;
