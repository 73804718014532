import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/mediaquerys";
import { SFullWidthBannerBase } from "./SFullWidthBannerBase";

export interface ISFullWidthBannerContainerProps {
  active?: boolean;
  hasLink?: boolean;
  animationDone?: boolean;
  animationTransitionDelayMs: number;
}

const Default = css<ISFullWidthBannerContainerProps>`
  width: 100%;
  z-index: 300;
  top: 0;
  position: fixed;
  ${(hasLink) => (hasLink ? "cursor: pointer;" : "")};
  .bg {
    z-index: 186;
  }
  svg {
    opacity: 0;
    transition: opacity 0ms ease;
    transition-delay: 0s;
    padding: 10px;
    width: 100%;
    height: 100%;
  }
  .close {
    position: absolute;
    z-index: 189;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  .bannerimg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 188;
    img {
      border-bottom: 1px solid #e7e6e5;
      width: 100%;
    }
  }

  img {
    opacity: 0;
    transition: opacity 300ms ease;
    transition-delay: ${({ animationTransitionDelayMs }) =>
      animationTransitionDelayMs ? animationTransitionDelayMs + "ms" : "1s"};
  }

  .goldImage {
    display: none;
  }
  .diamondImage {
    display: none;
  }
`;

const Silver = css``;

const Gold = css`
  .goldImage {
    display: block;
  }
  .bronzeImage {
    display: none;
  }
`;

const Platinum = css``;

const Diamond = css`
  .goldImage {
    display: none;
  }
  .diamondImage {
    display: block;
  }
`;

const activeState = css<ISFullWidthBannerContainerProps>`
  img {
    opacity: 1;
  }
  svg {
    opacity: 1;
    transition: opacity 300ms ease;
    transition-delay: ${({ animationTransitionDelayMs }) =>
      animationTransitionDelayMs ? animationTransitionDelayMs + "ms" : "1s"};
  }
`;

export const SFullWidthBannerContainer = styled(
  SFullWidthBannerBase
)<ISFullWidthBannerContainerProps>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ active }) => (active ? activeState : "")};
`;
