// @ts-nocheck
import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const BurguerIcon: any = styled.div<{isMenuActive: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 45px;
  font-size: 25px;
  color: rgb(230, 124, 25);
  position: absolute;
  top: 20px;
  right: 20px;
  transition: transform .8s ease;
  cursor: pointer;
  z-index: 999;
  &:hover {
    background: rgb(230, 124, 25);
    color: #fff;
    border-radius: 4px;
  }

`;

export const NavigationLink: FC<any> = styled.li<{ isActive: boolean; subItems: boolean; isShowSubItem: boolean }>`
  display: flex;
  flex-direction: column;
  width: 94%;
  margin-bottom: 1px;
  margin: 0 10px;
  div.sub-item-wrapper {
    background: #fff;
    border-top: 2px solid #e67c19;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    display: none;
    min-width: 140px;
    padding-bottom: 5px;
    top: 102px;
    z-index: 9999999;
  }
  div.link {
    display: flex;
    white-space: nowrap;
    color: #e67c19;
    font-size: 13px;
    font-stretch: 100%;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 15px 8px;
    text-align: center;
    width: 100%;
    border-top: 1px solid #ccc;
  }
  div.sub-item-mobile {
    display: flex;
    flex-direction: column;
  }

  a.sub-item {
    white-space: nowrap;
    color: #e67c19;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    padding: 5px 15px 8px;
    text-align: left;
    width: 100%;
  }
 
  div.icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #e67c19;
    width: 100%;
    padding-left: 10px;
    margin: -5px 0 0;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    border-bottom: none;
    margin-bottom: 0;
    margin-left: 0px;
    width: fit-content;
    margin: 0 0 0 0px;
    div.icon {
      color: #6e6e6e;
    }
    div.sub-item-mobile {
      display: none;
    }
    div.link {
      color: ${({ isActive }) => isActive ? "#e67c19" : "#6e6e6e"};
      width: fit-content;
      padding: 25px 15px 15px;
      border: none;
      a {
      font-size: 13px;
      font-weight: 300;
      }
    }
    div.underline {
      width: 30px;
      margin: 0 auto;
      height: 10px;
      border-top: ${({ isActive }) => isActive && "2px solid #e67c19"};
    }
    &:hover {
      div.sub-item-wrapper { 
        display: flex;
        flex-direction: column;
        position: absolute;
      }
      div.underline {
        border-top: 2px solid #e67c19;
      }
      div.icon {
        color: #e67c19;
      }
      > a {
        color: #e67c19;
      }
    }
  }
`;

export const IconWrapper: any = styled.div`
  display: flex;
  align-items: center;
  color: #6e6e6e;
  width: 20%;
  padding-left: 10px;
`;

export const Icon: any = styled.div<{isShowSubItems: boolean}>`
    
`;

export const NavigationLinks: any = styled.div<{isMenuActive: boolean}>`
  width: 100%;
  background-color: #fff;
  height: 0;
  ${BurguerIcon} {
    display: none;
  }

  ul {
    align-items: flex-start;
    display: ${({ isMenuActive }) => isMenuActive ? "flex" : "none"};
    flex-direction: column;
    list-style: none;
    width: 100%;
    background-color: #fff;
    padding-top: 50px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: unset;
    padding: 0 15px;
    top: 0;
    ${NavigationLink} {
      display: flex;
    }

    ${BurguerIcon} {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: fit-content;
      padding-top: 0px;
    }
  }
`;

export const NavigationContainer: FC<any> = styled.div<{isMenuActive: boolean}>`
  display: flex;
  width: 100%;
  
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: fit-content;
    ${BurguerIcon} {
      display: none;
    }
  }
`;

export const SubItem: any = styled.a<{isSubItemActive: boolean}>`
  ${({ isSubItemActive }) => isSubItemActive ? "background: #e67c19; color: #fff" : "background: #fff; color: #666"};
  border-bottom: 1px solid #ccc;
  font-size: 13px;
  font-weight: 300;
  line-height: 18.5514px;
  min-width: 100px;
  padding: 8px 20px;
  width: 100%;
  white-space: nowrap;
  &:hover {
    background: #f5f5f5;
    color: #e67c19
  }
`; 
