import { css } from "styled-components";

export const wrapper: any = css`
    background-color: gray;
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
    width: 100%;
    > div {
      width: 100%;
    }
`;
