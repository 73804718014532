
import React, { FunctionComponent } from "react";
import {  IThemingColor } from "src/common";


const ThemingColor: FunctionComponent<IThemingColor> = props => (
    <div >
       ThemingColor
    </div>
);

export default ThemingColor;
