import { css } from "styled-components";

export const wrapper: any = css`
    width: 100%;
    padding: 0 !important;
    &:before {
      border-bottom-color: ${props => props.theme.secondary};;
    }
    > div {
      border: solid 1px ${props => props.theme.secondary};
    }
`;
