import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const PhotoGalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-right: -15px;
    margin-left: -15px;
  }
`;

export const PhotoGalleryItem = styled.div<{column: number}>`
  width: 50%;
  [data-caption]{
    padding: 1em;
  }
  [data-caption],
  [data-image] {
    height: 300px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      object-fit: cover;
    }
  }

  [data-image] {
    > div{
      height: 100%;
    width: 100%;
    }
    position: relative;
    width: 100%;
  }
  
  [data-image]::before {
    content: " ";
    position: absolute;
    display: block; 
    width: 0px;
    height: 0px;
    border-color: ${({ column }) => column === 0 || column === 2 ? "#fff transparent transparent transparent" : "transparent transparent #fff transparent"};
    border-style: solid;
    border-width: 10px;
    transform: ${({ column }) => column === 0 || column === 2 ? "scale(1.2) translate(0, -2px)" : "scale(1.2) translate(0, 2px)"}; 
    margin: ${({ column }) => column === 0 || column === 2 ? "0 80px 280px 0" : "280px 80px 0 0"};
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 25%;
  }

  &:nth-child(odd) {
    div {
      display: flex;
      flex-direction: column;
      [data-caption] {
        order: 1;
      }
      [data-image] {
        order: 2;
      }
    }
  }
`;

