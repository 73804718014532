import { css } from "styled-components";

import { BREAKPOINTS_DM } from "./mediaquerys";
import { theme } from "./themes";

export const centeredContent: any = css`
    max-width: ${theme.maxWidth}px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;

`;
