import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useState,
} from "react";
import { Context, Img, getHeaderNavItemProps } from "src/common";

import { centeredContent } from "../../../constants/layout";
import LogoLink from "../LogoLink";
import { BurgerMenu } from "./burgerMenu/BurgerMenu";
import * as styles from "./Header.style";
import { ContentInner, Wrapper } from "./Header.style";
import NavItem, { eNavItemType } from "./navItem/NavItem";
import ResponsiveSidebar from "./responsiveSidebar/ResponsiveSidebar";

const Header: FunctionComponent = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const props = useContext(Context);
  const palette = props?.Settings;
  if (!props?.PrimaryNavigation?.items) {
    return null;
  }

  const desktopMenuVisualization = props?.Settings?.desktopMenuVisualization;
  const burgerMenuOnDesktop = [
    "logoLeftBurgerMenuRight",
    "logoRightBurgerMenuLeft",
    "logoCenterBurgerMenuRight"
  ].includes(desktopMenuVisualization);

  const logoRight = ["logoRightBurgerMenuLeft"].includes(
    desktopMenuVisualization
  );

  const logoCenter = ["logoCenterBurgerMenuRight"].includes(desktopMenuVisualization);
  const mobileMenuVisualization = props?.Settings?.mobileMenuVisualization;
  const mobileSwapBurger = ["logoRightBurgerMenuLeft"].includes(mobileMenuVisualization);

  const LogoComp = (
      <LogoLink href={"/"} logoRight={logoRight} logoCenter={logoCenter}
      mobileSwapBurger={mobileSwapBurger}
      >
        <Img
          lazyload={false}
          contain
          src={props?.CompanyInformationPublic?.logo?.[0]?.src || null}
          alt="logo"
        />
      </LogoLink>
  );

  const NavComp = (
    <>
      <div
        css={`
          position: relative;
          height: 100%;
        `}
      >
        <BurgerMenu
          burgerMenuOnDesktop={burgerMenuOnDesktop}
          onClick={() => setIsSidebarActive(!isSidebarActive)}
        />
        {burgerMenuOnDesktop ? null : (
          <nav css={styles.nav}>
            {props?.PrimaryNavigation?.items.map((item, i) => {
              const itemProps = getHeaderNavItemProps(item);
              if (!itemProps) {
                return null;
              }
              return (
                <Fragment key={`${i}NavItem`}>
                  <NavItem
                    type={eNavItemType.header}
                    link={itemProps?.link}
                    headline={itemProps?.headline}
                    title={itemProps?.title}
                    subItems={item.subitems as any}
                  />
                </Fragment>
              );
            })}
          </nav>
        )}
      </div>
    </>
  );

  return (
    <Fragment>
      <Wrapper
        className="header header-t3"
        borderColor={palette.themeColorPrimary}
        background={palette.themeBackgroundColor}
      >
        <ContentInner
         logoRight={logoRight}
         logoCenter={logoCenter}
         mobileSwapBurger={mobileSwapBurger}
         burgerMenuOnDesktop={burgerMenuOnDesktop}
          css={`
            ${centeredContent}
          `}
        >
          {logoRight ? (
            <>
              {NavComp}
              {LogoComp}
            </>
          ) : (
            <>
              {LogoComp}
              {NavComp}
            </>
          )}
        </ContentInner>
      </Wrapper>
      <div css={styles.placeholder} />
      <ResponsiveSidebar
        className="header-mobile header-mobile-t3"
        isActive={isSidebarActive}
        closeSidebar={() => setIsSidebarActive(false)}
      >
        {props?.PrimaryNavigation?.items.map((item, i) => {
          const itemProps = getHeaderNavItemProps(item);

          if (!itemProps) {
            return null;
          }

          return (
            <Fragment key={`${i}NavItem2`}>
              <NavItem
                type={eNavItemType.sidebar}
                link={itemProps?.link}
                headline={itemProps?.headline}
                title={itemProps?.title}
                subItems={item.subitems as any}
              />
            </Fragment>
          );
        })}
      </ResponsiveSidebar>
    </Fragment>
  );
};

export default Header;
