import styled from "styled-components";

export const SDivider = styled.hr`
height: 13px;
background-color: #92c2d6;
margin: 40px auto 30px auto;
display: block;
width: 100%;
border: none;
max-width: 1040px;
`