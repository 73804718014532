import React, { FC, Fragment, useContext } from "react";
import Gallery from "src/common/BaseComponents/Gallery/Gallery";
import { Context, getIfNewsShouldBeDisplayByDate, IContinuingPageGallery, Img } from "src/common";
import SContinuingPageGallery from "./Styles/SContinuingPageGallery";
import { IProduct } from "src/common/childTypes";
import { ItemDefault } from "./items/ItemDefault";
import { ProductItemT5 } from "./items/ProductItemT5";
import { ProductItemT7 } from "./items/ProductItemT7";
import { ProductItemT6 } from "./items/ProductItemT6";
import { ProductItemT8 } from "src/templates/t8/components/elements/ContinuingPageGallery/items/ProductItemT8";
import { ProductItemT17 } from "src/templates/t17/components/common/ContinuingPageGallery/items/ProductItemT17";
import { ProductItemT23 } from "src/templates/t23/components/common/ContinuingPageGallery/items/ProductItemT23";
import { PageItemT3 } from "./items/PageItemT3";
import { ContinungGalleryProductItemT3 } from "../../../../templates/t3/components/common/ContinungGalleryProductItem";
import { ProductItemT24 } from "src/templates/t24/components/common/ProductItem";
import { ProductItemT26 } from "src/templates/t26/components/common/ProductItem";
import PageItemT27 from "./items/PageItemT27/PageItemT27";
import ProductItemT27 from "./items/ProductItemT27/ProductItemT27";

export const ContinuingPageGallery: FC<{
    items: any;
    maxCountPerRow?: number;
}> = (props) => {
    const p = useContext(Context);

    const getItemsPerRow = () => {
        switch (p?.Settings?.template) {
            case "t27":
                
                if (props.maxCountPerRow === 3) return 3;

                return props.maxCountPerRow <= 2 ? 2 : 4;
            default:
                return props.maxCountPerRow || 2;
        }
    };

    const itemsPerRow = getItemsPerRow();

    // console.log(` items`, props.items);
    // console.log(` itemsPerRow`, itemsPerRow);

    return (
        <SContinuingPageGallery template={p?.Settings?.template} itemsPerRow={itemsPerRow}>
            <div className={`cpg ${p?.Settings?.template === "t7" ? " borderthing" : ""}`}>
                <Gallery fullWidth bronze={1} silver={itemsPerRow} gold={itemsPerRow}>
                    {props.items?.map((item, i) => {
                        const product = (item?.link?.[0] || item?.link?.[1]) as IProduct;
                        const isProduct = product?.__typename.includes("Product");
                        const isPage = product?.__typename.includes("Page");
                        const isExternalLink = product?.__typename.includes("ExternalLink");
                        if (
                            isProduct &&
                            (!product?.acceptAGB ||
                                !getIfNewsShouldBeDisplayByDate({
                                    startDate: product?.startDate,
                                    endDate: product?.endDate,
                                }))
                        ) {
                            console.log(` product not valid: `, product);
                            return null;
                        }

                        const DefaultItem = () => {
                            // Renderer should be injected as a render prop.
                            if (typeof props.children === "function") {
                                const ItemComponent = props.children;
                                return <ItemComponent key={`cpg-item-rp-${i}`} item={item} index={i} />;
                            }

                            return (
                                <Fragment key={`cpg-item${i}`}>
                                    <ItemDefault item={item}></ItemDefault>
                                </Fragment>
                            );
                        };

                        switch (p?.Settings?.template) {
                            case "t3":
                                return isPage ? (
                                    <Fragment key={`cpg-item${i}`}>
                                        <PageItemT3 item={item} i={i} maxCountPerRow={itemsPerRow}></PageItemT3>
                                    </Fragment>
                                ) : (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ContinungGalleryProductItemT3
                                            item={item}
                                            i={i}
                                        ></ContinungGalleryProductItemT3>
                                    </Fragment>
                                );

                            case "t5":
                                return isProduct ? (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ProductItemT5 item={item} i={i}></ProductItemT5>
                                    </Fragment>
                                ) : (
                                    <DefaultItem />
                                );

                            case "t6":
                                return isProduct ? (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ProductItemT6 item={item} i={i}></ProductItemT6>
                                    </Fragment>
                                ) : (
                                    <DefaultItem />
                                );

                            case "t7":
                                return isProduct ? (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ProductItemT7 item={item} i={i}></ProductItemT7>
                                    </Fragment>
                                ) : (
                                    <DefaultItem />
                                );

                            case "t8":
                                return isProduct ? (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ProductItemT8 item={item} i={i}></ProductItemT8>
                                    </Fragment>
                                ) : (
                                    <DefaultItem />
                                );

                            case "t17":
                                return isProduct ? (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ProductItemT17 item={item} i={i}></ProductItemT17>
                                    </Fragment>
                                ) : (
                                    <DefaultItem />
                                );

                            case "t23":
                                return (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ProductItemT23 item={item} i={i} />
                                    </Fragment>
                                );

                            case "t24":
                                return (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ProductItemT24 item={item} />
                                    </Fragment>
                                );

                            case "t26":
                                return (
                                    <Fragment key={`cpg-item${i}`}>
                                        <ProductItemT26 item={item} i={i} />
                                    </Fragment>
                                );

                            case "t27":
                                return (
                                    <Fragment key={`cpg-item${i}`}>
                                        {!isProduct ? (
                                            <PageItemT27 item={item} itemsPerRow={itemsPerRow} />
                                        ) : (
                                            <ProductItemT27 item={item} itemsPerRow={itemsPerRow} />
                                        )}
                                    </Fragment>
                                );

                            default:
                                return <DefaultItem />;
                        }
                    })}
                </Gallery>
            </div>
        </SContinuingPageGallery>
    );
};
export default ContinuingPageGallery;
