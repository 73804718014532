import styled, { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const contentArea_wrapper: any = css`
    margin-bottom: 20px;
    padding: 10px;
    display: block;
    a {
        display: flex;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        display: none;
    }
`;

export const sideBar_wrapper: any = css`
    @media (${BREAKPOINTS_DM.silver_768}) {
        display: block;
    }
`;

export const landingImage_wrapper: any = css`
    display: none;
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: 22%;
    border-radius: 50%;
    *,
    h3 {
        text-align: center;
    }
    h3 {
        /* margin-top: 45px; */
        padding-bottom: 0.6rem;
        padding-top: 0.6rem;
    }
    a > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: block;
    }
`;

export const iconWrapper: any = css`
    margin-right: 15px;
`;

export const EventWrapper: any = styled.div`
    ${({ isOnLanding }: any) =>
        isOnLanding
            ? css`
                  h1,
                  h2,
                  h3,
                  a,
                  p,
                  div,
                  h4,
                  h5,
                  h6 {
                      &,
                      span {
                          text-align: center !important;
                      }
                  }
                  max-width: 300px;
                  > * {
                      width: 100%;
                      padding: 0 20px;
                  }
              `
            : ""};

    h3.event-headline {
        padding-left: 0px;
    }
    font-size: 12px;
    font-family: "Roboto Light", Roboto, Helvetica, Arial, sans-serif;
    /* display: flex; */
    justify-content: center;
    /* align-items: center; */
    min-width: 150px;
    > * {
        width: 100%;
    }
    > h3,
    h3,
    h4,
    h2,
    h1 {
        font-family: "Roboto Bold", Roboto, Helvetica, Arial, sans-serif;
        margin-top: 0px;
        margin-bottom: 0px;
        text-overflow: clip;
        overflow: hidden;
        font-size: 16px;
        line-height: 140%;
        font-weight: 700;
        display: block;
        /* margin-right: 10px; */
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 16px;
    }
    > div {
        display: inline;
    }
    svg {
        margin-left: 2px;
        display: inline;
    }
`;

export const landingEventRichtext: any = css`
    overflow: hidden;
    opacity: inherit;
    line-height: 140%;
    font-size: 12px;
    * {
        font-size: inherit;
    }
`;

export const landingImage_landingEventRichtext: any = css`
    text-align: center;
    width: 80%;
`;

export const contentArea_landingEventRichtext: any = css`
    br {
        display: none;
    }
    p {
        display: block;
    }
`;

export const eventIcon: any = css`
    margin: 10px 10px 0px 0px;
    transform: translateY(-10px);
    height: 43px;
    width: 43px;
`;

export const Wrapper: any = styled.div<{
    isOnLanding: boolean;
    sideBar: boolean;
    clickAble: boolean;
    background: string;
    color: string;
}>`
    @media (${BREAKPOINTS_DM.gold_1024}) {
        &.first {
            display: none;
        }
    }
    background-color: ${({ background }) => (background ? background : colors.actionBanner)};
    color: ${({ color }) => (color ? color : "white")};
    a {
        cursor: ${({ clickAble }) => (clickAble ? "pointer" : "default")};
    }
    &:hover {
        * {
            opacity: ${({ clickAble }) => (clickAble ? 0.8 : 1)};
        }
    }
    * {
        color: inherit;
        opacity: 1;
    }
    svg {
        margin-top: 4px;
        width: 12px;
    }

    ${({ isOnLanding }) => (isOnLanding ? `${landingImage_wrapper}` : `${contentArea_wrapper}`)};
    ${({ sideBar }) => sideBar && `${sideBar_wrapper}`};

    @media (${BREAKPOINTS_DM.gold_1024}) {
        right: 9%;
        display: block;
    }
    @media (${BREAKPOINTS_DM.platinum_1366}) {
        right: 0%;
        display: block;
    }
`;
