import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const ST5HeaderWrapper: any = styled.div`
  width: 100%;
  margin-bottom: 27px;
  padding-top: 50px;
  @media (${BREAKPOINTS_DM.gold_1024})
  {
    padding-top: 70px;
  }
`;

export const ST5HeaderContent: any = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const ST5HeaderLogosWrapper: any = styled.a`
    >img:first-child{
      width: auto;
      height: 100px;
      margin: 0 30px 10px 0;
    }
    display: flex;
    align-items: flex-end;
    flex: 1;
    @media (max-width: 500px)
    {
        img {
          width: 100%;
          height: auto;
          margin-right: 0;
        }
    }
    @media (max-width: 500px)
    {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      >img:first-child{
        width: auto;
        height: 100px;
        margin: 0 0 10px 0;
      }
    }
`;

export const SHeaderLogo1: any = styled.img`
    width: auto;
    height: 100px;
    margin-right: 30px;
    max-width: calc(100vw - 8vw);
`;

export const SHeaderLogo2: any = styled.img`
    width: auto;
    height: 100px;
    margin-bottom: 10px;
    max-width: calc(100vw - 8vw);
`;


export const ST5HeaderNav: any = styled.nav`
  display: block;
  @media (${BREAKPOINTS_DM.gold_1024})
  {
      display: none;
  }
`;

