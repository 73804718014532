import { css } from "styled-components";

import { BREAKPOINTS_DM } from "./mediaquerys";
import { theme } from "./themes";

export const centeredContent: any = css`
    max-width: ${theme.maxWidth}px;
    width: 100%;
    margin: 0 auto;
`;
export const cutCorner: any = css`
    margin-bottom: 27px;
    padding: 27px;
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: 1;
      border-bottom: 1px solid ${props => props.theme.secondary};
      background: white;
      width: 40px;
      height: 40px;
      transform: rotate(-45deg);
    }
`;

export const contentWrapper: any = css`
    padding: 60px 0;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        padding: 30px 0;
    }
`;
const flexItem: any = css`
    width: calc(50%);
    flex-grow: 0;
    flex-shrink: 1;
`;

export const flexItem25: any = css`
    ${flexItem};
    width: calc(25%);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: calc(50%);
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        width: 100%;
    }
`;

export const flexItem50: any = css`
    ${flexItem};
    width: calc(50%);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: 100%;
    }
`;

export const flexItem100: any = css`
    ${flexItem};
    width: 100%;
`;