import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/mediaquerys";
import { SFullWidthBannerBase } from "./SFullWidthBannerBase";

export interface ISFullWidthBannerSpacerProps {
  active?: boolean;
  animationTransitionDelayMs: number;
}

const Default = css<ISFullWidthBannerSpacerProps>`
  position: relative;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const activeState = css<ISFullWidthBannerSpacerProps>``;

export const SFullWidthBannerSpacer = styled(SFullWidthBannerBase)<ISFullWidthBannerSpacerProps>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ active }) => (active ? activeState : "")};
`;
