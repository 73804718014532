import { useContext,  } from "react";
import { SDealerSearchBody } from "./styles/SDealerSearchBody";
import { SDealerSearchContainer } from "./styles/SDealerSearchContainer";
import { SDealerSearchTop } from "./styles/SDealerSearchTop";
import { useRouter } from "next/router";
import { Context } from "../../../../../common";

import React from "react";
import { useDealerSearch } from "../../../../../common/hooks/useDealerSearch";

export function IconArrowRight(props: any) {
  const colorBg = props?.colorBg || "#000";
  const colorLine = props?.colorLine || "#FFF";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 54 54"
      version="1.1"
      viewBox="0 0 54 54"
      xmlSpace="preserve"
    >
      <g fill={`${colorBg}`}>
        <path d="M27 53C12.641 53 1 41.359 1 27S12.641 1 27 1s26 11.641 26 26-11.641 26-26 26z"></path>
        <path d="M27 54C12.112 54 0 41.888 0 27S12.112 0 27 0s27 12.112 27 27-12.112 27-27 27zm0-52C13.215 2 2 13.215 2 27s11.215 25 25 25 25-11.215 25-25S40.785 2 27 2z"></path>
      </g>
      <path
        fill={`${colorLine}`}
        stroke={`${colorLine}`}
        strokeWidth="5"
        d="M22.294 40a.999.999 0 01-.707-1.707L32.88 27 21.587 15.707a.999.999 0 111.414-1.414l11.498 11.498a1.711 1.711 0 010 2.418L23.001 39.707a.997.997 0 01-.707.293z"
      ></path>
    </svg>
  );
}

export const DachseiteSearch = () => {
  const props = useContext(Context);
  const router = useRouter();
  const { triggerSearch } = useDealerSearch();

  const isGelectra =
  props.dealer?.dachseite &&
  props?.CompanyInformationPrivate?.line === "gelectra";

  return (
    <>
      <SDealerSearchContainer>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            triggerSearch((e.target as any)?.elements?.[0]?.value);
          }}
        >
          <SDealerSearchTop>
            <div>
              <input
                name="place"
                type={"text"}
                placeholder={ (router.query?.search as any) || "PLZ oder Ort eingeben"}
              />
            </div>
            <div>
              <button type="submit">
                Händler finden
                <IconArrowRight
                  colorBg={props.Settings.themeColorPrimary}
                ></IconArrowRight>
              </button>
            </div>
          </SDealerSearchTop>
        </form>
        <SDealerSearchBody>
          <p>
            <strong>Finden Sie Ihren{isGelectra ? " Gelectra Partner" : " Küchen.Geräte.Partner."}</strong>
            <br></br>
            <span>
              aus über 90 Elektromärkten und Kundendienststellen in Deutschland
            </span>
          </p>
        </SDealerSearchBody>
      </SDealerSearchContainer>
    </>
  );
};
