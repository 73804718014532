import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";
import { theme } from "../../../constants/themes";

const height = 50;
const height768 = 82;
const height1024 = 100;
const imgOverflowMultiplicator = 1.25;
const imgOverflowMultiplicator1024 = 1.1;

export const wrapper: any = css`
  overflow: hidden;
  height: calc(${height}vh - ${theme.headerHeight}px);
  width: 100%;
  position: relative;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: calc(${height768}vh - ${theme.headerHeight}px);
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: calc(${height1024}vh - ${theme.headerHeight}px);
  }
`;

export const img: any = css`
  background-size: cover;
  background-position: center;
  height: calc(
    ${height * imgOverflowMultiplicator}vh - ${theme.headerHeight}px
  );
  width: 100vw;
  position: fixed;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: calc(
      ${height768 * imgOverflowMultiplicator}vh - ${theme.headerHeight}px
    );
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: calc(
      ${height1024 * imgOverflowMultiplicator1024}vh - ${theme.headerHeight}px
    );
  }
`;

export const KW: any = css<any>`
  h1 {
    border-width: 3px;
    border-style: solid;
    padding: 8px;
    display: inline-flex;
  }
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &,
    span {
      border-width: 3px;
      border-top-width: 0;
      border-style: solid;
      padding: 8px;
      display: inline-flex;
    }
  }
`;
export const Heading: any = styled.div<{
  textColor: string;
  background: string;
  headlineWithoutBackground: boolean;
  themeColorSecondary: string;
  isKw: boolean;
}>`
  display: none;
  position: absolute;
  z-index: 2;
  max-width: 1000px;
  font-size: 40px;
  font-weight: 600;
  bottom: 25px;
  left: 20px;
  margin-right: 30px;
  h1,
  h2 {
    background-color: ${({ headlineWithoutBackground, background }) =>
      (!headlineWithoutBackground && background) ? background : "transparent"};
    color: ${({ textColor }) => (textColor ? textColor : "white")};
    font-family: "Poppins", sans-serif;
  }
  h1 {
    font-family: "Poppins", sans-serif;
    width: unset;
    display: inline;
    padding:  ${({ isKw }) => (isKw ? `3px` : "0px")} 0;
    font-size: 40px;
    font-weight: 600;
  }
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &,
    span {
      background-color: ${({ headlineWithoutBackground, background }) =>
        (!headlineWithoutBackground && background) ? background : "transparent"};
      font-family: "Poppins", sans-serif;
      color: ${({ themeColorSecondary, isKw }) =>
        (themeColorSecondary && isKw) ? themeColorSecondary : "white"};
      border-color: white;
      font-weight: 200;
      font-size: 40px;
      width: unset;
      display: inline;
      padding:  ${({ isKw }) => (isKw ? `6px` : "0px")} 0;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: block;
  }
  ${({ isKw }) => (isKw ? `${KW}` : "")};
`;

export const contentWrapper: any = css`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  display: flex;
  justify-content: flex-end;
`;
