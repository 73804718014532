import React, { Fragment, useContext } from "react";
import { Context } from "../../../../common";
import { default as PhotoGallery } from "src/common/components/PhotoGalleryVisualization/PhotoGallery";

interface IUniversalComponentsRendererProps {
  items: any[];
  children?: React.ReactNode;
}

const UniversalComponentsRenderer = ({
  items,
  children,
}: IUniversalComponentsRendererProps): React.ReactElement => {
  const c = useContext(Context);
  const isLanding = !c.slug;
  // console.log(` items`, items);
  return (
    <Fragment>
      {items &&
        items.map((item, key) => {
          try {
            if (`${item?.__typename}`.includes("UnknownSlice")) {
              return null;
            }

            if (item?.__typename == "PhotoGallery") {
              return (
                <React.Fragment key={key + `${item?.__typename}`}>
                  <PhotoGallery
                    // {...item}
                    item={item}
                    // isStickedToComponentAbove={isStickedToComponentAbove}
                    //   index={key}
                  >
                    {children}
                  </PhotoGallery>
                </React.Fragment>
              );
            }

            const MountedComponent =
              require(`../../components/elements/${item?.__typename}/${item?.__typename}`).default;

            // const MountedComponent = require(`../../components/elements/${item?.__typename}/${item?.__typename}`)
            //     .default;

            let isStickedToComponentAbove = false;

            if (key >= 1 && items[key - 1]?.__typename === "Asset") {
              isStickedToComponentAbove = true;
            }
            if (
              isLanding &&
              key >= 1 &&
              items[key - 1]?.__typename === "Slider"
            ) {
              isStickedToComponentAbove = true;
            }

            return (
              <React.Fragment key={key + `${item?.__typename}`}>
                <MountedComponent
                  {...item}
                  isStickedToComponentAbove={isStickedToComponentAbove}
                  index={key}
                >
                  {children}
                </MountedComponent>
              </React.Fragment>
            );
          } catch (e) {
            console.error(item?.__typename, e);
            return null;
          }
        })}
    </Fragment>
  );
};

export default UniversalComponentsRenderer;
