import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const linksWrapper: any = css`
  font-family: Roboto,sans-serif;
  font-size: 13px;
  line-height: 1.4;
  padding-bottom: 10px;
  font-weight: 500;
    &:hover {
      color: ${colors.green};
    }
`;

export const linksBoldContent: any = css`
  width: fit-content;
  margin: 7px 0px 20px;
  border: 2px solid;
  border-radius: 5px;
`;

export const linksBoldWrapper: any = css`
  font-family: Roboto,sans-serif;
  font-size: 13px;
  line-height: 1.4;
  padding: 2px 12px;
  font-weight: 800;
    &:hover {
      color: ${colors.green};
    }
`;

export const footerBottom: any = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${colors.ligth_gray};
  margin: 0px 0px 50px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }
`;
