import React, { FunctionComponent } from "react";

import { LinksWrapper, Link } from "./Footer.style";

const Navigation: FunctionComponent<{ items: any[] }> = ({ items }) => (
  <LinksWrapper>
    {items.map((linkItem, i) => (
      <Link isBold={linkItem.isBold} href={linkItem?.link} key={`links-key-${i}`}>
        {linkItem?.title}
      </Link>
    ))}
  </LinksWrapper>
);

export default Navigation;
