import React, { FunctionComponent } from "react";

import * as styles from "./ResponsiveSidebar.style";

interface IProps
{
  isActive: boolean;
  closeSidebar: () => void;
}

const ResponsiveSidebar: FunctionComponent<IProps> = props => (
  <div css={`${styles.wrapper}${props?.isActive && styles.active}`}>
    <div css={styles.burgerMenuWrapper}/>
    {props?.children}
  </div>
);

export default ResponsiveSidebar;
