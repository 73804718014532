import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const wrapper: any = css`
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;
  transition: height .3s linear;
  box-shadow: none;
  z-index: 999;
  overflow: hidden;
  min-height: 72px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  @media (${BREAKPOINTS_DM.gold_1024})
  {
      display: flex;
  }
`;

export const burgerMenuWrapper: any = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 72px;
`;
