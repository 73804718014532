import React, { FC } from "react";
import { IAsset, ISlider, Img, IPage } from "src/common";
import {
  IPageGuestbook,
  IPageRepairInquiry,
  IPageDateInquiry,
  IPageContact,
  IPageBrand,
  IPageBrandOverview,
  IPageLanding,
  IPageImprint,
  IPageNews,
  IPageCourse,
} from "src/common/childTypes";
import FadeSlider from "src/common/components/FadeSlider/Slider";

import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import { HeroContainer } from "./Hero.style";

const OVERRIDE_STYLE = `
  ${SSlickBase}
  ${SSlickTheme}
`;

const SLIDER_OPTIONS = {
  arrows: true,
  dots: false,
  autoplaySpeed: 4000,
  fade: true,
};

const Hero: FC<{
  page:
    | IPageGuestbook
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageContact
    | IPageBrand
    | IPageBrandOverview
    | IPageLanding
    | IPageImprint
    | IPageNews
    | IPageCourse
    | any;
  lg?: boolean;
}> = ({ page, lg }) => {
  // @ts-ignore
  const images =
    (page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[]) ||
    (page?.courses[0]?.elements?.filter(
      (e) => e?.__typename === "Asset"
    ) as IAsset[]);
  // @ts-ignore
  const slider =
    (page?.elements?.[0] &&
      [page?.elements[0]]?.filter((e) => e?.__typename === "Slider")) ??
    ([] as ISlider[]);
  let component = null;

  if (slider && slider?.[0]?.elements) {
    component = (
      <FadeSlider
        overrideStyle={OVERRIDE_STYLE}
        elements={slider?.[0]?.elements}
        options={SLIDER_OPTIONS}
      />
    );
  } else if (images?.[0]) {
    // if(images.length > 1)
    // {
    //   component = <FadeSlider
    //     overrideStyle={OVERRIDE_STYLE}
    //     elements={[...images?.map(f => f)]}
    //     options={SLIDER_OPTIONS}
    //   />;
    // }
    // else if(images?.length > 1)
    // {
    //   component = <FadeSlider
    //     overrideStyle={OVERRIDE_STYLE}
    //     elements={images}
    //     options={SLIDER_OPTIONS}
    //   />;
    // }
    // else
    // {
    component = (
      <Img
        alt={images?.[0]?.title}
        src={images}
        placeholderHeight="580px"
        quality={1080}
      />
    );
    // }
  }

  return <HeroContainer lg={lg}>{component}</HeroContainer>;
};

export default Hero;
