import React, { FC, useContext, useState } from "react";
import { Context, getHeaderNavItemProps, IContext } from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import Map from "../Map";
import { PlanningDateModaL } from "../PlanningDateModaL/PlanningDateModaL";
import { RichText } from "../RichText";
import { CompanyInfoWrapper, InfoTitle, InfoLink, MapWrapper, Info } from "./CompanyInfo.style";

const InfoItem: any = ({
  title, text, slugRef, map, onToogleShowModal, isContact 
}) => 
  (
    <>
      {slugRef && !map ? (
        <InfoLink href={slugRef}>{title}</InfoLink>
      ) : ( 
        <InfoTitle>
          {map ? (
            <>
              <span className="title">{title}</span>
              <div className="item-data" >
                <MapWrapper href={slugRef}>
                  {!isContact && <Map/>}
                </MapWrapper>
                <Info textAlign="left" margin>
                <p>{getContactCompanyNameShort()}</p>
                  <p>{text.street}</p>
                  <p>{text.postcode} {text.place}</p>
                </Info>
                
              </div>

            </>
          ):
            (
              <> 
                <span className="title">{title}</span> 
                <div className="item-data" >
                  <Info textAlign="center">
                    <RichText textContent={text}/>
                    <p>Weitere Termine nach</p>
                    <p className="button" onClick={() => onToogleShowModal(true)}>Vereinbarung</p>
                  </Info>
                </div>
              </>
            )}
        </InfoTitle>
      )}
    </>
  );
const CompanyInfo: FC<{isContact: boolean}> = ({ isContact }) => 
{
  const props = useContext<IContext>(Context);
  const [isShowModal, toogleShowModal] = useState(false);
  return (
    <>
      <CompanyInfoWrapper>
        <InfoItem title="Öffnungszeiten" text={props?.CompanyInformationPublic?.openingHours} onToogleShowModal={toogleShowModal} />
        <InfoItem title="Anfahrt" text={props?.CompanyInformationPublic} slugRef={props?.PageContact?.slug} map isContact={isContact}/>
        <InfoItem title="Kontakt" slugRef={props?.PageContact?.slug}/>

      </CompanyInfoWrapper>
      {isShowModal && <PlanningDateModaL onSetShowModal={toogleShowModal}/>}
    </>
  );
};

export default CompanyInfo;
