import React, { FunctionComponent, useState, useContext, useEffect } from "react";
import { INavigationItem, Context, IContext } from "src/common";

import {
  NavigationContainer,
  NavigationLinks,
  NavigationLink,
  BurguerIcon,
  SubItemWrapper,
  IconWrapper,
  Icon
} from "./Navigation.styles";

interface IHeaderItemProps
{
  title: string;
  link: string;
  headline?: string;
  external?: boolean;
}

const getHeaderNavItemProps = (item: Record<string, any>): IHeaderItemProps =>
{

  const linkItem = item?.link?.[0];

  if((linkItem as any)?.__typename === "ExternalLink")
  {
    return {
      link: `${(linkItem as any).externalLinkAddress}`,
      title: `${(item as any).label ?? (linkItem as any).externalLinkName}`,
      external: true,
    };
  }

  const link = linkItem?.slug || (item as any)?.slug;
  const title = (linkItem as any)?.nameInNavigation || (item as any)?.nameInNavigation || (item as any)?.label;
  let headline;

  if(!link || !title)
  {
    return;
  }

  if(linkItem?.__typename === "PageMicro")
  {
    return {
      link,
      title,
    };
  }

  if(linkItem?.__typename === "Page" || linkItem?.__typename === "PageNews" || linkItem?.__typename === "PageGuestbook")
  {
    headline = linkItem.elements?.find(e => e?.__typename === "TextElement")?.[0]?.headline;
  }

  return {
    link,
    title,
    headline
  };
};

const SubItem: any = ({ subItems }) => (
  <SubItemWrapper>
    {subItems?.map((subItem, ind) => (
      <a href={subItem?.slug} key={`subitem-${ind}-ind`} >
        {subItem?.nameInNavigation}
      </a>
    ))}
  </SubItemWrapper>
);

const Items: FunctionComponent<{item: Record<string, any>; itemProps: Record<string, any> }> = ({ itemProps, item }) =>
{
  const props = useContext<IContext>(Context);
  const [isShowSubItems, setShowSubItems] = useState(false);
  const currentItem = props?.PrimaryNavigation?.items?.find(primary => primary?.subitems?.find(secondary => secondary?.slug === props?.slug));
  const subItems = item?.subitems && item?.subitems.length > 0 ? item?.subitems : null;
  // console.log(` subItems`, subItems);
  return (
    <>
      <NavigationLink isActive={itemProps.link === props.slug || itemProps.link === currentItem?.link[0].slug}>
        <a href={itemProps.link ?? "/"}>
          {itemProps.title}
        </a>
        {subItems &&
          <IconWrapper onClick={() => setShowSubItems(!isShowSubItems)}>
            <Icon isShowSubItems={isShowSubItems}>
              <i className="fa fa-chevron-right"/>
            </Icon >
          </IconWrapper>
        }
      </NavigationLink>
      {isShowSubItems && 
        <SubItem subItems={subItems}/>
      }
    </>
  );
};

// Hardcoding home nav item
const homeNavItem: INavigationItem = {
  __typename: 'NavigationItem',
  label: "Gute Küchen",
  link: [{ __typename: "Page", slug: null, nameInNavigation: "Gute Küchen"}],
};

const homeNavItemProps = {link: null, title: "Gute Küchen"}

const Navigation: FunctionComponent<{ items: INavigationItem[]; slug: string }> = ({ items }) => 
{
  const [isMenuActive, toggleMenuActive] = useState(false);
  return (
    <NavigationContainer isMenuActive={isMenuActive}>
      <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} isMenuActive={isMenuActive}>
        <i className="close fa fa-times"/>
        <i className="open fa fa-bars"/>
      </BurguerIcon>

      <NavigationLinks isMenuActive={isMenuActive}>
        <ul>
        <Items itemProps={homeNavItemProps} item={homeNavItem} />
          {items.map((item, index) => 
          {
            const itemProps = getHeaderNavItemProps(item);
            if(!itemProps) { return; }
          
            return (
              <Items itemProps={itemProps} item={item} key={`item-key-${index}`}/> 
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
