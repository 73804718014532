import dynamic from "next/dynamic";
import { useContext } from "react";
import { Context } from "../..";
const FullWidthBannerLazy = dynamic(
  () => import("./FullWidthBannerLazy").then((mod) => mod.FullWidthBannerLazy as any),
  {}
);
export const FullWidthBanner = ({children}) => {

  const p = useContext(Context)
  if(!p.fullWidthBanner) return <>{children}</>;
  
  return <FullWidthBannerLazy>{children}</FullWidthBannerLazy>;
};
