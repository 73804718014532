import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";
import { theme } from "../../../../constants/themes";

const padding = 17;

export const active: any = css`
    transition: transform ease-in-out .45s, box-shadow ease .1s;
    transform: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    @media (${BREAKPOINTS_DM.silver_768})
    {
        display: none;
    }
`;

export const BurgerMenuWrapper: any = styled.div`
    height: ${theme.headerHeight}px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: ${padding}px;
    margin-bottom: 16px;
`;

export const Wrapper: any = styled.div<{isActive: boolean}>`
  position: fixed;
  width: 500px;
  max-width: 90%;
  background-color: white;
  top: 0;
  right: 0;
  height: 100%;
  transition: transform ease-in .45s, box-shadow ease .45s;
  transform: translateX(100%);
  box-shadow: none;
  z-index: 999;
  ${({ isActive }) => isActive && `${active}`}
  > div:not(:first-child) {
        > a {
            color: #222;
        }
    }
`;

