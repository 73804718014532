import styled, { css } from "styled-components";

const Default = css`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SModalContent = styled.div`
    ${Default}
`;
