import Head from "next/head";
import React, { useEffect } from "react";

function fcCallback(e) {
  const event = new CustomEvent("fcaptcha");
  window.sessionStorage.setItem("fcaptcha", e);
  document.dispatchEvent(event);
}

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  constructor(props) {
    super(props);
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
      if (this.state.hasError) {
        return <h1>Captcha wird nicht unterstützt</h1>;
      }

    return this.props.children;
  }
}

export const FriendlyCaptchaInner = () => {
  return (
    <>
      <Head>
        <script
          type="module"
          src="/scripts/fc.module.min.js"
          async
          defer
        ></script>
        <link rel="stylesheet" id="frc-style" href="/scripts/fc.css" />
      </Head>
      <div
        data-lang="de"
        style={{ height: "100px" }}
        className="frc-captcha"
        data-callback="fcCallback"
        data-sitekey="FCMTG4TENAJLK1P0"
      />
    </>
  );
};

const FriendlyCaptchaInternal = () => {
  useEffect(() => {
    (window as any).fcCallback = fcCallback;
  }, []);

  return (
    <>
      <ErrorBoundary>
        <FriendlyCaptchaInner />
      </ErrorBoundary>
    </>
  );
};

export const FriendlyCaptcha = React.memo(FriendlyCaptchaInternal);
