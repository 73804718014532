
import React, { FC, useContext } from "react";
import { Context, IContext } from "src/common";
import Affix from "src/common/components/Affix";

import { HaderContainer, LogoLink, Logo } from "./Header.styles";
import Navigation from "./Navigation";

const Header: FC = () => {
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  return (
    <HaderContainer>
      <Affix offset={50}>
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} />
      </Affix>
      {logo && <LogoLink href="/"><Logo src={props.slug ? logo.src : "/images/kg_home_teaser_logo.png"} /></LogoLink>}
    </HaderContainer>
  );
};

export default Header;