import { useEffect } from "react";
import Snowflakes from "magic-snowflakes";

export const Snowparticles = () => {
  useEffect(() => {
    const month = new Date().getMonth();
    const day = new Date().getDate();
    if (month == 11 || (month == 10 && day > 27) || (month == 0 && day <= 7)) {
      new Snowflakes({
        color: "#fff", // Default: "#5ECDEF"
        container: document.querySelector("#snowflakes-container"), // Default: document.body
        count: 350, // 100 snowflakes. Default: 50
        minOpacity: 0.3, // From 0 to 1. Default: 0.6
        maxOpacity: 0.95, // From 0 to 1. Default: 1
        minSize: 5, // Default: 10
        maxSize: 9, // Default: 25
        rotation: true, // Default: true
        speed: 2.5, // The property affects the speed of falling. Default: 1
        wind: true, // Without wind. Default: true
        // width: 500, // Default: width of container
        // height: 250, // Default: height of container
        zIndex: 100, // Default: 9999
      });
    }
  }, []);

  return <div></div>;
};
