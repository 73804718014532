import React, { FunctionComponent, useState, useContext, useEffect } from "react";
import { getHeaderNavItemProps, INavigationItem, Context, IContext } from "src/common";

import {
  NavigationContainer,
  NavigationLinks,
  NavigationLink,
  BurguerIcon,
  Icon,
  SubItem,
} from "./Navigation.styles";

const SubItemLink: FunctionComponent<{subItem: Record<string, any>; currentSubItem: boolean }> = ({ subItem, currentSubItem }) => (
  <SubItem href={`/${subItem?.slug}`} isSubItemActive={currentSubItem}>
    {subItem?.nameInNavigation}
  </SubItem>
);

const Items: FunctionComponent<{item: Record<string, any>; itemProps: Record<string, any> }> = ({ itemProps, item }) =>
{
  const props = useContext<IContext>(Context);
  const [isShowSubItem, toogleShowSubItem] = useState(false);
  const currentItem = props?.PrimaryNavigation?.items?.find(primary => primary?.subitems?.find(secondary => secondary?.slug === props?.slug));
  const subItems = item?.subitems;
  return (
    <>
      <NavigationLink isActive={itemProps.link === props.slug || itemProps.link === currentItem?.link[0].slug} subItems={subItems?.length > 0 && true} isShowSubItem={isShowSubItem}>
        <div className="link">
          <a href={`/${itemProps?.link}`}>
            {itemProps?.title}
          </a>
          {subItems?.length > 0 &&
          <div className="icon" onClick={() => toogleShowSubItem(!isShowSubItem)}>
            <Icon>
              <i className="fas fa-sort-down"/>
            </Icon >
          </div>
          }
        </div>
        <div className="underline"/>
        
        {subItems?.length > 0 && isShowSubItem && (
          <div className="sub-item-mobile">
            {subItems?.map((subItem, ind) => (
              <a className="sub-item "key={`subitem-${ind}-ind`} href={`/${subItem?.slug}`}>
                {subItem?.nameInNavigation}
              </a>
            ))}
          </div>
        )}
          
        {subItems?.length > 0 && (
          <div className="sub-item-wrapper">
            {subItems?.map((subItem, ind) => (
              <SubItemLink subItem={subItem} key={`subitem-${ind}-ind`} currentSubItem={subItem?.slug === props?.slug}/>
            ))}
          </div>
        )}
        
      </NavigationLink>
    </>
  );
};

const Navigation: FunctionComponent<{ items: INavigationItem[]; slug: string }> = ({ items }) => 
{
  const [isMenuActive, toggleMenuActive] = useState(false);
  const props = useContext<IContext>(Context);
  return (
    <NavigationContainer isMenuActive={isMenuActive}>
      <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} isMenuActive={isMenuActive}>
        <i className="open fa fa-bars"/>
      </BurguerIcon>

      <NavigationLinks isMenuActive={isMenuActive}>
        <ul>
          <NavigationLink isActive={!props.slug}>
            <div className="link">
              <a href={"/"}>
             Home
              </a>
            </div>
            <div className="underline"/>
          </NavigationLink>
          {items.map((item, index) => 
          {
            const itemProps = getHeaderNavItemProps(item);
            if(!itemProps) { return; }
          
            return (
              <Items itemProps={itemProps} item={item} key={`item-key-${index}`}/> 
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
