import { css } from "styled-components";
import { smallHeading } from "../../../constants/textStyles";

export const wrapper: any = css`
  margin-bottom: 0 !important;
  text-align: center;
  width: 100%;
  a,
  a > span {
    color: white !important;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const imageInBox: any = css`
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  img {
    max-width: 600px;
    height: auto;
    margin-bottom: 30px;
  }
`;

export const firstChild: any = css`
  background-color: ${(props) => props.theme.primary};
  * {
    color: white;
  }
  h2 {
    color: white;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 15px;
  }
  ol,
  ul,
  li {
    padding-left: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    * {
      color: ${(props) => props.theme.secondary};
    }
    color: ${(props) => props.theme.secondary};
  }
  a,
  a * {
    color: ${(props) => props.theme.secondary};
  }
`;

export const odd: any = css`
  background-color: white;
  a,
  a > span {
    color: ${(props) => props.theme.palette.blue} !important;
  }
  h3 {
    color: ${(props) => props.theme.primary};
  }
  h4,
  h4 * {
    color: ${(props) => props.theme.primary};
  }
  a,
  a * {
    color: ${(props) => props.theme.primary};
  }
`;

export const even: any = css`
  background-color: rgb(170, 212, 207);
  h3 {
    color: white;
  }
  h4,
  h4 * {
    color: white;
  }
  a,
  a * {
    color: white;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  * {
    color: white;
  }
`;

export const headline: any = css`
  color: ${(props) => props.theme.primary};
`;

export const richtext: any = css`
  display: flex;
  flex-direction: column;
  
  h1,
  h2,
  h3 {
    ${smallHeading};
    a {
      font-size: 24px;
    }
  }
  h4,
  h4 * {
    margin: 5px 0;
    a {
      font-size: 20px;
    }
  }
  table {
    margin: 0 auto 10px;
  }
  tr {
    td:first-child {
      text-align: left;
    }
    td:nth-child(2) {
      padding-left: 30px;
      text-align: right;
    }
  }
  ol,
  ul {
    display: inline-block;
    li {
      text-align: left;
    }
  }
  h1 > a {
    font-size: 32px;
  }
  h2 > a {
    font-size: 28px;
  }
  h5 > a {
    font-size: 18px;
  }
  h6 > a {
    font-size: 15px;
  }
`;

export const figureWrapper: any = css`
  display: table;
  width: fit-content;
  margin: 30px auto;

  figcaption {
    display: table-caption;
    caption-side: bottom;
    color: black;
    background-color: white;
    padding: 5px 5px 10px 5px;
  }
`;
