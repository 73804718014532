import { css } from "styled-components";


export const img: any = css`
  :not(:first-child) {
    margin-top: 20px;
    span {
      margin-top: 20px;
    }
  }
  height: auto;
  span {
    display: inline-block;
  }
`;
