import React, { useContext } from "react";
import { Img, IProduct, ITextElement, RichText, truncateText } from "src/common";
import { SPageItemT3 } from "./Styles/SPageItemT3";
import { Context } from "../../../../..";


export const PageItemT3 = ({ item, i, maxCountPerRow }) => {
  if (!item) {
    return item;
  }
  const c = useContext(Context);
  // console.log(` c`, c.Settings?.themeColorPrimary);
  const product = item?.link?.[0] as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const slug = link?.slug;
  const textElements =
    (
      item?.link[0].elements.filter(
        (e) => e?.__typename == "TextElement"
      ) as ITextElement[]
    )[0]?.text?.content ?? [];

  const firstTextParagraph = textElements.filter(
    (e) => e?.content?.[0]?.text
  )?.[0]?.content?.[0]?.text;

  const threeOrMorePerRow = maxCountPerRow >= 3;
  if (threeOrMorePerRow) {
    return (
      <>
        <SPageItemT3
          hasImage={item.thumbnail?.[0]}
          threeOrMorePerRow={threeOrMorePerRow}
        >
          <div className="textWrapper">
            <div className="textWrapper__content">
              <h2>{item.title}</h2>
              {item.thumbnail?.[0] && (
                <div className="imageWrapper">
                  <Img src={item.thumbnail}></Img>
                </div>
              )}
              {item?.text ? (
                <span className="padding__text">
                  <RichText fragment={true} textContent={item?.text}></RichText>
                </span>
              ) : (
                <p className="padding__text">
                  {truncateText(firstTextParagraph, 150)}
                </p>
              )}
              <a
                className="cpg-item"
                href={`/${isProduct ? "produkt/" : ""}${slug}`}
              >
                <button
                  style={{
                    border: `1px solid ${
                      c?.Settings?.themeTextColor
                        ? c.Settings.themeTextColor
                        : "#222"
                    }`,
                    color: c.Settings?.themeTextColor
                      ? c.Settings.themeTextColor
                      : "#222",
                  }}
                >
                  Mehr erfahren
                </button>
              </a>
            </div>
          </div>
        </SPageItemT3>
      </>
    );
  }

  return (
    <>
      <SPageItemT3 hasImage={item.thumbnail?.[0]}>
        {item.thumbnail?.[0] && (
          <div className="imageWrapper">
            <Img src={item.thumbnail}></Img>
          </div>
        )}
        <div className="textWrapper">
          <div className="textWrapper__content">
            <h2>{item.title}</h2>
            {item?.text ? (
              <>
                <RichText fragment={true} textContent={item?.text}></RichText>
              </>
            ) : (
              <p>{truncateText(firstTextParagraph, 150)}</p>
            )}
            <a
              className="cpg-item"
              href={`/${isProduct ? "produkt/" : ""}${slug}`}
            >
              <button
                style={{
                  border: `1px solid ${
                    c?.Settings?.themeTextColor
                      ? c.Settings.themeTextColor
                      : "#222"
                  }`,
                  color: c.Settings?.themeTextColor
                    ? c.Settings.themeTextColor
                    : "#222",
                }}
              >
                Mehr erfahren
              </button>
            </a>
          </div>
        </div>
      </SPageItemT3>
    </>
  );
};
