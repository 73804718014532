import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Container: any = styled.div<{ rows: boolean }>`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto;

  ${({ rows }) => rows && "flex-direction: row;"}
`;

export const Section: any = styled.div`
  background-color: white;
  padding: 0 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    width: 100%;
    padding: 30px 60px 0 60px;
  }
`;

export const Divider: any = styled.hr<{ nomargin: boolean }>`
  display: block;
  width: 100%;
  height: 12px;
  border: none;
  background: url(/images/kw_spacer_linie.png) top transparent;
  ${({ nomargin }) => (nomargin ? "margin: 0;" : "margin-bottom: 1rem;")}
`;
