import React, { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

const HEIGHT = "100vh";
const WIDTH = "300px";

export const BurguerIconWrapper: any = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 14px;
  padding: 8px 10px;
  cursor: pointer;
  z-index: 999;

  > span {
    display: block;
    height: 3px;
    width: 22px;
    border-radius: 0;
    background: #fff;

    &:not(first-child) {
      margin-top: 4px;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}){
    display: none;
  }
`;

export const HaderContainer = styled.div`
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.white};
  width: 100vw;
  z-index: 999;
  font-size: 12px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }

  @media (${BREAKPOINTS_DM.gold_1024}){
    position: fixed;
    height: ${HEIGHT};
    width: ${WIDTH};
  }
`;

export const Logo = styled.img<{ themeColorPrimary: string}>`
  display: block;
  width: 100%;
  padding: 15px 75px 10px 15px;
  background-color: ${({ theme, themeColorPrimary }) => themeColorPrimary || theme.palette.primary};
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 15px 10px;
  }
`;

export const NavigationLink: any = styled.li<{ active: boolean }>`
  display: flex;
  flex-direction: column;

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }

  :last-child {
    border-right: none;
  }


  width: 100%;

  > a {
    font-size: 13px;
    display: flex;

    padding: 10px 0 8px;
    color: #adadad;
    width: 100%;
    border-top: 1px solid #ccc;
    justify-content: center;

    @media (${BREAKPOINTS_DM.silver_768}) {
      color: ${({ active, theme }) => active ? theme.palette.textColorLight : "#333"};
      line-height: unset;
      padding-left: 10.5px;
      padding-right: 11.5px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-bottom: 2px;

      background-color: transparent;
      border: none;
    }
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks: any = styled.div<{ isMenuActive: boolean }>`
  background-color: ${({ theme }) => theme.palette.white};
  display: flex;
  width: 100%;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    list-style: none;
    overflow-y: auto;

  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: .5em 0;
    ${NavigationLink} {
      display: flex;
    }
  }
`;

export const NavigationContainer: any = styled.div<{ active: boolean }>`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.white};
  overflow: auto;
  ${({ active }) => active
    ? `
      ${NavigationLinks} {
        display: flex;
      }
    `
    : `
      ${NavigationLinks} {
        display: none;
      }
    `
}

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex: unset;
    ${NavigationLinks} {
      display: flex;
    }
  }
`;

export const SubNavigationLinks: any = styled.div<{ active: boolean }>`

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }

  > a {
      font-size: 13px;
      color: ${({ theme }) => theme.palette.white };
      display: flex;
      font-weight: 700;
      padding-left: 10.5px;
      background-color: ${({ active, theme }) => active ? theme.palette.primary : theme.palette.bgInverted};;
      line-height: 31px;
      width: 100%;
      margin-bottom: 2px;
    }

`;

export const Bottom: any = styled.div`
  display: none;
  padding: 20px;
  background-color: rgb(26, 26, 26);;
  font-weight: 300;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.textColorInverted };
  p {
    text-align: center;
    padding: 5px;
    margin: 0;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    flex-direction: column;
  }

  p,
  a {
    font-size: 12px!important;
    color: #aaa !important;
    line-height: 15.6px;
  }
`;

export const BottomLinks: any = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    cursor: pointer;
  }
`;
