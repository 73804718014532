import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

const Default = css`
  display: flex;
  height: 100%;
  > div{
      width: 50%;
  }
  .cpg-price-text, .cpg-price-number{
    color: #fff;
  }
  > div:first-child {
    background:  #fff;
  }
  > div:last-child {
    padding: 20px;
  }
  .cpg-item{
    display: block;
    padding: 20px;
    h2{
      font-weight: 400;
      font-size: 20px;
    }
    color: #fff;
    .cpg-price{
      padding: 0;
      > div:first-child{
        display: flex;
        .cpg-price-number{
          text-align: right;
        }
      }
    }
    .cpg-price-next{
      line-height: 48px !important;
        width: 100%;
        margin-top: 54px;
        background: #fff;
        font-size: 24px;
        width: 100%;
        height: 48px;
        color: ${(props: any) => props.color};
        display: block;
        line-height: 2em;
        text-align: center;
    }
  }
  background: ${(props: any) => props.color};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SProductItemT5 = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

