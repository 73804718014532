import { css } from "styled-components";

// H1
export const H1_360 = css`
  font-size: 26px;
`;

export const H1_768 = css`
  font-size: 30px;
`;

export const H1_1366 = css`
  font-size: 48px;
`;

// H1_Bold
export const H1_360_Bold = css`
  ${H1_360};
  font-weight: 700;
  line-height: 125% !important;
`;

export const H1_768_Bold = css`
  ${H1_768};
  font-weight: 700;
  line-height: 125% !important;
`;

export const H1_1366_Bold = css`
  ${H1_1366};
  font-weight: 700;
  line-height: 125% !important;
`;

// H1_Regular
export const H1_360_Regular = css`
  ${H1_360};
  font-weight: 400;
  line-height: 125% !important;
`;

export const H1_768_Regular = css`
  ${H1_768};
  font-weight: 400;
  line-height: 125% !important;
`;

export const H1_1366_Regular = css`
  ${H1_1366};
  font-weight: 400;
  line-height: 125% !important;
`;

// H2
export const H2_360 = css`
  font-size: 20px;
`;

export const H2_768 = css`
  font-size: 21px;
`;

export const H2_1366 = css`
  font-size: 36px;
`;

// H2_Bold
export const H2_360_Bold = css`
  ${H2_360};
  font-weight: 700;
  line-height: 133% !important;
`;

export const H2_768_Bold = css`
  ${H2_768};
  font-weight: 700;
  line-height: 140% !important;
`;

export const H2_1366_Bold = css`
  ${H2_1366};
  font-weight: 700;
  line-height: 140% !important;
`;

// H2_Regular
export const H2_360_Regular = css`
  ${H2_360};
  font-weight: 400;
  line-height: 133% !important;
`;

export const H2_768_Regular = css`
  ${H2_768};
  font-weight: 400;
  line-height: 140% !important;
`;

export const H2_1366_Regular = css`
  ${H2_1366};
  font-weight: 400;
  line-height: 140% !important;
`;

// H3
export const H3_360 = css`
  font-size: 17px;
  font-weight: 700;
  line-height: 125% !important;
`;

export const H3_768 = css`
  font-size: 18px;
  font-weight: 700;
  line-height: 125% !important;
`;

export const H3_1366 = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 125% !important;
`;

// H4
export const H4_360 = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 140% !important;
`;

export const H4_768 = css`
  font-size: 15px;
  font-weight: 700;
  line-height: 140% !important;
`;

export const H4_1366 = css`
  font-size: 21px;
  font-weight: 700;
  line-height: 140% !important;
`;

// H5
export const H5_360 = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 140% !important;
`;

export const H5_768 = css`
  font-size: 15px;
  font-weight: 700;
  line-height: 140% !important;
`;

export const H5_1366 = css`
  font-size: 21px;
  font-weight: 700;
  line-height: 140% !important;
`;

// H6
export const H6_360 = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 140% !important;
`;

export const H6_768 = css`
  font-size: 15px;
  font-weight: 700;
  line-height: 140% !important;
`;

export const H6_1366 = css`
  font-size: 21px;
  font-weight: 700;
  line-height: 140% !important;
`;

// Paragraph
export const P_360 = css`
  font-size: 17px;
`;

export const P_768 = css`
  font-size: 18px;
`;

export const P_1366 = css`
  font-size: 24px;
`;

// P_Bold
export const P_360_Bold = css`
  ${P_360};
  font-weight: 700;
  line-height: 150% !important;
`;

export const P_768_Bold = css`
  ${P_768};
  font-weight: 700;
  line-height: 150% !important;
`;

export const P_1366_Bold = css`
  ${P_1366};
  font-weight: 700;
  line-height: 150% !important;
`;

// P_Regular
export const P_360_Regular = css`
  ${P_360};
  font-weight: 400;
  line-height: 150% !important;
`;

export const P_768_Regular = css`
  ${P_768};
  font-weight: 400;
  line-height: 150% !important;
`;

export const P_1366_Regular = css`
  ${P_1366};
  font-weight: 400;
  line-height: 150% !important;
`;

// P_Italic
export const I_360_Italic = css`
  ${P_360};
  font-style: italic;
  line-height: 150% !important;
`;

export const I_768_Italic = css`
  ${P_768};
  font-style: italic;
  line-height: 150% !important;
`;

export const I_1366_Italic = css`
  ${P_1366};
  font-style: italic;
  line-height: 150% !important;
`;

// P_A_Link
export const A_360_Link = css`
  ${P_360};
  font-weight: 400;
  line-height: 150% !important;
  text-decoration: underline;
`;

export const A_768_Link = css`
  ${P_768};
  font-weight: 400;
  line-height: 150% !important;
  text-decoration: underline;
`;

export const A_1366_Link = css`
  ${P_1366};
  font-weight: 400;
  line-height: 150% !important;
  text-decoration: underline;
`;

// P_Large
export const P_Large_360 = css`
  font-size: 36px;
`;

export const P_Large_768 = css`
  font-size: 42px;
`;

export const P_Large_1366 = css`
  font-size: 66px;
`;

// P_Bold_Large
export const P_Large_360_Bold = css`
  ${P_Large_360};
  font-weight: 700;
  line-height: 125% !important;
`;

export const P_Large_768_Bold = css`
  ${P_Large_768};
  font-weight: 700;
  line-height: 125% !important;
`;

export const P_Large_1366_Bold = css`
  ${P_Large_1366};
  font-weight: 700;
  line-height: 125% !important;
`;

// P_Regular_Large
export const P_Large_360_Regular = css`
  ${P_Large_360};
  font-weight: 400;
  line-height: 125% !important;
`;

export const P_Large_768_Regular = css`
  ${P_Large_768};
  font-weight: 400;
  line-height: 125% !important;
`;

export const P_Large_1366_Regular = css`
  ${P_Large_1366};
  font-weight: 400;
  line-height: 125% !important;
`;

// C
export const C_360 = css`
  font-size: 14px;
`;

export const C_768 = css`
  font-size: 15px;
`;

export const C_1366 = css`
  font-size: 21px;
`;

// C_Bold
export const C_360_Bold = css`
  ${C_360};
  font-weight: 700;
  line-height: 140% !important;
`;

export const C_768_Bold = css`
  ${C_768};
  font-weight: 700;
  line-height: 140% !important;
`;

export const C_1366_Bold = css`
  ${C_1366};
  font-weight: 700;
  line-height: 140% !important;
`;

// C_Regular
export const C_360_Regular = css`
  ${C_360};
  font-weight: 400;
  line-height: 140% !important;
`;

export const C_768_Regular = css`
  ${C_768};
  font-weight: 400;
  line-height: 140% !important;
`;

export const C_1366_Regular = css`
  ${C_1366};
  font-weight: 400;
  line-height: 140% !important;
`;

// C_Link
export const C_Link_360 = css`
  ${C_360};
  font-weight: 400;
  line-height: 140% !important;
  text-decoration: underline;
`;

export const C_Link_768 = css`
  ${C_768};
  font-weight: 400;
  line-height: 140% !important;
  text-decoration: underline;
`;

export const C_Link_1366 = css`
  ${C_1366};
  font-weight: 400;
  line-height: 140% !important;
  text-decoration: underline;
`;

// OrderedList
export const OlItem_360 = css`
  font-size: 17px;
  font-weight: 400;
  line-height: 150% !important;
`;

export const OlItem_768 = css`
  font-size: 18px;
  font-weight: 400;
  line-height: 150% !important;
`;

export const OlItem_1366 = css`
  font-size: 24px;
  font-weight: 400;
  line-height: 150% !important;
`;

// UnOrderedList
export const UlItem_360 = css`
  font-size: 17px;
  font-weight: 400;
  line-height: 150% !important;
`;

export const UlItem_768 = css`
  font-size: 18px;
  font-weight: 400;
  line-height: 150% !important;
`;

export const UlItem_1366 = css`
  font-size: 24px;
  font-weight: 400;
  line-height: 150% !important;
`;
