import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/mediaquerys";

const Default = css`
  div > h1.ps-title {
    margin: 2rem 0;
  }
  div > h1 {
    &,
    span {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.75;
    }
    text-align: left;
  }
  .ps-image {
    height: 100%;
    width: 100%;
    > div,
    > div > ul {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  /* SERIVCE GALLERY */
  .ps-service-icon {
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
      height: 32px;
      width: 32px;
    }
  }
  .ps-service-gallery {
    > div > div > div {
      margin-top: 10px;
    }
  }
  .ps-download {
    padding: 4px 0;
    display: block;
    svg {
      margin-bottom: -2px;
      width: 14px;
      height: 14px;
    }
  }
  /* END SERIVCE GALLERY */

  /* PRICE TAGS */
  .ps-our-price-before {
    line-height: 20px;
  }
  .ps-our-price {
    background: ${(props: any) => props.theme.palette.productBgLight100};
    line-height: 20px;
    color: ${(props: any) => props.theme.palette.productPrime};
  }
  .ps-our-price-label-text {
    display: block;
  }

  .ps-our-price-label-number {
    display: block;
    font-weight: 700;
  }

  /* END PRICE TAGS */

  /* BUTTONS */

  .ps-button {
    border: 1px solid ${(props: any) => props.theme.palette.productPrime};
    color: ${(props: any) => props.theme.palette.productPrime};
    border-radius: 4px;
    width: 100%;
    height: 47px;
    margin: 10px 0;
  }
  .ps-offer {
    background: ${(props: any) => props.theme.palette.productPrime};
    color: ${(props: any) => props.theme.palette.white};
  }
  .ps-button:hover {
    background: ${(props: any) => props.theme.palette.bg1};
  }
  /* END BUTTONS */
  .ps-product-details {
    table {
      width: 100%;
      position: relative;
    }
    p {
      padding: 10px;
    }
    td:first-child {
      width: 60%;
      p,
      p > span {
        font-weight: 700;
      }
    }
    td:not(:first-child) {
      width: 40%;
      p,
      p > span {
        text-align: left;
      }
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SProductShowcase = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SProductShowcase;
