import React, { FunctionComponent, useState, useContext } from "react";
import { getHeaderNavItemProps, INavigationItem, Context, IContext } from "src/common";

import { NavigationContainer, NavigationLinks, NavigationLink, BurguerIconWrapper, CloseIconWrapper, SubItemWrapper, LinksWrapper, SubItem } from "./Navigation.styles";

const getSubItem = (item: any): { link: string; title: string; external?: boolean } => {
  if (item?.__typename === "ExternalLink") {
    return {
      link: `${item.externalLinkAddress}`,
      title: `${item.label ?? item.externalLinkName}`,
      external: true,
    };
  }

  const link = item?.slug;
  const title = item?.nameInNavigation;

  return {
    link,
    title
  };
};

const SubItemComponent: FunctionComponent<{subItem: Record<string, any>; isActive: boolean }> = ({ subItem, isActive }) => (
  <SubItem isActive={isActive} href={`${subItem.external ? "" : "/"}${subItem.link}`}>
    {isActive && <div><i className="fa fa-arrow-right"/></div>}
    {subItem.title}
  </SubItem>
);

const Items: FunctionComponent<{item: Record<string, any>; itemProps: Record<string, any> }> = ({ itemProps, item }) =>
{
  const props = useContext<IContext>(Context);
  const { subitems } = item;
  const primariActive = props?.PrimaryNavigation.items?.find(pirmary => pirmary?.subitems?.find(secondary => secondary?.slug === props.slug));
  return (
    <>
      <NavigationLink isActive={itemProps.link === props.slug || itemProps.link === primariActive?.link[0]?.slug }>
        <a href={itemProps.link}>
          {itemProps.title}
        </a>
      </NavigationLink>
      {subitems?.length > 0 &&
      <SubItemWrapper>
        {subitems.map((subItem, i) => {
          const subItemProps = getSubItem(subItem);
          return subItemProps && (subItem.nameInNavigation || subItem.externalLinkName) && (
            <SubItemComponent subItem={subItemProps} isActive={props?.slug === subItemProps.link} key={`subitem-${i}`}/>
          );
        })}
      </SubItemWrapper>}
    </>
  );
};

const Navigation: FunctionComponent<{ items: INavigationItem[]; slug: string; onToggleMenuActive: any; isMenuActive: boolean }> = ({
  items, slug, onToggleMenuActive, isMenuActive
}) =>
  (
    <NavigationContainer active={isMenuActive}>
      <BurguerIconWrapper onClick={() => onToggleMenuActive(!isMenuActive)}>
        <i className="fa fa-bars"/>
      </BurguerIconWrapper>
      <NavigationLinks>
        <CloseIconWrapper onClick={() => onToggleMenuActive(!isMenuActive)}>
          <i className="fa fa-times"/>
        </CloseIconWrapper>
        <LinksWrapper>
          <ul>
            {items.map((item, index) =>
            {
              const itemProps = getHeaderNavItemProps(item);
              if(!itemProps) { return; }
            
              return (
                <Items itemProps={itemProps} item={item} key={`itemlink-key-${index}`}/>
              );
            })}
          </ul>
        </LinksWrapper>

      </NavigationLinks>
    </NavigationContainer>
  );

export default Navigation;
