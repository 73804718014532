// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { keyframes, css } from "styled-components";

export const MenuIconWrapper: any = styled.div<{isMenu: boolean}>`
  cursor: pointer;
  font-size: 54px;
  color: #000;
  z-index: 999;

  @media (${BREAKPOINTS_DM.silver_768}) {
    color: #fff;
    font-size: 28px;
    padding: 7.5px 10px;
    background-color: rgb(51, 51, 51);
    &:hover {
      color: ${({ isMenu }) => isMenu ? "#FA8800" : ""};
    }
  }
`;

export const NavIconsWrapper: any = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  right: 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 145px;
    position: static;
  }

  > a {
    display: none;
    @media (${BREAKPOINTS_DM.silver_768}) {
      display: initial;
    }
  }
`;

export const NavigationLink = styled.li<{isActive: boolean; isShowSubitem: boolean; icon: boolean}>`
  margin-bottom: 1px;
  display: flex;
  color: #000;
  border-bottom: ${({ isShowSubitem }) => isShowSubitem ? "none" : "1px solid #000"};
  &:first-child {
    border-top: ${({ isShowSubitem }) => isShowSubitem ? "none" : "1px solid #000"};
  }
  div.icon {
    display: none;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30px;
      &:hover {
        color: #F19024;
      }
    }
  }
  > a {
    white-space: nowrap;
    padding: 10px;
    display: flex;
    align-items: center;
    font-weight: 100;
    font-size: 14px;
    line-height: 21px;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    width: ${({ icon }) => icon ? "fit-content" : "100%"};
    &:hover {
      color: #F19024;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    color: ${({ isActive }) => isActive ? "#F19024" : "#FFFFFF"};
    border-bottom: ${({ isShowSubitem }) => isShowSubitem ? "none" : "1px solid #fff"};
    border-bottom-right-radius: 20px;

    &:first-child {
      border-top-color: #FFF;
    }
  }
`;

export const Links: any = styled.ul`
  height: calc(100vh - 64px);
  padding: 20px 0;
  list-style: none;
  overflow: auto;
  background-color: #fff;
  width: 70vw;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    background-color: rgb(51, 51, 51);
    padding: 20px 0 30px;
    width: 100%;
  }
`;

export const NavigationLinks = styled.div`
  position: relative;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transition: transform .8s ease;
  transform: translate3d(90vw,0,0);
  height: 100vh;
  padding-top: 80px;
  box-shadow: -2px -2px 5px rgb(50 50 50 / 50%);

  ${NavIconsWrapper} {
    left: 10px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    background-color: rgb(51, 51, 51);
    transform: translate3d(-20vw,0,0);
    padding-top: 0;
    width: 288px;
    box-shadow: none;
    ${NavIconsWrapper} {
      display: none;
    }
  }

`;

export const NavigationContainer: any = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  z-index: 999;

  ${({ active }) => active
    ? `
      ${NavigationLinks} {
        transform: translate3d(0,0,0);
      }
    `
    : `
      width: 0;
    `}

  @media (${BREAKPOINTS_DM.silver_768}) {
    left: 0;
    ${({ active }) => active
      && `
        width: 288px;
      `}
  }
`;

export const SubItemWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-left: 20px;
`;

export const SubItemWrapperMobile: any = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const SubItem: any = styled.a<{isActive: boolean}>`
    color: ${({ isActive }) => isActive ? "#F19024" : "rgb(51, 51, 51)"};
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 21px;
    font-weight: 100;
    display: flex;
    padding: 10px 0 10px 25px;
    margin: 0 0 1px;

    &:last-child {
      border-bottom: none;
    }
    &:hover {
      color: #F19024;
    }

    div {
      display: none;
      @media (${BREAKPOINTS_DM.silver_768}) {
        display: block;
        color: #BCBCBC;
        position: relative;
        right: 25px;
        margin-right: -13px;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      padding: 10px;
      border-bottom: 1px solid rgb(51, 51, 51);
      border-bottom-right-radius: 20px;
    }
`;

export const Logo = styled.img`
  display: none;
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    display: initial;
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
`;

export const Icon: any = styled.div<{isShowSubitem: boolean}>`
  transform: ${({ isShowSubitem }) => isShowSubitem ? "rotate(90deg)" : ""};
`;
