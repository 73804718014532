import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";
import { Container } from "../Grid";
import Img from "../../../BaseComponents/Img";
import {
  FurtherAwardsWrapper,
  FurtherAwardsContet,
  ImageWrapper,
} from "./FurtherAwards.style";
const FurtherAwards: FunctionComponent = () =>
{
  const props = useContext(Context);
  const furtherAwards = props?.CompanyInformationPrivate?.furtherAwards;

  return (
    <FurtherAwardsWrapper>
      <Container>
        <h2>Presse</h2>
        <h3>Ausgezeichnet von</h3>
        <FurtherAwardsContet>
          {furtherAwards?.map((item, i) => (
            <ImageWrapper key={i}>
              <Img quality={1600} key={i} src={[{ src: item?.src }]} alt={item?.title ?? "image"}/>
            </ImageWrapper>
          ))}
        </FurtherAwardsContet>
      </Container>
    </FurtherAwardsWrapper>
  );
};

export default FurtherAwards;
