import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND
} from "src/common/constants/mediaquerys";

const Default = css`
  .c-empty{
    min-height: 21px;
    width: 100%;
    display: block;
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
  }
  svg {
    path {
      fill: ${({ theme }) => theme.palette.primary};
    }
  }
  li, ul{
    display: block;
    list-style: inside;
  }
  ul {
    list-style: disc;
    padding-inline-start: 26px;
  }
  li{
    strong{
      height: 21px
    }
    list-style: inside;
    display: list-item;
  }

  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }

  tr{
    td {
      padding: 10.5px;
      width: auto !important;
      p{
        margin-bottom: 10px;
        padding-right: 10px;
      }
    }
  }

`;

const Silver = css`
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SRichTextGeneral = css`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

export const RichTextGeneralWrapper = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

export default SRichTextGeneral;
