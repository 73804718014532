import React, { Fragment } from "react";
import Gallery from "src/common/BaseComponents/Gallery/Gallery";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import { IPhotoGallery } from "src/common/childTypes";
import SPhotoGallery from "./Styles/SPhotoGallery";
import { FullscreenImage } from "../../FullscreenImage/FullscreenImage";

export const PhotoGallery = (props: IPhotoGallery) => {
  return (
    <Fragment>
      <SPhotoGallery>
        <div className="photoGallery">
          <Gallery
            fullWidth
            bronze={1}
            silver={2}
            gold={props.maxCountPerRow || 2}
          >
            {props.elements?.map((item, i) => {
              return (
                <Fragment key={`cpg-item${i}`}>
                  <div>
                    <Spacer bronze={5} />
                  </div>
                  <FullscreenImage
                    img={[item]}
                    subtitle={item.description}
                  ></FullscreenImage>
                </Fragment>
              );
            })}
          </Gallery>
        </div>
      </SPhotoGallery>
    </Fragment>
  );
};
export default PhotoGallery;
