import React, { FunctionComponent } from "react";

import Img from "../../../../../BaseComponents/Img/Img";
import RichText from "../../../../../BaseComponents/RichText/RichText";
import * as styles from "./NewSlide.style";

interface IProps {
  index: number;
  totalCount: number;
  img?: string;
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
  teaser?: string;
}

export const NewSlide: FunctionComponent<IProps> = (props) => {
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find((p) =>
    p.type.includes("heading")
  );
  if (elementToShowAsSubtitle) {
    text = { content: [elementToShowAsSubtitle] };
  }
  return (
    <div css={styles.wrapper}>
      <div css={styles.contentWrapper}>
        {props.title && <h4>{props.title}</h4>}
        {props.teaser && (
          <div css={styles.richText}>
            <p>{props.teaser}</p>{" "}
          </div>
        )}
        {!props.teaser && text && (
          <div css={styles.richText}>
            <RichText textContent={text}></RichText>
          </div>
        )}
        {props.link && <a href={props.link}>Erfahren Sie mehr</a>}
      </div>
      {props.img && (
        <a css={styles.imgWrapper} href={props.link}>
          <Img
            quality={550}
            src={props.img}
            alt={props.imgAlt ? props.imgAlt : ""}
          />
        </a>
      )}
    </div>
  );
};
