import { IAsset, Context } from "src/common";
import { LineLogo } from "src/common/components/LineLogo";

import React, { Fragment, FunctionComponent, useContext } from "react";

import { hexIsLight } from "../../../../../common/utils/colorHelper";
import UniversalComponentRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentRenderer";
import { centeredContent } from "../../../constants/layout";
import { getHeaderNavItemProps, getPageElements } from "../../../utils/helpers";
import * as styles from "./Header.style";
import NavItem, { eNavItemType } from "./navItem/NavItem";
import ResponsiveSidebar from "./responsiveSidebar/ResponsiveSidebar";

const Header = ({ mainSlider = undefined, children = undefined, noHeadComponent = false }) => {
    const props = useContext(Context);
    const elements = props?.PageLanding?.elements || getPageElements(props);

    // @ts-ignore
    const mainAsset = elements?.filter((element) => element?.__typename === "Asset") as IAsset[];
    const companyInformationPublic = props.CompanyInformationPublic;

    if (!props?.PrimaryNavigation?.items) {
        return null;
    }
    const palette = props?.Settings;
    const bgColor = palette.themeColorPrimary || "#009FE3";

    const lightBg = hexIsLight(bgColor);

    return (
        <Fragment>
            <ResponsiveSidebar />
            <div css={styles.topBarWrapper} id={"top"}>
                <div
                    css={`
                        ${centeredContent}${styles.topBarContent}
                    `}
                >
                    <a css={styles.logosWrapper} href={"/"}>
                        <LineLogo backgroundIsDark={!lightBg} />
                        {companyInformationPublic?.logo && (
                            <img className="nogray" src={companyInformationPublic.logo?.[0]?.src} alt="logo" />
                        )}
                    </a>
                    <p>
                        {companyInformationPublic?.managingDirector && (
                            <span>
                                <b>{companyInformationPublic.managingDirector}</b>
                            </span>
                        )}
                        {companyInformationPublic?.street && <span> | {companyInformationPublic.street}</span>}
                        {companyInformationPublic?.postcode && companyInformationPublic.place && (
                            <span> | {companyInformationPublic.postcode + " " + companyInformationPublic.place}</span>
                        )}
                    </p>
                </div>
            </div>
            <div css={styles.serviceNummerAndAssetWrapper}>
                {companyInformationPublic?.serviceNumber && (
                    <div
                        css={`
                            ${centeredContent}${styles.serviceNummer}
                        `}
                    >
                        {companyInformationPublic?.phone && (
                            <p>
                                <span>Unsere Servicenummer - </span>
                                <span>
                                    {companyInformationPublic.serviceNumber || companyInformationPublic.phone || ""}
                                </span>
                            </p>
                        )}
                        {companyInformationPublic?.telephoneHours && <p>{companyInformationPublic.telephoneHours}</p>}
                    </div>
                )}
                {!noHeadComponent && (
                    <>
                        {(!mainAsset || mainAsset.length === 0) && (
                            <div
                                style={{
                                    backgroundColor: companyInformationPublic?.serviceNumber ? "white" : bgColor,
                                    height: 20,
                                }}
                            />
                        )}
                        <UniversalComponentRenderer items={[...mainAsset, mainSlider].filter((e) => !!e)} />
                    </>
                )}

                <div css={styles.outerNavWrapper}>
                    <nav css={styles.navWrapper}>
                        {props.PrimaryNavigation.items.map((item, i) => {
                            const itemProps = getHeaderNavItemProps(item);

                            if (!itemProps) {
                                return null;
                            }

                            const isItemActive = props.slug === item?.link?.[0]?.slug;
                            let isSubItemActive = false;

                            if (item.subitems && item.subitems.length > 0) {
                                for (const subItem of item.subitems) {
                                    if (subItem?.slug === props.slug) {
                                        isSubItemActive = true;
                                        break;
                                    }
                                }
                            }

                            return (
                                <Fragment key={`${i}NavItem`}>
                                    <NavItem
                                        active={isItemActive || isSubItemActive}
                                        type={eNavItemType.header}
                                        link={itemProps.link}
                                        headline={itemProps.headline}
                                        title={itemProps.title}
                                        subItems={item.subitems as any}
                                    />
                                </Fragment>
                            );
                        })}
                    </nav>
                </div>
                {noHeadComponent && (
                    <>
                        <div style={{ height: 40, background: bgColor }} />
                    </>
                )}
            </div>
        </Fragment>
    );
};

export default Header;
