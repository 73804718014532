import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context } from "src/common";

import Address from "./Address";
import { FooterWrapper, Wrapper, SocialWrapper } from "./Footer.style";
import Navigation from "./Navigation";
import SocialShare from "./SocialShare";

interface IFooterLinkInColumnList 
{
  title: string;
  link: string;
  isBold: boolean;
}

const fixURL: (url: string) => string = (url) => 
{
  if(/https/.test(url)) 
  {
    return url;
  }
  return `https://${url}`;
}; 

const Footer: FunctionComponent<{withSocial?: boolean}> = ({ withSocial }) => 
{
  const props = useContext(Context);
  const allFooterLinksForUpperWrapper: IFooterLinkInColumnList[] = [];
  const companyInformation = props.CompanyInformationPublic;
  const footerNavItems = props.Footer?.Navigationspunkte || [];
  const contact = props?.PageContact?.slug;

  const facebook = companyInformation?.facebook;
  const pinterest = companyInformation?.pintrest;
  const twitter = companyInformation?.twitter;
  const youtube = companyInformation?.youtube;

  for(const item of props.PrimaryNavigation?.items || []) 
  {
    if(item?.label && item?.link?.[0]?.slug) 
    {
      allFooterLinksForUpperWrapper.push({
        title: item.label || item?.link?.[0]?.nameInNavigation,
        link: item?.link?.[0]?.slug,
        isBold: true
      });
    }

    if(item?.subitems) 
    {
      for(const subItem of item.subitems) 
      {
        if(subItem?.nameInNavigation && subItem?.slug) 
        {
          allFooterLinksForUpperWrapper.push({
            title: subItem?.nameInNavigation,
            link: subItem?.slug,
            isBold: false
          });
        }
      }
    }
  }

  return (
    <>
      {withSocial && <SocialShare companyInformation={companyInformation} />}
      <Wrapper>
        <FooterWrapper>
          <SocialWrapper>
            {facebook && <a className="icon" href={fixURL(facebook)} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"/></a>}
            {pinterest && <a className="icon" href={fixURL(pinterest)} target="_blank" rel="noreferrer"><i className="fab fa-pinterest-p"/></a>}
            {twitter && <a className="icon" href={fixURL(twitter)} target="_blank" rel="noreferrer"><i className="fab fa-twitter"/></a>}
            {youtube && <a className="icon" href={fixURL(youtube)} target="_blank" rel="noreferrer"><i className="fab fa-youtube"/></a>}
          </SocialWrapper>
          <Navigation items={allFooterLinksForUpperWrapper} />
          <Address companyInformation={companyInformation} items={footerNavItems} impressum={props?.PageImprint?.slug} contact={contact}/>
        </FooterWrapper>
      </Wrapper> 
    </>
  );
};

export default Footer;
