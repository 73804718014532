import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const HeroContainer: any = styled.div<{
  empty?: boolean;
  slug?: string;
  isDealerSearchPage?: boolean;
}>`
  ${({ empty }) => empty && "padding-top: 260px;"}

  height: 420px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: 575px;
    ${({ isDealerSearchPage }) =>
    isDealerSearchPage &&
    css`
      height: 300px;
    `}
  }
  ${({ slug }) =>
    slug &&
    slug !== "gruender" &&
    css`
      height: 300px;
    `}

  max-width: calc(100vw);
  background-color: white;
  [data-slider-wrapper] > div {
    border: none;
    .slick-slider {
      height: 574px;
      img {
        height: 574px;
      }
    }
  }

  > div {
    img {
      height: 100%;
      object-fit: cover;
    }
    button {
      display: none !important;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 1140px;
    > div {
      img {
        ${({ slug }) =>
          slug === "gruender" &&
          css`
            height: 600px;
          `}
      }
    }
  }
`;

export const HeroContainerMobile: any = styled.div`
  height: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;
export const HeroContainerDesktop: any = styled.div`
  display: none;
  height: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: block;
  }
`;
