import styled from "styled-components";
import { BREAKPOINTS_DM } from "../../../../../../common/constants/mediaquerys";

const SFooterWrapper = styled.div`
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 10px auto 130px;
    padding: 0 10px;
    @media (${BREAKPOINTS_DM.gold_1024}){
      padding: 0;
    }
`;

export default SFooterWrapper;
