import React from "react";
import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { number } from "../../../../templates/t1/components/common/ServiceNumber/ServiceNumber.style";

export const PhotoGalleryWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const PhotoGalleryItem = styled.div<{ chunkIndex?: number; hideModal?:boolean  }>`
  display: flex;
  margin-bottom: 100px;
  
  [data-caption]{
    padding: 1rem;
    word-break: normal;
    hyphens: auto;
    overflow-wrap: anywhere;
  }

  [data-caption],
  [data-image] {
    width: 50%;
    ${({ hideModal }) => !hideModal && `cursor: pointer;`};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ chunkIndex }) =>
    typeof chunkIndex === "number"
      ? chunkIndex % 2 === 0
        ? css`
            [data-caption] {
              order: 1;
            }
            [data-image] {
              order: 2;
            }
          `
        : css`
            [data-caption] {
              order: 2;
            }
            [data-image] {
              order: 1;
            }
          `
      : css`
          :nth-child(odd) {
            [data-caption] {
              order: 2;
            }
            [data-image] {
              order: 1;
            }
          }
        `}
`;

export const PhotoGalleryRow: any = styled.div<{ cols?: number }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${PhotoGalleryItem} {
    width: calc(100% / ${({ cols }) => cols});
  }
`;
