// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FooterWrapper = styled.div`
  width: 100%;
  background-color: #5E5E5D;
  color: #fff;
`;

export const FooterContent: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px 32px ;
  margin: 0 auto;
  background-color: #5E5E5D;
  color: #fff;
  @media (${BREAKPOINTS_DM.gold_1024})
  {
    padding: 80px 0 0 110px;
    flex-direction: row;
  }
  @media (${BREAKPOINTS_DM.diamond_1920}) 
  {
    max-width: 1220px;
    padding: 80px 0 40px;
  }
`;

export const wrapper: any = css`
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 10px auto 130px;
    padding: 0 10px;
`;

export const LinksWrapper: any = styled.div`
    column-count: 2;
    column-gap: 82px;
    margin-bottom: 30px;
    display: none;

    p {
      margin-bottom: 3px;
    }
    a {
      font-size: 16px;
      color: #fff;
      font-weight: 100;
      line-height: 28px;
    }

    @media (${BREAKPOINTS_DM.silver_768})
    {
      margin-bottom: 10.5px;
      padding: 0 10.5px 0 10px;
      display: none;
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
      margin-bottom: 10.5px;
      padding: 0 10.5px 0 48px;
      display: block;
    }
`;

export const Link: any = styled.a`
  display: block;
  color: #736c65;
  line-height: 1.2rem;
  ${({ isBold }) => isBold && 'font-weight: bold;'}
`;

export const AddressWrapper: any = styled.div`
    width: 100%;
    margin-top: 50px;
    margin-bottom: 5px;
    p, div {
      font-size: 16px;
      color: #fff;
      font-weight: 100;
      line-height: 28px;
    }

    h1 {
      font-size: 25.6px;
      line-height: 42.6668px;
      font-weight: 400;
      margin: 0 0 2px;
    }

    a {
      color: ${props => props.theme.palette.green};
      &:hover {
        color: ${props => props.theme.palette.green};
      }
    }

    & > .managingDirectorWrapper {
      display: flex;
      align-items: center;
      p {
        margin-top: 0;
      }
    }

    & > .addressLinksWrapper {
      & > span {
        margin: 0 4px;
      }
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 23%;
      margin-top: 0;
    }
`;

export const ShareOnSocialMediaWrapper: any = styled.div`
    width: 100%;
    padding: 0 5px;
    height: 120px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    a {
      background-color: rgb(241, 144, 36);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
      padding: 8px;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      svg {
        height: 100%;
        fill: white;
      }
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
      width: 20%;
      margin-top: 0;
      padding: 0 30px 0 5px;
    }
`;
