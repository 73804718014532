import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";
export const Smenu: any = styled.div<any>`
  width: 30px;
  height: 30px;
  position: relative;
  margin-top: 16px;
  cursor: pointer;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        display: ${({burgerMenuOnDesktop}) => burgerMenuOnDesktop ? "" : "none"};
    }

`;

const line2: any = css`
  width: 100%;
  height: 3px;
  position: absolute;
  border-radius: 3px;
`;

export const Line: any = styled.div<{background: string}>`
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: background-color ease 0.2s .15s;
  ${line2}
  background-color: black;
  background-color: ${props => props.background ? props.background : 'black'};
  &:before, &:after {
    content: '';
    transition: transform ease .5s, background-color ease .3s .2s, top ease .5s;
    ${line2}
    background-color: black;
    background-color: ${props => props.background ? props.background : 'black'};
  }
  &:before {
    top: -11px
  }
  &:after {
    top: 11px
  }
`;
