import React, { FunctionComponent, useContext } from "react";
import {
  IContext,
  Context,
  ILandingEvent,
  getIfNewsShouldBeDisplayByDate,
} from "src/common";

import RichText from "../../../../BaseComponents/RichText/RichText";
import * as styles from "./LandingEvent.style";
import { Wrapper, EventWrapper } from "./LandingEvent.style";
import { getDownloadLinkFromConnection, getLinkFromConnection } from "../../../../../../common/utils/getLinkFromConnection";
export enum eLandingEventLocation {
  landingImage,
  contentArea,
  sideBar,
}
interface IProps {
  type: eLandingEventLocation;
  event: any;
  className?: string;
}

export const LandingEvent: FunctionComponent<IProps> = ({
  type,
  event,
  className,
}) => {
  const props = useContext(Context);
  const palette = props?.Settings;

  const shouldLandingEventBeDisplay = getIfNewsShouldBeDisplayByDate({
    startDate: event?.startDate,
    endDate: event?.endDate,
  });

  const isOnLanding = type === eLandingEventLocation.landingImage;
  if (!event || !shouldLandingEventBeDisplay) {
    return null;
  }

  let link = getLinkFromConnection(event?.linksTo);
  if (!link.valid) {
    link = undefined;
  }
  let downloadLink = undefined;
  if (!link){
    downloadLink = getDownloadLinkFromConnection(event?.downloadLink);
    if (downloadLink.valid) {
      link = downloadLink;
    }
  }

  return (
    <Wrapper
      className={className}
      isOnLanding={isOnLanding}
      sideBar={type === eLandingEventLocation.sideBar}
      background={palette.themeColorPrimary}
      color={palette.themeTextColourIsland}
      clickAble={
        !!(
          link?.href 
        )
      }
    >
      <a
        href={link?.href }
        target={link?.target}
        title={link?.title}
        download={link?.download}
        rel={link?.rel}
      >
        {!isOnLanding && event?.icon?.[0]?.src && (
          <img css={styles.eventIcon} src={event?.icon?.[0].src} />
        )}
        <EventWrapper isOnLanding={isOnLanding}>
          {event.headline && <h3 className={`event-headline${!isOnLanding && event?.icon?.[0]?.src ? ` with-icon` : ""}`}>{event.headline}</h3>}
          {event.text && (
            <RichText
              textContent={event.text}
              overwriteStyle={`${styles.landingEventRichtext}${
                isOnLanding
                  ? styles.landingImage_landingEventRichtext
                  : styles.contentArea_landingEventRichtext
              }`}
            ></RichText>
          )}
          {link?.href && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="9"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          )}
        </EventWrapper>
      </a>
    </Wrapper>
  );
};
