import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .close-icon {
        margin-left: auto;
        margin-bottom: 8px;
        margin-top: 8px;
        cursor: pointer;
        svg {
            width: 16px;
            height: 16px;
            fill: #f9f9f9;
            ${CSSWithOpacityHoverAnimation}
        }
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    .close-icon {
        margin-bottom: 12px;
        margin-top: 12px;
        svg {
            width: 20px;
            height: 20px;
        }
    }
`;

const Diamond = css``;

export const SModalOverlayInner = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
