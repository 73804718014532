import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context, Img } from "src/common";

import { Section, Container } from "../Grid";
import Address from "./Address";
import { FooterWrapper, Logo, Bottom } from "./Footer.style";
import OpeningHours from "./OpeningHours";
interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => {
  const props = useContext(Context);
  const companyInformation = props.CompanyInformationPublic;
  // const footerNavItems = props.Footer?.Navigationspunkte || [];

  return (
    <Section>
      <FooterWrapper>
        <Container>
          <Logo href="/">
            <img src={companyInformation?.logo?.[0].src} />  
            <br/>
            <span>Über uns</span>
          </Logo>
          <OpeningHours companyInformation={companyInformation} />
          <Address companyInformation={companyInformation}/>
        </Container>
        <Container>
          <Bottom>
            <div>
              <span>
                Empfohlen vom
              </span>
              {props?.CompanyInformationPrivate?.showInKitchenCompass ? (
                <a href={"https://www.kuechenkompass.com/"} className="kitchen-compass">
                  <img src={"/images/kompass_inverted.png"} alt={"Küchenkompass"} />
                </a>
              ) : null}
            </div>

            <div>
              <span>
                © Copyright 2021 - Birke GK Küchenhaus SaintElmos Testpartner
              </span>
            </div>
          </Bottom>
        </Container>
      </FooterWrapper>
    </Section>
  );
};

export default Footer;
