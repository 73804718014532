import React from 'react'
import { LinkBanner, TextTeaser, TextTeaserLeft, TextTeaserLink, TextTeaserMiddle, TextTeaserRight } from './LinkBannerTeaser.style'

const LinkBannerTeaser = () => {
  return (
    <LinkBanner>
    <a href="https://musterkuechen.kuechenwerkstatt.de/" target="_blank" rel="noopener noreferrer">
        <TextTeaser>
          <TextTeaserLeft />
          <TextTeaserMiddle>
            <h2>Deutschlandweit günstige Musterküchen finden</h2>
          </TextTeaserMiddle>
          <TextTeaserRight />
          <TextTeaserLink>mehr</TextTeaserLink>
        </TextTeaser>
      </a>
    </LinkBanner>
  )
}

export default LinkBannerTeaser