import styled, {css} from "styled-components";
import { MAX_WIDTH, PADDING } from "../../../constants/grid";
import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "../../../constants/mediaquerys";

const Bronze = css`
  padding: 0 ${PADDING.BRONZE};
`;

const Silver = css`
  padding: 0 ${PADDING.SILVER};
`;

const Gold = css`
  padding: 0 ${PADDING.GOLD};
`;

const Platinum = css`
  padding: 0 ${PADDING.PLATINUM};
`;

const Diamond = css`
  padding: 0 ${PADDING.DIAMOND};
  
  max-width: ${`${MAX_WIDTH}px`};

`;
export const SGridPadding = styled.div`
  ${Bronze};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
