import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    display: flex;
    padding-bottom: 48px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    padding-bottom: 76px;
`;

const Diamond = css``;

export const SMosaicPhotoGallery = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
