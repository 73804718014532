// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FooterWrapper = styled.div`
  max-width: 950px;
  width: 100%;
  padding: 50px 0px 40px;
  margin: auto auto 0;
  background-color: #000;
`;

export const Wrapper: any = styled.div`
    width: 100%;
    background-color: #000;
`;

export const LinksWrapper: any = styled.div`
    column-count: 4;
    column-gap: 20px;
    margin-bottom: 30px;
    display: none;

    p {
      margin-bottom: 10px;
    }
    a {
      font-size: 12px;
      margin: 0 10px 10px;
      font-weight: 300;
      color: #EFEFEF;
    }

    @media (${BREAKPOINTS_DM.silver_768})
    {
      //margin-bottom: 10.5px;
      margin: 21px 30px;
      display: block;
    }
    @media (${BREAKPOINTS_DM.platinum_1366})
    {
      //margin-bottom: 10.5px;
      margin: 21px 0px;
      display: block;
    }
`;

export const Link: any = styled.a<{ isBold: boolean }>`
  display: block;
  color: #505050;
  line-height: 1.2rem;
  ${({ isBold }) => isBold && 'font-weight: bold;'}
`;

export const AddressWrapper: any = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 15px 0 10.5px;
    color: #999999;
    p, div {
      text-aling: center;
      line-height: 16px;
      font-size: 12px;
      font-weight: 300;
    }
    p {
      margin-bottom: 5px;
    }

    a {
      // color: ${props => props.theme.palette.light_blue};
    }

    & > .managingDirectorWrapper {
      display: flex;
      align-items: center;
      p {
        margin-top: 0;
      }
    }

    & > .addressLinksWrapper {
      & > span {
        margin: 0 4px;
      }
    }
`;

export const ShareOnSocialMediacontainer: any = styled.div`
  max-width: 950px;
  margin: 50px auto;
`;

export const ShareOnSocialMediaWrapper: any = styled.div`
    display: flex;
    justify-content: center;
    a {
      display: flex;
      align-items: center;
      margin-right: 17px;
      padding: 8px;
      height: 30px;
      width: 85px;
      span {
        margin-left: 10px;
        color: white;
        display: none;
      }
      &:first-child {
        background-color: #3b5998;
        &:hover {
          background-color: #4273c8;
        }
      }
      &:nth-child(2) {
        background-color: #55acee;
        &:hover {
          background-color: #78c2ff;
        }
      }
      &:nth-child(3) {
        background-color: #bd081c;
        &:hover {
          background-color: #f10c23;
        }
      }
      &:nth-child(4) {
        background-color: #999;
        &:hover {
          background-color: #c1c1c1;
        }
      }
      &:nth-child(5) {
        background-color: #999;
        &:hover {
          background-color: #c1c1c1;
        }
      }
      svg {
        height: 100%;
        fill: white;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768})
    {
        a {
            width: 160px;
            span {
              display: block;
            }
        }
    }
`;

export const SocialWrapper: any = styled.div`
	width: 100%;
  display: flex;
  justify-content: center;
  a.icon {
    color: #94c03e;
    font-size: 26px;
    height: 26px;
    margin: 0 10px;
    width: 26px;
    &:hover {
      color: #777878;
    }
  }
`;
