import { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../constants/mediaquerys";

export default css`
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('/fonts/slick.ttf');
}
.slick-prev,
.slick-next
{
    z-index: 1;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 25px;
    height: 25px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 28px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 10px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: 10px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    position: absolute;
}

.slick-dots
{
    position: absolute;
    display: block;
    top: 495px;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    height: 400px;
    z-index: 9;
    @media (${BREAKPOINTS_DM.bronze_375}) {
        top: 520px;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        top: 665px;
    }
}
.slick-dots li
{
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 3px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 16px;
    height: 16px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}

.slick-dots li button:before
{
    border-radius: 50%;
    border: 2px solid white;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    content: '•';
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    background-color: #70c13e;
    border: none;
    width: 16.2px;
    height: 16.2px;
}
`;
