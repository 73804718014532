import styled from "styled-components";
import { Container } from "../Grid";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FurtherAwardsWrapper: any = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.palette.primary};
  background-image: url("/images/bg_award.png");
  ${Container} {
    flex-direction: column;
    padding: 40px 15px;
    justify-content: center;
  }

  h2 {
    text-align: center;
    font-size: 24px!important;
    font-weight: 400!important;
    line-height: 1.4;
    color: ${({ theme }) => theme.palette.white};
  }

  h3 {
    font-size: 13px!important;
    font-weight: 400!important;
    color: ${({ theme }) => theme.palette.white};
    text-transform: none!important;
    position: relative;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
    align-self: center;
    padding: 0 15px;
    line-height: 1.4;

    :before,
    :after {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      top: 50%;
      width: 30px;
      background: #fff!important;
    }

    :before {
      left: -30px;
    }

    :after {
      right: -30px;
    }
  }
`;

export const FurtherAwardsContet: any = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ImageWrapper: any = styled.div`
  width: 100%;
  padding: 10px 30px 0 30px;


  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 12 * 4);
    padding: 10px 15px 0 15px;
  }
`;

