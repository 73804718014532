import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../../constants/mediaquerys";

const Default = css``;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SProductItemT27 = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
