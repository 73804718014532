import React, { FC } from "react";
import Masonry from "react-responsive-masonry";
import { FullscreenImage } from "src/common";
import { FullscreenSlider } from "src/common";
import { IPhotoGallery } from "src/common/childTypes";

import {
  PhotoGalleryWrapper,
  PhotoGalleryItem,
} from "./MasonryPhotoGallery.style";

const getImageProps = (item: IPhotoGallery): any =>
  item?.modal === "slider" ? { elements: item.elements } : null;

const MasonryPhotoGallery: FC<{ item: IPhotoGallery; columns?: number }> = ({
  item,
  columns,
}) => {
  if (!item.elements) return null;
  const itemsPerRow = columns || item.maxCountPerRow || 5;
  return (
    <PhotoGalleryWrapper data-gallery>
      <Masonry columnsCount={itemsPerRow}>
        {item.elements?.map((asset, i) => (
          <a
            key={`gallery-image-${i}`}
            title={asset.description ? asset.description : undefined}
          >
            <PhotoGalleryItem data-image>
              {item.modal == "slider" ? (
                <FullscreenSlider
                  img={[asset]}
                  subtitle={asset.description}
                  lazyload={false}
                  {...getImageProps(item)}
                />
              ) : (
                <FullscreenImage
                  key={`gallery-item-image-${i}`}
                  img={[asset]}
                  id={`gii-${i}-${asset.id}`}
                  subtitle={asset.description}
                  lazyload={false}
                />
              )}
            </PhotoGalleryItem>
          </a>
        ))}
      </Masonry>
    </PhotoGalleryWrapper>
  );
};

export default MasonryPhotoGallery;
