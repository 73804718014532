import { css } from "styled-components";

import { BREAKPOINTS_DM } from "./mediaquerys";

export const bigHeading: any = css`
    font-size: 30px;
    margin-bottom: 15px;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
      font-size: 24px;
      margin-bottom: 10px;
    }
`;

export const smallHeading: any = css`
    font-size: 24px;
    margin-top: -5px;
    margin-bottom: 15px;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
      font-size: 19px;
      margin-bottom: 8px;
    }
`;
