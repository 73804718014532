import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { C_1366_Regular, C_360_Regular, C_768_Regular } from "src/common/brand-area/constantsOverwrites/typography";

interface IProps {
    column: number;
    oneRowMode?: boolean;
}

const Default = css<IProps>`
    width: 50%;
    position: relative;

    [data-caption] {
        width: 100%;
        height: 100%;
        padding-top: 100%;
        position: relative;

        > p {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--front-2);
            text-transform: capitalize;
            transition: color 0.3s ease-in-out;
            ${C_360_Regular}
        }
    }

    [data-gallery-item] {
        :not(.has-caption) {
            [data-image] {
                &::before {
                    display: none;
                }
            }
        }
    }

    [data-image] {
        cursor: pointer;
        width: 100%;
        position: relative;
        pointer-events: all;

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 0px;
            height: 0px;
            z-index: 4;
            border-style: solid;
            border-width: 12px;
            left: 50%;
            transition: border-color 0.3s ease-in-out;

            ${({ column }) =>
                column === 0 || column === 2
                    ? css`
                          border-color: var(--front-2) transparent transparent transparent;
                          transform: translate(-50%, 0);
                          top: 0px;
                      `
                    : css`
                          border-color: transparent transparent var(--front-2) transparent;
                          transform: translate(-50%, 0);
                          bottom: 0px;
                      `}
        }

        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 3;
            background-color: transparent;
            top: 0;
            left: 0;
            pointer-events: none;
            transition: background-color 0.3s ease-in-out;
        }
    }

    [data-gallery-item] {
        :has(p) {
            &:hover {
                [data-image] {
                    &::before {
                        ${({ column }) =>
                            column === 0 || column === 2
                                ? css`
                                      border-color: var(--primary-100) transparent transparent transparent;
                                  `
                                : css`
                                      border-color: transparent transparent var(--primary-100) transparent;
                                  `}
                    }

                    &::after {
                        background-color: rgba(217, 217, 217, 0.3);
                    }
                }

                [data-caption] {
                    > p {
                        color: var(--primary-100);
                    }
                }
            }
        }
    }

    &:nth-child(odd) {
        [data-gallery-item] {
            display: flex;
            flex-direction: column;

            [data-caption] {
                order: -1;
            }

            [data-image] {
                order: 1;
            }
        }
    }
`;

const Silver = css<{ oneRowMode?: boolean }>`
    width: ${({ oneRowMode }) => (oneRowMode ? "50%" : "25%")};

    [data-caption] {
        > p {
            ${C_768_Regular}
        }
    }
`;

const Gold = css``;

const Platinum = css`
    [data-image] {
        &::before {
            border-width: 15px;
        }
    }

    [data-caption] {
        > p {
            ${C_1366_Regular}
        }
    }
`;

const Diamond = css``;

export const SMosaicPhotoGalleryItemWrapper = styled.div<IProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
