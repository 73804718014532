import React, { FC, useContext } from "react";
import { Context, IContext } from "src/common";
import { Container, Section } from "../Grid";

import { HaderContainer, LogoLink, Logo, LogoText } from "./Header.styles";
import Navigation from "./Navigation";

const Header: FC = () => {
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  return (
    <HaderContainer>
      <Container>
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} />
        {logo && (
          <LogoLink href="/">
            <Logo src={logo.src} />
            <LogoText>MEINE KÜCHE</LogoText>
          </LogoLink>
        )}
      </Container>
    </HaderContainer>
  );
};

export default Header;
