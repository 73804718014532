import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    display: flex;
    .tabbar-header {
        color: #000;
        background: rgba(255, 255, 255, 0.4);
        margin-right: 10.5px;
        padding: 4px 10px;
        text-decoration: none;
        cursor: pointer;
    }
    .tabbar-header-active {
        background: #e6e7e9;
        font-weight: 700;
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const STabBarHeader = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default STabBarHeader;
