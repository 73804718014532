import * as React from "react";
import { FunctionComponent, useContext } from "react";
import { IContext, Context } from "src/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTiktok,
  faXTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { addHttpsToLinks } from "../../../../../common/utils/addHttpsToLinks";
import { Wrapper } from "./Banner.style";
import { BannerLink } from "./BannerLink/BannerLink";

interface IProps {
  hidden?: boolean;
}

export const Banner: FunctionComponent<IProps> = () => {
  const props = useContext<IContext>(Context);
  const palette = props?.Settings;
  const companyInformation = props?.CompanyInformationPublic;

  const items = [
    {
      className: "phone",
      href: props?.PageContact?.slug && `/${props?.PageContact?.slug}`,
    },
    {
      className: "at",
      href: companyInformation?.email && `mailto:${companyInformation?.email}`,
    },
    {
      className: "way",
      href: props?.PageContact?.slug && `/${props?.PageContact?.slug}`,
    },
    {
      className: "fb",
      target: "_blank",
      href:
        companyInformation?.facebook &&
        addHttpsToLinks(companyInformation?.facebook),
    },
    {
      className: "insta",
      target: "_blank",
      href:
        companyInformation?.instagram &&
        addHttpsToLinks(companyInformation?.instagram),
    },
    {
      className: "youtube",
      target: "_blank",
      href:
        companyInformation?.youtube &&
        addHttpsToLinks(companyInformation?.youtube),
    },
    {
      className: "twitter",
      target: "_blank",
      href:
        companyInformation?.twitter &&
        addHttpsToLinks(companyInformation?.twitter),
    },
    {
      className: "pintrest",
      target: "_blank",
      href:
        companyInformation?.pintrest &&
        addHttpsToLinks(companyInformation?.pintrest),
    },
    {
      className: "whatsapp",
      target: "_blank",
      href:
        companyInformation?.whatsapp &&
        addHttpsToLinks(companyInformation?.whatsapp),
    },
    {
      className: "tiktok",
      target: "_blank",
      href:
        companyInformation?.tiktok &&
        addHttpsToLinks(companyInformation?.tiktok),
    },
  ].filter((item) => item.href);

  if (items.length < 1) {
    return null;
  }

  if (props.slug == props.PageContact?.slug) {
    return null;
  }

  return (
    <Wrapper
      background={palette.themeColorSecondary}
      color={palette.themeColorPrimary}
      id="banner1"
    >
      {items.map(({ className, href, target }, index) => (
        <BannerLink
          key={index}
          href={href}
          className={className + (target == "_blank" ? " boxicon" : "")}
          target={target}
        >
          {className === "fb" ? <FontAwesomeIcon icon={faFacebook} /> : null}
          {className === "insta" ? (
            <FontAwesomeIcon icon={faInstagram} />
          ) : null}
          {className === "youtube" ? (
            <FontAwesomeIcon icon={faYoutube} />
          ) : null}
          {className === "tiktok" ? <FontAwesomeIcon icon={faTiktok} /> : null}
          {className === "whatsapp" ? (
            <FontAwesomeIcon icon={faWhatsapp} />
          ) : null}
          {className === "twitter" ? (
            <FontAwesomeIcon icon={faXTwitter} />
          ) : null}
          {className === "pintrest" ? (
            <FontAwesomeIcon icon={faPinterest} />
          ) : null}
        </BannerLink>
      ))}
    </Wrapper>
  );
};
