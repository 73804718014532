import styled, { css } from "styled-components";

const borderHorizontal = css`
  height: 8px;
  width: 100%;
  background-image: url("/images/kw_leistungen_line_horizontal.png");
`;

const borderVertical = css`
  height: 100%;
  width: 8px;
  background-image: url("/images/kw_leistungen_line_vertical.png");
`;

export const ItemDefaultWrapper = styled.div`
  position: relative;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: 100px;
  position: relative;

  p {
    text-align: center;
    padding: 0 20px;
    font-weight: bold !important;
    color: #f9b000;
    width: 100%;
    hyphens: manual;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 140px;
  margin-bottom: 30px;
`;

export const RichTextWrapper = styled.div`
  text-align: center;
  padding: 0 10px;
  color: #777;

  > p {
    hyphens: manual !important;
    line-height: 1.4 !important;

    &:first-child {
      color: #f9b000;
      margin-bottom: 0px;
    }
  }
`;

export const BoxContainer = styled.div`
  position: absolute;
  width: 100%;
  transform: translate(0, -100px);
`;

export const BordersWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
`;

export const BoxBorderTop = styled.div`
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;

  ${borderHorizontal}
`;

export const BoxBorderLeft = styled.div`
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;

  ${borderVertical}
`;

export const BoxBorderMiddle = styled.div`
  position: absolute;
  top: 100px;
  z-index: 2;
  left: 0px;

  ${borderHorizontal}
`;

export const BoxBorderBottom = styled.div`
  position: absolute;
  bottom: 0px;
  z-index: 2;
  left: 0px;

  ${borderHorizontal}
`;

export const BoxBorderRight = styled.div`
  position: absolute;
  z-index: 2;
  top: 0px;
  right: 0px;

  ${borderVertical}
`;
