import React, { FunctionComponent, HTMLAttributes } from "react";
import { SOffsetGalleryItem } from "./styles/SOffsetGalleryItem";
import { FancyImageWithModal } from "src/common/BaseComponents/FancyImageWithModal/FancyImageWithModal";
import { IGenAsset } from "src/common/types_dealer_gen";
import { IResponseOptionalValue } from "src/common/constants/types";
import { IPhotoGallery } from "src/common/childTypes";
import FullScreenSliderWithFancyImage from "../../FullScreenSliderWithFancyImage/FullScreenSliderWithFancyImage";

interface IProps {
    image: IGenAsset;
    responsiveAspectRatio: IResponseOptionalValue<number>;
    item: IPhotoGallery;
}

export const OffsetGalleryItem: FunctionComponent<IProps> = ({ image, responsiveAspectRatio, item }) => {
    if (!image?.src) return null;

    return (
        <SOffsetGalleryItem data-gallery-item>
            <div data-image>
                {item.modal === "slider" ? (
                    <FullScreenSliderWithFancyImage elements={item.elements} img={image} 
                    responsiveMaxWidth={{ platinum: 1366 / 2 }}
                    responsiveFactor={{ bronze: 1, silver: 0.5 }}
                    responsiveAspectRatio={responsiveAspectRatio}
                    />
                ) : (
                    <FancyImageWithModal
                        img={image}
                        responsiveMaxWidth={{ platinum: 1366 / 2 }}
                        responsiveFactor={{ bronze: 1, silver: 0.5 }}
                        responsiveAspectRatio={responsiveAspectRatio}
                    />
                )}
            </div>
            {image?.description && <p data-caption>{image.description}</p>}
        </SOffsetGalleryItem>
    );
};
