import { Context, getHeaderNavItemProps, IContext } from "src/common";
import React, { FunctionComponent, useContext } from "react";
import { HeaderContactRow } from "./headerContactRow/HeaderContactRow";
import NavItem, { eNavItemType } from "./navItem/NavItem";
import ResponsiveSidebar from "./responsiveSidebar/ResponsiveSidebar";
import {
  SHeaderLogo2,
  ST5HeaderContent,
  ST5HeaderLogosWrapper,
  ST5HeaderNav,
  ST5HeaderWrapper,
} from "./Header.style";
import { LineLogo } from "src/common/components/LineLogo";

import dynamic from "next/dynamic";


const DachseiteSearch = dynamic(
  () =>
    import("./dachseiteSearch/DachseiteSearch").then(
      (mod) => mod.DachseiteSearch as any
    ),
  { ssr: false }
) as any;

const Header: FunctionComponent<{OverwriteSearch?: any}> = ({ ...p }: any) => {
  const OverwriteSearch = p?.OverwriteSearch;
  const props = useContext<IContext>(Context);
  return (
    <div id="T5Header">
      <ST5HeaderWrapper>
        <ST5HeaderContent>
          <ST5HeaderLogosWrapper href={"/"}>
            <LineLogo />
            {props?.CompanyInformationPublic?.logo?.[0]?.src && (
              <SHeaderLogo2
                src={props?.CompanyInformationPublic?.logo?.[0]?.src}
                alt="logo"
              />
            )}
          </ST5HeaderLogosWrapper>
          <ST5HeaderNav>
            {props.PrimaryNavigation?.items.map((item, i) => {
              const itemProps = getHeaderNavItemProps(item);
              let isActive = false;
              if (!itemProps) {
                return null;
              }
              if (props.slug === item?.link?.[0]?.slug) {
                isActive = true;
              }

              if (item.subitems && item.subitems.length > 0 && !isActive) {
                for (const subItem of item.subitems) {
                  if (props.slug === subItem?.slug) {
                    isActive = true;
                  }
                }
              }

              return (
                <NavItem
                  active={isActive}
                  type={eNavItemType.header}
                  key={i}
                  link={itemProps.link}
                  headline={itemProps.headline}
                  title={itemProps.title}
                  subItems={item.subitems as any}
                />
              );
            })}
          </ST5HeaderNav>
        </ST5HeaderContent>
      </ST5HeaderWrapper>
      {OverwriteSearch ? (
        <OverwriteSearch></OverwriteSearch>
      ) : (
        <>
          {(props?.dealer as any)?.dachseite ? (
            <DachseiteSearch />
          ) : (
            <HeaderContactRow />
          )}
        </>
      )}

      <ResponsiveSidebar />
    </div>
  );
};

export default Header;
