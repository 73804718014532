import { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const topBarWrapper: any = css`
    background-color: ${(props) => props.theme.primary};
    padding: 4px;
`;

export const topBarContent: any = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    * {
        color: white;
    }
    p {
        display: block;
        margin: 15px 0;
    }
    @media (max-width: 500px) {
        p {
            width: 100%;
            text-align: center;
        }
    }
`;

export const logosWrapper: any = css`
    display: flex;
    margin-right: 150px;
    img {
        height: 70px;
        width: auto;
        &:first-child {
            width: auto;
        }
        &:first-child:not(.nogray) {
            margin-right: 20px;
            /* filter:saturate(0) invert(1); */
        }
        &:nth-child(2) {
            padding: 7px 0 6px;
        }
    }
    @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
        flex-direction: column;
        align-items: center;
        img {
            height: 80px;
            max-width: 95%;
            margin-right: 0 !important;
            padding: 5px !important;
        }
    }
`;

export const serviceNummer: any = css`
    padding: 20px 0;
    p {
        text-align: center;
        color: ${(props) => props.theme.secondary};
        &:first-child {
            margin-bottom: 5px;
            font-size: 30px;
            text-transform: uppercase;
            span:nth-child(2) {
                white-space: nowrap;
                font-weight: 600;
            }
        }
        &:nth-child(2) {
            font-size: 16px;
        }
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
        p:first-child {
            font-size: 24px;
        }
    }
`;

export const serviceNummerAndAssetWrapper: any = css`
    position: relative;
`;

export const outerNavWrapper: any = css`
    transform: translateX(-50%);
    left: 50%;
    z-index: 8;
    position: absolute;
    bottom: -18px;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: none;
    }
`;

export const navWrapper: any = css`
    display: flex;
    align-items: center;
    span {
        white-space: nowrap;
    }
`;
