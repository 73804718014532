// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const FooterWrapper = styled.div`
  width: 100%;
  padding: 20px 0 0;
  margin: 0 auto 0;
  background-color: #333;
  color: #aaa;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0 0;
  }
`;

export const LinksWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-bottom: 3px;
  }
  a {
    color: #aaa;
    font-size: 13px;
    font-weight: 300;
    line-height: 18.2px;
    padding-bottom: 10px;
    &:hover {
      color: #fff;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    align-items: flex-start;
  }
`;

export const Link: any = styled.a<{ isBold: boolean }>`
  display: block;
  color: #505050;
  line-height: 1.2rem;
  ${({ isBold }) => isBold && "font-weight: bold;"}
`;

export const AddressWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  background-color: #222222;
  p,
  div {
    color: #AAAAAAA;
    font-size: 11.7px;
    font-weight: 300;
    line-height: 33px;
    padding: 0 0 0 20px;
  }

  div.social-links {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    padding: 0 30px;
    a {
      padding-left: 20px;
      color: #ccc !important;
      font-size: 25px;
      width: 45px;
    }
  }

  & > .managingDirectorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 83%;
    margin: auto;
    padding: 0 30px;
    p {
      margin-top: 0;
      padding-left: 10px;
      text-align: center;
    }
    a {
      padding-left: 20px;
      font-size: 11.7px;
      font-weight: 300;
      &:hover {
        color: #fff;
      }
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 55px;
    flex-direction: row;
    & > .managingDirectorWrapper {
      flex-direction: row;
      p {
        text-align: left;
      }
    }
    div.social-links {
      justify-content: flex-end;
      width: 17%;
    }
  }
`;

export const ShareOnSocialMediaWrapper: any = styled.div`
  display: flex;
  a {
    display: flex;
    align-items: center;
    margin-right: 17px;
    padding: 8px;
    height: 30px;
    width: 85px;
    span {
      margin-left: 10px;
      color: white;
      display: none;
    }
    &:first-child {
      background-color: #3b5998;
      &:hover {
        background-color: #4273c8;
      }
    }
    &:nth-child(2) {
      background-color: #55acee;
      &:hover {
        background-color: #78c2ff;
      }
    }
    &:nth-child(3) {
      background-color: #bd081c;
      &:hover {
        background-color: #f10c23;
      }
    }
    &:nth-child(4) {
      background-color: #999;
      &:hover {
        background-color: #c1c1c1;
      }
    }
    &:nth-child(5) {
      background-color: #999;
      &:hover {
        background-color: #c1c1c1;
      }
    }
    svg {
      height: 100%;
      fill: white;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    a {
      width: 160px;
      span {
        display: block;
      }
    }
  }
`;

export const ContentSide: any = styled.div<{ withOutPaddingTop: boolean }>`
    width 100%;
    justify-content: center;
    align-items: center;
    div.column {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${({ withOutPaddingTop }) =>
        withOutPaddingTop ? "0 15px 0" : "10px 15px 0"};
      p {
        text-align: center;
        color: #AAA;
        font-size: 13px;
        font-weight: 300;
        line-height: 18.2px;
      }
      img {
        width: min-content;
      }
      img.linelogo {
        width: 20%;
        max-height: 75px;
        object-fit: contain;
        @media (${BREAKPOINTS_DM.silver_768}) {
          object-position: left;
        }
      }
    }
    div.content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      width 50%;
      div.content {
        width: 100%;
        flex-direction: row;
      }
      div.column {
        align-items: flex-start;
        p {
          text-align: left;
        }
      }
    }
`;

export const Content: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1170px;
  margin: 150px auto 0;
  padding: 15px 0;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    margin: 0 auto 0;
  }
`;
export const FooterBannerWrapper: any = styled.div`
  width: 100%;
  padding: 0 30px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
  }
`;

export const ContentHead: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 40px 0;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 190px;
  }
`;

export const Title: any = styled.div`
  h3 {
    color: #aaa;
    font-size: 16px;
    font-weight: 300;
    line-height: 22.4px;
    margin: 30px 0 10px;
    text-align: center;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    h3 {
      margin: 0px 0 10px;
      text-align: left;
    }
  }
`;
