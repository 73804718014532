import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const ItemDefaultWrapper = styled.div``;

export const BoxContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  min-height: 100px;
  margin-bottom: 50px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    min-height: 600px;
    margin-bottom: 20px;
  }
`;

export const BoxBorderTop = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border-top: 2px dotted #85b2c3;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
  }
`;

export const BoxBorderLeft = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  border-left: 2px dotted #85b2c3;
`;

export const BoxBorderArrow = styled.div`
  position: absolute;
  top: 30px;
  left: 0px;
`;

export const BoxBorderIconNumber = styled.div<{ iconNumber: number }>`
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -20px;

  &:after {
    ${({ iconNumber }) =>
      css`
        content: "${iconNumber + 1}";
      `}

    background-color: #92c2d6;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  padding-top: 40px;
  padding-left: 25px;
  min-height: 10px;

  h4 {
    letter-spacing: 1px;
    margin: 0;

    font-size: 15px;
    line-height: 18px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    min-height: 100px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 242px;
  text-align: center;

  img {
    width: 100%;
    vertical-align: middle;
  }
`;

export const RichTextWrapper = styled.div`
  position: relative;
  padding-top: 0px;
  padding-left: 30px;

  > p {
    &,
    strong {
      color: #575656;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      letter-spacing: 1px;
    }

    strong {
      font-weight: bold;
    }

    &:nth-child(2) {
      padding-right: 10px;
    }
  }
`;
