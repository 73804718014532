import { FunctionComponent, useState, Fragment } from "react";
import React from "react";
import STabBarHeader from "./Styles/STabBarHeader";
import STabBar from "./Styles/STabBar";
import STabBarElement from "./Styles/STabBarElement";

const TabBarElement = ({ children, label }) => {
    return (
        <div>
            {React.Children.map(children, (child, i) => (
                <>
                    <div>{child}</div>
                </>
            ))}
        </div>
    );
};

const TabBar = ({ children }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <STabBar activeIndex={activeIndex} className={'tabbar'}>
            <STabBarHeader>
                {React.Children.toArray(children)
                    .filter((child) => !!(child as any)?.props?.children)
                    .map((child, i) => {
                        return (
                            <div
                                className={`tabbar-header ${i === activeIndex ? "tabbar-header-active" : ""}`}
                                onClick={() => setActiveIndex(i)}
                                key={`stbh${i}`}
                            >
                                {(child as any)?.props?.label}
                            </div>
                        );
                    })}
            </STabBarHeader>
            {React.Children.toArray(children)
                .filter((child) => !!(child as any)?.props?.children)
                .map((child, i) => {
                    return (
                        <Fragment key={`stbh2${i}`}>
                            <STabBarElement className={'tabbar-element'} active={activeIndex === i}>{child}</STabBarElement>
                        </Fragment>
                    );
                })}
        </STabBar>
    );
};

export { TabBar, TabBarElement };
