// @ts-nocheck
import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const NavigationContainer = styled.div`
  height: fit-content;
  position: fixed;
  width: 100%;
  max-width: 1100px;
  z-index: 999;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    background-color: #92c2d6;
  }
  pointer-events: ${({ isMenuActive }) => (isMenuActive ? "all" : "none")};
  > * {
    pointer-events: all;
  }
`;

export const HeaderContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  height: fit-content;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    max-width: 100vw;
    overflow: hidden;
  }
`;

export const Logo = styled.img`
  position: absolute;
  height: 120px;
  z-index: 1;
  margin: 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 150px;
    margin: 100px 0 30px 30px;
    width: calc(100% - 97px);
    max-width: 480px;
    width: unset;
  }
`;
