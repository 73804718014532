import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    background: ${(props: any) => props.theme.palette.productBgLight100};
    padding: 20px;
    display: none;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const active = css`
    display: block;
`;

const STabBarElement = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
    ${(props) => (props.active ? active : "")};
`;
export default STabBarElement;
