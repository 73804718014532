import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const SiegelWrapper: any = styled.div<{active: boolean}>`
  ${({ active }) => !active && "display: none;"}
  width: 600px;
  heigth: 600px;
  position: fixed;
  top: 3%;
  right: -33.5%;
  z-index: 9;
  svg {
    position: absolute;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    top: 23%;
    right: -1.5%;
  }
`;

export const SCookieBannerInnerBox: any = styled.div`
  color: #333;
  width: 277.5px;
  position: relative;
  margin: 36% auto;
  p {
    color: #333!important;
    text-align: center;
    font-family: Arial,Sans-serif!important;
    font-size: 10.5px!important;
    line-height: 1.4!important;
    margin-bottom: .8rem!important;
  }
`;

export const SCookieBannerInnerBottomBox: any = styled.div`
  display: flex;
  justify-content: center;
  a {
    color: rgb(0, 128, 0)!important;
    cursor: pointer;
    font-family: Arial, sans-serif!important;
    font-size: 15px!important;
    font-weight: 700!important;
    &:hover {
      text-decoration: underline!important;
      text-decoration-color: rgb(0, 128, 0)!important;
    }
  }
  span {
    margin: 0 5px;
  }
`;
