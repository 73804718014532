import styled, { css } from "styled-components";

interface ISFancyImageImgProps {
  isLoaded?: boolean;
}

const Default = css<ISFancyImageImgProps>`
    transition: opacity 0.3s ease;
    opacity: ${(props) => (props.isLoaded ? 1 : 0)};
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
`;

export const SFancyImageImg = styled.img<ISFancyImageImgProps>`
  ${Default};
`;
