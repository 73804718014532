import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { responsiveFactor } from "../ComparisonPhotoGallery";

interface IProps {
    aspectRatioFactor: typeof responsiveFactor;
}

const displayLabel = css`
    &::before {
        background-color: transparent;
    }

    label {
        opacity: 1;
        color: var(--back-1);
        background-color: var(--primary-100);
    }
`;

// ${({ aspectRatioFactor }: IProps) => css`
// padding-top: ${aspectRatioFactor?.bronze ? aspectRatioFactor.bronze * 100 : 85}%;
// `}
const Default = css`
    pointer-events: none;
    position: relative;
    width: 100%;

    padding-top: 84.83754513%;

    [data-image-right-trigger],
    [data-image-left-trigger] {
        pointer-events: all;
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        transition: clip-path 0.15s linear 0.01s;

        &::before {
            pointer-events: none;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            z-index: 3;
            transition: background-color 0.15s linear 0.01s;
        }
    }

    [data-image-right-trigger] {
        right: 0;
        clip-path: polygon(17% 0%, 100% 0%, 100% 100%, 17% 100%);
    }

    [data-image-left-trigger] {
        left: 0;
        clip-path: polygon(0% 0%, 15.3% 0%, 15.3% 100%, 0% 100%);
    }

    &:hover {
        [data-image-right-trigger],
        [data-image-left-trigger] {
            &::before {
                background: rgba(217, 217, 217, 0.6);
            }
        }

        .icon {
            &.before-icon {
                left: 3.1%;
            }

            &.after-icon {
                opacity: 1;
            }
        }

        [data-image-right-trigger] {
            clip-path: polygon(92.4% 0%, 100% 0%, 100% 100%, 92.4% 100%);

            &:hover {
                clip-path: polygon(11.2% 0%, 100% 0%, 100% 100%, 11.2% 100%);

                .icon {
                    &.after-icon {
                        opacity: 0;
                    }
                }

                ${displayLabel}
            }
        }

        [data-image-left-trigger] {
            clip-path: polygon(0% 0%, 7.6% 0%, 7.6% 100%, 0% 100%);

            &:hover {
                clip-path: polygon(0% 0%, 88.7% 0%, 88.7% 100%, 0% 100%);

                .icon {
                    &.before-icon {
                        opacity: 0;
                    }
                }

                ${displayLabel}
            }
        }
    }
`;

// ${({ aspectRatioFactor }: IProps) => css`
// padding-top: ${aspectRatioFactor?.silver ? aspectRatioFactor.silver * 100 : 82}%;
// `}
const Silver = css`
    padding-top: 82.65306122%;

    [data-image-right-trigger] {
        clip-path: polygon(31.9% 0%, 100% 0%, 100% 100%, 31.9% 100%);
    }

    [data-image-left-trigger] {
        clip-path: polygon(0% 0%, 31.6% 0%, 31.6% 100%, 0% 100%);
    }

    &:hover {
        label {
            opacity: 1;
        }

        .icon {
            &.before-icon {
                left: 23%;
            }
        }

        [data-image-right-trigger] {
            clip-path: polygon(71.66% 0%, 100% 0%, 100% 100%, 71.66% 100%);

            &:hover {
                clip-path: polygon(31.8% 0%, 100% 0%, 100% 100%, 31.8% 100%);
            }
        }

        [data-image-left-trigger] {
            clip-path: polygon(0% 0%, 28.33% 0%, 28.33% 100%, 0% 100%);

            &:hover {
                clip-path: polygon(0% 0%, 68.19% 0%, 68.19% 100%, 0% 100%);
            }
        }
    }
`;

const Gold = css``;

const Platinum = css`
    padding-top: 84.32098765%;

    [data-image-right-trigger] {
        clip-path: polygon(33.3% 0%, 100% 0%, 100% 100%, 33.3% 100%);
    }

    [data-image-left-trigger] {
        clip-path: polygon(0% 0%, 33% 0%, 33% 100%, 0% 100%);
    }

    &:hover {
        .icon {
            &.before-icon {
                left: 25.9%;
            }
        }

        [data-image-right-trigger] {
            clip-path: polygon(69.81% 0%, 100% 0%, 100% 100%, 69.81% 100%);

            &:hover {
                clip-path: polygon(33.3% 0%, 100% 0%, 100% 100%, 33.3% 100%);
            }
        }

        [data-image-left-trigger] {
            clip-path: polygon(0% 0%, 30.18% 0%, 30.18% 100%, 0% 100%);

            &:hover {
                clip-path: polygon(0% 0%, 66.9% 0%, 66.9% 100%, 0% 100%);
            }
        }
    }
`;

const Diamond = css``;

export const SComparisonPhotoGalleryItem = styled.div<IProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
