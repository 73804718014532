// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const BurguerIconWrapper: any = styled.button`
  position: absolute;
  font-size: 45px;
  color: #000;
  z-index: 99999;
  left: 0px;
  top: 12px;
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    font-size: 40px;
    top: 12px;
    left: 4px;
  }
  
`;

export const CloseIconWrapper: any = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  cursor: pointer;
  font-size: 60px;
  color: #F19024;
  z-index: 999;
  top: 5px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    color: #F19024;
    top: 15px;
    width: 5%;
    font-size: 40px;
   
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    color: #F19024;
    top: 15px;
    width: 30%;
    font-size: 40px;
   
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    color: #FFFFFF;
    top: 15px;
    width: 19.5%;
    font-size: 40px;
    &:hover {
      color: #F19024;
    }
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    width: 20.5%;
  }
`;

export const NavigationLink = styled.li<{isActive: boolean}>`
  margin-bottom: 1px;
  display: flex;
  color: ${({ isActive }) => isActive ? "#F19024" : "#FFFFFF"};

  > a {
    text-transform: uppercase;
    display: flex;
    font-family: franklin_gothic_fs_bookRg,Helvetica,Arial,sans-serif;
    font-size: 22px;
    align-items: center;
    font-weight: 400;
    padding-left: 10.5px;
    line-height: 37px;
    width: fit-content;
    &:hover {
      color: #F19024;
    }
`;
export const LinksWrapper: any = styled.div`
  box-shadow: -2px -2px 5px rgb(50 50 50 / 50%);
  ul {
    padding: 40px 0 0 10px;
    height: 667px;
    margin: 56px 0 0;
    list-style: none;
    overflow: auto;
    background-color: #5E5E5D!important ;
    width: 150vw;
    height: calc(117vh - 125px);
    @media (${BREAKPOINTS_DM.silver_768}) {
      height: calc(100vh - 55px);
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      margin: 124px 0 0;
      padding: 40px 0 0 70px;
      height: calc(101vh - 140px);
    }
    @media (${BREAKPOINTS_DM.platinum_1366}) {
      margin: 124px 0 0;
      padding: 40px 0 0 70px;
      height: calc(101vh - 130px);
    }
    @media (${BREAKPOINTS_DM.diamond_1920}) {
      margin: 124px 0 0;
      padding: 40px 0 0 70px;
      height: calc(101vh - 140px);
    }
  }
`;

export const NavigationLinks = styled.div`
  position: relative;
  background-color: #5E5E5D!important ; 
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transform: translate3d(70vw,0,0);
  transition: transform .8s ease;
  width: 150vw;
  height: 125px;

  ${BurguerIconWrapper} {
    display: none;
  }

 
`;

export const NavigationContainer: any = styled.div`
  position: absolute;
  display: flex;
  right: 0px;
  top: 0;
  background-color: #FFFFFF;
  ${({ active }) => active
    ? `
      width: 90vw;
      ${NavigationLinks} {
        ${BurguerIconWrapper} {
          display: block;
          margin-left: 5px;
        }
        transform: translate3d(0,0,0);
      }

      ${BurguerIconWrapper} {
        display: none;
      }
      @media(${BREAKPOINTS_DM.silver_768}) {
        width: 69vw;
      }
      @media(${BREAKPOINTS_DM.gold_1024}) {
        width: 50.4vw;
      }
      @media(${BREAKPOINTS_DM.platinum_1366}) {
        width: 34.4vw;
      }
      @media(${BREAKPOINTS_DM.platinum_1366}) {
        width: 34.4vw;
    `
    : `
      width: 15%;
      @media(${BREAKPOINTS_DM.silver_768}) {
        width: 7%;
      }
      @media(${BREAKPOINTS_DM.gold_1024}) {
        width: 10%;
      }
      @media(${BREAKPOINTS_DM.platinum_1366}) {
        width: 7%;
      }
      @media(${BREAKPOINTS_DM.diamond_1920}) {
        width: 5%;
      }
    `}
`;

export const SubItemWrapper: any = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

export const SubItem: any = styled.a<{isActive: boolean}>`
    color: ${({ isActive }) => isActive ? "#F19024" : "#FFFFFF"};
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    padding: 8px 0 8px 11px;
    margin: 0 0 5px;
    &:hover {
      color: #F19024;
    }
    div {
      display: none;
      @media (${BREAKPOINTS_DM.gold_1024}) {
        display: initial;
        color: #BCBCBC;
        position: relative;
        right: 25px;
        margin-right: -13px;
      }
    }
`;

export const sidebar_SubItem: any = css`
    display: block;
    padding: 10px 0 12px 36px;
    &:last-child {
      margin-bottom: 5px;
    }
`;

export const sidebar_SubItemsWrapper: any = css`
  display: none;
`;

export const sidebar_SubItemsWrapperExpanded: any = css`
    display: block;
    @media (${BREAKPOINTS_DM.silver_768}) {
      display: none;
    }
`;
