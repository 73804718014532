import React, { FunctionComponent } from "react";
import { INews } from "src/common";
import { TheOnlySlider } from "src/common/components/TheOnlySlider";

import * as styles from "./NewSlider.style";
import { NewSlide } from "./slide/NewSlide";

interface IProps 
{
  newsSlug: string;
  news: (INews | null)[] | null;
}

export const NewSlider: FunctionComponent<IProps> = ({ news, newsSlug }) => 
{
  if(news?.length <= 0) 
  {
    return null;
  }

  const newsToRender = news.filter((newsItem) => 
  {
    if(!newsItem?.startDate && !newsItem?.endDate) 
    {
      return true;
    }
    else if(!newsItem?.startDate && newsItem?.endDate) 
    {
      return new Date() <= new Date(newsItem?.endDate);
    }
    else if(newsItem?.startDate && !newsItem?.endDate) 
    {
      return new Date() >= new Date(newsItem?.startDate);
    }
    else 
    {
      return new Date() >= new Date(newsItem?.startDate) && new Date() <= new Date(newsItem?.endDate);
    }
  });

  return (
    <div css={styles.wrapper}>
      <TheOnlySlider>
        {newsToRender.map((newsItem, i) => (
          <NewSlide
            key={i}
            index={i}
            totalCount={newsToRender?.length}
            img={(newsItem?.extras as any[])?.[0]?.src}
            imgAlt={(newsItem?.extras as any[])?.[0]?.title}
            title={newsItem?.headline}
            text={newsItem?.text}
            teaser={newsItem?.teaser}
            link={newsSlug + "/" + newsItem?.id}
          />
        ))}
      </TheOnlySlider>
    </div>
  );
};
