// @ts-nocheck
import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const BurguerIcon: any = styled.div<{isMenuActive: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 50px;
  font-size: 25px;
  color: #fff;
  background: #1d1d1b;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
  .close {
    display: ${({ isMenuActive }) => isMenuActive ? "initial" : "none"};
  }
  .open {
    display: ${({ isMenuActive }) => isMenuActive ? "none" : "initial"}
  }
`;

export const NavigationLink: FC<any> = styled.li<{ isActive: boolean }>`
  display: flex;
  margin-bottom: 1px;
  > a {
    text-transform: uppercase;
    color: white;
    font-size: 13px;
    padding: 15px 8.4px 7.5px;
    letter-spacing: .28px;
    font-weight: 300;
    line-height: 19.6px;
    width: fit-content;
    text-align: center;
    font-stretch: 100%;
    background-color: ${({ isActive }) => isActive && "#76B729"};
    &:hover {
      background-color: #76B729; 
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      color: #fff;
      font-size: 14px;
      border-right: 1px solid #fff;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    border-bottom: none;
    margin-bottom: 0;
  }
`;
export const SubItem: any = styled.div`
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  background-color: #1d1d1b;
  padding: 2px 5px 2px 0;
  height: auto;
  margin-bottom: 2px;
`;

export const IconWrapper: any = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  background-color: #1d1d1b;
  width: 100%;
  padding-left: 10px;
  
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const Icon: any = styled.div<{isShowSubItems: boolean}>`
  transform: ${({ isShowSubItems }) => isShowSubItems && "rotate(90deg)"};   
`;

export const NavigationLinks: any = styled.div<{isMenuActive: boolean}>`
  
  ${({ isMenuActive }) => isMenuActive ? 
    `padding: 60px 0 0 0;
    display: flex;
    ` 
    : 
    `padding: 0;
    display: none`
};
  
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;

  ${BurguerIcon} {
    display: none;
  }

  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    list-style: none;
    overflow-y: auto;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: unset;
    padding: 0 60px;
    top: 0;
    // margin-bottom: 10px;
    ${NavigationLink} {
      display: flex;
      
    }

    ${BurguerIcon} {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      height: unset;
      li:first-child {
        border-left: 1px solid #fff;
      }
    }
  }
`;

export const NavigationContainer: FC<any> = styled.div`
  display: flex;
  justify-content: center;
  background-color: #1d1d1b;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 1200px;
    margin: auto;
    ${BurguerIcon} {
      display: none;
    }
  }
`;

export const SubItemWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
`;
