
import React, { FC, useContext } from "react";
import { Context, getHeaderNavItemProps, IContext } from "src/common";

import { HeaderContainer, Logo, NavigationContainer } from "./Header.styles";
import Navigation from "./Navigation/Navigation";

const Header: FC = () => {
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  return (
    <HeaderContainer>
      <NavigationContainer>
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} />
      </NavigationContainer>
      {logo && <a href="/" className="logoLink"><Logo src={logo.src} /><h1 className="headerText">Küchen, die passen</h1></a>}
    </HeaderContainer>
  );
};

export default Header;
