import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css`
    label {
        display: block;
        height: 20px;
        font: 0.9375em/1.4 sofia_pro_regularregular, Helvetica, Arial, sans-serif;
        font-weight: 300;
        color: ${(props) => props.theme.palette.black};
    }
    input,
    textarea {
        width: 100%;
        font: 0.9375em/1.4 sofia_pro_regularregular, Helvetica, Arial, sans-serif;
    }
    input[type="checkbox"] {
        width: auto;
        margin: 3px 10px 0 0;
    }
    .datenschutzAkzeptieren {
        display: inline-block;
    }
    .datenschutzAkzeptieren > a {
        color: ${(props) => props.theme.palette.productPrime};
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SNonBindingRequestFrom = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SNonBindingRequestFrom;
