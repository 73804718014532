import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const PhotoGalleryWrapper = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const PhotoGalleryImage = styled.div`
  margin-bottom: 14px;
  position: relative;

  label {
    background: rgba(255, 255, 255, 0.5);
    padding: 14px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 24px;
    font-weight: 600;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      opacity: 0;
      transform: translateY(-100%);
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
  }

  [data-caption] {
    background-color: #717171;
    color: white;
    fon-size: 15px;
    font-weight: 300;
    line-height: 21px;
    padding: 10.5px;
  }

  .caption-comparison {
    background-color: #717171;
    color: white;
    fon-size: 15px;
    font-weight: 300;
    line-height: 21px;
    padding: 10.5px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: 100%;
    background-color: white;
    img {
      min-width: 520px;
    }

    .caption-comparison {
      background-color: rgba(255,255,255,0.5);
      color: black;
      position: absolute;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: translateY(100%);
    }
    transition: clip-path 0.1s linear;

    :hover {
      label {
        opacity: 1;
        transform: translateY(00%);
      }
      .caption-comparison {
        opacity: 1;
        transform: translateY(0%);
      }
    }
  }
`;

export const PhotoGalleryItem = styled.div`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    position: relative;
    height: 390px;
    margin-bottom: 28px;

    [data-image] {
      transition-duration: 0.1s;
      transition-delay: 0s;
    }

    [data-image-right-trigger] {
      label {
        right: 0;
        left: inherit;
      }
      right: 0;
      [data-image] {
        clip-path: polygon(4.5% 0, 100% 0, 100% 100%, 4.5% 100%);
        .caption-comparison {
          right: 0;
        }
      }
    }
    [data-image-left-trigger] {
      left: 0;
      [data-image] {
        clip-path: polygon(0 0, 95.5% 0, 95.5% 100%, 0% 100%);
        .caption-comparison {
          left: 0;
        }
      }
    }

    [data-image-right-trigger],
    [data-image-left-trigger] {
      position: absolute;
      height: 100%;
      top: 0;
    }

    &:hover {
      [data-image-right-trigger],
      [data-image-left-trigger] {
        [data-image] {
          transition-delay: 0.09s;
        }
        &:hover {
          [data-image] {
            transition-delay: 0s;
          }
        }
      }

      [data-image-right-trigger] {
        [data-image] {
          clip-path: polygon(34.5% 0, 100% 0, 100% 100%, 34.5% 100%);
        }
        &:hover {
          [data-image] {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }
        }
      }
      [data-image-left-trigger] {
        [data-image] {
          clip-path: polygon(0 0, 65.5% 0, 65.5% 100%, 0% 100%);
        }
        &:hover {
          [data-image] {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }
        }
      }
    }
  }
`;
