import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const FooterNote: any = styled.a`
  display: flex;
  flex-direction: column;
  border: 13px solid #92c2d6;
  padding: 20px;
  gap: 40px;
  align-items: center;
  justify-content: space-between;

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }

  .left-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }

    h2 {
      line-height: 32px;
      letter-spacing: 2px;
      font-weight: 700;
      font-size: 20px;
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      h2 {
        font-size: 29px;
      }
    }
  }

  .right-wrapper {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;

    span {
      display: flex;
      align-items: center;

      gap: 5px;

      svg {
        width: 20px;
        height: 20px;
        fill: #92c2d6;
      }
    }
  }
`;

export const PercentIconBlue: any = styled.img`
  width: 70px;
  height: 70px;
  object-fit: contain;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 90px;
    height: 90px;
  }
`;
