import React, { FunctionComponent, useState, useContext } from "react";
import { Context, IContext } from "src/common";

import {
  NavigationContainer,
  NavigationLinks,
  NavigationLink,
  Icon,
  BurguerIcon,
  SubItemWrapper,
  IconWrapper,
  SubItem,
} from "./Header.styles";

interface IHeaderItemProps 
{
  title: string;
  link: string;
  headline?: string;
  external?: boolean;
}

interface INavigationItem 
{
  link?: Record<string, any>[];
  externalLink?: Record<string, any>[];
}
export const getHeaderNavItemProps = (item: INavigationItem): IHeaderItemProps | false => 
{

  const linkItem = item?.link?.[0] || item?.externalLink?.[0];
  if((linkItem as any)?.__typename === "ExternalLink") 
  {
    return {
      link: `${(linkItem as any).externalLinkAddress}`,
      title: `${(item as any).label ?? (linkItem as any).externalLinkName}`,
      external: true,
    };
  }

  const link = linkItem?.slug || (item as any)?.slug;
  const title = (linkItem as any)?.nameInNavigation || (item as any)?.nameInNavigation || (item as any)?.label;
  let headline;

  if(!link || !title) 
  {
    return false;
  }

  if(linkItem?.__typename === "Page" || linkItem?.__typename === "PageNews" || linkItem?.__typename === "PageGuestbook" || linkItem?.__typename === "PageMicro") 
  {
    headline = linkItem.elements?.find(e => e?.__typename === "TextElement")?.[0]?.headline;
  }

  return {
    link,
    title,
    headline
  };
};

const Items: FunctionComponent<{item: Record<string, any>; itemProps: Record<string, any> }> = ({ itemProps, item }) =>
{
  const props = useContext<IContext>(Context);
  const [isShowSubItems, setShowSubItems] = useState(false); 
  const currentItem = props?.PrimaryNavigation?.items?.find(primary => primary?.subitems?.find(secondary => secondary?.slug === props?.slug));
  const { subitems } = item;
  return (
    <>
      <NavigationLink position={isShowSubItems} isActive={itemProps.link === props.slug || itemProps.link === currentItem?.link[0].slug}>
        <a href={itemProps.external ? `${itemProps.link}` : `/${itemProps.link}`}>
          {itemProps.title}
        </a>
        {subitems?.length > 0 && 
            <IconWrapper onClick={() => setShowSubItems(!isShowSubItems)} position={isShowSubItems}>
              <Icon isShowSubItems={isShowSubItems}>
                <i className="fa fa-chevron-right"/>
              </Icon>
            </IconWrapper>
        }
      </NavigationLink>

      {subitems?.length > 0 &&
      <SubItemWrapper>
        {isShowSubItems && subitems.map((subItem, i) =>
          <SubItem key={i}>
            <a href={`/${subItem.slug}`}><img src="/images/doubble-arrow--right--positive_t17.png" style={{ margin: "0 5px 3px" }}/>{subItem.nameInNavigation}</a>
          </SubItem>
        )}
      </SubItemWrapper>}
    </>
  );
};

const Navigation: FunctionComponent<{ items: INavigationItem[]; slug: string }> = ({ items, slug }) => 
{
  const [isMenuActive, toggleMenuActive] = useState(false);
  return (
    <NavigationContainer active={isMenuActive}>
      <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} />
      <NavigationLinks>
        <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} />

        <ul>
          <ul>
            {items.map((item, index) => 
            {
              
              const itemProps = getHeaderNavItemProps(item);
              if(!itemProps) { return; }
              
              return (
                <Items itemProps={itemProps} item={item} key={`item-key-${index}`}/> 
              );
            })}
          </ul>
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
