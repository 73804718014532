import React, { FC, Fragment } from "react";
import { AddressWrapper } from "./Footer.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Address: FC<{ companyInformation: any; items: any[] }> = ({
  companyInformation,
  items,
}) => (
  <AddressWrapper>
    <div className="addressLinksWrapper">
      <a style={{ paddingRight: "10px" }} href="/imprint-datenschutz">
        Impressum
      </a>
      <a style={{ paddingRight: "10px" }} href="/imprint-datenschutz">
        Datenschutz
      </a>
      <a
        style={{ paddingRight: "10px" }}
        href="https://www.facebook.com/kwkuechenwerkstatt"
        target="_blank"
      >
        <svg
          style={{ width: "18px", height: "18px", verticalAlign: "-13%" }}
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 58 58"
        >
          <defs>
            <style>{`.cls-1{fill:#898f9c;}.cls-2{fill:#fff;}`}</style>
          </defs>
          <path
            className="cls-1"
            d="M54.8,0H3.2A3.2,3.2,0,0,0,0,3.2V54.8A3.2,3.2,0,0,0,3.2,58H31V35.57H23.45V26.79H31V20.33c0-7.49,4.58-11.57,11.26-11.57A64.2,64.2,0,0,1,49,9.1v7.83h-4.6c-3.64,0-4.35,1.72-4.35,4.26v5.59h8.7l-1.13,8.78H40V58H54.8A3.2,3.2,0,0,0,58,54.8V3.2A3.2,3.2,0,0,0,54.8,0Z"
          ></path>
          <path
            id="f"
            className="cls-2"
            d="M40,58V35.57h7.57l1.13-8.78H40V21.2c0-2.54.71-4.26,4.35-4.26H49V9.1a64.2,64.2,0,0,0-6.75-.34C35.56,8.76,31,12.84,31,20.33v6.46H23.45v8.78H31V58Z"
          ></path>
        </svg>
        <span style={{ paddingLeft: "5px" }}>FACEBOOK</span>
      </a>
      <a
        className="insta"
        style={{ paddingRight: "15px", paddingBottom: "10px" }}
        href="https://www.instagram.com/kwkuechenwerkstatt"
        target={"_blank"}
        rel="noopener noreferrer"
      >
        {/* @ts-ignore */}
        <FontAwesomeIcon icon="fa-brands fa-instagram" />
        <span style={{ paddingLeft: "5px" }}>INSTAGRAM</span>
      </a>
    </div>
  </AddressWrapper>
);

export default Address;
