import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const menu: any = css`
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  display: none;
  margin-right: 20px;
    svg {
      fill: white;
      width: 14px;
      margin: auto;
    }
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        display: flex;
    }
`;

const line2: any = css`
  width: 100%;
  height: 3px;
  position: absolute;
  background-color: white;
  border-radius: 3px;
`;

export const line: any = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: background-color ease 0.2s .15s;
  ${line2}
  &:before, &:after {
    content: '';
    transition: transform ease .5s, background-color ease .3s .2s, top ease .5s;
    ${line2}
  }
  &:before {
    top: -8px
  }
  &:after {
    top: 8px
  }
`;
