// @ts-nocheck
import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const NavigationContainer = styled.div`
  height: fit-content;
  position: fixed;
  width: 100%;
  max-width: 1100px;
  z-index: 999;
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const HeaderContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  height: fit-content;
  display: flex;
  flex-direction: column;

  .headerText {
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 10px;
    display: none;
  }

  .logoLink {
    display: flex;
    gap: 30px;
    align-items: flex-end;
    padding-bottom: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    .headerText {
      display: inline-block;
      margin-bottom: 12px;
    }

    .logoLink {
      position: initial;
    }
  }
`;

export const Logo = styled.img`
  display: block;
  width: 81px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 45px 0 10px 45px;
    max-width: 480px;
    width: auto;
  }
`;
