import React, { Fragment } from "react";
import { LandingBannerLeft } from "src/common";
import { ProductLandingBanner } from "src/common/components/ProductLandingBanner";
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../../../common/constants/mediaquerys";
import { theme } from "../../../constants/themes";
import ResponsiveSidebar from "../../common/header/responsiveSidebar/ResponsiveSidebar";
import { MainAreaWrapper } from "../../common/mainAreaWrapper/MainAreaWrapper";
import { MainSidebarLeft } from "../../common/mainSidebarLeft/MainSidebarLeft";

export const SPageLayoutWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: ${theme.maxWidth}px;
    margin: 0;
    width: 100%;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        width: 92%;
        margin: 40px auto;
    }
`;

const PageLandingT7 = () => 
  (
    <div >
      <div>
        <ResponsiveSidebar />
      </div>
      <div>
        <ProductLandingBanner />
      </div>
      <div>
        <LandingBannerLeft />
      </div>
      <SPageLayoutWrapper>
        <div >
          <MainSidebarLeft />
        </div>
        <div css={css`width: 100%;`} >
          <MainAreaWrapper />
        </div>
      </SPageLayoutWrapper>
    </div>
  );

PageLandingT7.displayName = "PageLandingT7";

export { PageLandingT7 };
