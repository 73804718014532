import React, { FC, Fragment } from "react";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";
import { AddressWrapper } from "./Footer.style";


const Address: FC<{ companyInformation: any }> = ({ companyInformation }) => (
  <AddressWrapper>
    <div className="managingDirectorWrapper">
      <h1>{getContactCompanyNameShort()}</h1>
    </div>
    <p>{companyInformation?.street}</p>
    <p>
      {companyInformation?.postcode} {companyInformation?.place}
    </p>
    <div className="addressLinksWrapper">
      Telefon: {companyInformation?.phone}
    </div>
  </AddressWrapper>
);

export default Address;