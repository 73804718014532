import React, { FC } from "react";
import { AffixWrapper } from "src/common/components/Affix/Affix.style";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";
import { css } from "styled-components";

const HEIGHT = "130px";
const LOGO_WIDTH = "320px";

export const BurguerIconWrapper: any = styled.div<{ open?: boolean }>`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  z-index: 999;
  width: 28px;
  height: 28px;

  ${({ open, theme }) => !open
    ? css`
      > span {
        display: block;
        height: 3px;
        width: 26px;
        border-radius: 0;
        background-color: rgba(255, 255, 255, .7);

        :first-child {
          margin-top: 3px;
        }

        &:not(:first-child) {
          margin-top: 5px;
        }
      }
    `
    : css`

    > span {
      position: absolute;
      top: 12px;
      left: 0;
      display: block;
      height: 3px;
      width: 30px;
      border-radius: 0;
      background: rgba(255, 255, 255, .7);

      &:nth-child(1) {
        transform: rotate(45deg)
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        transform: rotate(-45deg)
      }
    }
  `}


  @media (${BREAKPOINTS_DM.gold_1024}){
    display: none;
  }
`;

export const HaderContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1100px;
  flex-direction: column;
  justify-content: center;
  z-index: 999;

  @media (${BREAKPOINTS_DM.silver_768}) {
  }

  ${AffixWrapper} {
    position: absolute;
    width: 2px;
    max-width: 1100px;

    display: flex;
    justify-content: center;

    &.affix {
      position: fixed;
    }


    @media (${BREAKPOINTS_DM.silver_768}) {
      transform: translateX(-20%);
      left: 78%;
    }
  }
`;

export const LogoLink: any = styled.a`
  display: block;
  position: absolute;
  top: 10px;
  padding-top: 20px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    top: 100px;
    left: 75px;
    padding-top: 0;
  }
`;

export const Logo = styled.img`
  display: block;
  width: 250px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-left: unset;
    max-width: ${LOGO_WIDTH};
    width: unset;
  }
`;

export const NavigationHeader: any = styled.div`
  position: relative;
  background-color: black;
  padding: 7.5px 15px;
  color: white;
  text-transform: uppercase;
  line-height: 21px;
`;

export const NavigationLink: any = styled.li<{ active: boolean }>`
  display: flex;
  width: 100%;
  line-height: 21px;
  > a {
    display: flex;
    color: ${({ active, theme }) => active ? "black" : theme.palette.textColor};
    font-weight: lighter;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0 15px;
    width: 100%;

    @media (${BREAKPOINTS_DM.silver_768}) {
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks = styled.div`
  display: block;
  flex-wrap: wrap;
  align-items: center;
  padding: .5em 0;
  transition: transform .8s ease;

  ul {
    list-style: none;
    overflow-y: auto;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;
    display: flex;
    transform: translate3d(0,0,0);
    width: 100%;
    height: unset;

    box-shadow: none;
    ${NavigationLink} {
      display: flex;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      height: unset;
    }
  }
`;

export const NavigationContainer: any = styled.div<{ active: boolean; affix: boolean }>`
  right: 30px;
  overflow: hidden;
  transition: height 1s ease;
  ${({ affix }) => affix
    ? `
      position: fixed;
      top: 0;
    `
    : `
      position: absolute;
      top: 30px;
    `}

  width: 150px;
  background-color: rgba(248,248,248,.9);

  ${({ active }) => active
    ? `

    `
    : `
      height: 36px;
    `}

  @media (${BREAKPOINTS_DM.silver_768}) {
    right: unset;
    z-index: 9999;
    ${BurguerIconWrapper} {
      display: none;
    }

    ${({ affix, active }) => affix && !active
    ? `
      height: 36px;
    `
    : `
      height: auto;
    `}
  }
`;

export const SubNavigationLinks: any = styled.div<{ active: boolean }>`

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }

  > a {
    color: ${({ theme }) => theme.palette.white };
    display: flex;
    font-weight: 700;
    font-size: 15px;
    padding-left: 10.5px;
    background-color: ${({ active, theme }) => active ? theme.palette.primary : theme.palette.bgInverted};;
    line-height: 31px;
    width: 100%;
    margin-bottom: 2px;
  }
`;
