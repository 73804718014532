import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css`
padding-bottom: 48px; 

    > div {
        gap: 24px;

        > div {
            gap: 24px;
        }
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
padding-bottom: 76px;

    > div {
        gap: 38px;

        > div {
            gap: 38px;
        }
    }
`;

const Diamond = css``;

export const SMasonryPhotoGallery = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
