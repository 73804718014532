import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context } from "src/common";

import {  Section } from "../Grid";
import Address from "./Address";
import { FooterContainer, FooterWrapper } from "./Footer.style";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";

interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => {
  const props = useContext(Context);
  const companyInformation = props.CompanyInformationPublic;
  const footerNavItems = props.Footer?.Navigationspunkte || [];

  return (
    <FooterContainer>
      <Section>
        <FooterWrapper>
          <Address companyInformation={companyInformation} items={footerNavItems} />
          <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "8px",
            fontSize: "12px",
          }}
        >
          <p>
            <CopyrightNotice />
          </p>
        </div>
        </FooterWrapper>
       
      </Section>
     
    </FooterContainer>
  );
};

export default Footer;
