import React, { FunctionComponent, useState, useContext } from "react";
import { INavigationItem, Context, IContext, IAsset } from "src/common";

import {
  NavigationContainer,
  NavigationLinks,
  NavigationLink,
  NavigationIcon,
  SubItemWrapper,
  SubItem,
  ToogleMenu,
} from "./Navigation.style";

interface IHeaderItemProps
{
  title: string;
  link: string;
  headline?: string;
  external?: boolean;
  icon?: IAsset;
}

const getHeaderNavItemProps = (item: Record<string, any>): IHeaderItemProps =>
{

  const linkItem = item?.link?.[0];
  const icon = item?.icon;

  if((linkItem as any)?.__typename === "ExternalLink")
  {
    return {
      link: `${(linkItem as any).externalLinkAddress}`,
      title: `${(item as any).label ?? (linkItem as any).externalLinkName}`,
      external: true,
    };
  }

  const link = linkItem?.slug || (item as any)?.slug;
  const title = (linkItem as any)?.nameInNavigation || (item as any)?.nameInNavigation || (item as any)?.label;
  let headline;

  if(!link || !title)
  {
    return;
  }

  if(linkItem?.__typename === "PageMicro")
  {
    return {
      link,
      title,
    };
  }

  if(linkItem?.__typename === "Page" || linkItem?.__typename === "PageNews" || linkItem?.__typename === "PageGuestbook")
  {
    headline = linkItem.elements?.find(e => e?.__typename === "TextElement")?.[0]?.headline;
  }

  return {
    link,
    title,
    headline,
    icon,
  };
};

const Items: FunctionComponent<{item: Record<string, any>; itemProps: Record<string, any> }> = ({ itemProps, item }) =>
{
  const props = useContext<IContext>(Context);
  const currentItem = props?.PrimaryNavigation?.items?.find(primary => primary?.subitems?.find(secondary => secondary?.slug === props?.slug));
  const { subitems } = item;
  const isShowSubItems = item?.link[0]?.slug === props?.slug || subitems?.find(sub => sub?.slug === props?.slug);
  return (
    <>
      <NavigationLink isActive={itemProps.link === props.slug || itemProps.link === currentItem?.link[0].slug}>
        <a href={itemProps.link}>
          {itemProps.icon && (
            <NavigationIcon src={itemProps.icon[0]?.src} />
          )}
          {itemProps.title}
        </a>
      </NavigationLink>

      {subitems?.length > 0 &&
      <SubItemWrapper>
        {isShowSubItems && subitems?.map((subItem, i) =>
          <SubItem key={i}>
            <a href={`/${subItem?.slug}`}>{subItem?.nameInNavigation}</a>
          </SubItem>
        )}
      </SubItemWrapper>}
    </>
  );
};

const Navigation: FunctionComponent<{ items: INavigationItem[]; slug: string; onToogleDeployed: any; isDeployed: boolean }> = ({
  items, slug, isDeployed, onToogleDeployed
}) =>
{
  const [isMenuActive, toggleMenuActive] = useState(false);

  return (
    <NavigationContainer active={isMenuActive} isDeployed={isDeployed}>
      <NavigationLinks isDeployed={isDeployed}>
        {isDeployed &&
          <ul>
            {!!slug && (
              <NavigationLink>
                <a href="/">
                  <NavigationIcon src="/images/icon-kontakt.svg"/>
                  <span>Home</span>
                </a>
              </NavigationLink>
            )}

            {items?.map((item, index) =>
            {
              const itemProps = getHeaderNavItemProps(item);
              if(!itemProps) { return; }

              return (
                <Items itemProps={itemProps} item={item} key={`item-key-${index}`}/>
              );
            })}
            <ToogleMenu onClick={() => onToogleDeployed(!isDeployed)} className="toogle_menu">
              <div className="icon_mobile"><i className="fa fa-chevron-up"/></div>
              <div className="icon"><i className="fa fa-ban"/></div>
              <span>Menü ausblenden</span>
            </ToogleMenu>
          </ul>
        }
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
