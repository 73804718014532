import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Context, IContext } from "src/common";

import Banner from "../Banner";
import {
  HaderContainer,
  Logo,
  HeaderContent,
  LogoContainer,
} from "./Header.styles";
import Navigation from "./Navigation/Navigation";

const Header: FunctionComponent<{
  onToggleMenuActive: any;
  isMenuActive: boolean;
}> = ({ onToggleMenuActive, isMenuActive }) => {
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  const [isScrolling, toogleScrolling] = useState(false);

  useEffect(() => {
    window.onscroll = () => handleAnimation();
  });

  const handleAnimation: any = () => {
    if (document.documentElement.scrollTop < 180) {
      toogleScrolling(false);
    }
    if (document.documentElement.scrollTop > 180) {
      toogleScrolling(true);
    }
  };
  return (
    <HaderContainer>
      <Banner isScrolling={isScrolling} />
      <HeaderContent>
        {logo && (
          <LogoContainer>
            <a href="/">
              <Logo src={logo.src} />
            </a>
          </LogoContainer>
        )}
        <Navigation
          items={props.PrimaryNavigation?.items}
          slug={props.slug}
          onToggleMenuActive={onToggleMenuActive}
          isMenuActive={isMenuActive}
        />
      </HeaderContent>
    </HaderContainer>
  );
};

export default Header;
