import React, { FC, Fragment } from "react";

import { AddressWrapper } from "./Footer.style";

const LinksSeparator: FC = () => (
  <span style={{ width: "18px", height: "18px" }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
    </svg>
  </span>
);

const Address: FC<{ items: any[] }> = ({ items }) => (
  <AddressWrapper>
    <LinksSeparator />
    &nbsp;
    {items &&
      items.map((item, i) => {
        const slug = item?.slug;
        const nameInNavigation = item?.nameInNavigation;

        if (!slug || !nameInNavigation) {
          return null;
        }

        return (
          <Fragment key={i}>
            <a href={slug}>{nameInNavigation}</a>
            {i !== items.length - 1 && (
              <>
                &nbsp;
                <LinksSeparator />
                &nbsp;
              </>
            )}
          </Fragment>
        );
      })}
  </AddressWrapper>
);

export default Address;
