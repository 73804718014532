import { css } from "styled-components";

import colors from "../../../constants/colors";

export const wrapper: any = css`
    display: flex;
    justify-content: space-between;
    background-color: ${colors.gray};
    margin: 0 !important;
    width: calc(100% - 13.5px);
`;


export const links: any = css`
    color: white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      margin: 0 5px;
    }
    a {
      color: inherit;
    }
`;


export const shareOnSocialMediaWrapper: any = css`
    display: flex;
    a {
        align-items: center;
        margin-right: 16px;
        svg {
            height: 100%;
            fill: white;
        }
    }
`;