const getTeaser = (obj, text) => {
  const keyExists = (obj) => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return;
    }
    else if (obj.hasOwnProperty('text')) {
      if (`${obj['text']}`.trim() != "" && `${text}`.trim() == ""){
        return text = text +  `${obj['text']}`;
      }
    }
    else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        keyExists(obj[i])
      }
    }
    else {
      for (const k in obj) {
        keyExists(obj[k]);
      }
    }
  };

  keyExists(obj);
  return text;
}

export const richToTeaser = (x, limit=0) => {
  let teaser = ""

  teaser = getTeaser( x, teaser)

  if (limit > 0 && teaser.length > limit) {
    teaser = teaser.substring(0, limit) + "..."
  }
  
  return teaser;
}