import styled, { css } from "styled-components";
import { centeredContent } from "../../../../../common/constants/layout";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";
import { theme } from "../../../constants/themes";

const height = `${theme.headerHeight}px`;

export const Wrapper: any = styled.div<{
  borderColor: string;
  background: string;
  logoCenter?: boolean;
  logoRight?: boolean;
}>`
  height: ${height};
  background-color: ${({ background }) => (background ? background : "white")};
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid 1px
    ${({ borderColor }) => (borderColor ? borderColor : colors.brown)};
  z-index: 10;
`;

export const BurgerRightDesktop: any = css`
  :not(.fake){
    margin: 0;
    max-width: calc(100vw);
    margin-left: calc((100vw - 1050px ) / 2);
  }
`;

export const ContentInner: any = styled.div<{
  logoCenter?: boolean;
  logoRight?: boolean;
  mobileSwapBurger?: boolean;
  burgerMenuOnDesktop?: boolean;
}>`
  ${centeredContent};
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
  justify-content: ${({mobileSwapBurger}) => mobileSwapBurger ? 'space-between' : 'flex-end' };
  @media (${BREAKPOINTS_DM.gold_1024}) {
    justify-content: ${({logoRight}) => logoRight ? 'space-between' : 'flex-end' };
    ${({burgerMenuOnDesktop, logoRight}) => (burgerMenuOnDesktop && !logoRight)  ? BurgerRightDesktop : '' };
  }
  width: 100%;
  position: relative;
  align-items: center;
  height: 100%;
`;

export const nav: any = css`
  display: none;
  margin-top: 10px;
  height: calc(100% - 10px);

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: block;
  }
`;

export const placeholder: any = css`
  height: ${height};
`;
