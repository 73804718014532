import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    padding-bottom: 48px;
    width: 100%;
`;

const Silver = css`
    /* the iframe should fit full size into the screen which will not work if the screen has a wider aspect ratio then the 16:9 sometimes */
    margin: 0 auto;
    /* 82px nav size and 72px padding left and right + 40px buffer */
    max-width: calc(((100vh - 82px) * 1.7777777777777778) - 72px - 72px - 40px);
`;

const Gold = css``;

const Platinum = css`
    padding-bottom: 76px;
`;

const Diamond = css``;

export const SImage = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
