import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    span {
        display: block;
    }
    a {
        cursor: pointer;
    }
    svg {
        width: 14px;
        height: 14px;
    }
    .cpg-price {
        padding: 10px;
        color: ${(props) => props.theme.palette.productPrime};
        background: ${(props) => props.theme.palette.productBgLight100};
    }
    .cpg-price-text {
        height: 21px;
        width: 100%;
    }
    .cpg-price-number {
        width: 50%;
        display: inline-block;
        font-weight: 700;
        text-align: left;
    }
    .cpg-price-next {
        width: 50%;
        display: inline-block;
        text-align: right;
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SPhotoGallery = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SPhotoGallery;
