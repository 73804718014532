// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const FooterWrapper = styled.footer`
  max-width: 980px;
  width: 100%;
  padding: 20px 20px 32px 20px;
  margin: auto auto 30px;
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 20px 25px 32px 25px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 20px 0 32px 0;
  }
`;

const LinkStyle = css`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    line-height: 21px;
    text-transform: uppercase;
    padding: 0 4px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const wrapper: any = css`
  max-width: 980px;
  width: 100%;
  margin: 10px auto 130px;
  padding: 0 10px;
`;

export const LinksWrapper: any = styled.div`
  column-count: 4;
  column-gap: 20px;
  margin-bottom: 30px;
  display: none;

  p {
    margin-bottom: 3px;
  }
  a {
    font-size: 13px;

    color: #efefef;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-bottom: 10.5px;
    padding: 5.25px 10.5px;
    display: block;
  }
`;

export const Link: any = styled.a<{ isBold: boolean }>`
  display: block;
  color: #505050;
  line-height: 1.2rem;
  ${({ isBold }) => isBold && "font-weight: bold;"}
`;

export const AddressWrapper: any = styled.div`
  ${LinkStyle}
`;

export const ShareOnSocialMediaWrapper: any = styled.div`
  ${LinkStyle}

  svg {
    width: 18px;
    height: 18px;
    fill: white;
  }
`;
