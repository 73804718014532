import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

import { css } from "styled-components";

import {
    A_1366_Link,
    A_360_Link,
    A_768_Link,
    C_1366_Bold,
    C_1366_Regular,
    C_360_Bold,
    C_360_Regular,
    C_768_Bold,
    C_768_Regular,
    H1_1366_Regular,
    H1_360_Regular,
    H1_768_Regular,
    H2_1366_Regular,
    H2_360_Regular,
    H2_768_Regular,
    H3_1366,
    H3_360,
    H3_768,
    H4_1366,
    H4_360,
    H4_768,
    H5_1366,
    H5_360,
    H5_768,
    H6_1366,
    H6_360,
    H6_768,
    OlItem_1366,
    OlItem_360,
    OlItem_768,
    P_1366_Regular,
    P_360_Regular,
    P_768_Regular,
    UlItem_1366,
    UlItem_360,
    UlItem_768,
} from "./typography";

const TextStyles = css`
    &,
    a {
        color: var(--front-2);
    }

    b,
    strong {
        &,
        > * {
            font-weight: 700 !important;
        }
    }

    em {
        &,
        > * {
            font-style: italic;
        }
    }

    br {
        display: block;
        content: "";
        line-height: 0 !important;
        height: 16px;
    }

    > *:not(:last-child) {
        margin-bottom: 16px;
    }

    h1 {
        ${H1_360_Regular}
        color: var(--front-2);
    }

    h2 {
        ${H2_360_Regular}
    }

    h3 {
        ${H3_360}
    }

    h4 {
        ${H4_360}
    }

    h5 {
        ${H5_360}
    }

    h6 {
        ${H6_360}
    }

    p {
        ${P_360_Regular}
    }

    a {
        ${A_360_Link}
    }

    ul,
    ol {
        transform: translateX(20px);

        > *:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    ul {
        li {
            list-style-type: disc;

            &,
            > * {
                ${UlItem_360}
            }
        }
    }

    ol {
        li {
            list-style-type: decimal;

            &,
            > * {
                ${OlItem_360}
            }
        }
    }

    table {
        vertical-align: middle;

        tr {
            td,
            th {
                padding: 8px 10px;
            }

            td {
                :first-child {
                    padding-left: 0;
                }
                color: var(--front-2);
                > * {
                    ${C_360_Regular}
                }
            }

            th {
                background-color: var(--front-3);
                color: var(--back-1);
                > * {
                    ${C_360_Bold}
                }
            }
        }
    }

    // Sliver breakpoint
    @media (${BREAKPOINTS_DM.silver_768}) {
        h1 {
            ${H1_768_Regular}
        }

        h2 {
            ${H2_768_Regular}
        }

        h3 {
            ${H3_768}
        }

        h4 {
            ${H4_768}
        }

        h5 {
            ${H5_768}
        }

        h6 {
            ${H6_768}
        }

        p {
            ${P_768_Regular}
        }

        a {
            ${A_768_Link}
        }

        ul {
            li {
                &,
                > * {
                    ${UlItem_768}
                }
            }
        }

        ol {
            li {
                &,
                > * {
                    ${OlItem_768}
                }
            }
        }

        table {
            tr {
                td {
                    > * {
                        ${C_768_Regular}
                    }
                }

                th {
                    > * {
                        ${C_768_Bold}
                    }
                }
            }
        }
    }

    // Platinum breakpoint
    @media (${BREAKPOINTS_DM.platinum_1366}) {
        > *:not(:last-child) {
            margin-bottom: 24px;
        }

        h1 {
            ${H1_1366_Regular}
        }

        h2 {
            ${H2_1366_Regular}
        }

        h3 {
            ${H3_1366}
        }

        h4 {
            ${H4_1366}
        }

        h5 {
            ${H5_1366}
        }

        h6 {
            ${H6_1366}
        }

        p {
            ${P_1366_Regular}
        }

        a {
            ${A_1366_Link}
        }

        ul {
            li {
                &,
                > * {
                    ${UlItem_1366}
                }
            }
        }

        ol {
            li {
                &,
                > * {
                    ${OlItem_1366}
                }
            }
        }

        table {
            tr {
                td {
                    > * {
                        ${C_1366_Regular}
                    }
                }

                th {
                    > * {
                        ${C_1366_Bold}
                    }
                }
            }
        }
    }
`;

export default TextStyles;
