import styled, { css } from "styled-components";


const marginLeft = 25;
const dropdownPadding = 16;

export const wrapper: any = css`
  display: inline-block;
  position: relative;
  margin-left: ${marginLeft}px;
  margin-top: 20px;
  height: calc(100% - 20px);
  &:hover {
    > a {
      opacity: .7;
    }
    > div {
      opacity: 1;
      pointer-events: auto;
    }
  }
  > a {
    display: block;
    font-size: 14px;
    cursor: pointer;
    height: 100%;
  }
`;

export const sidebar_wrapper: any = css`
    > a {
      border-bottom: solid rgba(0,0,0,0.6) 1px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 0 10px 17px;
      outline: none !important;
    }
`;

export const sidebarExpandItemArrow: any = css<any>`
    width: 35px;
    padding: 2px 11px;
    transform: none;
    transition: transform ease .1s;
    &:hover {
      opacity: .5;
    }
    ${({ color }) => color ? `fill: ${color ? color : "#222"};` : ''}
`;

export const dropdownWrapper: any = css`
    position: absolute;
    padding: 20px ${dropdownPadding}px 10px;
    left: ${-dropdownPadding}px;
    top: 20px;
    opacity: 0;
    width: 200px;
    overflow: hidden;
    pointer-events: none;
    transition: opacity ease .3s;
`;

export const sidebar_SubItemsWrapper: any = css`
  display: none;
`;

export const sidebar_SubItemsWrapperExpanded: any = css`
    display: block;
`;

export const dropDrownItem: any = css`
    display: block;
    padding: 6px 0 7px;
    
    font-size: 14px;
    opacity: .6;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &:last-child {
      border-bottom: none;
    }
`;

export const sidebar_SubItem: any = css`
    display: block;
    padding: 10px 0 12px 36px;
    &:last-child {
      margin-bottom: 5px;
    }
`;

export const SubItem: any = styled.a<{isInSidebar: boolean; color: string}>`
    ${({ isInSidebar, color }) => isInSidebar ? `${sidebar_SubItem}` : `border-bottom: solid ${color ? color : "#222"} 1px; ${dropDrownItem}`}
    color: ${({ color }) => color ? color : "#222"};
`;
