import React, { FunctionComponent } from "react";
import { SProductItemT27 } from "./styles/SProductItemT27";
import { IGenAsset, IGenContinuingPageGalleryItem } from "src/common/types_dealer_gen";

import { SProductItemT27ImageWrapper } from "./styles/SProductItemT27ImageWrapper";
import { FancyImage } from "src/common/BaseComponents/FancyImage/FancyImage";
import { IProduct } from "src/common";
import { SProductItemT27Card } from "./styles/SProductItemT27Card";
import Link from "next/link";
import ArrowLinkIcon from "src/common/icons/ArrowLinkIcon";

const ProductItemT27: FunctionComponent<{ item: IGenContinuingPageGalleryItem; itemsPerRow: number }> = ({
    item,
    itemsPerRow,
}) => {
    const product = item?.link?.[0] as IProduct;
    const image = item?.thumbnail?.[0] as IGenAsset;

    if (!item || !product) return null;

    return (
        <SProductItemT27>
            {image?.src && (
                <SProductItemT27ImageWrapper>
                    <FancyImage
                        img={image}
                        responsiveAspectRatio={{ bronze: 312 / 234 }}
                        responsiveFactor={{ bronze: 1, silver: 1 / itemsPerRow }}
                        responsiveMaxWidth={{ platinum: 1366 / itemsPerRow }}
                    />
                </SProductItemT27ImageWrapper>
            )}
            {(item.title || product.title) && (
                <SProductItemT27Card className="title">
                    <h2>{item.title || product.title}</h2>
                </SProductItemT27Card>
            )}
            {product.ourPrice && (
                <SProductItemT27Card className="price">
                    <p>Jetzt für nur:&nbsp;{product.ourPrice}&nbsp;€</p>
                    <Link href={`produkt/${product.slug}`} passHref>
                        <a>
                            zum Angebot&nbsp;
                            <ArrowLinkIcon />
                        </a>
                    </Link>
                </SProductItemT27Card>
            )}
        </SProductItemT27>
    );
};

export default ProductItemT27;
