import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface Link {
    isVisible: boolean;
}

const Default = css`
    font-family: "ahb-icons";
    display: ${(props: Link) => (props?.isVisible ? "inline" : "none")};
    color: ${props => props?.theme.palette.white};
    font-size: 28px;
    line-height: 1;
    &:not(:last-of-type) {
        padding-bottom: 7px;
        margin-bottom: -3px;
    }
    /* margin-right: 10px; */
    &.phone:before {
        content: "T";
    }
    &.at:before {
        content: "@";
    }
    &.way:before {
        content: "A";
    }
    svg{
        color: ${props => props?.theme.palette.white};
    }
`;

const Silver = css``;

const Gold = css`
    /* margin: 10px 0 0 0; */
    &:first-child {
        margin-top: 0;
    }
`;

const Platinum = css``;

const Diamond = css``;

const SLink = styled.a`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SLink;
