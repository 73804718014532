import styled from "styled-components";

const SItemDefault = styled.div`
  &:not(.isProduct) {
    .cpg-item-head {
      h2 {
        display: inline;
      }
    }
    .cpg-item-head-arrow {
      margin-left: 5px;
      opacity: 0;
      display: inline;
      svg {
        display: inline;
      }
    }
    &:hover {
      .cpg-item-head-arrow {
        opacity: 1;
      }
    }
  }

  &.isProduct {
    .cpg-item-head-arrow {
      display: none;
      svg {
        display: none;
      }
    }
    div.imageWrapper {
      height: 400px;
      background: white;

      img {
        object-fit: contain !important;
      }
    }
  }
`;

export default SItemDefault;
