/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useContext, useState } from "react";
import {
  Context,
  getCurrentNavItem, IPage
} from "src/common";
import { css } from "styled-components";

import colors from "../../../../constants/colors";
import * as styles from "./NavItem.style";
export enum eNavItemType {
  header,
  sidebar
}

interface IProps {
  link: string;
  headline?: string;
  title: string;
  subItems?: IPage[];
  type: eNavItemType;
  active?: boolean;
  external?: boolean;
}

interface SProps {
  isInSidebar?: boolean;
  slug?: string;
  subItem?: {
    nameInNavigation?: string;
    __typename?: string;
  };
  currentSlug?: string;
}

const SubItem: FunctionComponent<SProps> = ({
  isInSidebar, slug, subItem, currentSlug
}) =>
(
  <a
    className={"test"}
    css={isInSidebar ? styles.sidebar_SubItem : currentSlug === slug ? styles.dropDrownItemSelected : styles.dropDrownItem}
    href={"/" + slug}
  >
    {subItem?.nameInNavigation || subItem?.__typename}
  </a>
);

const NavItem: FunctionComponent<IProps> = props => {
  const isInSidebar = props?.type === eNavItemType.sidebar;
  const p = useContext(Context);
  const { link } = props;
  const currentMainNavItem = getCurrentNavItem(p?.PrimaryNavigation?.items, link);
  const currentMainNavSubItem = currentMainNavItem?.subitems?.find(i => i?.slug === p.slug);
  const active = p.slug === link || props.active || currentMainNavSubItem;
  const [isExpanded, setIsExpanded] = useState(false);
  const expandSubItems = (e): void => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };
  
  return (
    <div css={isInSidebar ? styles.sidebar_wrapper : styles.wrapper}>
      <a
        target={(props.external ? "_blank" : "")}
        href={(props.external ? "" : "/") + props?.link}
        css={
          active
            ? css`color: white;`
            : css`color: ${colors.green};`
        }
        title={props?.headline}

      >
        {props?.title}
        <div onClick={expandSubItems} css={styles.sidebarExpandItemArrowContainer} >
          {props?.subItems && props?.subItems.length > 0 && <i className={"fas fa-caret-down"} css={styles.sidebarExpandItemArrow}></i>}
        </div>
      </a>
      {props?.subItems && props?.subItems?.[0]?.slug && (
        <div
          css={
            isInSidebar
              ? isExpanded
                ? styles.sidebar_SubItemsWrapperExpanded
                : styles.sidebar_SubItemsWrapper
              : styles.dropdownWrapper
          }
        >
          {props?.subItems.map((subItem, i) => {
            if (!subItem?.slug) {
              return null;
            }

            return (
              <div key={i}>
                <SubItem
                  isInSidebar={isInSidebar}
                  slug={subItem?.slug}
                  subItem={subItem}
                  currentSlug={p.slug}
                />
              </div>

            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavItem;
