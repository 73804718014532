import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    padding-bottom: 48px;

    > [data-mobile-wrapper] {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
`;

const Silver = css`
    > [data-mobile-wrapper] {
        display: none;
    }

    > [data-desktop-wrapper] {
        display: flex;
        flex-direction: column;
    }
`;

const Gold = css``;

const Platinum = css`
    padding-bottom: 76px;
`;

const Diamond = css``;

export const SOffsetGallery = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
