import React, { FC, useEffect, useRef, useState } from "react";
import { IGenAsset } from "src/common/types_dealer_gen";
import { FancyImage } from "src/common/BaseComponents/FancyImage/FancyImage";
import { createPortal } from "react-dom";
import Slider, { Settings } from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SSlickBase from "src/common/BaseComponents/SliderWithFocalPoint/styles/SSlickBase";
import SSlickTheme from "src/common/BaseComponents/SliderWithFocalPoint/styles/SSlickTheme";
import { SFullScreenSliderWithFancyImage } from "./styles/SFullScreenSliderWithFancyImage";
import { SModalOverlay } from "./styles/SModalOverlay";
import { SModalOverlayInner } from "./styles/SModalOverlayInner";
import { CloseIcon } from "src/common/icons/CloseIcon";
import { IResponseOptionalValue } from "../../constants/types";

const Modal: FC = (props) => {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let el;

        if (typeof window !== "undefined") {
            el = document.createElement("div");
            const rootContainer = document.querySelector("#__next");
            rootContainer.appendChild(el);
            container.current = el;
        }

        return () => el?.remove();
    }, []);

    return container.current ? createPortal(props.children, container.current) : null;
};

interface IThumbnailSliderProps {
    elements: IGenAsset[];
    img: IGenAsset;
    responsiveFactor?: IResponseOptionalValue<number>;
    responsiveAspectRatio?: IResponseOptionalValue<number>;
    responsiveMaxWidth?: IResponseOptionalValue<number>;
    lazyload?: boolean;
}

const FullScreenSliderWithFancyImage: FC<IThumbnailSliderProps> = ({ img, elements, responsiveMaxWidth, responsiveFactor, responsiveAspectRatio }) => {
    const [isActive, setIsActive] = useState(false);

    if (!elements?.length) {
        return null;
    }

    const initialSlide = elements.indexOf(img);

    const STANDARD_SETTINGS: Settings = {
        dots: false,
        infinite: true,
        arrows: true,
        fade: true,
        waitForAnimate: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        initialSlide,
        nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
        prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
        adaptiveHeight: true,
    };

    return (
        <>
            <div style={{ cursor: "pointer" }}>
                <FancyImage
                    img={img}
                    responsiveMaxWidth={responsiveMaxWidth}
                    responsiveAspectRatio={responsiveAspectRatio}
                    responsiveFactor={responsiveFactor}
                    onClick={() => setIsActive(true)}
                />
            </div>
            <Modal>
                <SModalOverlay isActive={isActive} onClick={() => setIsActive(false)}>
                    <SModalOverlayInner style={isActive ? { display: "flex" } : { display: "none" }}>
                        <span className="close-icon">
                            <CloseIcon size={20}/>
                        </span>
                        {isActive && (
                            <SFullScreenSliderWithFancyImage className="thumbnail-slider">
                                <div
                                    css={`
                                        ${SSlickBase}
                                        ${SSlickTheme}
                                    `}
                                    className="slider"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Slider {...STANDARD_SETTINGS}>
                                        {elements?.map(
                                            (element, i) =>
                                                element.src && (
                                                    <div key={`image-${i}`} className={`slider__image-wrapper`}>
                                                        <FancyImage img={element} />
                                                        <p className="image-wrapper__description">
                                                            {element.description ? element.description : ""}
                                                        </p>
                                                    </div>
                                                )
                                        )}
                                    </Slider>
                                </div>
                            </SFullScreenSliderWithFancyImage>
                        )}
                    </SModalOverlayInner>
                </SModalOverlay>
            </Modal>
        </>
    );
};

export default FullScreenSliderWithFancyImage;
