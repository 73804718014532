import * as React from "react";
import { SScrollIndicator } from "./Styles/SScrollIndicator";
import { SScrollIndicatorCircle } from "./Styles/SScrollIndicatorCircle";
import { SScrollIndicatorAngle } from "./Styles/SScrollIndicatorAngle";

const ScrollIndicator = props => {
    return (
        <SScrollIndicator {...props} className="scroll-indicator" onClick={() => window.scrollTo({ top: 300, behavior: "smooth" })}>
            <SScrollIndicatorCircle>
                <SScrollIndicatorAngle> </SScrollIndicatorAngle>
            </SScrollIndicatorCircle>
        </SScrollIndicator>
    );
};

export default ScrollIndicator;
