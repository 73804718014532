import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

const gray = "#d3d3d3ff";

export const wrapper: any = css`
    position: fixed;
    bottom: 0px;
    transform: translateY(100%);
    overflow-x: hidden;
    background-color: white;
    z-index: 216;
    width: 100%;
    height: 100vh;
    box-shadow: 0 0 40px white;

    transition: all 1s;

    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: 650px;
        right: 8vh;
        max-width: 90vw;
        width: 500px;
    }
`;

export const topBar: any = css`
    color: white;
    padding: 15px 38px 18px 13px;
    cursor: pointer;
    height: 90px;
    background-color: ${colors.gray};
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
    }
    img {
      margin-left: 10px;
      width: 42px;
      height: auto;
      margin-top: 5px;
    }
    @media (min-width: 500px)
    {
        h3 {
          font-size: 18px;
        }
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        img {
          width: 50px;
          margin-left: 15px;
        }
        h3 {
          font-size: 20px;
        }
    }
`;

export const closeButton: any = css`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 11;
    cursor: pointer;
    fill: white;
    width: 15px;
    &:hover {
      opacity: .6;
    }
`;

export const contentArea: any = css`
    border: solid 1px ${gray};
    overflow-y: auto;
    max-height: 80vh;
    padding: 13px;
    border-bottom: none;
    .content-header {
      padding-bottom: 20px;
      margin-bottom: 13px;
      border-bottom: solid ${gray} 1px;
    }
`;

export const formRow: any = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    > div {
      width: 49%;
    }
    label {
      display: block;
      font-weight: 600;
      cursor: pointer;
    }
    input, select, textarea {
      width: 100%;
      height: 24px;
      padding: 3px 2px;
      display: block;
      border: solid ${gray} 1px;
      &::placeholder {
        font-size: 13px;
      }
    }
    textarea {
      height: 100px;
      resize: vertical;
    }
`;

export const fcWrapper = css`
    .frc {
        &-captcha {
            overflow: hidden;
            display: none;
            margin-bottom: 20px;
            margin-top: 0;
            padding: 5px 10px;
            width: 100%;
            background-color: #ffffff;

            &[data-attached] {
                display: block !important;
            }
        }

        &-container {
            display: flex;
            align-items: center;
        }

        &-icon {
            color: #505050;
            fill: #505050;
            stroke: #505050;
            margin: 0;
            margin-right: 10px;
        }

        &-content {
            button {
                color: #505050;
                border-color: #505050;
            }
        }

        &-banner {
            &,
            a {
                &,
                b {
                    color: #505050;
                }
            }
        }
    }
`;

export const submitWrapper: any = css`
    display: flex;
    button {
      margin-right: 30px;
      padding: 4px 6px;
      background-color: black;
      color: white;
    }
    label, a {
      cursor: pointer;
      font-size: 12px;
    }
    input {
      margin-right: 3px;
    }
    a {
      color: ${colors.brown};
    }
`;
