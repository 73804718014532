import { FC, MouseEventHandler, ReactEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { IGenAsset } from "../../types_dealer_gen";
import { BREAKPOINTS } from "../../constants/mediaquerys";
import { SFancyImageSkeleton } from "./styles/SFancyImageSkeleton";
import SFancyImageAspectContainer from "./styles/SFancyImageAspectContainer";
import { IResponseOptionalValue } from "../../constants/types";
import { calculateResponsiveImagesSizes } from "../../utils/calculateResponsiveImagesSizes";
import { SFancyImageImg } from "./styles/SFancyImageImg";
import { blurhashToCssGradient } from "blurhash-to-css-gradient";

export interface IFancyImage {
    img: IGenAsset;
    responsiveFactor?: IResponseOptionalValue<number>;
    responsiveAspectRatio?: IResponseOptionalValue<number>;
    responsiveMaxWidth?: IResponseOptionalValue<number>;
    lazyload?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onLoad?: ReactEventHandler<HTMLImageElement>;
}

/**
This image component support focal point, lazyload and responsive images.
@img: the image object
@responsiveFactor: The responsiveFactor is the amount of space used on the screen in percent - default is 1 for full width image
@responsiveAspectRatio: overwrite the aspect ratio for each breakpoint (width/height)  - default is to use the aspect ratio of the image
@responsiveMaxWidth: The responsiveMaxWidth is the max width of the image in px - default the is no limit and will scale to max of current breakpoint
@lazyload: lazyload the image or not - default is true
*/
export const FancyImage: FC<IFancyImage> = ({
    img,
    responsiveFactor,
    responsiveAspectRatio,
    responsiveMaxWidth,
    lazyload = true,
    onClick,
    onLoad,
}) => {
    const loading = lazyload ? "lazy" : "eager";
    const [isImgLoaded, setIsImgLoaded] = useState(false);

    const imageRef = useRef<HTMLImageElement>(null);

    const handleOnLoad = useCallback(() => {
        setIsImgLoaded(true);
    }, [setIsImgLoaded]);

    useEffect(() => {
        if (!imageRef.current) {
            return;
        }

        if (imageRef.current.complete) {
            setIsImgLoaded(true);
            return;
        }
        imageRef.current.onload = handleOnLoad;
    }, [imageRef.current, handleOnLoad]);

    if (!img) {
        return null;
    }

    const sizes = calculateResponsiveImagesSizes({
        responsiveFactor,
        responsiveMaxWidth,
        responsiveAspectRatio,
        images: [img],
    });

    const actualSrc: string = img.src;
    let _src = actualSrc;

    const hasSVG = _src.includes(".svg");

    if (!hasSVG) {
        _src = _src.includes("?") ? _src + "&" : _src + "?";
    }

    const srcSet = `${_src + `width=${sizes.bronze.width}&height=${sizes.bronze.height}`} ${BREAKPOINTS.SILVER - 1}w,
    ${_src + `width=${sizes.silver.width}&height=${sizes.silver.height}`} ${BREAKPOINTS.GOLD - 1}w,
    ${_src + `width=${sizes.gold.width}&height=${sizes.gold.height}`} ${BREAKPOINTS.PLATINUM - 1}w,
    ${_src + `width=${sizes.platinum.width}&height=${sizes.platinum.height}`} ${BREAKPOINTS.DIAMOND - 1}w,
    ${_src + `width=${sizes.diamond.width}&height=${sizes.diamond.height}`} ${BREAKPOINTS.MASTER - 1}w,
    ${_src + `width=${sizes.master.width}&height=${sizes.master.height}`} ${BREAKPOINTS.CHALLENGER - 1}w`;

    let placeholderBackground = "#e3e3e3e3";

    const { dominantColor, blurHash } = img;

    if (blurHash) {
        try {
            placeholderBackground = blurhashToCssGradient(blurHash);
        } catch {}
    } else {
        if (dominantColor) {
            if (dominantColor.startsWith("#")) {
                placeholderBackground = dominantColor;
            } else {
                placeholderBackground = `#${dominantColor}`;
            }
        }
    }

    return (
        <SFancyImageAspectContainer
            brozeAspectRatio={sizes.bronze.width / sizes.bronze.height}
            silverAspectRatio={sizes.silver.width / sizes.silver.height}
            goldAspectRatio={sizes.gold.width / sizes.gold.height}
            platinumAspectRatio={sizes.platinum.width / sizes.platinum.height}
            diamondAspectRatio={sizes.diamond.width / sizes.diamond.height}
            masterAspectRatio={sizes.master.width / sizes.master.height}
            onClick={onClick}
            hasLoaded={isImgLoaded}
        >
            <div className="absolute-layer layer-z1">
                <SFancyImageSkeleton background={placeholderBackground} />
            </div>
            <div className="absolute-layer layer-z2">
                <SFancyImageImg
                    ref={imageRef}
                    src={hasSVG ? img.src : `${_src}width=${sizes.bronze.width}&height=${sizes.bronze.height}`}
                    isLoaded={isImgLoaded}
                    srcSet={!hasSVG ? srcSet : ""}
                    sizes="100vw"
                    title={img.description || undefined}
                    alt={img.description || undefined}
                    loading={loading}
                    onLoad={(e) => {
                        handleOnLoad();
                        typeof onLoad === "function" && onLoad(e);
                    }}
                />
            </div>
        </SFancyImageAspectContainer>
    );
};
