import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { Img } from "../..";
import { SLogoBannerHorizontal, SShadowOverlay } from "./LogoBannerHorizontal.style";
import { SImgWrapper } from "./LogoBannerHorizontal.style";
import { Context } from "../..";
import Marquee from "react-fast-marquee";

const LogoBannerHorizontalNoSSR: FunctionComponent<any> = ({ shadowOverlayVisible, whiteBackground }) => {
    const props = useContext(Context);
    const allBrands = props.AllBrand;

    if (!allBrands || !allBrands?.length) {
        return null;
    }

    const Companies = () => (
        <>
            {allBrands.map((company, i) => {
                if (!company?.logo || company.logo.length === 0 || !company.logo?.[0]?.src) {
                    return null;
                }

                return (
                    <SImgWrapper key={i}>
                        <Img src={company?.logo} quality={200} alt={company?.name || ""} lazyload={false} />
                    </SImgWrapper>
                );
            })}
        </>
    );

    return (
        <SLogoBannerHorizontal>
            <Marquee gradient={false} >
                <Companies />
            </Marquee>
            {shadowOverlayVisible && (
                <>
                    <SShadowOverlay />
                    <SShadowOverlay style={{ right: 0, left: "unset", transform: "rotate(180deg)" }} />
                </>
            )}
        </SLogoBannerHorizontal>
    );
};

export { LogoBannerHorizontalNoSSR };
