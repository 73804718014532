import styled from "styled-components";

export const SSlider = styled.div`
    width: 100%;
    ul,
    li {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    ul{
        height: 400px;
    }
    li {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: all 0.5s ease;
    }
    li.active {
        opacity: 1;
    }
`;
