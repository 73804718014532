// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const HaderContainer = styled.div`
  background-color: white;
  height: 125px;
  position: fixed;
  z-index: 111;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
  }
`;

export const HeaderContent: any = styled.div`
  position: relative;
  width: 100%;
`;

export const LogoContainer = styled.div`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 1240px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
  }
`;
export const Logo = styled.img`
  margin: 12px 0 0 20px;
  max-width: 270px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 82.5px;
    max-width: 360px;
    margin-top: 12px;
    margin-left: 0;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: 99px;
    max-width: 360px;
    margin-top: 12px;
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    height: 110px;
    max-width: 360px;
    margin-top: 15px;
  }
`;
