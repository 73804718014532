// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const HaderContainer = styled.div`
  background-color: white;
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 10px 10.5px 5px;
  box-shadow: 0 1px 6px #efefef;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: 150px;
  }
`;

export const HeaderContent: any = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin: auto;
    width: 950px;
  }

`;

export const Logo = styled.img`
  width: calc(100% - 57px);
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 132px;
    width: unset;
  }
`;
