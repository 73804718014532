import React, { FC, Fragment } from "react";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";
import { AddressWrapper } from "./Footer.style";

const LinksSeparator: FC = () => <span>|</span>;

const Address: FC<{ companyInformation: any }> = ({ companyInformation }) => (
  <AddressWrapper>
    <p>
      <div><b>{getContactCompanyNameShort()}</b></div>
      <div>{companyInformation?.street}</div>
      <div>{companyInformation?.postcode} {companyInformation?.place}</div>
    </p>
    <p>
      <br />
      <div>t. {companyInformation?.phone}</div>
      <div><a href={`mailto:${companyInformation?.email}`}>{companyInformation?.email}</a></div>
    </p>
    <p>
      <a href="/kontakt">Anfahrt</a>
      <a href="/imprint-datenschutz">Impressum</a>
      <a href="/imprint-datenschutz">Datenschutzerklärung</a>
    </p>
  </AddressWrapper>
);

export default Address;