import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from "react";
import {
  getHeaderNavItemProps,
  INavigationItem,
  Context,
  IContext,
} from "src/common";

import {
  NavigationContainer,
  NavigationLinks,
  NavigationLink,
  BurguerIcon,
} from "./Navigation.styles";

const Items: FunctionComponent<{
  item: Record<string, any>;
  itemProps: Record<string, any>;
}> = ({ itemProps, item }) => {
  const props = useContext<IContext>(Context);
  const currentItem = props?.PrimaryNavigation?.items?.find((primary) =>
    primary?.subitems?.find((secondary) => secondary?.slug === props?.slug)
  );
  return (
    <>
      <NavigationLink
        isActive={
          itemProps.link === props.slug ||
          itemProps.link === currentItem?.link[0].slug
        }
      >
        <a href={itemProps.link ?? "/"}>{itemProps.title}</a>
      </NavigationLink>
    </>
  );
};



// Hardcoding home nav item
const homeNavItem: INavigationItem = {
  __typename: 'NavigationItem',
  label: "dk das küchenhaus",
  link: [{ __typename: "Page", slug: null, nameInNavigation: "dk das küchenhaus"}],
};

const homeNavItemProps = {link: null, title: "dk das küchenhaus"}

const Navigation: FunctionComponent<{
  items: INavigationItem[];
  slug: string;
}> = ({ items }) => {
  const [isMenuActive, toggleMenuActive] = useState(false);
  return (
    <NavigationContainer>
      <BurguerIcon
        onClick={() => toggleMenuActive(!isMenuActive)}
        isMenuActive={isMenuActive}
      >
        <i className="close fa fa-times" />
        <i className="open fa fa-bars" />
      </BurguerIcon>

      <NavigationLinks isMenuActive={isMenuActive}>
        <ul>
          <Items itemProps={homeNavItemProps} item={homeNavItem} />
          {items.map((item, index) => {
            const itemProps = getHeaderNavItemProps(item);
            if (!itemProps) {
              return;
            }

            return (
              <Items
                itemProps={itemProps}
                item={item}
                key={`item-key-${index}`}
              />
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
