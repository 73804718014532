import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div<{isScrolling: boolean}>`
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #F19024;
  position: fixed;
  z-index: 70;
  height: ${({ isScrolling }) => isScrolling ? "63px" : "72.66px"};
  box-sizing: content-box;
  box-shadow: 2px 6px 16px -7px rgb(0 0 0 / 75%);
  display: none;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: flex;
    width: fit-content;
    right: 165px;
  }

  a {
    color: white;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    min-height: 150px;
  }
`;

export const InfoWrapper: any = styled.div<{isScrolling: boolean}>`
  position: fixed;
  margin: ${({ isScrolling }) => isScrolling ? "665px 0 0 -125px" : "657px 0 0 -96px"};
  height: 477px;
  width: 460px;
  opacity: 0.9;
  background-color: rgb(241, 144, 36);
`;

export const DataWrapper: any = styled.div`
  padding: 75px 60px;
  opacity: 1.5;
`;

export const H3: any = styled.div`
  color: #fff;
  opacity: 1;
  font-size: 20px;
  letter-spacing: .85px;
  font-weight: 400; 
  border-bottom: 1px solid white;  
  margin-bottom: 16px;
  padding-bottom: 43px;   
`;

export const H1: any = styled.div`
  color: white;
  opacity: 1;
  font-size: 30px;
  letter-spacing: 1.25px;
  font-weight: 100; 
`;

export const RichTextWrapper: any = styled.div`
  table {
    display: flex;
    flex-direction: column;
    tr {
      display: flex;
      flex-direction: column;
    }
    p {
      border: none;
    }
  }
`;

export const AddressWrapper: any = styled.div`
  padding-top: 5px;
  p {
    font-size: 16px;
    font-weight: 100;
    margin: 0 0 10px;
  }
`;
