import React, { FunctionComponent, useContext } from "react";
import dynamic from "next/dynamic";
const SlickSlider = dynamic(() => import("react-slick"), { ssr: false });

import * as styles from "./Slider.style";
import { Context, IContext, IAsset, Img } from "src/common";
import SSlickTheme from "../../utils/slick/SSlickTheme";
import SSlickBase from "../../utils/slick/SSlickBase";
import { LazyLoadTypes } from "react-slick";

export interface ISlider {
  elements: IAsset[];
  overrideStyle?: string;
  options?: {
    infinite?: boolean;
    arrows?: boolean;
    dots?: boolean;
    slidesToShow?: number;
    slidesToScroll?: number;
    autoplaySpeed?: number;
    autoplay?: boolean;
    fade?: boolean;
    lazyLoad?: LazyLoadTypes;
    adaptiveHeight?: boolean;
  };
}

const DEFAULT_OPTIONS = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 6000,
  autoplay: true,
  fade: true,
  lazyLoad: "progressive" as LazyLoadTypes,
  adaptiveHeight: false,
};

export const FadeSlider: FunctionComponent<ISlider> = (props) => {
  const isPage = useContext<IContext>(Context);
  const sliderSettings = {
    ...DEFAULT_OPTIONS,
    arrows: isPage.PageLanding ? false : true,
    ...props.options,
  };

  return (
    <div css={styles.wrapper} data-slider-wrapper>
      <div
        css={props.overrideStyle || `
          ${SSlickBase}
          ${SSlickTheme}
        `}
      >
        <SlickSlider {...sliderSettings}>
          {props?.elements.map((element, i) => (
            <Img quality={900} key={`sliderimage${i}`} src={element?.src} alt={element?.description ?? "image"} />
          ))}
        </SlickSlider>
      </div>
    </div>
  );
};

export default FadeSlider;
