import { css } from "styled-components";

import {
    MIN_SILVER,
    MIN_GOLD,
    MIN_PLATINUM,
    MIN_DIAMOND
} from "src/common/constants/mediaquerys";

const Default = css`
  .c-empty{
    min-height: 21px;
    width: 100%;
    display: block;
  }
  h1 {
    ${({ theme }) => theme?.textStyles?.h1Red}
  }
  h1,
  h2,
  h3 {
    &,
    span {
     color: ${({ theme }) => theme.palette.red};
      font-weight: 600;
    }
  }

  h1, h1>span {
    margin: 0;
    padding: 0;
    margin-bottom: 1.4rem;

    color: ${({ theme }) => theme.palette.red};
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  h2, h2>span {
    margin: 0;
    padding: 0;
    margin-bottom: 1.4rem;

    color: ${({ theme }) => theme.palette.red};
    font-size: 1.2rem;
    line-height: 1.16667;
    font-weight: 400;
    letter-spacing: 0.02em;
  }
  h3, h3>span {
    margin: 0;
    padding: 0;
    margin-bottom: 1.4rem;

    color: ${({ theme }) => theme.palette.red};
    font-weight: 400;
    letter-spacing: 0.02em;
    font-size: 1rem;
    line-height: 1.4;
  }
  h4,
  h5,
  h6 {
    &, span {
      margin: 0;
    padding: 0;
    margin-bottom: 1.4rem;

    font-size: 0.8rem;
    line-height: 1.75;
    margin-bottom: 1.4rem;
    font-weight: 400;
    }

  }

  p, table, iframe, ol, ul{
    margin-bottom: 1.4rem;
  }
  a {
    color: ${({ theme }) => theme.palette.red};
  }
  svg {
    path {
      fill: ${({ theme }) => theme.palette.red};
    }
  }
  li, ul{
    display: block;
    list-style: inside;
  }
  ul {
    list-style: disc;
  }
  li{
    strong{
      height: 21px
    }
    list-style: inside;
    line-height: 21px;
    display: list-item;
  }
  h1 {
    ${({ theme }) => theme?.textStyles?.h1Red}
  }
  a {
    color: ${({ theme }) => theme.palette.red};
  }
  
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
  svg {
    path {
      fill: ${({ theme }) => theme.palette.red};
    }
  }
  ul {
    list-style: disc;
  }
  li{
    strong{
      height: 21px
    }
    display: list-item;
  }
  tr{
    font-size: 12px;
    font-weight: 300;
    p{
    margin-bottom: 10px;
    padding-right: 10px;
    }
  }
`;

const Silver = css`
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SRichTextGeneral = css`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SRichTextGeneral;
