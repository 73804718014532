import React, { useState, useContext, Fragment, useEffect } from "react";
import { Context, getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";
import { IAsset } from "src/common/rootTypes";
import SProductLandingBanner from "./Styles/SProductLandingBanner";
import IconAngle from "./Icons/IconAngle";
import IconDownload from "./Icons/IconDownload";
import Gallery from "src/common/BaseComponents/Gallery/Gallery";

export const ProductLandingBanner = () => {
    const [closed, setClosed] = useState(false);
    const [slidedOut, setSlidedOut] = useState(false);
    const [initalSlidedOut, setInitalSlidedOut] = useState(false);

    useEffect(() => {
        if (!slidedOut) {
            setInitalSlidedOut(true);
        }
    }, []);
    useEffect(() => {
        let t = setTimeout(() => {
            setInitalSlidedOut(false);
        }, 7000);
        return () => clearTimeout(t);
    }, []);

    const [currentOfferIndex, setCurrentOfferIndex] = useState(0);
    const props = useContext(Context);
    const offers = props?.PageLanding?.promotionalOffer?.filter((p) => {
        return !!p && p.acceptAGB && getIfNewsShouldBeDisplayByDate({ startDate: p?.startDate, endDate: p?.endDate });
    });
    if (!offers?.length) {
        return null;
    }

    const product: IProduct = offers[currentOfferIndex] as any;
    if (!product) {
        return null;
    }

    return (
        <SProductLandingBanner closed={closed} slidedOut={initalSlidedOut || slidedOut}>
            <div className="plb-c0" onMouseEnter={() => setSlidedOut(true)} onMouseLeave={() => setSlidedOut(false)}>
                <a className="plb-link plb-desktop-link-left" href={`/produkt/${product.slug}`}>
                    Aktionsangebot
                </a>
                <div className="plb-c10">
                    <a className="plb-link" href={`/produkt/${product.slug}`}>
                        <h1 className="plb-title">
                            <span className="plb-title-prefix">Aktionsangebot:</span> {product?.title}
                        </h1>
                        {/* <RichText textContent={product.text} fragment /> */}
                    </a>
                    <div className="plb-slider-with-arrows">
                        {offers?.length > 1 && (
                            <div
                                className="plb-arrow plb-arrow-left"
                                onClick={() =>
                                    currentOfferIndex < offers.length - 1
                                        ? setCurrentOfferIndex(currentOfferIndex + 1)
                                        : setCurrentOfferIndex(0)
                                }
                            >
                                <IconAngle />
                            </div>
                        )}

                        <a className="plb-link" href={`/produkt/${product.slug}`}>
                            <div className="plb-image">
                                {product?.images?.[0]?.src && (
                                    <Img placeholderHeight={"350px"} src={[product?.images?.[0]] as IAsset[]} />
                                )}
                            </div>
                        </a>
                        {offers?.length > 1 && (
                            <div
                                className="plb-arrow plb-arrow-right"
                                onClick={() =>
                                    currentOfferIndex !== 0
                                        ? setCurrentOfferIndex(currentOfferIndex - 1)
                                        : setCurrentOfferIndex(offers.length - 1)
                                }
                            >
                                <IconAngle />
                            </div>
                        )}
                    </div>
                    <a className="plb-link plb-price-link" href={`/produkt/${product.slug}`}>
                        <div className="plb-price-container">
                            <div className="plb-price-badge">nur {product.ourPrice} EUR</div>
                        </div>
                    </a>
                    {product.productDownloads?.[0]?.src && (
                        <div className="plb-download">
                            <IconDownload />
                            <a href={product.productDownloads?.[0]?.src} target="_blank" type="download">
                                Zum Produktdatenblatt
                            </a>
                        </div>
                    )}
                    <hr></hr>
                    {!!product.services?.length && (
                        <>
                            <h3>Unser Preis inklusive:</h3>
                            <div className="plb-service-gallery">
                                <Gallery fullWidth bronze={2} silver={2}>
                                    {product.services?.map((s, i) => {
                                        return s ? (
                                            <div className="plb-service-icon" key={`plb2` + i}>
                                                <Img src={s.icon} />
                                                {s.title}
                                            </div>
                                        ) : null;
                                    })}
                                </Gallery>
                            </div>
                        </>
                    )}
                    <p>Für weitere Informationen klicken Sie in das Bild oder den Titel</p>
                    <button className="plb-close" onClick={() => setClosed(true)}>
                        Schließen
                    </button>
                </div>
            </div>
        </SProductLandingBanner>
    );
};
