import { Context } from "src/common";
import React, { Fragment, FunctionComponent, useContext } from "react";

import Img from "../../../../BaseComponents/Img/Img";
import { cutCorner } from "../../../../constants/layout";
import * as styles from "./SidebarRight.style";

export const SidebarRight: FunctionComponent = () => {

    const caisyProps = useContext(Context);
    return (
        <div css={styles.sidebarWrapper}>
            <div css={`${cutCorner}${styles.sidebarItemWrapper}`}>
                <div css={`${styles.content}${styles.markenContent}`}>
                    <Img quality={300} src="/images/dude.png" alt="Daumen hoch" />
                    <div css={styles.innerContentWrapper}>
                        <h3>MARKEN</h3>
                        <p>In unserem Marken-Portfolio bieten wir Ihnen die meisten bewährten Marken an.</p>
                        <div css={styles.brands}>
                            {caisyProps?.AllBrand?.filter(brand => brand?.logo?.[0]?.src).map((brand, i) =>
                                <Img quality={200} key={`brandlogo` + i} src={brand?.logo?.[0]?.src} alt={`${brand?.logo?.[0]?.description || "logo"}`} />)}
                        </div>
                    </div>
                </div>
            </div>
            <div css={`${cutCorner}${styles.sidebarItemWrapper}`}>
                <div css={`${styles.rezensionImgWrapper}${styles.content}`}>
                    <Img quality={300} src="/images/reparatur_partner.svg" alt="Daumen hoch" />
                </div>
            </div>
        </div>
    );
};
