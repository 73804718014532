import React, {useState} from 'react';
import {SCompFounderForm} from './styles/SCompFounderForm';
import {SCompFounderFormLabel} from "./styles/SCompFounderFormLabel";
import {SCompFounderFormInput} from "./styles/SCompFounderFormInput";
import {SCompFounderFormTextarea} from "./styles/SCompFounderFormTextarea";
import styled from "styled-components";
import {SCompFounderFormCheckbox} from "./styles/SCompFounderFormCheckbox";
import {SCompFounderFormButton} from "./styles/SCompFounderFormButton";
import {SCompFounderFormSuccessMessage} from "./styles/SCompFounderFormSuccessMessage";
import { GridPadding } from '../../BaseComponents/grid-padding/GridPadding';
import { SGrid } from '../../BaseComponents/grid/styles/SGrid';
import { GridItem } from '../../BaseComponents/grid-item/GridItem';
import { SCompFounderFormErrorMessage } from './styles/SCompFounderFormErrorMessage';
import { useLineShort } from '../../hooks/useLineId';

const GridItemWithMargin = styled(GridItem)`
  margin-bottom: 1rem;
`;

export const CompFounderForm: React.FC = () => {
    const [name, setName] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [privacy, setPrivacy] = useState(false);
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const lineShort = useLineShort();

    console.log(` CompFounderForm`, );

    const submitForm = (e) => {
        e.preventDefault();

        if (!privacy) {
            alert("Bitte akzeptieren Sie die Datenschutzerklärung")
        }

        const formData = {
            name,
            zipcode,
            email,
            message,
            // 'referrer': 'www.perfekte-kuechen.de,' @todo
        };

        let formBody: any = [];
        for (const property in formData) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(formData[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody.push("line_short=" + lineShort);

        formBody = formBody.join("&");

        fetch(`${process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT}/api/v1/headpage/founderform`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: formBody
        }).then((response) => response.json())
            .then((data) => {
                if (!data.success) {
                    setError(true)
                } else {
                    setSuccess(true)
                }
            });
    }

    return (
        <SCompFounderForm>
            <GridPadding>
                <SGrid>
                    <GridItem
                        bronze={{start: 2, end: 3}}
                        silver={{start: 5, end: 9}}
                    >
                        {success && <SCompFounderFormSuccessMessage>
                            Ihre Nachricht wurde erfolgreich versendet, vielen Dank.
                        </SCompFounderFormSuccessMessage>}

                        {error && <SCompFounderFormErrorMessage>Es ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre
                            Eingaben.</SCompFounderFormErrorMessage>}

                        {!success && <form className="gruender-form" onSubmit={submitForm}>

                            <SGrid>
                                <GridItemWithMargin
                                    bronze={{start: 2, end: 3}}
                                    silver={{start: 1, end: 12}}
                                >
                                    <SCompFounderFormLabel>Name</SCompFounderFormLabel>
                                    <SCompFounderFormInput type="text"
                                                           value={name}
                                                           onChange={e => setName(e.target.value)}
                                                           required={true} name="name"/>
                                </GridItemWithMargin>
                                <GridItemWithMargin
                                    bronze={{start: 2, end: 3}}
                                    silver={{start: 1, end: 12}}
                                >
                                    <SCompFounderFormLabel>E-Mail Adresse</SCompFounderFormLabel>
                                    <SCompFounderFormInput
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        type="email" required={true} name="email"/>
                                </GridItemWithMargin>
                                <GridItemWithMargin
                                    bronze={{start: 2, end: 3}}
                                    silver={{start: 1, end: 12}}
                                >
                                    <SCompFounderFormLabel>PLZ</SCompFounderFormLabel>
                                    <SCompFounderFormInput
                                        value={zipcode}
                                        onChange={(e) => setZipcode(e.target.value)}
                                        type="text" required={true} name="plz"/>
                                </GridItemWithMargin>

                                <GridItemWithMargin
                                    bronze={{start: 2, end: 3}}
                                    silver={{start: 1, end: 12}}
                                >
                                    <SCompFounderFormLabel>Nachricht</SCompFounderFormLabel>
                                    <SCompFounderFormTextarea
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        required={true}
                                        name="nachricht" rows={5}>

                                    </SCompFounderFormTextarea>
                                </GridItemWithMargin>

                                <GridItemWithMargin
                                    bronze={{start: 2, end: 3}}
                                    silver={{start: 1, end: 12}}
                                >
                                    <SCompFounderFormLabel htmlFor={"privacy"}>
                                        <SCompFounderFormCheckbox id={"privacy"} type="checkbox" name="privacy"
                                                                  onChange={() => setPrivacy(!privacy)}
                                                                  value="1" required={true}/>
                                        Ich akzeptiere die {" "}
                                        <a href='/imprint-datenschutz#Impressum'>
                                            Datenschutzerklärung
                                        </a>
                                    </SCompFounderFormLabel>
                                </GridItemWithMargin>

                                <GridItemWithMargin
                                    bronze={{start: 2, end: 3}}
                                    silver={{start: 1, end: 6}}
                                >
                                    <SCompFounderFormButton type={"submit"}>
                                        Absenden
                                    </SCompFounderFormButton>
                                </GridItemWithMargin>
                            </SGrid>
                        </form>}
                    </GridItem>
                </SGrid>
            </GridPadding>
        </SCompFounderForm>
    )
}
