import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

import styled, { keyframes, css } from "styled-components";

const fadeInPlatinum = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 250px;
  }
`;

const fadeInGold = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 180px;
  }
`;
const animation: any = css`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    animation: 800ms ${fadeInGold};
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    animation: 800ms ${fadeInPlatinum};
  }
`;

export const Content: any = styled.div<{isShowMenu: boolean}>`
  width: 100%;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    ${({ isShowMenu }) => isShowMenu ? animation : ""};
    margin-left: ${({ isShowMenu }) => isShowMenu ? "180px" : ""};
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    ${({ isShowMenu }) => isShowMenu ? animation : ""};
    margin-left: ${({ isShowMenu }) => isShowMenu ? "250px" : ""};
  }
`;
