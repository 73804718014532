import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import colors from "../../../constants/colors";

const gray = "#d3d3d3ff";

export const FormWrapper: any = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0,.5);
  z-index: 9999999999;
`;

export const wrapper: any = css`
    display: flex;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    margin: 50px 0;
    flex-direction: column;
    background: ${colors.white};
    box-shadow: 0 0 40px black;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-direction: row;
        right: 8vh;
        max-width: 90vw;
        width: 900px;
        height: 496px;
    }
`;

export const topBar: any = css`
    display: flex;
    flex-direction: column;
    color: ${colors.textColor};
    padding: 32px 18px 18px 18px;
    cursor: pointer;
    background-color: ${colors.primary};
    position: relative;
    width: 100%;
    align-items: center;
    text-align: center;
    p {
      padding: 12px 0px 27px;
      text-align: left;
      width: 97%;
      color: ${colors.white};
      font-family: Roboto,sans-serif;
      font-size: 13px;
      line-height: 18px;
      border-bottom: 1px solid ${colors.textColor};
      margin: 0px 0px 20px;
      word-break: break-all;
    }
    span {
      display: flex;
      align-items: center;
      color: ${colors.textColor};
      text-align: left;
      width: 90%;
      font-family: Roboto,sans-serif;
      font-size: 12.6px;
      line-height: 2em;
      padding: 0px 0px;
    }
    h1 {
      color: #434a4c;
      font-family: Roboto,sans-serif;
      font-size: 30px;
      font-weight: 400;
      line-height: 100%;
      margin: 0;
    }
    h2 {
      color: #434a4c;
      font-family: Roboto,sans-serif;
      font-size: 30px;
      font-weight: 400;
      line-height: 100%;
      margin: 0;
    }
    h3 {
      color: #434a4c;
      font-family: Roboto,sans-serif;
      font-size: 30px;
      font-weight: 400;
      line-height: 100%;
      margin: 0;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        width: 42.6%;

        h1 {
          font-size: 84px;
        }
        h2 {
          font-size: 29px;
        }
        h3 {
          font-size: 54px;
        }
    }
    :after {
      display: block;
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      right: -10px;
      top: 29%;
      transform: rotate(45deg);
      background-color: ${colors.primary};
     }
`;

export const closeButton: any = css`
    position: absolute;
    top: 50px;
    right: 12px;
    z-index: 9999;
    cursor: pointer;
    fill: ${colors.textColor};
    width: 15px;
    &:hover {
      opacity: .6;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
      position: relative;
      top: 12px;
      left: 550px;
    }
`;

export const contentArea: any = css`
    overflow-y: auto;
    padding: 13px;
    height: 600px;
    p {
      padding-bottom: 20px;
      margin-bottom: 13px;
      border-bottom: solid ${gray} 1px;
    }
    h1 {
      color: ${colors.textColor};
      font-family: Roboto,sans-serif;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.4;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
      padding: 0px 20px;
      margin: 0px 30px;
      width: 100%;
      h1 {
        font-size: 35px;
        margin: 22px 0px 28px;
      }
    }
`;

export const formRow: any = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    input, select, textarea {
      color: #555;
      padding: 6px 12px;
      border: 1px solid ${colors.primary};
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
      width: 100%;
      height: 34px;
      border-radius: 4px 0px 0px 4px;
      &::placeholder {
        font-family: Roboto,sans-serif;
        color: ${colors.textColor};
      }
    }
    textarea {
      margin: 10px 10px;
      border-radius: 4px;
      width: 94.5%;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
      flex-direction: row;
      textarea {
        margin: 15px 0px 15px;
        width: 100%;
      }
    }
`;

export const rowContent: any = css`
{
  display: flex;
  width: 100%;
  padding: 10px 10px;
  @media (${BREAKPOINTS_DM.silver_768})
  {
    width: 47%;
    padding: 0px;
    margin-bottom: 15px;
  }
}
`;

export const doubleRowcontent: any = css`
{
  display: flex;
  width: 100%;
  padding: 10px 10px;
  @media (${BREAKPOINTS_DM.silver_768})
  {
    padding: 0px;
  }
}
`;

export const submitWrapper: any = css`
    display: flex;
    flex-direction: column;
    padding: 0px 0px 50px;

    button {
      align-self: center;
      display: inline-block;
      font-family: Roboto,sans-serif;

      background-color: #efefef;
      border: 2px solid #efefef;
      color: #222;

      padding: 17px 25px;
      border-radius: 27px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;


      &: hover {
        background-color: ${colors.primary};
        color: #fff;
      }
    }
    label {
      font-family: Roboto,sans-serif;
      cursor: pointer;
      font-size: 15px;
      padding: 3px 0px 0px 9px;
    }
    a {
      font-family: Roboto,sans-serif;
      cursor: pointer;
      font-size: 15px;
      padding: 3px 0px 0px 0px;
    }
    input {
      margin: 14px 0px;
    }
    a {
      color: ${colors.primary};
    }
`;
export const sidebarExpandIconContainer: any = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: auto;
    opacity: .9;
    background-color: #EFEFEF;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #ccc;
    &:hover {
      opacity: 1;
    }
`;

export const sidebarExpandIconContainerDouble: any = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: auto;
    opacity: .9;
    background-color: #EFEFEF;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #ccc;
    &:hover {
      opacity: 1;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
      width: 52.5px;
    };
`;

export const sidebarExpandIcon: any = css`
    height: 17px;
    opacity: .9;
    &:hover {
      opacity: 1;
    }
`;
export const sidebarExpandIconButton: any = css`
    height: 20px;
    font-size: 20px;
    opacity: .9;
    margin-right: 9px;
    &:hover {
      opacity: 1;
    }
`;

export const sidebarExpandIconSpan: any = css`
    height: 10px;
    opacity: .9;
    margin-right: 5px;
    &:hover {
      opacity: 1;
    }
`;

export const Separator: any = styled.hr`
  margin: 20px 0px;
  width: 95%;
`;
