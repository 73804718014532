import styled, { keyframes, css } from "styled-components";

const showHide = keyframes`
        0% {
            opacity: 0;
        }
        10% {
            opacity: 0;
        }
        15% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
`;

export const SScrollIndicator = styled.div<any>`
    border-radius: 50%;
    opacity: ${(props: any) => (props.active ? 1 : 0)};
    ${(props: any) => (props.active ?  '': css`pointer-events: none;`)};
    ${(props: any) => (props.active ? css`animation: ${showHide} 20s normal forwards linear;` : '')};
    transition: opacity 0.8s ease;
    z-index: 1;
    cursor: pointer;
    border-color: #fff;
    box-shadow: inset 2px 2px 5px rgba(50, 50, 50, 0.2), 2px 2px 5px rgba(50, 50, 50, 0.2);
    width: 55px;
    height: 55px;
    margin-left: -14px;
    border-width: 2px;
    border-style: solid;
    position: fixed;
    top: calc(50% - 27px);
    left: calc(50% - 27px);
`;
