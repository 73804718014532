import React, { FC } from "react";
import { Img, IProduct, RichText } from "src/common";

import {
  ImageWrapper,
  RichTextWrapper,
  BoxBorderIconNumber,
  BoxBorderLeft,
  BoxBorderArrow,
  BoxBorderTop,
  TitleWrapper,
  BoxContainer,
  ItemDefaultWrapper
} from "./ItemDefault.style";

const ItemDefault: FC<{ item: any; i: number }> = ({ item, i }) => {
  if (!item) {
    return null;
  }

  return (
    <ItemDefaultWrapper>
      <BoxContainer>
        <BoxBorderTop />
        <BoxBorderLeft />
        <BoxBorderArrow>
          <img src="/images/gk_icon_arrow.png" alt="Arrow" />
        </BoxBorderArrow>
        <BoxBorderIconNumber iconNumber={i} />
        {item.title && (
          <TitleWrapper>
            <h4>{item.title}</h4>
          </TitleWrapper>
        )}
        {item.thumbnail?.length > 0 && (
          <ImageWrapper>
            {item.thumbnail.map((thumbnail, i) => (
              <Img src={thumbnail.src} key={`image-${i}`} />
            ))}
          </ImageWrapper>
        )}
        {item.text?.content && (
          <RichTextWrapper>
            <RichText fragment={true} textContent={item.text} />
          </RichTextWrapper>
        )}
      </BoxContainer>
    </ItemDefaultWrapper>
  );
};

export default ItemDefault;
