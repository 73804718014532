import React from "react";
import {
  getIfNewsShouldBeDisplayByDate,
  Img,
  IProduct,
  RichText,
} from "src/common";
import SItemDefault from "./Styles/SItemDefault";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import IconAngle from "src/common/components/ProductLandingBanner/Icons/IconAngle";

export const ItemDefault = ({ item }) => {
  if (!item) {
    return item;
  }
  const product = item?.link?.[0] as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const price = link?.ourPrice;
  const slug = link?.slug;

  const externLink =
    link?.__typename == "ExternalLink" ? link?.externalLinkAddress : null;

  if (
    isProduct &&
    product?.acceptAGB &&
    !getIfNewsShouldBeDisplayByDate({
      startDate: product?.startDate,
      endDate: product?.endDate,
    })
  ) {
    return null;
  }

  return (
    <SItemDefault className={isProduct && "isProduct"}>
      <a
        className="cpg-item"
        href={
          externLink
            ? "https://" +
              `${externLink}`.replace("http:", "https:").replace("https://", "")
            : `/${isProduct ? "produkt/" : ""}${slug}`
        }
        target={externLink ? "_blank" : undefined}
      >
        <div
          className={`cpg-item-wrapper${
            item.thumbnail?.[0] ? " with-img" : " no-img"
          }`}
        >
          <div className="cpg-item-head">
            { item.title && <h2>{item.title}</h2>}
            <div className="cpg-item-head-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="9"
                viewBox="0 0 24 24"
              >
                <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
              </svg>
            </div>
            <Spacer bronze={20} />
            <div className="cpg-item-richt-text">
              {item?.text?.content && (
                <RichText fragment={true} textContent={item?.text}></RichText>
              )}
            </div>
          </div>
          <div
            className={`cpg-item-img${
              item.thumbnail?.[0] ? " with-img" : " no-img"
            }`}
          >
            {item.thumbnail?.[0] && (
              <div className="imageWrapper">
                <Img src={item.thumbnail}></Img>
              </div>
            )}
          </div>
          {isProduct && (
            <div className="cpg-price">
              <span className="cpg-price-text">Jetzt für nur</span>
              <div>
                {price && <span className="cpg-price-number">{price} €</span>}
                <span className="cpg-price-next">
                  zum Angebot <IconAngle />
                </span>
              </div>
            </div>
          )}
        </div>
      </a>
      <Spacer bronze={20} />
    </SItemDefault>
  );
};
