import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 10px auto 130px;
    padding: 0 10px;
`;

export const linksWrapper: any = css`
    column-count: 4;
    column-gap: 20px;
    margin-bottom: 30px;
    display: none;
    p {
        margin-bottom: 3px;
    }
    a {
        font-size: 12px;

        color: ${(props) => props.theme.palette.bg1};
        &:hover {
            color: ${(props) => props.theme.palette.red};
        }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
        display: block;
    }
`;

export const addressWrapper: any = css`
    margin-bottom: 20px;
    p,
    div {
        margin-top: 9px;
        font-size: 12px;
        color: ${(props) => props.theme.palette.bg1};
    }
    a {
        color: ${(props) => props.theme.palette.red};
        &:hover {
            color: ${(props) => props.theme.palette.red};
        }
    }
`;

export const managingDirectorWrapper: any = css`
    display: flex;
    align-items: center;
    p {
        margin-top: 0;
        margin-left: 4px;
    }
    img {
        width: auto;
        object-fit: contain;
        height: 30px;
    }
`;

export const shareOnSocialMediaWrapper: any = css`
    display: flex;
    .twitter {
        svg {
            color: white;
        }
    }
    a {
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 8px;
        height: 35px;
        width: 85px;
        span {
            margin-left: 10px;
            color: white;
            display: none;
        }
        &:first-child {
            background-color: #3b5998;
            &:hover {
                background-color: #486dbc;
            }
        }
        &:nth-child(2) {
            background-color: #55acee;
            &:hover {
                background-color: #78c2ff;
            }
        }
        &:nth-child(3) {
            background-color: #bd081c;
            &:hover {
                background-color: #f10c23;
            }
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            background-color: #999;
            &:hover {
                background-color: #c1c1c1;
            }
        }
        svg {
            height: 100%;
            fill: white;
        }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
        a {
            width: 180px;
            span {
                display: block;
            }
        }
    }
`;
