import { css } from "styled-components";

export const textStyleDefaults = css`
  h1 {
    &,
    span {
      font-size: 1.71429rem;
      line-height: 1.4;
      font-weight: 600;
    }
  }
  h2 {
    &,
    span {
      font-size: 1.14286rem;
      line-height: 1.375;
      font-weight: 600;
    }
  }
  h3 {
    &,
    span {
      font-size: 1.07143rem;
      line-height: 1.46667;
      font-weight: 600;
    }
  }
  h4 {
    &,
    span {
      font-size: 0.85714rem;
      line-height: 1.83333;
      font-weight: 600;
    }
  }
  h1, h2, h3, h4, h5, h6, p, table, ol, ul {
    margin-bottom: 2rem;
  }
`;
