// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container, Section } from "../Grid";

export const FooterContainer = styled(Container)`
  ${Section} {
    padding-top: 0;
    paddinb-bottom: 30px;
  }
`;

export const FooterWrapper = styled.div`
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: auto;
  padding-bottom: 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0px 30px 20px 0px;
  }
`;

export const Link: any = styled.a`
  display: block;
  color: ${props => props.theme.palette.textColor};
  line-height: 1.2rem;
  ${({ isBold }) => isBold && 'font-weight: bold;'}
`;

export const AddressWrapper: any = styled.div`
    margin-bottom: 21px;
    p, div {
      line-height: 21px;
      font-size: 15px;
    }

    a {
      text-transform: uppercase;
      font-weight: 700;
      color: ${props => props.theme.palette.primary};
      &:hover {
        color: ${props => props.theme.palette.primary};
      }
    }

    & > .managingDirectorWrapper {
      display: flex;
      align-items: center;
      p {
        margin-top: 0;
      }
    }

    & > .addressLinksWrapper {
      display: flex;
      flex-wrap: wrap;
      & > span {
        margin: 0 4px;
      }
    }
    a.insta {
      svg{
        height: 18px;
        width: 18px;
        &, path {
         fill: #898f9c;
        }
      }
    }
`;

export const ShareOnSocialMediaWrapper: any = styled.div`
    display: flex;
    a {
      display: flex;
      align-items: center;
      margin-right: 12px;
      padding: 8px;
      height: 35px;
      width: 85px;
      span {
        margin-left: 10px;
        color: white;
        display: none;
      }
      &:first-child {
        background-color: #3b5998;
        &:hover {
          background-color: #486dbc;
        }
      }
      &:nth-child(2) {
        background-color: #55acee;
        &:hover {
          background-color: #78c2ff;
        }
      }
      &:nth-child(3) {
        background-color: #bd081c;
        &:hover {
          background-color: #f10c23;
        }
      }
      &:nth-child(4) {
        background-color: #999;
        &:hover {
          background-color: #c1c1c1;
        }
      }
      svg {
        height: 100%;
        fill: white;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768})
    {
        a {
            width: 180px;
            span {
              display: block;
            }
        }
    }
`;
