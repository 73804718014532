import styled from "styled-components";

export const Container = styled.div`
  min-height: 630px;
  width: 110%;
  padding: 10px;
  margin-bottom: 30px;
  padding-right: 20px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  font-size: 14px;
  line-height: 25px;

  h5 {
    color: #d7e8c9 !important;
    margin-bottom: 0;
    letter-spacing: 2px;
    padding-top: 10px;
  }
`;

export const RichTextWrapper = styled.div`
  font-size: 14px;
  line-height: 25px;

  > p {
    &:first-child {
      margin: 0;
      strong {
        font-weight: bold;
      }
    }

    &:last-child {
      color: #d7e8c9;
      margin: 0;
    }
  }
`;
