import styled from "styled-components";
import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

const SFooterWrapper: any = styled.div`
  width: 100%;
  margin: 0px auto 0px;
  padding: 0 10px;
  background-color: white;
`;

export const SectionFooterWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    width: 82.5%;
  }
`;

export const AreasWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px 15px 0px;
  color: ${colors.ligth_gray};
  a {
    color: ${colors.ligth_gray};
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 33.3%;
    align-items: flex-start;
  }
`;

export const AdressInfoWrapper: any = styled.div`
  padding: 20px 15px 0px;
  margin-bottom: 20px;
  color: ${colors.ligth_gray};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 33.3%;
    align-items: flex-start;
  }
`;

export const ContactWrapper: any = styled.div`
  color: ${colors.ligth_gray};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px 0px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 33.3%;
    padding: 20px 15px 0px;
    align-items: flex-start;
  }
`;

export const Separator: any = styled.hr`
  color: ${colors.ligth_gray};
  margin: 13px 0px 13px;
  width:100%;
`;

export default SFooterWrapper;
