
import React, { FC, useContext, useState } from "react";
import { Context, IContext } from "src/common";

import PlanningDateModal from "../PlanningDateModal";
import { Bottom, BottomLinks } from "./Header.styles";

const BottomContainer: FC = () => {
  const props = useContext<IContext>(Context);
  const [isShowModal, setShowModal] = useState(false);

  return (
    <>
      <Bottom>
        <p>
          <i className="fa fa-fw fa-phone" /> {props.CompanyInformationPublic.phone}
        </p>
        <p>
          <i className="fa fa-fw fa-envelope" /> <a href={`mailto:${props.CompanyInformationPublic.email}`}>{props.CompanyInformationPublic.email}</a>
        </p>

        <BottomLinks>
          Weitere Termine nach
          <a onClick={() => setShowModal(true)}>Vereinbarung</a>
        </BottomLinks>
      </Bottom>
      {isShowModal && (
        <PlanningDateModal onSetShowModal={setShowModal} />
      )}
    </>
  );
};

export default BottomContainer;