import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import { Smenu, Line } from "./BurgerMenu.style";

interface IProps {
  onClick?: () => void;
  burgerMenuOnDesktop?: boolean;
}

export const BurgerMenu: FunctionComponent<IProps> = ({
  onClick,
  burgerMenuOnDesktop,
}) => {
  const p = useContext(Context);
  const palette = p?.Settings;
  return (
    <Smenu
      className="burger-menu burger-menu-t3"
      burgerMenuOnDesktop={burgerMenuOnDesktop}
      onClick={onClick}
    >
      <Line background={palette.themeTextColor} />
    </Smenu>
  );
};
