import { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const wrapper: any = css`
    display: flex;
    /* justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap; */
    margin-right: -13.5px;
    > div {
        border: 2px solid ${colors.mint};
    }
    > div:first-child {
        background: ${colors.mint};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        > div:not(:first-child) {
          display: none;
        }
    }
`;

export const richText: any = css`
    br:first-child {
      display: none;
    }
    td:last-child {
      padding-left: 1.5em; 
      text-align: right
    }
    h2, h3, h4, h5, h6, a {
      color: ${colors.mint};
    }
`;

export const disableListStyle: any = css`
    ol, li {
      list-style: none !important;
      padding-left: 0 !important;
    }
`;

export const phone: any = css`
    color: ${colors.mint};
    padding: 8px;
    max-width: 300px;
    width: 100%;
    text-align: center;
    background: white;
    font-size: 24px;
`;

export const serviceNumberText: any = css`
    display: flex;
    color: white;
    margin-top: 8px;
    align-items: center;
    img {
      width: 38px !important;
    }
`;
