import React from "react";
import { useState } from "react";
if (typeof window === "undefined") React.useLayoutEffect = React.useEffect;

export interface UseDimensionsArgs {
    liveMeasure?: boolean;
}

export function useWindowDimensions({ liveMeasure = true }: UseDimensionsArgs = {}): {
    windowWidth: number;
    windowHeight: number;
} {
    const [dimensions, setDimensions] = useState({
        width: typeof window === "undefined" ? 640 : window.innerWidth,
        height: typeof window === "undefined" ? 960 : window.innerHeight,
    });

    React.useLayoutEffect(() => {
        if (typeof window === "undefined") return;
        const measure = () =>
            window.requestAnimationFrame(() =>
                setDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight,
                })
            );
        measure();

        if (liveMeasure) {
            window.addEventListener("resize", measure);
            window.addEventListener("scroll", measure);

            return () => {
                window.removeEventListener("resize", measure);
                window.removeEventListener("scroll", measure);
            };
        }
    }, [typeof window === "undefined"]);

    return { windowWidth: dimensions.width, windowHeight: dimensions.height };
}
