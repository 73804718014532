import React, { FunctionComponent } from "react";

import { BurgerMenu } from "../burgerMenu/BurgerMenu";
import { Wrapper, BurgerMenuWrapper } from "./ResponsiveSidebar.style";

interface IProps
{
  isActive: boolean;
  closeSidebar: () => void;
  className?: string;
}

const ResponsiveSidebar: FunctionComponent<IProps> = props => 
{

  return (
    <Wrapper
    className={props?.className}
    isActive={props?.isActive}>
      <BurgerMenuWrapper>
        <BurgerMenu onClick={props?.closeSidebar}/>
      </BurgerMenuWrapper>
      {props?.children}
    </Wrapper>
  );
};

export default ResponsiveSidebar;
