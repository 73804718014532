import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/mediaquerys";

export interface ISFullWidthBannerBaseProps {
  active?: boolean;
  animationDone?: boolean;
  height?: string;
  animationTransitionDelayMs: number;
}

const Default = css<ISFullWidthBannerBaseProps>`
  height: calc(100vw / ((375 / 175)));
  max-height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform
      ${({ animationTransitionDelayMs }) => (animationTransitionDelayMs ? animationTransitionDelayMs + "ms" : "1s")}
      ease,
    max-height
      ${({ animationTransitionDelayMs }) => (animationTransitionDelayMs ? animationTransitionDelayMs + "ms" : "1s")}
      ease;
  width: 100%;
  z-index: 184;
  background-color: #fff;
  .bg {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform
      ${({ animationTransitionDelayMs }) => (animationTransitionDelayMs ? animationTransitionDelayMs + "ms" : "1s")}
      ease;
    height: calc(100vw / ((375 / 175)));
    width: 100%;
    position: fixed;
    z-index: 184;
    background-color: #fff;
  }
`;

const Silver = css``;

const Gold = css`
  height: calc(100vw / ((1024 / 150)));
  .bg {
    height: calc(100vw / ((1024 / 150)));
  }
`;
const GoldActive = css`
  max-height: calc(100vw / ((1024 / 150)));
`;


const Platinum = css``;

const Diamond = css`
  height: calc(100vw / ((1920 / 150)));
  .bg {
    height: calc(100vw / ((1920 / 150)));
  }
`;

const DiamondActive = css`
  max-height: calc(100vw / ((1920 / 150)));
`;

const activeState = css<ISFullWidthBannerBaseProps>`
  transform: scaleY(1);
  max-height: calc(100vw / ((375 / 175)));
  .bg {
    transform: scaleY(1);
  }
  ${MIN_GOLD`${GoldActive}`};
  ${MIN_DIAMOND`${DiamondActive}`};
`;

export const SFullWidthBannerBase = styled.div<ISFullWidthBannerBaseProps>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ active }) => (active ? activeState : "")};
`;
