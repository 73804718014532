import styled, { FlattenSimpleInterpolation, css } from "styled-components";

export const SImgWrapper = styled.div`
    height: 27px;

    img {
        filter: grayscale(1);
        height: 100%;
        width: auto !important;
        margin-right: 40px;
        opacity: 0.7;
    }
`;

export const SShadowOverlay = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    width: 5px;
    z-index: 1;
`;

export const SLogoBannerHorizontal = styled.div<{ stylesOverwrite?: FlattenSimpleInterpolation; }>`
    position: relative;
    margin: 30px 0;

    ${({ stylesOverwrite }) => stylesOverwrite && stylesOverwrite};
`;
