import React from "react";

import SRow from "./Styles/SRow";
import SRowOuter from "./Styles/SRowOuter";

const Row = props => 
{
    if(props.fullWidth) 
    {
        return <SRow className="row">{props.children}</SRow>;
    }
    return (
        <SRowOuter>
            <SRow className="row">{props.children}</SRow>
        </SRowOuter>
    );
};

export default Row;
