import React, { FC } from "react";
import { IPhotoGallery } from "src/common/childTypes";

import { PhotoGalleryWrapper } from "./SliderPhotoGallery.style";
import StandardSlider from "./StandardSlider";
import ThumbnailSlider from "./ThumbnailSlider";

export const getSlider = (type: string): string => ["standard", "slideshowwithoutthumbnails", "gallerywiththumbnails", "gallerywithimagedescriptionundermainimages"].find((item) => item === type);

const MAP = {
  standard: StandardSlider,
  slideshowwithoutthumbnails: StandardSlider,
  gallerywiththumbnails: ThumbnailSlider,
  gallerywithimagedescriptionundermainimages: ThumbnailSlider,
};

const SliderPhotoGallery: FC<{ item: IPhotoGallery }> = ({ item }) => {
  if (!item.elements) return null;

  const Slider = MAP[item.visualization];
  return (
    <PhotoGalleryWrapper data-gallery className={`${""+item.visualization}${!!item.elements.find(e => e.description && e.description != "") ? " hasdesc" : ""}`}>
      <Slider elements={item.elements} />
    </PhotoGalleryWrapper>
  );
};

export default SliderPhotoGallery;