import React, { FC } from "react";
import { RichText as RichTextCommon } from "src/common";

import SRichTextGeneral, { RichTextGeneralWrapper } from "./RichText.style";

export const RichTextGeneral: FC<{ textContent?: any; css?: any; fragment?: boolean }> = (props) => {

  // If it has nested elements just render a container
  if (props.children && !props.textContent) {
    return (
      <RichTextGeneralWrapper>
        {props.children}
      </RichTextGeneralWrapper>
    );
  }
  return (
    <RichTextCommon overwriteStyle={SRichTextGeneral} {...props} />
  );
};

export { SRichTextGeneral, RichTextCommon as RichText, RichTextGeneral as default };
