import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../../constants/mediaquerys";
import { C_1366_Bold, C_360_Bold, C_768_Bold } from "src/templates/t27/constants/typography";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

const Default = css`
    color: var(--primary-100);
    ${C_360_Bold}

    ${CSSWithOpacityHoverAnimation}

    display: flex;
    align-items: center;

    > svg {
        width: 19px;
        height: 11px;
    }
`;

const Silver = css`
    ${C_768_Bold}
    gap: 4px;

    > svg {
        margin-top: 6px;
        width: 23px;
        height: 17px;
    }
`;

const Gold = css``;

const Platinum = css`
    ${C_1366_Bold}

    > svg {
        width: 30px;
        height: 21px;
    }
`;

const Diamond = css``;

export const SPageItemT27Link = styled.a`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
