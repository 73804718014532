import AOS from "aos";
import React, { FC, useEffect } from "react";

import { IPhotoGallery } from "src/common/childTypes";

import MosaicPhotoGallery from "../MosaicPhotoGallery";

const EffectPhotoGallery: FC<{ item: IPhotoGallery; columns?: number }> = ({ item, columns }) => {
    useEffect(() => {
        AOS.init({
            duration: 700,
            offset: 250,
        });
        AOS.refresh();
    }, []);

    return (
        <>
            <MosaicPhotoGallery item={item} hasAnimation />
        </>
    );
};

export default EffectPhotoGallery;
