import { INavigationItem, IContext } from "src/common";
import {
    IAsset,
    ICallToAction,
    ISlider,
    ITextElement,
    IThemingColor,
    IIcon,
    IPhotoGallery,
} from "src/common/childTypes";

interface IHeaderItemProps {
    title: string;
    link: string;
    headline?: string;
}

export const getHeaderNavItemProps = (item: INavigationItem): IHeaderItemProps | false => {
    const link = item?.link?.[0]?.slug;
    const title = item.label;

    if (!link || !title) {
        return false;
    }

    return {
        link,
        title,
    };
};

export const getIfNewsShouldBeDisplayByDate = (eventDates: {
    endDate: string | Date;
    startDate: string | Date;
}): boolean => {
    if (!eventDates.startDate && !eventDates.endDate) {
        return true;
    } else if (!eventDates.startDate && eventDates.endDate) {
        return new Date() <= new Date(eventDates.endDate);
    } else if (eventDates.startDate && !eventDates.endDate) {
        return new Date() >= new Date(eventDates.startDate);
    } else {
        return new Date() >= new Date(eventDates.startDate) && new Date() <= new Date(eventDates.endDate);
    }
};

export const getPageElements = (
    pageProps: IContext
): (IAsset | ICallToAction | IIcon | ITextElement | ISlider | IThemingColor | IPhotoGallery)[] =>
    (pageProps.Page?.elements ||
        pageProps.PageNews?.elements ||
        pageProps.PageBrand?.elements ||
        pageProps.PageBrandOverview?.elements ||
        pageProps.PageContact?.elements ||
        pageProps.PageGuestbook?.elements ||
        pageProps.PageImprint?.elements ||
        pageProps.PageDateInquiry?.elements ||
        pageProps.PageRepairInquiry?.elements) as any[];
