import React, { Fragment, FunctionComponent, useContext, useState } from "react";
import { Context, Img, getHeaderNavItemProps } from "src/common";
import { css } from "styled-components";
import { BurgerMenu } from "./burgerMenu/BurgerMenu";
import * as styles from "./Header.style";
import { HeaderWrapper, Navigation, Placeholder, HeaderContent } from "./Header.style";
import NavItem, { eNavItemType } from "./navItem/NavItem";
import ResponsiveSidebar from "./responsiveSidebar/ResponsiveSidebar";

const Header: FunctionComponent = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const props = useContext(Context);
  if (!props?.PrimaryNavigation?.items) {
    return null;
  }
  const isActive = !props.slug && true;

  return (
    <Fragment>
      <HeaderWrapper>
        <HeaderContent>
          <a href={"/"}><Img css={styles.logo} contain src={props?.CompanyInformationPublic?.logo?.[0]?.src || null} alt="logo" /></a>
          <BurgerMenu onClick={() => setIsSidebarActive(!isSidebarActive)} />
          <Navigation>
            <NavItem
              type={eNavItemType.header}
              link={""}
              title={"Home"}
              active={isActive}
            />

            {props?.PrimaryNavigation?.items.map((item, i) => {
              const itemProps = getHeaderNavItemProps(item);
              if (!itemProps) {
                return null;
              }
              return (
                <NavItem
                  key={`${i}NavItem`}
                  type={eNavItemType.header}
                  link={itemProps?.link}
                  headline={itemProps?.headline}
                  title={itemProps?.title}
                  external={itemProps.external}
                  subItems={(item.subitems as any)}
                />
              );
            })}
          </Navigation>
        </HeaderContent>
      </HeaderWrapper>
      <Placeholder />
      <ResponsiveSidebar
        isActive={isSidebarActive}

        closeSidebar={() => setIsSidebarActive(false)}>
        <div css={css`margin-top: 16px`}>
          <NavItem
            type={eNavItemType.sidebar}
            link={""}
            title={"Home"}
            active={isActive}
          />
        </div>

        {props?.PrimaryNavigation?.items.map((item, i) => {
          const itemProps = getHeaderNavItemProps(item);

          if (!itemProps) {
            return null;
          }

          return (
            <Fragment
              key={`${i}NavItem2`}
            >
              <NavItem
                type={eNavItemType.sidebar}

                link={itemProps?.link}
                headline={itemProps?.headline}
                title={itemProps?.title}
                subItems={(item.subitems as any)}
              />
            </Fragment>
          );
        })}
      </ResponsiveSidebar>
    </Fragment>
  );
};

export default Header;
