
import React, { FunctionComponent } from "react";
import { ICallToAction } from "src/common";


const RichLinkItem: FunctionComponent<ICallToAction> = props => (
    <div >
    </div>
);

export default RichLinkItem;
