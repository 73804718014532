import styled, { css, keyframes } from "styled-components";

interface ISImageLoadingPlaceholderContainerProps {
  background: string;
}

const pulseAnimation = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
`;

const Default = css<ISImageLoadingPlaceholderContainerProps>`
  width: 100%;
  height: 100%;
  background: ${({ background }) => background && background};
  animation: ${pulseAnimation} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

export const SFancyImageSkeleton = styled.div<ISImageLoadingPlaceholderContainerProps>`
  ${Default};
`;
