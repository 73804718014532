import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css``;

const Silver = css`
    width: 100%;
    display: flex;
    gap: 24px;
    justify-content: center;

    &:not(:last-child) {
        padding-bottom: 48px;
        border-bottom: 1px solid var(--back-3);
    }

    &:not(:first-child) {
        padding-top: 48px;
    }
`;

const Gold = css``;

const Platinum = css`
    gap: 38px;

    &:not(:last-child) {
        padding-bottom: 76px;
    }

    &:not(:first-child) {
        padding-top: 76px;
    }
`;

const Diamond = css``;

export const SOffsetGalleryChunkWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
