export const getLinkFromUrl = (url) => {
  const internal = url?.startsWith("/");

  if (internal && url) {
    return {
      href: url,
    };
  }

  if (url) {
    return {
      rel: "noopener noreferrer",
      href:
        "https://" +
        `${url}`
          .replace("http:", "https:")
          .replace("https://", ""),
      target: "_blank",
    };
  }
};
