import styled, { css } from "styled-components";

import colors from "../../../constants/colors";
import { centeredContent } from "../../../constants/layout";
import MEDIA, { BREAKPOINTS_DM } from "../../../constants/mediaquerys";
import { theme } from "../../../constants/themes";

const height = `${theme.headerHeight}px`;

export const HeaderWrapper: any = styled.div`
    background-color: #000;
    position: fixed;
    width: 100%;
    display: block;
    height: 125px;
    align-items: center;
    z-index: 10;
    @media (${BREAKPOINTS_DM.silver_768}) {
			display: flex;
			height: auto;
    }
`;

export const HeaderContent: any = styled.div`
    ${centeredContent};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    align-items: center;
    height: 100%;
    a {
        &:hover {
            opacity: 1;
        }
    }
`;

export const logo: any = css`
    position: absolute;
		top: 4px;
		right: 0px;
		transform: scale(.185);
    @media (${BREAKPOINTS_DM.silver_768}) {
			top: 2px;
            transform: scale(1.2);
            right: 150px;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
        transform: scale(.9);
        right: 740px;
    }
    @media (${BREAKPOINTS_DM.platinum_1366}) {
        transform: scale(1.2);
        right: 911px;
    }
`;

export const Navigation: any = styled.div`
    display: none;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: calc(100% - 10px);
    padding: 15px;
    width: 75%;
    @media (${BREAKPOINTS_DM.silver_768}) {
        display: flex;
        justify-content: center;
        width: 100%;
    };
    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: flex;
        justify-content: flex-start;
        width: 75%
    };
`;

export const Placeholder: any = styled.div`
    height: ${height};
`;

export const textNav: any = css`
    font-family: "Roboto", sans-serif;
    margin-left: 16px;
    font-size: 13px;
		letter-spacing: 1px;
		font-weight: 400;
    margin-top: 4px;
    opacity: .9;
    &:hover {
        {
            opacity: 1;
        }
    };
		@media (${BREAKPOINTS_DM.silver_768}) {
			font-size: 10px;
			margin-left: 0px;
			font-weight: 600;
		}
`;
