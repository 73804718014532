import { css } from "styled-components";

import colors from "../../../../constants/colors";

const marginLeft = 25;
const dropdownPadding = 16;

export const wrapper: any = css`
  display: inline-block;
  position: relative;
  margin-left: ${marginLeft}px;
  margin-top: 20px;
  height: calc(100% - 20px);
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    > div {
      opacity: 1;
      pointer-events: auto;
    }
  }
  > a {
    display: block;
    cursor: pointer;
    height: 100%;
    span {
        text-transform: uppercase;
        font-weight: 700;
        color: black;
        font-size: 15px;
    }
  }
`;

export const inSidebar_wrapper: any = css`
    overflow: hidden;
    align-items: center;
    border-top: solid rgba(255,255,255,0.6) 1px;
    transition: height .3s linear;
    > a {
      text-transform: uppercase;
      color: white;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      outline: none !important;
      padding-left: 5%;
      font-weight: 300;
      height: 53px;
      &:hover {
        opacity: 1;
        background-color: rgba(255,255,255,0.16);
      }
    }
`;

export const sidebarExpandWrapper: any = css`
    height: 100%;
    width: 53px;
    border-left: solid rgba(255,255,255,0.4) 1px;
    background-color: #242424;
    display: flex;
    svg {
        fill: white;
        width: 32px;
        padding: 2px 11px;
        margin: auto;
        transform: none;
        transition: transform ease .1s;
        &:hover {
          opacity: .5;
        }
    }
`;

export const dropdownWrapper: any = css`
    position: absolute;
    padding: 20px ${dropdownPadding}px 10px;
    left: ${-dropdownPadding}px;
    top: 20px;
    background-color: white;
    opacity: 0;
    width: 200px;
    overflow: hidden;
    pointer-events: none;
    transition: opacity ease .3s;
`;

export const subItemsWrapper: any = css`
    overflow: hidden;
`;

export const dropDrownItem: any = css`
    display: block;
    color: black;
    padding: 6px 0 7px;
    border-bottom: solid black 1px;
    font-size: 14px;
    opacity: .6;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &:last-child {
      border-bottom: none;
    }
`;

export const sidebar_SubItem: any = css`
    display: block;
    padding: 0 10%;
    height: 53px;
    line-height: 50px !important;
    color: white;
    opacity: .7;
    border-top: solid rgba(255,255,255,0.3) 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background-color: rgba(255,255,255,0.11);
    }
`;
