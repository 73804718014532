import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const topBarWrapper: any = css`
  display: flex;
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    flex-direction: column;
  }
`;

export const dealerSearchWrapper: any = css`
  background-color: ${(props) => props.theme.primary};
  &:before {
    border-bottom: 1px solid ${(props) => props.theme.primary};
  }
  padding: 30px 27px 45px !important;
`;
export const serviceNummer: any = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.primary};
  padding: 30px 27px 45px !important;
  p {
    text-align: center;
    &:first-child {
      margin-bottom: 8px;
    }
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 42px;
      height: 42px;
    }
    p {
      font-size: 30px;
    }
  }
  * {
    color: white;
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    width: 100% !important;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    margin-bottom: 0;
    border-top: solid white 1px;
    border-bottom: solid white 1px;
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: center;
    p {
      margin-bottom: 0 !important;
      font-size: 20px;
    }
    > div {
      margin-left: 10px;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 20px;
      }
    }
    &:before {
      display: none;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: column !important;
  }
`;

export const inquiriesWrapper: any = css`
  display: flex;
  margin-left: 40px;
  justify-content: space-between;
  flex: 1;
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin-left: 0;
    flex: auto;
    width: 100%;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-direction: column;
  }
`;

export const inquiryElement: any = css`
  cursor: pointer;
  background-color: ${(props) => props.theme.secondary};
  padding: 0 !important;
  width: calc(50% - 20px);
  &:before {
    border-color: ${(props) => props.theme.secondary} !important;
  }
  > div {
    border: solid 1px ${(props) => props.theme.secondary};
  }
  h3 {
    font-size: 22.5px;
    display: block;
    width: 100%;
    background-color: white;
    padding: 10px 27px;
    color: #54aa9e;
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin-left: 0;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 100% !important;
    &:before {
      display: none;
    }
    > div {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      align-items: center !important;
      h3 {
        margin: 10px;
        width: auto;
      }
    }
  }
  @media (max-width: 600px) {
    > div {
      flex-direction: column-reverse;
      h3 {
        margin-top: 0;
      }
    }
  }
`;

export const pageRepairInquiry: any = css`
  margin-left: 20px;
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin-left: 0;
  }
`;

export const pageDateInquiry: any = css`
  margin-right: 20px;
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin-right: 0;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    margin-bottom: 0 !important;
    border-bottom: 1px solid white;
  }
`;

export const inquiryElementRichtext: any = css`
  padding: 10px 27px 27px;
  color: white;
  * {
    text-align: center !important;
    color: white;
    white-space: nowrap;
    width: 100%;
  }
  p {
    color: inherit;
  }
  a {
    color: ${(props) => props.theme.primary};
    font-weight: normal;
    &:hover {
      text-decoration: underline;
    }
  }
  a p {
    &:hover {
      text-decoration: underline;
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 10px;
  }
`;
