import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import Address from "./Address";
import { FooterWrapper } from "./Footer.style";
import SocialShare from "./SocialShare";
import Divider from "../Divider/Divider";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";

interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => {
  const props = useContext(Context);
  const allFooterLinksForUpperWrapper: IFooterLinkInColumnList[] = [];
  const footerNavItems = props.Footer?.Navigationspunkte || [];
  const companyInformation = props.CompanyInformationPublic;

  for (const item of props.PrimaryNavigation?.items || []) {
    if (item?.label && item?.link?.[0]?.slug) {
      allFooterLinksForUpperWrapper.push({
        title: item.label || item?.link?.[0]?.nameInNavigation,
        link: item?.link?.[0]?.slug,
        isBold: true,
      });
    }

    if (item?.subitems) {
      for (const subItem of item.subitems) {
        if (subItem?.nameInNavigation && subItem?.slug) {
          allFooterLinksForUpperWrapper.push({
            title: subItem?.nameInNavigation,
            link: subItem?.slug,
            isBold: false,
          });
        }
      }
    }
  }

  return (
    <>
    <Divider />
    <FooterWrapper>
      <div className="flex">
        <Address items={footerNavItems} />
        <SocialShare companyInformation={companyInformation} />
        </div>
      <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "8px",
            fontSize: "12px",
          }}
        >
          <p>
            <CopyrightNotice />
          </p>
        </div>
    </FooterWrapper>
    </>
  );
};

export default Footer;
