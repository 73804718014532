import styled from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const Wrapper: any = styled.div<{ background: string; color: string }>`
  z-index: 215;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 14px;
  background-color: ${({ background }) => (background ? background : "white")};
  position: fixed;
  bottom: 0;
  box-sizing: content-box;
  box-shadow: 2px 2px 5px rgba(50, 50, 50, 0.5);
  a {
    color: ${({ color }) => (color ? color : colors.brown)};
    
  }

  @media (${BREAKPOINTS_DM.under_gold_1024}) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    width: calc(100% - 28px);
    a{
          margin: 0 !important;
    }
    a:not(.boxicon) {
      padding: 0 !important;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 30px;
    bottom: initial;
    right: 0;
    top: 130px;
    height: initial;
    a.boxicon {
      margin-top: 4px;
    }
    a.boxicon ~ a.boxicon {
      margin-top: 20px;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    min-height: 200px;
  }

  a.boxicon {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 4px 4px;
    color: ${({ background }) => (background ? background : "white")};
    background-color: ${({ color }) => (color ? color : colors.brown)};
  }

  a.boxicon {
    svg {
      width: 19px;
      height: 18px;
      color: ${({ background }) => (background ? background : "white")};
    }
  }
`;
