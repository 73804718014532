import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const NavigationContainer: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 81px;
  margin: 0 auto;
  width: 100%;
  z-index: 120;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 105px;
    padding: 0 25px;
  }
`;

export const HeaderContainer: any = styled.div<{ isScrolling: boolean }>`
  background-color: ${({ isScrolling }) =>
    isScrolling ? "#fff" : "transparent"};
  ${({ isScrolling }) =>
    isScrolling &&
    "box-shadow: 0 0 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)"};
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 121;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0 35px;
  }
`;

export const Logo: any = styled.img`
  position: absolute;
  top: 10px;
  max-height: 58px;
  max-width: 60%;
  z-index: 120;
  margin-left: 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    max-height: none;
    position: initial;
    max-width: unset;
    height: 75px;
    width: unset;
    margin-left: 12px;
  }
`;
