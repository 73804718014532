import { css } from "styled-components";

export const wrapper: any = css`
    display: inline-block;
    position: relative;
    margin: 0 15px;
    padding: 0 16px;
    background-color: white;
    border: solid 1px ${(props) => props.theme.primary};
    border-radius: 8px;
    height: 36px;
    box-shadow: 0 20px 40px -13px rgba(0, 0, 0, 0.5);
    &:hover {
        background-color: ${(props) => props.theme.primary} !important;
        border-radius: 8px 8px 0 0;
        > a span {
            color: white;
        }
        > div {
            opacity: 1;
            pointer-events: auto;
        }
    }
    > a {
        hyphens: none;
        display: inline-block;
        cursor: pointer;
        height: 100%;
        span {
            text-transform: uppercase;
            color: ${(props) => props.theme.primary};
            line-height: 35px !important;
        }
        .subItemActive {
            color: ${(props) => props.theme.seconday} !important;
            background: ${(props) => props.theme.primary} !important;
        }
    }
`;

export const wrapperActive: any = css`
    ${wrapper};
    background-color: ${(props) => props.theme.palette.red};

    &:hover {
        background-color: ${(props) => props.theme.palette.red}!important;
    }
`;

export const activeLink: any = css`
    span {
        color: white !important;
    }
`;

export const inSidebar_wrapper: any = css`
    overflow: hidden;
    align-items: center;
    border-top: solid rgba(255, 255, 255, 0.6) 1px;
    transition: height 0.3s linear;
    > a {
        color: white;
        text-transform: uppercase;
        color: ${(props) => props.theme.seconday} !important;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        outline: none !important;
        padding-left: 5%;
        height: 53px;
        &:hover {
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.12);
        }
    }
`;

export const sidebarExpandWrapper: any = css`
    height: 100%;
    width: 53px;
    border-left: solid rgba(255, 255, 255, 0.4) 1px;
    background-color: #161616;
    display: flex;
    svg {
        fill: white;
        width: 38px;
        padding: 2px 11px;
        margin: auto;
        transform: none;
        transition: transform ease 0.1s;
        &:hover {
            opacity: 0.5;
        }
    }
`;

export const dropdownWrapper: any = css`
    position: absolute;
    min-width: calc(100% + 2px);
    z-index: 8;
    left: -1px;
    top: 35px;
    border: solid 1px ${(props) => props.theme.primary};
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    border-radius: 0 0 8px 8px;
`;

export const subItemsWrapper: any = css`
    overflow: hidden;
`;

export const dropDrownItem: any = css`
    display: block;
    border-top: solid 1px ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};
    white-space: nowrap;
    height: 36px;
    padding: 0 6px;
    line-height: 34px !important;
    text-align: center;
    cursor: pointer;
    &:first-child {
        border-top: none;
    }
    &:hover {
        background-color: ${(props) => props.theme.primary} !important;
        color: white;
    }
`;

export const sidebar_SubItem: any = css`
    display: block;
    padding: 0 10%;
    height: 53px;
    line-height: 50px !important;
    color: white;
    opacity: 0.7;
    border-top: solid rgba(255, 255, 255, 0.3) 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        background-color: rgba(255, 255, 255, 0.11);
    }
`;
