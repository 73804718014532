import { css } from "styled-components";

import { BREAKPOINTS_DM } from "./mediaquerys";

export const centeredContent: any = css`
    max-width: 1600px;
    width: 92%;
    margin: 0 auto;
`;

export const flexWrapper: any = css`
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-right: -13.5px;
    > * {
      margin-bottom: 13.5px;
    }
`;

const flexItem: any = css`
    flex-basis: calc(50% - 13.5px);
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 13.5px;
    margin-bottom: 13.5px;
    padding: 27px;
`;

export const flexItem25: any = css`
    ${flexItem};
    flex-basis: calc(25% - 13.5px);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        flex-basis: calc(50% - 13.5px);
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        flex-basis: 100%;
    }
`;

export const flexItem50: any = css`
    ${flexItem};
    flex-basis: calc(50% - 13.5px);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        flex-basis: 100%;
    }
`;

export const flexItem100: any = css`
    ${flexItem};
    flex-basis: 100%;
`;
