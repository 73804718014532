// @ts-nocheck
import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const BurguerIcon: any = styled.div<{ isMenuActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 50px;
  font-size: 25px;
  color: #fff;
  background: #92c2d6;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: transform 0.8s ease;
  transform: ${({ isMenuActive }) =>
    isMenuActive ? "translate3d(-60vw,0,0)" : "translate3d(0,0,0)"};
  cursor: pointer;
  z-index: 999;
  .close {
    display: ${({ isMenuActive }) => (isMenuActive ? "none" : "none")};
  }
  .open {
    display: ${({ isMenuActive }) => (isMenuActive ? "initial" : "initial")};
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    transform: ${({ isMenuActive }) =>
      isMenuActive ? "translate3d(-51vw,0,0)" : "translate3d(0,0,0)"};
  }
`;

export const NavigationLink: FC<any> = styled.li<{ isActive: boolean }>`
  display: flex;
  width: calc(100% - 50px);
  margin-bottom: 1px;
  border-bottom: 1px solid #fff;
  margin-left: 10px;
  > a {
    font-size: 18px;
    padding: 12px 16px 6px;
    text-transform: uppercase;
    color: #fff;
    /* font-size: 18px; */
    /* padding: 15px 21px 7.5px; */
    letter-spacing: 0.36px;
    font-weight: 400;
    line-height: 25.2px;
    text-transform: uppercase;
    width: fit-content;
    text-align: center;
    font-stretch: 100%;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      background-color: ${({ isActive }) => isActive && "#575656"};
      &:hover {
        background-color: #575656;
      }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      color: #fff;
    }
  }
  div.icon {
    color: #000;
    padding: 20px 50% 0px 10px;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      display: none;
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    border-bottom: none;
    margin-bottom: 0;
    margin-left: 0px;
    width: fit-content;
  }
`;
export const SubItem: any = styled.div`
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  background-color: #1d1d1b;
  padding: 2px 5px 2px 0;
  height: auto;
  margin-bottom: 2px;
`;

export const IconWrapper: any = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  width: 20%;
  padding-left: 10px;
  padding-top: 8px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const Icon: any = styled.div<{ isShowSubItems: boolean }>`
  transform: ${({ isShowSubItems }) => isShowSubItems && "rotate(90deg)"};
`;

// ${({ isMenuActive }) => isMenuActive ? "pointer-events: all" : "pointer-events: none"};
export const NavigationLinks: any = styled.div<{ isMenuActive: boolean }>`
  background-color: #92c2d6;
  transition: transform 0.8s ease;
  overflow-x: hidden;
  transform: ${({ isMenuActive }) =>
    isMenuActive ? "translate3d(20vw,0,0)" : "translate3d(100vw,0,0)"};
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 50px 0px 20px;
  overflow-y: auto;
  ${BurguerIcon} {
    display: none;
  }

  ul {
    position: relative;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    list-style: none;
    overflow-y: auto;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    transform: ${({ isMenuActive }) =>
      isMenuActive ? "translate3d(40vw,0,0)" : "translate3d(120vw,0,0)"};
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: unset;
    transform: translate3d(0, 0, 0);
    padding: 0 30px;
    top: 0;
    ${NavigationLink} {
      display: flex;
    }

    ${BurguerIcon} {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: fit-content;
      height: unset;
    }
  }
  @media (max-width: 1100px) {
    padding: 0 44px;
  }
`;

export const NavigationContainer: FC<any> = styled.div<{
  isMenuActive: boolean;
}>`
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  width: 100%;
  pointer-events: ${({ isMenuActive }) => (isMenuActive ? "all" : "none")};
  > * {
    pointer-events: all;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: 1100px;
    margin: 24px auto 0;
    ${BurguerIcon} {
      display: none;
    }
  }
`;

export const SubItemWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    line-height: 31.5px;
    margin-bottom: 2px;
    padding-left: 21px;
    width: fit-content;
    text-align: left;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      background-color: ${({ isActive }) => isActive && "#575656"};
      &:hover {
        background-color: #575656;
      }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      color: #fff;
    }
  }
`;
