import React, { FC, useContext } from "react";
import { IPhotoGallery } from "src/common/childTypes";
import dynamic from "next/dynamic";
import { SPhotoGallery2024 } from "./SPhotoGallery2024";
import { Context } from "src/common";

const SliderWithFocalPoint = dynamic(
    async () => import("src/common/BaseComponents/SliderWithFocalPoint/SliderWithFocalPoint"),
    { ssr: false }
);
const EffectPhotoGallery = dynamic(async () => import("./EffectPhotoGallery"), { ssr: false });
const MasonryPhotoGallery = dynamic(async () => import("./MasonryPhotoGallery"), { ssr: false });
const MosaicPhotoGallery = dynamic(async () => import("./MosaicPhotoGallery"), { ssr: false });
const ThumbnailSlider = dynamic(async () => import("./ThumbnailSlider/ThumbnailSlider"), { ssr: false });
const ComparisonPhotoGallery = dynamic(async () => import("./ComparisonPhotoGallery"), { ssr: false });
const OffsetGallery = dynamic(async () => import("./OffsetGallery/OffsetGallery"), { ssr: false });

export enum IPhotoGalleryVisualization {
    Standard = "standard",
    Slideshowwithoutthumbnails = "slideshowwithoutthumbnails",
    Gallerywiththumbnails = "gallerywiththumbnails",
    Gallerybeforeafter = "gallerybeforeafter",
    Gallerywithimagedescriptionundermainimages = "gallerywithimagedescriptionundermainimages",
    Riverimage = "riverimage",
    Mosaicimage = "mosaicimage",
    Fadeeffect = "fadeeffect",
    Flipeffect = "flipeffect",
    Zoomeffect = "zoomeffect",
    Offset = "offset",
}

const getVisualization = (item: any) => {
    switch (true) {
        case item.visualization === IPhotoGalleryVisualization.Standard ||
            (item.visualization as any) === "Standard" ||
            (item.visualization as any) === "Standard":
            return "standard";
        case item.visualization === IPhotoGalleryVisualization.Slideshowwithoutthumbnails ||
            (item.visualization as any) === "Slideshowwithoutthumbnails" ||
            (item.visualization as any) === "Slideshow ohne Thumbnails":
            return "slideshowwithoutthumbnails";
        case item.visualization === IPhotoGalleryVisualization.Gallerywiththumbnails ||
            (item.visualization as any) === "Gallerywiththumbnails" ||
            (item.visualization as any) === "Galerie mit Thumbnails":
            return "gallerywiththumbnails";
        case item.visualization === IPhotoGalleryVisualization.Gallerybeforeafter ||
            (item.visualization as any) === "Gallerybeforeafter" ||
            (item.visualization as any) === "Galerie vorher/nachher":
            return "gallerybeforeafter";
        case item.visualization === IPhotoGalleryVisualization.Gallerywithimagedescriptionundermainimages ||
            (item.visualization as any) === "Gallerywithimagedescriptionundermainimages" ||
            (item.visualization as any) === "Galerie mit Bildbeschreibung unter Hauptbildern":
            return "gallerywithimagedescriptionundermainimages";
        case item.visualization === IPhotoGalleryVisualization.Riverimage ||
            (item.visualization as any) === "Riverimage" ||
            (item.visualization as any) === "Flussbild":
            return "riverimage";
        case item.visualization === IPhotoGalleryVisualization.Mosaicimage ||
            (item.visualization as any) === "Mosaicimage" ||
            (item.visualization as any) === "Mosaikbild":
            return "mosaicimage";
        case item.visualization === IPhotoGalleryVisualization.Fadeeffect ||
            (item.visualization as any) === "Fadeeffect" ||
            (item.visualization as any) === "Fade-Effekt":
            return "fadeeffect";
        case item.visualization === IPhotoGalleryVisualization.Flipeffect ||
            (item.visualization as any) === "Flipeffect" ||
            (item.visualization as any) === "Flip-Effekt":
            return "flipeffect";
        case item.visualization === IPhotoGalleryVisualization.Zoomeffect ||
            (item.visualization as any) === "Zoomeffect" ||
            (item.visualization as any) === "Zoom-Effekt":
            return "zoomeffect";
        case item.visualization === IPhotoGalleryVisualization.Offset ||
            (item.visualization as any) === "Offset" ||
            (item.visualization as any) === "Versatz":
            return "offset";
        default:
            return "standard";
    }
};

const PhotoGallery2024: FC<{ item: IPhotoGallery; columns?: number }> = ({ item, columns }) => {
    const props = useContext(Context);

    if (!item || !item.elements) {
        return null;
    }

    const visualization = getVisualization(item);

    const GalleryRenderer = () => {
        switch (visualization) {
            case "standard":
                return (
                    <div className="pg pg_standard">
                        <SliderWithFocalPoint {...item} __typename="SliderComp" hasContainerPaddingWrapper={false} />
                    </div>
                );
            case "slideshowwithoutthumbnails":
                return (
                    <div className="pg pg_slideshowwithoutthumbnails">
                        <SliderWithFocalPoint
                            {...item}
                            __typename="SliderComp"
                            hasContainerPaddingWrapper={false}
                            shouldShowSliderDots={false}
                        />
                    </div>
                );
            case "gallerywiththumbnails":
                return (
                    <div className="pg pg_gallerywiththumbnails">
                        <ThumbnailSlider {...item} />
                    </div>
                );
            case "gallerywithimagedescriptionundermainimages":
                return (
                    <div className="pg pg_gallerywithimagedescriptionundermainimages">
                        <ThumbnailSlider {...item} shouldHideThumbnailSlider />
                    </div>
                );
            case "gallerybeforeafter":
                return (
                    <div className="pg pg_gallerybeforeafter">
                        <ComparisonPhotoGallery item={item} />
                    </div>
                );
            case "riverimage":
                return (
                    <div className="pg pg_riverimage">
                        <MasonryPhotoGallery item={item} />
                    </div>
                );
            case "mosaicimage":
                return (
                    <div className="pg pg_mosaicimage">
                        <MosaicPhotoGallery item={item} />
                    </div>
                );
            case "fadeeffect":
                return (
                    <div className="pg pg_fadeeffect">
                        <EffectPhotoGallery item={item} />
                    </div>
                );
            case "flipeffect":
                return (
                    <div className="pg pg_flipeffect">
                        <EffectPhotoGallery item={item} />
                    </div>
                );
            case "zoomeffect":
                return (
                    <div className="pg pg_zoomeffect">
                        <EffectPhotoGallery item={item} />
                    </div>
                );
            case "offset":
                return (
                    <div className="pg pg_offset">
                        <OffsetGallery item={item} />
                    </div>
                );
            default:
                return (
                    <div className="pg pg_default">
                        <ThumbnailSlider {...item} />
                    </div>
                );
        }
    };

    const backgroundColor = props?.Settings?.themeBackgroundColor ?? "#ffffff";
    const primaryColor = props?.Settings?.themeColorPrimary ?? "#FF0001";
    const shouldOverwriteGalleryVariables = props?.Settings?.template !== `${"t27"}`;

    return shouldOverwriteGalleryVariables ? (
        <SPhotoGallery2024
            className="overwrite-wrapper"
            backgroundColor={backgroundColor}
            primaryColor={primaryColor}
            shouldOverwriteStyle
        >
            <GalleryRenderer />
        </SPhotoGallery2024>
    ) : (
        <GalleryRenderer />
    );
};

export default PhotoGallery2024;
