import styled from "styled-components";

export const SButton = styled.div`

    > button {
        margin: 0px 8px;
        background-color: #54AA9E;
        color: #54AA9E;
        border-radius: 50%;
        height: 12px; 
        width: 12px;
    }

    button.active {
        background-color: ${props => props.theme.palette.productPrime};
    }
`;
