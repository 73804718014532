import Head from "next/head";
const regexScript = /src="([^"]*)"/gm;

export const CustomHtmlHandler = (props) => {
  const host = props?.host;
  let ccmScript = props?.ccmScript;
  if (!ccmScript && host == '14-dev-template.dev.gedk.caisy.site') {
    ccmScript = '<script src="https://www.gedk-consent.he-webpack.de/public/app.js?apiKey=8efd31764a600effe4fffdf08fe1e6548f7a27ccae946dec&amp;domain=cf24eed" referrerpolicy="origin"></script>'
  }

  const disableCookieBanner = props?.Settings?.disableCookieBanner
  const ccmActive = props?.CompanyInformationPrivate?.ccm?.active;
  if (typeof window !== "undefined") {
    console.log(` ccm`, { ccmActive, ccmScript});
  }

  if (!disableCookieBanner && ccmScript && ccmActive) {
    const src = ccmScript.match(regexScript)[0].split('"')?.[1];
    if (src) {
      return (
        <>
          <Head>
            // @ts-ignore
            <script
              src={src.replace("&amp;", "&")}
              // @ts-ignore
              referrerPolicy="origin"
            ></script>
          </Head>
          {props?.CompanyInformationPrivate?.customHtml?.value ? (
            <div
              id="customHtml"
              dangerouslySetInnerHTML={{
                __html: props?.CompanyInformationPrivate?.customHtml?.value,
              }}
            />
          ) : null}
        </>
      );
    }
  }

  return (
    <>
      {props?.CompanyInformationPrivate?.customHtml?.value ? (
        <div
          id="customHtml"
          dangerouslySetInnerHTML={{
            __html: props?.CompanyInformationPrivate?.customHtml?.value,
          }}
        />
      ) : null}
    </>
  );
};
