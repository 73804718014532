import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../../constants/mediaquerys";
import { H2_1366_Bold, H2_360_Bold, H2_768_Bold } from "src/templates/t27/constants/typography";

const Default = css`
    ${H2_360_Bold}
    color: var(--front-2);
`;

const Silver = css`
    ${H2_768_Bold}
`;

const Gold = css``;

const Platinum = css`
    ${H2_1366_Bold}
`;

const Diamond = css``;

export const SPageItemT27Title = styled.h2`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
