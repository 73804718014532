import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context, LogoBannerHorizontal } from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";


import Address from "./Address";
import { FooterWrapper, ContentSide, Content, ContentHead, Title } from "./Footer.style";
import Navigation from "./Navigation";
import SocialShare from "./SocialShare";
interface IFooterLinkInColumnList 
{
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => 
{
  const props = useContext(Context);
  const allFooterLinksForUpperWrapper: IFooterLinkInColumnList[] = [];
  const companyInformation = props.CompanyInformationPublic;
  const footerNavItems = props.Footer?.Navigationspunkte || [];
  const impressum = props.PageImprint || {};

  for(const item of props.PrimaryNavigation?.items || []) 
  {
    if(item?.label && item?.link?.[0]?.slug) 
    {
      allFooterLinksForUpperWrapper.push({
        title: item.label || item?.link?.[0]?.nameInNavigation,
        link: item?.link?.[0]?.slug,
        isBold: true
      });
    }
  }

  return (
    <FooterWrapper>
      {/* footer top */}
      <ContentHead>
        {/* left side */}
        <ContentSide>
          <div className="content">
            <ContentSide withOutPaddingTop>
              <div className="column">
                <Title><h3>Empfohlen von</h3></Title>
                <img src={"/images/kompass.png"} alt={"Küchenkompass"} />
              </div>
            </ContentSide>
            <ContentSide withOutPaddingTop>
              <div className="column">
                <Title><h3>Partner von</h3></Title>
                <img className="max-width" src={"/images/kg_linebadge.png"} alt={"Linebadge"} />
              </div>
            </ContentSide>
          </div>
        </ContentSide>

        {/* right side */}
        <ContentSide>
          <Title><h3>Unsere Marken</h3></Title>
          <LogoBannerHorizontal />
        </ContentSide>
      </ContentHead>
      
      {/* footer center */}
      <Content>
        {/* left side */}
        <ContentSide>
          <div className="column">
            <Title><h4>{getContactCompanyNameShort()}</h4></Title>
            <p>
              {`Willkommen im Küchenstudio ${getContactCompanyNameShort()} in ${companyInformation?.place}. 
              Die beste Adresse in Sachen Küche. 
              Als Partner der Küchengilde sind wir eines von mehreren erstklassigen, 
              inhabergeführten Küchenhäusern mit modernster Ausstattung. 
              Wir arbeiten streng nach unserem selbst auferlegten Kodex in bewährter
                Handwerkstradition und geben die gut`}
            </p>
          </div>
        </ContentSide>
        
        {/* right side */}
        <ContentSide>
          <div className="content">
            {/* left side */}
            <ContentSide>
              <div className="column">
                <Title><h4>Links</h4></Title>
                <Navigation items={allFooterLinksForUpperWrapper} />
              </div>
            </ContentSide>

            {/* right side */}
            <ContentSide>
              <div className="column">
                <Title><h4>Kontakt</h4></Title>
                <p>{getContactCompanyNameShort()}</p>
                <p>{companyInformation?.street}</p>
                <p>{companyInformation?.postcode} {companyInformation?.place}</p>
                <br/>
                <p>{`Telefon: ${companyInformation?.phone}`}</p>
                <p>{`E-mail: ${companyInformation?.email}`}</p>
              </div>
            </ContentSide>
          </div>
        </ContentSide>
      </Content>
      
      {/* footer bottom */}
      <Address companyInformation={companyInformation} items={footerNavItems} impressum={impressum}/>
    </FooterWrapper>
  );
};

export default Footer;
