// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";
import { theme } from "../../../../constants/themes";

export const Wrapper: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 475px;
  border-right: 2px solid #fff;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 475px;
  }
`;

export const SlideWrapper: any = styled.div<{ src: string; newpage: boolean }>`
  &,
  > div {
    height: 475px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  display: flex;
  align-items: center;
  justiyfy-content: center;
  background-size: 100% 100%;
  background-position: center;
  object-fit: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ withImage }) => withImage && "justify-content: center;"}

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  ${({ withImage }) => withImage && "justify-content: center;"}

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }

  img {
    transition: all 0.8s;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
  position: relative;
  .imgwrapper {
    z-index: 2;
    ::before {
      filter: brightness(45%);
      background-color: var(--color-primary);
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    > div {
      position: relative;
      z-index: 2;
    }
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    div {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }
  > *:not(.imgwrapper) {
    z-index: 3;
    position: relative;
  }
`;

export const ContentWrapper: any = styled.div`
  padding: 15px 15px;
  width: 100%;
`;

export const ImgWrapper: any = styled.div`
  object-fit: cover;
  width: 100%;
  height: 475px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    height: 475px;
  }
`;

export const Counter: any = styled.div`
  > h3 {
    &:not(.fake){
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 21.6px;
      margin: 5px 0 10px;
      text-align center;
    }
  }
`;

export const Content: any = styled.div`
  color: #fff;
  line-height: 140%;
  overflow: hidden;
  font-size: 14px;
  h3 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.32px;
  }
  h3.title {
    color: rgb(255, 255, 255);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    margin-bottom: 10px;
    margin-top: 5px;
    text-align: center;
  }
  h2,
  h4,
  p {
    text-align: center;
    span {
      font-family: Roboto, sans-serif;
      font-size: 28px;
      font-weight: 400;
      line-height: 33.6px;
    }
    margin: 5px 0 10px;
    padding-bottom: 20px;
    font-weight: 300;
    font-size: 13px;
  }
  > a {
    margin: 10px auto 0;
    padding: 7px 16px;
    background: ${theme.palette.default};
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    width: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    span {
      letter-spacing: 1px;
      line-height: 22px;
      color: #fff;
      font-size: 13px;
      font-weight: 100;
    }
    &:hover {
      background-color: var(--color-primary-light);
    }
  }
`;
export const Spacer: any = styled.div`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 7%;
  }
`;
