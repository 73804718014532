
import React, { FunctionComponent, useContext } from "react";
import { Context, IContext } from "src/common";

import { HaderContainer, Logo, HeaderContent } from "./Header.styles";
import Navigation, { NavigationIcons } from "./Navigation/Navigation";

const Header: FunctionComponent<{onShowMenu: any; isShowMenu: boolean}> = ({ onShowMenu, isShowMenu }) =>
{
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];

  return (
    <HaderContainer>
      <HeaderContent>
        <NavigationIcons onShowMenu={onShowMenu} isShowMenu={isShowMenu}/>
        {logo && <a href="/"><Logo src={logo.src} /></a>}
      </HeaderContent>
      <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} onShowMenu={onShowMenu} isShowMenu={isShowMenu} />
    </HaderContainer>
  );
};

export default Header;
