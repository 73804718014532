import React, { FunctionComponent, ReactNode } from "react";
import SSpacer from "./Styles/SSpacer";

export interface ISpacer {
    bronze: number;
    silver?: number;
    gold?: number;
    platinum?: number;
    diamond?: number;
}

const Spacer: FunctionComponent<ISpacer> = (props) => {
    return <SSpacer {...props}>{props.children}</SSpacer>;
};
export default Spacer;
