import dynamic from "next/dynamic";
import React, { FC } from "react";
const SlickSlider = dynamic(() => import("react-slick"), { ssr: false });

import { IAsset, Img } from "src/common";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import SSlickBase from "../../../utils/slick/SSlickBase";
import { LazyLoadTypes } from "react-slick";

const STANDARD_SETTINGS = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 6000,
  autoplay: true,
  fade: false,
  lazyLoad: "progressive" as LazyLoadTypes,
  adaptiveHeight: false,
};

export const StandardSlider: FC<{ elements: IAsset[] }> = (props) => {
  return (
    <div
      css={`
        ${SSlickBase}
        ${SSlickTheme}
      `}
      data-gallery-slider
    >
      <SlickSlider {...STANDARD_SETTINGS}>
        {props?.elements.map((element, i) => (
          <div key={`image-${i}`} data-image>
            <Img quality={1200} key={`sliderimage${i}`} src={element?.src} alt={element.description ?? "image"} />
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};

export default StandardSlider;
