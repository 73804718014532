import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../..";
import { SFullWidthBannerChildren } from "./styles/SFullWidthBannerChildren";
import { SFullWidthBannerContainer } from "./styles/SFullWidthBannerContainer";
import { SFullWidthBannerSpacer } from "./styles/SFullWidthBannerSpacer";

const animationTransitionDelayMs = 2260;

const timeoutDurationMs = 7000 + animationTransitionDelayMs;

export const FullWidthBannerLazy: any = ({ children }) => {
    const { fullWidthBanner } = useContext(Context);
    const externalLinkAddress = (fullWidthBanner as any)?.linksTo?.[0]?.externalLinkAddress?.replace(
        "http://",
        "https://"
    );
    const linkSlug = (fullWidthBanner as any)?.linksTo?.[0]?.slug;

    // DIMENSIONS
    // BRONZE: 375x175
    // GOLD: 1024x150
    const [{ active }, setCurrState] = useState({
        active: false,
    });

    useEffect(() => {
        setCurrState({ active: true });
    }, []);

    useEffect(() => {
        let t = setTimeout(() => {
            setCurrState({ active: false });
        }, timeoutDurationMs);
        return () => clearTimeout(t);
    }, []);

    const onClose = (e) => {
        e.preventDefault();
        setCurrState({ active: false });
    };

    const hasLink = externalLinkAddress || linkSlug;

    const handleBannerClick = (e) => {
        const clickedEl = e.target;
        const closeBtn = document.getElementById("bfwclose");

        if (closeBtn && clickedEl && closeBtn.contains(clickedEl)) {
            return;
        }

        e.preventDefault();
        if (!hasLink) {
            return;
        }
        if (externalLinkAddress && externalLinkAddress.includes("https://")) {
            window.open(externalLinkAddress, "_blank", "noopener,noreferrer");
            return;
        }
        if (externalLinkAddress) {
            window.location.href = externalLinkAddress;
            return;
        }
        if (linkSlug) {
            window.location.href = `/${linkSlug}`;
            return;
        }
    };

    return (
        <>
            <SFullWidthBannerSpacer active={active} animationTransitionDelayMs={animationTransitionDelayMs}>
                <div className="bg" />
            </SFullWidthBannerSpacer>
            <SFullWidthBannerContainer
                active={active}
                animationTransitionDelayMs={animationTransitionDelayMs}
                hasLink={hasLink}
                onClick={handleBannerClick}
                id="full-width-banner"
            >
                <div className="bg" />
                <div className="bannerimg">
                    {fullWidthBanner.bronzeImage && (
                        <img
                            className="bronzeImage"
                            src={active ? fullWidthBanner.bronzeImage?.src + `?w=${375 * 4}&h=${175 * 4}` : null}
                        ></img>
                    )}
                    {fullWidthBanner.goldImage && (
                        <img
                            className="goldImage"
                            src={active ? fullWidthBanner.goldImage?.src + `?w=${1024 * 4}&h=${150 * 4}` : null}
                        ></img>
                    )}
                    {fullWidthBanner.diamondImage && (
                        <img
                            className="diamondImage"
                            src={active ? fullWidthBanner.diamondImage?.src + `?w=${1920 * 2}&h=${150 * 2}` : null}
                        ></img>
                    )}
                </div>
                <div className="close" id="bfwclose" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
                        <g clipPath="url(#clip0_1_3)">
                            <circle cx="20" cy="20" r="20" fill="#1E1E1E"></circle>
                            <path stroke="#fff" strokeWidth="2" d="M9.707 10.293L30.92 31.506"></path>
                            <path stroke="#fff" strokeWidth="2" d="M9.293 31.506L30.506 10.293"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_1_3">
                                <path fill="#fff" d="M0 0H40V40H0z"></path>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </SFullWidthBannerContainer>
            <SFullWidthBannerChildren>{children}</SFullWidthBannerChildren>
        </>
    );
};
