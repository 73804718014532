import React from "react";
import { getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";

import { SProductItemT17, Title, TextWrapper, TextPrice, TextSecondary, Icon } from "./Styles/SProductItemT17";

export const ProductItemT17 = ({ item, i }) => 
{
  if(!item) 
  {
    return item;
  }
  const product = (item?.link?.[0]) as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const price = link?.ourPrice;
  const slug = link?.slug;

  if(isProduct && product?.acceptAGB && !getIfNewsShouldBeDisplayByDate({ startDate: product?.startDate, endDate: product?.endDate })) 
  {
    return null;
  }
  return (
    <SProductItemT17 >
      <Title>{item?.title}</Title>
      <Img src={item.thumbnail}></Img>
      <TextWrapper>
        <a href={`/${isProduct ? "produkt/" : ""}${slug}`}>
          <div className="bg-pink">
            <div className="block">
              <TextSecondary>
                Jetzt für nur
              </TextSecondary>
              {price && <TextPrice>{price} Euro</TextPrice>}
            </div>
            <TextSecondary>
              <Icon><i className="fa fa-arrow-right"/></Icon><p>zum Angebot</p>
            </TextSecondary>
          </div>
        </a>
      </TextWrapper> 
      
    </SProductItemT17>
  );
};
