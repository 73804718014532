import { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const wrapper: any = css`
  position: relative;
  padding: 8px 12px;
  height: calc(100% - 20px);
  &:hover {
    > a {
      opacity: 1;
    }
    > div {
      opacity: 1;
      pointer-events: auto;
    }
  }
  
  > a {
    text-align: left;
    padding-top: 1.5px;
    opacity: .9;
    display: flex;
    font-size: 11.5px;
    font-weight: 600;
    cursor: pointer;
    height: 100%;
    font-family: "Roboto", sans-serif;
  }
 
`;

export const sidebar_wrapper: any = css`
    > a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0 16px 17px;
      outline: none !important;
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 500;
      font-family: "Roboto", sans-serif;
    }
`;

export const sidebarExpandItemArrowContainer: any = css`
    display: flex;
    justify-content: flex-end;
    width: 35%;
    padding: 0px 25px;
    color: ${colors.green};
    height: 100%;
    opacity: .9;
    &:hover {
      opacity: 1;
    }
   
    @media (${BREAKPOINTS_DM.silver_768}) {
      justify-content: flex-start;
      width: 20px;
      padding: 0px 0px 0px 5px;
    }
`;
export const sidebarExpandItemArrow: any = css`
    color: ${colors.green};
    margin: 0px 10px 0px 0px;
    height: 12px;
    opacity: .9;
    &:hover {
      opacity: 1;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      margin: 0px 0px 4px;
      
    };
`;

export const dropdownWrapper: any = css`
    position: absolute;
    padding: 10px 0px 10px;
    left: -3px;
    background-color: #000;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: opacity ease .3s;
    @media (${BREAKPOINTS_DM.silver_768})
    {
      z-index: 9999999;
    };
`;

export const sidebar_SubItemsWrapper: any = css`
  display: none;
`;

export const sidebar_SubItemsWrapperExpanded: any = css`
    display: block;
    @media (${BREAKPOINTS_DM.silver_768})
    {
      z-index: 9999999;
    }
`;

export const dropDrownItem: any = css`
    width: 100%;
    display: flex;
    color: white;
    padding: 6px 16px;
    font-size: 8px;
    line-height: 19px;
    opacity: .6;
    cursor: pointer;
    white-space: nowrap;
    font-family: "Roboto", sans-serif;
    &:hover {
      background-color: #4F4444;
      opacity: 1;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
      font-size: 13px;
    }
`;

export const dropDrownItemSelected: any = css`
    width: 100%;
    display: flex;
    color: white;
    padding: 6px 16px;
    font-size: 13px;
    line-height: 19px;
    opacity: 1;
    cursor: pointer;
    background-color: ${colors.green}
`;

export const sidebar_SubItem: any = css`
    display: block;
    font-size: 12.5px;
    letter-spacing: 0.5px;
    color: ${colors.green};
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    padding: 4px 0px 10px 25px;
    
    &:last-child {
      margin-bottom: 5px;
    }
`;
