import { css } from "styled-components";

import { BREAKPOINTS_DM } from "./mediaquerys";
import { theme } from "./themes";

export const centeredContent: any = css`
    max-width: ${theme.maxWidth}px;
    width: 92%;
    margin: 0 auto;
    position: relative;
`;

export const flexWrapper: any = css`
    margin-bottom: 15px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-right: -13.5px;
    > * {
      margin-bottom: 13.5px;
    };
`;

export const imageWrapper: any = css`
    ${flexWrapper};
    &, >div {
      width: 100%;
    }
    > * {
      margin-bottom: 0;
    };
`;

const flexItem: any = css`
    width: calc(50% - 13.5px);
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 13.5px;
    margin-bottom: 13.5px;
    padding: 27px;
`;

export const flexItem25: any = css`
    ${flexItem};
    width: calc(25% - 13.5px);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: calc(50% - 13.5px);
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        width: 100%;
    }
`;
export const flexItem25Early: any = css`
    ${flexItem};
    width: calc(25% - 13.5px);
    @media (max-width: 1400px)
    {
        width: calc(50% - 13.5px);
    }
    @media (max-width: 768px)
    {
        width: 100%;
    }
`;

export const flexItem50Early: any = css`
    ${flexItem};
    width: calc(50% - 13.5px);
    @media (max-width: 1400px)
    {
        width: 100%;
    }
`;
export const flexItem50: any = css`
    ${flexItem};
    width: calc(50% - 13.5px);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: 100%;
    }
`;

export const flexItem100: any = css`
    ${flexItem};
    width: 100%;
`;

export const galleryItemIcon: any = css`
    width: 100%;
    display: contents;
    justify-content: center;
    align-items: center;
    
    img {
      object-fit: contain !important;
      height: auto !important;
    }
`;

export const galleryItemText: any = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
    line-clamp: 9;
    color: black;
`;

export const galleryWrapper: any = css<{color: string}>`
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-right: -13.5px;

    > div {
      overflow: hidden;
      h6 {
        color: ${(props) => props.color ? props.color : '#fed061'};
      }
      p {
        margin-top: revert;
      }
      &:first-child{
        border: 2px solid ${(props) => props.color ? props.color : '#fed061'};
      }
      &:nth-child(2) {
        padding: 0 0 6px;
        background-color: ${(props) => props.color ? props.color : '#fed061'};
      }
    }
    h4 {
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 14px;
    }
`;

export const articleContent: any = css<{color: string}>`
    * {
      color: ${(props) => props.color ? props.color : '#fed061'};
    }
    br {
      margin-top: 12px;
    }
    h3 {
      span {
        font-size:  20px;
      }
    }
    h2, h4, h5, h6 {
      font-size: 20px;
      margin-bottom: 13,5px;
    }
    h4 {
      span {
        font-weight: bold;
      }
    }
`;