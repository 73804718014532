import React, { FunctionComponent } from "react";

const ChevronLeftIcon: FunctionComponent<{size?: number}> = ({ size = 20 }) => 
  (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 28" fill="none">
      <path d="M17 2L3 13.7447L17 26" stroke="currentColor" strokeWidth="3"/>
    </svg>
  );

export default ChevronLeftIcon;
