import React, { FunctionComponent, Fragment } from "react";
import SlickSlider from "react-slick";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import * as styles from "./Slider.style";
import { IAsset, Img } from "src/common";

export interface ISlider {
  elements: IAsset[];
  landscapeFormat?: boolean;
}

const Slider: FunctionComponent<ISlider> = (props) => {
  const landscapeFormat = !!props.landscapeFormat;

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    autoplay: true,
  };

  return (
    <div css={styles.wrapper}>
      <div css={centeredContent}>
        <div css={contentWrapper}>
          <div
            css={`
              ${SSlickBase}
              ${SSlickTheme}
            `}
          >
            <SlickSlider {...sliderSettings}>
              {props?.elements.map((element, i) => (
                <Fragment key={`SSI-${i}`}>
                  {landscapeFormat ? (
                    <div css={styles.imageWrapper}>
                      <Img
                        quality={1600}
                        key={i}
                        contain={true}
                        src={element?.src}
                        alt={element?.description ?? "image"}
                      />
                    </div>
                  ) : (
                    <Img
                      quality={1600}
                      key={i}
                      contain={true}
                      src={element?.src}
                      alt={element?.description ?? "image"}
                    />
                  )}
                </Fragment>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
