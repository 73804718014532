import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

const Default = css`
 h2{
      color: #d40046;
      font-size: 20px;
      line-height: 20px;
      font-weight: 400;
    }
  .cpg-item{
    display: block;
    background: #d40046;
    color: #fff;
    padding: 20px;
   
    a{
      background: #d40046;
      color: #fff;
    }
    .cpg-price{
      padding: 13px;
      padding: 0;
      position: relative;
      color: #fff;
      > div:first-child{
        .cpg-price-number{
          text-align: left;
        }
      }
      > div:last-child{
        top: 0;
        position: absolute;
        right: 0;
      }
    }
    .cpg-price-next{
      line-height: 28px !important;
        width: 100%;
        margin-top: 14px;
        font-size: 16px;
        width: 100%;
        height: 28px;
        cursor: pointer;
        &:hover{
          color: black;
        }
        display: block;
        line-height: 2em;
        text-align: center;
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SProductItemT7 = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

