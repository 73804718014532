import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    margin-bottom: 0 !important;
    text-align: center;
    width: 100%;
`;

export const content: any = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    strong p {
      font-weight: 500;
    }
`;

export const firstChild: any = css`
    background-color: ${colors.backgroundMint};
`;

export const odd: any = css`
    background-color: ${colors.lightGray};
`;

export const even: any = css`
    background-color: white;
`;

export const firstChildAboveButton: any = css`
    * {
      color: white;
    }
`;

export const aboveButton: any = css`
    * {
      color: black;
    }
`;

export const button: any = css`
    background-color: white;
    max-width: 500px;
    width: 100%;
    padding: 20px;
    color: ${props => props.theme.secondary};
    display: block;
    font-size: 32px;
    margin: 15px 0;
`;

export const belowButton: any = css`
    color: black;
    a {
      color: ${props => props.theme.primary};
      &:hover {
        text-decoration: underline !important;
        * {
          text-decoration: underline !important;
        }
      }
    }
`;
