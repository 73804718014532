// @ts-nocheck
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
  BREAKPOINTS,
  MediaQueryArrayToVal
} from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

const loaded = css`
  opacity: 1;
`;

const cover = css`
  font-family: "object-fit: cover;";
  object-fit: cover;
`;

const _contain = css`
  font-family: "object-fit: contain;";
  object-fit: contain;
`;

const Default = css`
  height: ${({ height }) =>
    height ? `${MediaQueryArrayToVal(height, BREAKPOINTS.BRONZE)}px` : "100%"};
  opacity: 0;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    opacity: 1;
  }
  transition: opacity 0.8s ease;
  img {
    height: ${({ height }) =>
    height
      ? `${MediaQueryArrayToVal(height, BREAKPOINTS.BRONZE)}px`
      : "100%"};
    ${({ contain, noFit }) => (!noFit ? (contain ? _contain : cover) : "")};
    width: 300%;
  }
`;

const Silver = css`
  height: ${({ height }) =>
    height ? `${MediaQueryArrayToVal(height, BREAKPOINTS.SILVER)}px` : "100%"};
  img {
    height: ${({ height }) =>
    height
      ? `${MediaQueryArrayToVal(height, BREAKPOINTS.SILVER)}px`
      : "100%"};
      width: 100%;
  }
`;

const Gold = css`
  height: ${({ height }) =>
    height ? `${MediaQueryArrayToVal(height, BREAKPOINTS.GOLD)}px` : "100%"};
  img {
    height: ${({ height }) =>
    height ? `${MediaQueryArrayToVal(height, BREAKPOINTS.GOLD)}px` : "100%"};
    width: 100%;
  }
`;

const Platinum = css`
  height: ${({ height }) =>
    height
      ? `${MediaQueryArrayToVal(height, BREAKPOINTS.PLATINUM)}px`
      : "100%"};
  img {
    height: ${({ height }) =>
    height
      ? `${MediaQueryArrayToVal(height, BREAKPOINTS.PLATINUM)}px`
      : "100%"};
  }
`;

const Diamond = css`
  height: ${({ height }) =>
    height ? `${MediaQueryArrayToVal(height, BREAKPOINTS.DIAMOND)}px` : "100%"};
  img {
    height: ${({ height }) =>
    height
      ? `${MediaQueryArrayToVal(height, BREAKPOINTS.DIAMOND)}px`
      : "100%"};
  }
`;

const SImg = styled.div`
  ${Default}
  ${props => (props.loaded ? loaded : "")}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SImg;
