
import React, { FunctionComponent } from "react";

import RichText from "../../../BaseComponents/RichText/RichText";
import { centeredContent, contentWrapper } from "../../../constants/layout";
import * as styles from "./CallToAction.style";

export interface ICallToAction
{
    ButtonLabel?: string;
    TextAboveButton?: string;
    TextBelowButton?: string;
    linkTo?: [{slug: string}];
    index: number;
    isFirstCTA: boolean;
}

const CallToAction: FunctionComponent<ICallToAction> = props =>
{
    let cssClass;

    if(props.isFirstCTA)
    {
        cssClass = styles.firstChild;
    }
    else if(props.index % 2 === 1)
    {
        cssClass = styles.odd;
    }
    else
    {
        cssClass = styles.even;
    }


    return (
        <div css={`${styles.wrapper}${contentWrapper}${cssClass}`}>
            <div css={`${centeredContent}${styles.content}`}>
                {props.TextAboveButton && (
                    <RichText  textContent={props.TextAboveButton} overwriteStyle={props.isFirstCTA ? styles.firstChildAboveButton : styles.aboveButton}>
                    </RichText>
                )}
                {props.ButtonLabel && (
                    <a css={styles.button} href={props.linkTo?.[0]?.slug}>
                        {props.ButtonLabel}
                    </a>
                )}
                {props.TextBelowButton && (
                    <RichText overwriteStyle={styles.belowButton} textContent={props.TextBelowButton}>
                    </RichText>
                )}
            </div>
        </div>
    );
}
;

export default CallToAction;
