import React, { Fragment } from "react";
import { getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import { SProductItemT5 } from "./Styles/SProductItemT5";

export const ProductItemT5 = ({ item, i }) => {
    if (!item) {
        return item;
    }
    const product = (item?.link?.[0]) as IProduct;
    const isProduct = product?.__typename.includes("Product");
    const link = item?.link?.[0] as any;
    const price = link?.ourPrice;
    const slug = link?.slug;

    if (isProduct && product?.acceptAGB && !getIfNewsShouldBeDisplayByDate({ startDate: product?.startDate, endDate: product?.endDate })) {
        return null;
    }
    const index = i % 5;
    const colors = ["#008bc5", "#fed061", "#ee765e", "#83d0f5", "#69c0ac"];

    return (
        <>
            <SProductItemT5 color={colors[index] || "#008bc5"}>
                <div>
                    <Img src={item.thumbnail}></Img>
                </div>
                <div>
                    <a className="cpg-item" href={`/${isProduct ? "produkt/" : ""}${slug}`}>
                        <h2>{item.title}</h2>
                        <Spacer bronze={20} />
                        <div className="cpg-price">
                            <div>
                                <span className="cpg-price-text">Jetzt für nur</span>
                                {price && <span className="cpg-price-number">{price} €</span>}
                            </div>
                            <div>
                                <span className="cpg-price-next">
                                    zum Angebot
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
            </SProductItemT5>
        </>
    );
}