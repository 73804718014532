import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import Img from "../../../BaseComponents/Img";
import { FurtherAwardsWrapper, ImageWrapper, Wrapper } from "./FurtherAwards.style";

const FurtherAwards: FunctionComponent = () => 
{
  const props = useContext(Context);
  const furtherAwards = props?.CompanyInformationPrivate?.furtherAwards;

  return (
    <Wrapper>
      <h3>Ausgezeichnet von</h3>
      <FurtherAwardsWrapper>
        {furtherAwards?.map((item, i) => (
          <ImageWrapper key={i}>
            <Img quality={800} key={i} src={[{ src: item?.src }]} alt={item?.title ?? "image"}/>
          </ImageWrapper>
        ))}
      </FurtherAwardsWrapper>
    </Wrapper>
  );
};

export default FurtherAwards;
