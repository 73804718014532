import React, { useContext } from "react";
import { Context } from "../..";
import { GEDK_LINES } from "../../utils/lineShorts";
import { isDarkTheme } from "../../../templates/t27/hooks/isDarkTheme";

export const LineLogo = (props: any) => {
    const backgroundIsDark = props?.backgroundIsDark;

    const c = useContext(Context);
    if (!c?.CompanyInformationPrivate?.line || c?.CompanyInformationPrivate?.line === "none") {
        return null;
    }

    const template = c.Settings?.template;
    let line: string | undefined = c?.CompanyInformationPrivate?.line;

    let style = undefined;

    // edge cases ... for logos that are not visible
    if (template == "t7" && line == "kgp") {
        style = {
            filter: "saturate(0) invert(1)",
        };
    }

    if (line?.toLowerCase()?.includes("keine linie anzeigen")) {
        return null;
    }

    if (line?.includes("kw ")) {
        line = "kw";
    }
    if (line?.includes("gk ")) {
        line = "gk";
    }
    if (line?.includes("dk ")) {
        line = "dk";
    }

    const LINE = GEDK_LINES.find((l) => l.short === line || l.name === line);

    if (LINE) {
        line = LINE.short;
    }

    const fileEnd = ["kw", "gelectra", "gk", "dk", "kg"].includes(`${line}`) ? ".png" : ".svg";

    if (template == "t3" && fileEnd == ".png") {
        style = {
            maxHeight: "160px",
            maxWidth: "214px",
            height: "unset",
        };
    }

    let src = `/images/${line}${backgroundIsDark ? "_dark" : ""}${fileEnd}`;

    if (line == "pk") {
        style = {
            maxHeight: "160px",
            maxWidth: "214px",
            height: "unset",
        };
        if (["t11", "t28", "t29"].includes(template)) {
            style = {};
        }
        src = `/images/PK-logo_RGB.svg`;
    }

    if (["t27", "t28", "t29"].includes(template)) {
        const dark = isDarkTheme({ settings: c.Settings });
        if (line == "kw" || line == "kg") {
            src = `/images/linelogo/${line}${dark ? "_dark" : ""}.svg`;
        }
    }

    return <img style={style} src={src} className="linelogo" alt={`${line} logo`} />;
};
