import React, { FunctionComponent } from "react";
import { IAsset, Img } from "src/common";

import { RichText } from "../../RichText/RichText";
import {
  SlideWrapper,
  Counter,
  Content,
  ContentWrapper,
  Wrapper,
} from "./NewSlide.style";

interface IProps {
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
  newpage: boolean;
  onSetCurrent?: (number) => void;
}

const NewSlide: FunctionComponent<IProps> = (props) => {
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find((p) =>
    p.type.includes("heading")
  );
  if (elementToShowAsSubtitle) {
    text = { content: [elementToShowAsSubtitle] };
  }
  return (
    <Wrapper>
      {props.img && (
        <SlideWrapper
          withImage={!!props.img}
          src={props?.img[0]?.src}
          newpage={props.newpage}
        >
          {props?.img[0]?.src && <div className="imgwrapper"><Img src={props?.img[0]?.src}></Img></div>}
          <ContentWrapper>
            <Counter>
              {props.newpage ? (
                <h3>
                  {props?.index + 1}/{props?.totalCount}
                </h3>
              ) : (
                <h3>Nachrichten: {props?.index + 1}</h3>
              )}
            </Counter>
            <Content>
              {props.newpage ? (
                <h3 className="title">{props.title}</h3>
              ) : (
                <RichText textContent={text} />
              )}
              {props.newpage ? (
                <a
                href={props?.link}
                >
                  <span>Lesen</span>
                </a>
              ) : (
                <a href={props?.link}>
                  <span>Mehr erfahren</span>
                </a>
              )}
            </Content>
          </ContentWrapper>
        </SlideWrapper>
      )}
    </Wrapper>
  );
};

export default NewSlide;
