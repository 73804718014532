import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context } from "src/common";

import { Container, Section } from "../Grid";
import Address from "./Address";
import { FooterWrapper } from "./Footer.style";
import SocialShare from "./SocialShare";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";

interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => {
  const props = useContext(Context);
  const companyInformation = props.CompanyInformationPublic;
  const footerNavItems = props.Footer?.Navigationspunkte || [];

  return (
    <Container>
      <Section>
        <FooterWrapper>
          <Address items={footerNavItems} />
          <SocialShare companyInformation={companyInformation} />
          <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "8px",
            fontSize: "12px",
          }}
        >
          <p>
            <CopyrightNotice />
          </p>
        </div>
        </FooterWrapper>
      </Section>
    </Container>
  );
};

export default Footer;