
import React, { FC, useContext, useState } from "react";
import { Context, IContext } from "src/common";

import Bottom from "./Bottom";
import { HaderContainer, Logo } from "./Header.styles";
import Navigation, { BurguerIcon } from "./Navigation";

const Header: FC = () => {
  const [isMenuActive, toggleMenuActive] = useState(false);
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  
  return (
    <HaderContainer>
      {logo && <a href="/"><Logo src={logo.src} themeColorPrimary={props?.Settings?.themeColorPrimary}/></a>}
      <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} />
      <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} isMenuActive={isMenuActive} />
      <Bottom />
    </HaderContainer>
  );
};

export default Header;