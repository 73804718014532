import React, { FunctionComponent, ReactNode } from "react";
import { FlattenSimpleInterpolation } from "styled-components";

import { SBrandAreaContainer } from "./styles/SBrandAreaContainer";
import { SBrandAreaContainerInner } from "./styles/SBrandAreaContainerInner";

export const BrandAreaContainer: FunctionComponent<{
    children: ReactNode;
    overwriteStyle?: FlattenSimpleInterpolation;
    noContainer?: boolean;
}> = ({ children, overwriteStyle, noContainer }) =>
    noContainer ? (
        <>{children}</>
    ) : (
        <SBrandAreaContainer overwriteStyle={overwriteStyle} className="brand-area-container">
            <SBrandAreaContainerInner>{children}</SBrandAreaContainerInner>
        </SBrandAreaContainer>
    );
