import Head from 'next/head'
import React from 'react'
import S500 from './Styles/S500'

export default function CustomErrorPage(props: any) {
    console.error(` error statusCode was: ${props?.statusCode}`);
    return (
        <S500>
            <Head>
                <title> Wartungsarbeiten | Diese Seite befindet sich gerade in der Wartung und steht Ihnen in Kürzer wieder zur Verfügung </title>
            </Head>
            <h1>
                Wartungsarbeiten
            </h1>
            <p>
                Diese Seite befindet sich gerade in der Wartung und steht Ihnen in Kürzer wieder zur Verfügung
            </p>
        </S500>
    )
}