import React, { useState, Fragment, FunctionComponent, useEffect } from "react";
import {
  Context,
  getCurrentNavItem,
  IExternalLink,
  INavigationItem,
  IPage,
  IPrimaryNavigation_Items,
} from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import { BurgerMenu } from "./BurgerMenu/BurgerMenu";
import * as styles from "./MainNavigation.style";

const MainNavigation: FunctionComponent = () => {
  const props = React.useContext(Context);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const NavigationItemsDesktop = [];

  const toggleMenu = (): void => {
    setIsMenuActive(!isMenuActive);
  };

  const NavigationItemsResponsive = props?.PrimaryNavigation?.items?.map(
    (item, index) => {
      const link =
        item?.link?.[0]?.slug ||
        ((item as any)?.externalLink?.[0]?.externalLinkAddress
          ? `https://${`${
              (item as any)?.externalLink?.[0]?.externalLinkAddress
            }`
              .replace("https://", "")
              .replace("http://", "")}`
          : undefined);

      if (!link) {
        return;
      }

      const currentMainNavItem: any = getCurrentNavItem(
        props?.PrimaryNavigation?.items,
        `${props.slug}`
      );
      const isActive =
        !!props.slug &&
        (`${props.slug}` === `${link}` ||
          currentMainNavItem?.link?.[0]?.slug === `${link}`);

      const navItemDesktop = (
        <li css={styles.navItem} key={index}>
          <a
            href={
              link?.includes("https://") ? link : `/${item?.link?.[0]?.slug}`
            }
            target={link?.includes("https://") ? "_blank" : undefined}
            css={`
              ${styles.link}
            `}
          >
            {item.icon && item.icon.length > 0 ? (
              <img
                src={item.icon?.[0]?.src}
                alt={"hoverIcon"}
                css={`
                  ${isActive ? styles.activeImage : null}
                `}
              />
            ) : (
              <img
                src="https://assets.caisy.io/assets/69983cb0-621b-4cf0-8886-367b6f4a4d47/mPvGg5QT26/add885ec-cd9c-4373-bc3d-06471f74423cnavitemdefault.png"
                alt={"hoverIcon"}
                css={`
                  ${isActive ? styles.activeImage : null}
                `}
              />
            )}

            <span
              css={`
                ${isActive ? styles.active : null}
              `}
            >
              {item?.label ?? ""}
            </span>
          </a>
        </li>
      );

      NavigationItemsDesktop.push(navItemDesktop);

      const subNavItems = ((item as any)?.subitems as any[])?.map(
        (subItem: IPage | IExternalLink, subIndex) => {
          if (subItem.__typename === "ExternalLink") {
            return (
              <li key={subIndex}>
                <a
                  target={"_blank"}
                  css={styles.subNavItemResponsive}
                  rel="noreferrer"
                  href={`https://${`${subItem?.externalLinkAddress}`
                    .replace("https://", "")
                    .replace("http://", "")}`}
                >
                  {subItem?.externalLinkName ?? ""}
                </a>
              </li>
            );
          }

          if (!subItem?.slug) {
            return;
          }
          return (
            <li key={subIndex}>
              <a css={styles.subNavItemResponsive} href={subItem?.slug}>
                {subItem?.nameInNavigation ?? ""}
              </a>
            </li>
          );
        }
      );

      const toggleSubNavItemsMenu = (e): void => {
        e.preventDefault();

        const el = document.getElementById(`navItem${String(index)}`);

        if (el.style.display === "none") {
          el.style.display = "block";
        } else {
          el.style.display = "none";
        }
      };

      const openSubMenuButton = (
        <span
          css={styles.openSubMenuButton}
          onClick={(e) => toggleSubNavItemsMenu(e)}
        />
      );

      return (
        <Fragment key={index}>
          <li css={styles.navItemResponsive}>
            <a
              href={
                link?.includes("https://") ? link : `/${item?.link?.[0]?.slug}`
              }
              target={link?.includes("https://") ? "_blank" : undefined}
              css={styles.linkResponsive}
            >
              {item.label}
              {item.subitems ? openSubMenuButton : null}
            </a>
          </li>
          <ul
            style={{ width: "99%", display: "none" }}
            id={`navItem${String(index)}`}
          >
            {subNavItems}
          </ul>
        </Fragment>
      );
    }
  );

  return (
    <div css={styles.navContainer}>
      <div css={styles.menuDesktop}>
        <a href={"/"}>
          {props?.CompanyInformationPublic?.logo ? (
            <img
              css={styles.companyLogo}
              src={props.CompanyInformationPublic?.logo?.[0]?.src}
              alt={
                getContactCompanyNameShort()
              }
            />
          ) : null}
        </a>
        <ul css={styles.ulMenuDesktop}>{NavigationItemsDesktop}</ul>
        <BurgerMenu isInSidebar={false} onClick={toggleMenu} />
      </div>
      <div
        css={styles.menuResponsive}
        style={{
          transform: isMenuActive ? "translateX(0)" : "translateX(100%)",
        }}
      >
        <BurgerMenu isInSidebar={true} onClick={toggleMenu} />
        <div css={styles.backDrop} onClick={() => setIsMenuActive(false)} />
        <ul css={styles.ulMenuResponsive}>{NavigationItemsResponsive}</ul>
      </div>
    </div>
  );
};

export default MainNavigation;
