import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const LogoLinkWrapper: any = styled.a<{
  logoSizeDesktop: number;
  logoSizeMobile: number;
  offsetY: number;
  offsetX: number;
  mobileOffsetX: number;
  mobileOffsetY: number;
  desktoVisualization: string;
  mobileVisualization: string;
  logoRight: boolean;
  mobileSwapBurger: boolean;
  logoCenter: boolean;
}>`
  position: absolute;
  padding: inherit;
  :hover {
    opacity: 1;
  }
  ${({ mobileOffsetX, mobileSwapBurger }) =>
    (mobileSwapBurger ? "right: " : "left: ") + (mobileOffsetX ? mobileOffsetX : 0)}px;
  top: ${({ mobileOffsetY }) => (mobileOffsetY ? mobileOffsetY : 0)}px;
  width: ${({ mobileSwapBurger }) => (mobileSwapBurger ? "" : "100%")};
  img {
    &:first-child {
      width: ${({ logoSizeMobile }) =>
        logoSizeMobile ? `${logoSizeMobile * 3}px` : "400px"};
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    ${({ offsetX, logoRight }) =>
      (logoRight ? "right: " : "left: ") + (offsetX ? offsetX : 0)}px;
    top: ${({ offsetY }) => (offsetY ? offsetY : 0)}px;
    width: ${({ logoRight }) => (logoRight ? "" : "100%")};
    img {
      &:first-child {
        width: ${({ logoSizeDesktop }) =>
          logoSizeDesktop ? `${logoSizeDesktop * 4}px` : "400px"};
      }
    }
    ${({ logoCenter }) =>
      logoCenter
        ? css`
            display: flex;
            justify-content: center;
          `
        : ""}
  }
`;
