import React, { FunctionComponent } from "react";

const ChevronRightIcon: FunctionComponent<{size?: number}> = ({ size = 20 }) => 
  (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 28" fill="none">
      <path d="M1 26L15 14.2553L1 2" stroke="currentColor" strokeWidth="3"/>
    </svg>
  );

export default ChevronRightIcon;
