import { css } from "styled-components";

import { BREAKPOINTS_DM } from "./mediaquerys";
import { theme } from "./themes";

export const centeredContent: any = css`
    max-width: ${theme.maxWidth}px;
    width: 92%;
    margin: 40px auto;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        margin: 0;
        width: 100%;
    }
`;

export const cutCorner: any = css`
    margin-bottom: 27px;
    padding: 27px;
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: 1;
      border-bottom: 1px solid ${props => props.theme.secondary};
      background: white;
      width: 40px;
      height: 40px;
      transform: rotate(-45deg);
    }
`;
