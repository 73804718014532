import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context } from "src/common";

import Address from "./Address";
import { FooterWrapper, FooterContent } from "./Footer.style";
import Navigation from "./Navigation";
import SocialShare from "./SocialShare";

interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => {
  const props = useContext(Context);
  const allFooterLinksForUpperWrapper: IFooterLinkInColumnList[] = [];
  // const companyInformation = {
  //   pintrest: props?.CompanyInformationPublic?.pintrest,
  //   facebook: props?.CompanyInformationPublic?.facebook,
  //   twitter: props?.CompanyInformationPublic?.twitter,
  //   email: props?.CompanyInformationPublic.email,
  // };

  for (const item of props.PrimaryNavigation?.items || []) {
    if (item?.label && item?.link?.[0]?.slug) {
      allFooterLinksForUpperWrapper.push({
        title: item.label || item?.link?.[0]?.nameInNavigation,
        link: item?.link?.[0]?.slug,
        isBold: true,
      });
    }

    if (item?.subitems) {
      for (const subItem of item.subitems) {
        if (subItem?.nameInNavigation && subItem?.slug) {
          allFooterLinksForUpperWrapper.push({
            title: subItem?.nameInNavigation,
            link: subItem?.slug,
            isBold: false,
          });
        }
      }
    }
  }

  allFooterLinksForUpperWrapper.push({
    title: "Impressum",
    link: "/imprint-datenschutz",
    isBold: false,
  });
  allFooterLinksForUpperWrapper.push({
    title: "Datenschutz",
    link: "/imprint-datenschutz#Datenschutz",
    isBold: false,
  });

  // console.log(` allFooterLinksForUpperWrapper`, allFooterLinksForUpperWrapper);

  return (
    <FooterWrapper>
      <FooterContent>
        <Address companyInformation={props?.CompanyInformationPublic} />
        <SocialShare companyInformation={props?.CompanyInformationPublic} />
        <Navigation items={allFooterLinksForUpperWrapper} />
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;
