import { IGenAsset } from "src/common/__generated/root/types";
import { BrandAreaContainer } from "src/common/brand-area/components/brand-area-container/BrandAreaContainer";

import React, { FunctionComponent } from "react";

import { SImage } from "./styles/SImage";
import { FancyImage } from "../../../BaseComponents/FancyImage/FancyImage";

interface IGenAssetProps extends IGenAsset {
    /** if false then Container padding will be removed  */
    hasContainerPaddingWrapper?: boolean;
}

const Asset: FunctionComponent<IGenAssetProps> = ({ hasContainerPaddingWrapper = true, ...props }) => {
    if (!props || !props.id || !props.src) {
        return null;
    }

    const Content = (
        <SImage>
            <FancyImage
                img={props}
                responsiveMaxWidth={{
                    platinum: 1366,
                }}
            />
        </SImage>
    );

    return hasContainerPaddingWrapper ? <BrandAreaContainer>{Content}</BrandAreaContainer> : Content;
};

export default Asset;
