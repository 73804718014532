import React, { FC, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddressWrapper } from "./Footer.style";
import {  faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
const LinksSeparator: FC = () => (
  <span style={{ width: "18px", height: "18px" }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4C1.9 395.3-4.5 414.5 3.4 430.3s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
    </svg>
  </span>
);

const Address: FC<{ items: any[] }> = ({ items }) => (
  <AddressWrapper>
    {items &&
      items.map((item, i) => {
        const slug = item?.slug;
        const nameInNavigation = item?.nameInNavigation;

        if (!slug || !nameInNavigation) {
          return null;
        }

        return (
          <div key={i}>
            <>
              &nbsp;
              <LinksSeparator />
              &nbsp;
            </>
            <a href={slug}>{nameInNavigation}</a>
          </div>
        );
      })}
    <div>
      <>
        &nbsp;
        <LinksSeparator />
        &nbsp;
      </>
      <a
        href={"https://www.facebook.com/kuechengilde/"}
        target="_blank"
        rel={"noref noopener"}
      >
        <FontAwesomeIcon icon={faFacebook as any} />{" "}
        <div style={{ width: "4px" }}></div> FACEBOOK
      </a>
    </div>
    <div>
      <>
        &nbsp;
        <LinksSeparator />
        &nbsp;
      </>
      <a
        href={"https://www.instagram.com/kuechengilde"}
        target="_blank"
        rel={"noref noopener"}
      >
        <FontAwesomeIcon icon={faInstagram as any} />{" "}
        <div style={{ width: "4px" }}></div>INSTAGRAM
      </a>
    </div>
  </AddressWrapper>
);

export default Address;
