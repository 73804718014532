import * as React from "react";
import { FunctionComponent } from "react";

import SLink from "./BannerLink.style";

interface IProps
{
  icon: string;
  onToogleActive: Function;
  isActive: boolean;
  isScrolling?: boolean;
}

const BannerLink: FunctionComponent<IProps> = props =>
  (
    <SLink onMouseOver={() => props.onToogleActive(!props.isActive)} onMouseOut={() => props.onToogleActive(!props.isActive)} isScrolling={props.isScrolling}>
      <i className={`fa fa-${props.icon} ${props.icon === "clock" && "far"}`}/>
    </SLink>
  );
export default BannerLink;
