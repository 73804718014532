// @ts-nocheck
import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

import { theme } from "../../../../constants/themes";

export const BurguerIcon: any = styled.div<{isScrolling: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 45px;
  font-size: 25px;
  color: ${({ isScrolling }) => isScrolling ? `${theme.palette.default}` : "#efefef"};
  position: fixed;
  /* top: 35px; */
  transition: transform .8s ease;
  cursor: pointer;
  /* 120 is the header and we want to be on top on scroll */
  z-index: 121; 
  /* right: 15px; */
  &:hover {
    background: ${theme.palette.default};
    color: #fff;
    border-radius: 4px;
  }
  /* @media (${BREAKPOINTS_DM.silver_768}) {
    right: 63px;
  } */
`;

export const NavigationLink: FC<any> = styled.li<{ isActive: boolean; subItems: boolean; isShowSubItem: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1px;
  margin: 0 10px;
 
  div.link {
    display: flex;
    white-space: nowrap;
    color: ${({ isActive }) => isActive ? `${theme.palette.default}` : `${theme.palette.default}`};
    font-size: 13px;
    font-stretch: 100%;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 15px 8px;
    text-align: center;
    width: 100%;
    border-top: 1px solid #ccc;
  }

  div.sub-item-mobile {
    display: flex;
    flex-direction: column;
  }

  a.sub-item {
    white-space: nowrap;
    color: ${theme.palette.default};
    font-size: 13px;
    font-weight: 300;
    line-height: 18.5714px;
    padding: 4px 10px;
    text-align: left;
    width: 100%;
    &:hover {
      background-color: #f5f5f5;
    }
  }
 
  div.icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${theme.palette.default};
    width: 100%;
    padding-left: 10px;
    margin: -5px 0 0;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    border-bottom: none;
    margin-bottom: 0;
    margin-left: 0px;
    margin: 0 0 0 0px;

    div.link {
      color: ${({ isActive }) => isActive ? `${theme.palette.default}` : `${theme.palette.default}`};
      padding: 10px 0 8px;
      a {
      font-size: 13px;
      font-weight: 300;
      }
    }
   
    &:hover {
      div.sub-item-wrapper { 
        display: flex;
        flex-direction: column;
        position: absolute;
      }
    }
  }
`;

export const SubItem: any = styled.a<{isSubItemActive: boolean}>`
  ${({ isSubItemActive }) => isSubItemActive ? "background: #e67c19; color: #fff" : "background: #fff; color: #666"};
  border-bottom: 1px solid #ccc;
  font-size: 13px;
  font-weight: 300;
  line-height: 18.5514px;
  min-width: 100px;
  padding: 8px 20px;
  width: 100%;
  white-space: nowrap;
  &:hover {
    background: #f5f5f5;
    color: #e67c19
  }
`; 

export const IconWrapper: any = styled.div`
  display: flex;
  align-items: center;
  color: #6e6e6e;
  width: 20%;
  padding-left: 10px;
`;

export const Icon: any = styled.div<{isShowSubItems: boolean}>`
    
`;

export const NavigationLinks: any = styled.div<{isMenuActive: boolean}>`
  width: 100%;
  height: 0;
  ul {
    align-items: flex-start;
    display: ${({ isMenuActive }) => isMenuActive ? "flex" : "none"};
    flex-direction: column;
    list-style: none;
    width: 100%;
    background-color: #fff;
    padding: 15px 15px;
    margin-top: 52px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    height: unset;
    padding: 15 15px;

    ul {
      margin-top: 52px;
      border-top: 0;
      position: fixed;
      right: 0;
      min-width: 300px;
      flex-wrap: wrap;
      justify-content: center;
      width: fit-content;
    }
  }
`;

export const NavigationContainer: FC<any> = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
