import styled, { css } from "styled-components";

const Default = css`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    svg {
        cursor: pointer;
        color: white;
        path {
            fill: white;
        }
        margin: 8px 0;
    }
    min-height: 40px;
`;

export const SModalCloseWrapper = styled.div`
    ${Default}
`;
