import React, { FunctionComponent, useState, useContext } from "react";
import { INavigationItem, Context, IContext } from "src/common";

import {
  NavigationContainer,
  NavigationLinks,
  NavigationLink,
  MenuIconWrapper,
  NavIconsWrapper,
  SubItemWrapper,
  Links,
  SubItem,
  Logo,
  SubItemWrapperMobile
} from "./Navigation.styles";

interface IHeaderItemProps
{
  title: string;
  link: string;
  headline?: string;
  external?: boolean;
}

const getHeaderNavItemProps = (item: Record<string, any>): IHeaderItemProps =>
{

  const linkItem = item?.link?.[0];

  if((linkItem as any)?.__typename === "ExternalLink")
  {
    return {
      link: `${(linkItem as any).externalLinkAddress}`,
      title: `${(item as any).label ?? (linkItem as any).externalLinkName}`,
      external: true,
    };
  }

  const link = linkItem?.slug || (item as any)?.slug;
  const title = (linkItem as any)?.nameInNavigation || (item as any)?.nameInNavigation || (item as any)?.label;
  let headline;

  if(!link || !title)
  {
    return;
  }

  if(linkItem?.__typename === "PageMicro")
  {
    return {
      link,
      title,
    };
  }

  if(linkItem?.__typename === "Page" || linkItem?.__typename === "PageNews" || linkItem?.__typename === "PageGuestbook")
  {
    headline = linkItem.elements?.find(e => e?.__typename === "TextElement")?.[0]?.headline;
  }

  return {
    link,
    title,
    headline
  };
};

const SubItemComponent: FunctionComponent<{subItem: Record<string, any>; isActive: boolean }> = ({ subItem, isActive }) => (
  <SubItem isActive={isActive} href={`/${subItem.slug}`}>
    {isActive && <div><i className="fa fa-arrow-right"/></div>}
    {subItem.nameInNavigation}
  </SubItem>
);

const Items: FunctionComponent<{item: Record<string, any>; itemProps: Record<string, any> }> = ({ itemProps, item }) =>
{
  const props = useContext<IContext>(Context);
  const [isShowSubitem, toogleShowSubitem] = useState(false);
  const { subitems } = item;
  const primariActive = props?.PrimaryNavigation.items?.find(pirmary => pirmary?.subitems?.find(secondary => secondary?.slug === props.slug));
  return (
    <>
      <NavigationLink isActive={itemProps.link === props.slug || itemProps.link === primariActive?.link[0]?.slug } isShowSubitem={isShowSubitem} icon={subitems?.length}>
        <a href={itemProps.link}>
          {itemProps.title}
        </a>
        {subitems?.length > 0 &&
          <div onClick={() => toogleShowSubitem(!isShowSubitem)} className="icon">
            <i className="fa fa-chevron-down" />
          </div>
        }
      </NavigationLink>
      {subitems?.length > 0 && isShowSubitem ?
        (
          <SubItemWrapper>
            {subitems?.map((subItem, i) =>
              subItem && (
                <SubItemComponent subItem={subItem} isActive={props?.slug === subItem.slug} key={`subitem-${i}`}/>
              )
            )}
          </SubItemWrapper>
        ) : (
          <SubItemWrapperMobile>
            {subitems?.map((subItem, i) =>
              subItem && (
                <SubItemComponent subItem={subItem} isActive={props?.slug === subItem.slug} key={`subitem-${i}`}/>
              )
            )}
          </SubItemWrapperMobile>
        )
      }
    </>
  );
};

export const NavigationIcons: FunctionComponent<{onShowMenu: any; isShowMenu: boolean }> = ({ onShowMenu, isShowMenu }) => (
  <NavIconsWrapper isMenuActive={isShowMenu}>
    <MenuIconWrapper onClick={() => onShowMenu(!isShowMenu)} isMenu>
      <i className="fa fa-bars"/>
    </MenuIconWrapper>

    <a href={"/kontakt"}>
      <MenuIconWrapper>
        <i className="fa fa-map-marker-alt"/>
      </MenuIconWrapper>
    </a>

    <a href={"/kontakt"}>
      <MenuIconWrapper>
        <i className="fa fa-envelope"/>
      </MenuIconWrapper>
    </a>
  </NavIconsWrapper>
);

const Navigation: FunctionComponent<{ items: INavigationItem[]; slug: string; onShowMenu: any; isShowMenu: boolean; }> = ({ items, onShowMenu, isShowMenu }) =>
{
  const props = useContext<IContext>(Context);
  const [logo] = props.CompanyInformationPublic?.logo;

  return (
    <NavigationContainer active={isShowMenu}>
      <NavigationLinks>
        <NavigationIcons onShowMenu={onShowMenu} isShowMenu={isShowMenu}/>
        {logo && <a href="/"><Logo src={logo.src} /></a>}
        <Links>
          {items?.map((item, index) =>
          {
            const itemProps = getHeaderNavItemProps(item);
            if(!itemProps) { return; }

            return (
              <Items itemProps={itemProps} item={item} key={`itemlink-key-${index}`}/>
            );
          })}
        </Links>

      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
