import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  createRef
} from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { IContext, Context, ILandingEvent, RichText } from "src/common";

import { handleFormSubmit } from "src/common/utils/formSubmit";
import { richToPlainText } from "src/common/utils/text/plaintext";
import * as styles from "./PlanningDateModal.style";
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

const PlanningDateModal: FunctionComponent = () => {
  const props = useContext(Context);
  const [hidePlanningDateModal, setHidePlanningDateModal] = useState(false);
  const [isHiddenByTime, setIsHiddenByTime] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hiddenByTimeTimeoutId, setHiddenByTimeTimeoutId] = useState(null); // Store the timeout identifier in a state variable


  useEffect(() => {
    const storedIsHidden = sessionStorage.getItem('hidePlanningDateModal');
    if (storedIsHidden !== null) {
      setHidePlanningDateModal(JSON.parse(storedIsHidden));
    } else {
      sessionStorage.setItem('hidePlanningDateModal', JSON.stringify(hidePlanningDateModal));
    }

  }, []);

  useEffect(() => {

    sessionStorage.setItem('hidePlanningDateModal', JSON.stringify(hidePlanningDateModal));
  }, [hidePlanningDateModal]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const ele = document.getElementById("PlanningDateModal");
      const hasStayClass = ele?.classList?.contains("stay");
      if( !hasStayClass){
        setIsHiddenByTime(true);
      }
    }, 6000);

    setHiddenByTimeTimeoutId(timeoutId); // Store the timeout identifier in a state variable

    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts
    };
  }, []);

  const { register, handleSubmit, errors, reset } = useForm();
  const [success, setSuccess] = React.useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const Settings = props.Settings;
  if ("deaktiviert" === Settings?.appointmentRequestDisplay) {
    return null;
  }


  const universalTexts = props?.UniversalTexts;
  const customPlanningDateText = universalTexts?.planningDateText;

  const planningDateContent = {
    headline: universalTexts?.planningDateHeadline || "Jetzt gleich einen Planungstermin für Ihre neue Küche vereinbaren!",
    text: "Nur einen Klick von Ihrer neuen Küche entfernt! Starten Sie jetzt Ihre persönliche und unverbindliche Planungsterminanfrage. Wir melden uns bei Ihnen so schnell wie möglich, um Ihre Planungsterminanfrage zu bestätigen. Damit wir Ihren Planungstermin bestätigen können, tragen Sie bitte Ihre Telefonnummer und E-Mail Adresse ein.",
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    clearTimeout(hiddenByTimeTimeoutId);
    isExpanded ? setIsLoaded(true) : setIsLoaded(false);
  };

  const onSubmit = (data): void => {
    // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
    // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
    // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441
    // Produktanfrage: d-ee2129a87d2d4e70bbcf21f1bff6491e
    let title: string | undefined;
    if (typeof window !== "undefined") {
      title = document.title;
    }

    const fcValue = (
      document.querySelector(".frc-captcha-solution") as any
    )?.value;
   

    handleFormSubmit({
      ...data,
      fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
      subject: `Planungsterminanfrage ${
        data?.Name ? `von ${data?.Name} ` : ""
      }${title ? `- ${title}` : ""}`,
      dealerMail: props?.CompanyInformationPublic?.email,
      line: props?.CompanyInformationPrivate?.line,
      templateId: "d-deb56cdeb9c34309be7a1a521bda38e6",
      // cc: [{ email: "serviceseite@gedk.de" }]
    }).then((r) => {
      reset();
      setSuccess(true);
    });
  };

  const timeOptions: React.ReactElement[] = [];

  for (let i = 6; i < 20; i++) {
    let value;
    let value2;

    if (i < 10) {
      value = `0${i}:00`;
      value2 = `0${i}:30`;
    } else {
      value = `${i}:00`;
      value2 = `${i}:30`;
    }

    timeOptions.push(
      <Fragment key={i}>
        <option value={value}>{value}</option>
        <option value={value2}>{value2}</option>
      </Fragment>
    );
  }

  const vorname = "Vorname";
  const nachname = "Nachname";
  const datum = "Datum";
  const uhrzeit = "Uhrzeit";
  const email = universalTexts?.eMail || "E-Mail";
  const telefon = "Telefon";
  const kommentar = "Kommentar";

  if (hidePlanningDateModal || isHiddenByTime) {
    return null;
  }

  return (
    <div id="PlanningDateModal">
      <div
        css={styles.wrapper}
        style={{ ...(isExpanded ? { transform: "none", bottom: 0 } : {}), ...(isLoaded ? { bottom: "90px" } : {}) }}
      >
        <div css={styles.topBar} onClick={handleExpand}>
          <svg
            css={styles.closeButton}
            onClick={() => setHidePlanningDateModal(true)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
          </svg>
          <h3>{planningDateContent?.headline}</h3>
          <div>
            <img src={"/images/date.jfif"} alt="" />
          </div>
        </div>
        <div css={styles.contentArea}>
          <div className="content-header">
            {(customPlanningDateText && richToPlainText(customPlanningDateText) != "") ? (
              <RichText fragment textContent={customPlanningDateText} />
            ) : (
              <p>{planningDateContent?.text}</p>
            )}
          </div>
          {success ? (
            <form>
              <h1>DANKE!</h1>
              Wir werden uns sobald wie möglich bei Ihnen melden.
            </form>
          ) : (
            <form className="withfc" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div css={styles.formRow}>
                  <div>
                    <label htmlFor={"vorname"}>{vorname}</label>
                    <input
                      required={true}
                      type="text"
                      placeholder={vorname}
                      id={"vorname"}
                      name="vorname"
                      ref={register}
                    />
                  </div>
                  <div>
                    <label htmlFor={"nachname"}>{nachname}</label>
                    <input
                      required={true}
                      type="text"
                      placeholder={nachname}
                      id={"nachname"}
                      name="nachname"
                      ref={register}
                    />
                  </div>
                </div>
                <div css={styles.formRow}>
                  <div>
                    <label htmlFor={"datum"}>{datum}</label>
                    <input
                      required={true}
                      type="date"
                      placeholder={datum}
                      id={"datum"}
                      name="datum"
                      ref={register}
                    />
                  </div>
                  <div>
                    <label htmlFor={"uhrzeitVon"}>{uhrzeit}</label>
                    <select
                      name={"uhrzeitVon"}
                      placeholder={uhrzeit}
                      id={"uhrzeitVon"}
                      ref={register}
                    >
                      {timeOptions}
                    </select>
                  </div>
                </div>
                <div css={styles.formRow}>
                  <div>
                    <label htmlFor={"email"}>{email}</label>
                    <input
                      required={true}
                      type="email"
                      placeholder={email}
                      id={"email"}
                      name="email"
                      ref={register}
                    />
                  </div>
                  <div>
                    <label htmlFor={"tel"}>{telefon}</label>
                    <input
                      required={true}
                      type="text"
                      placeholder={telefon}
                      id={"tel"}
                      name="tel"
                      ref={register}
                    />
                  </div>
                </div>
                <div css={styles.formRow} style={{ display: "block" }}>
                  <label htmlFor={"kommentar"}>{kommentar}</label>
                  <textarea
                    rows={8}
                    required={true}
                    name="kommentar"
                    id={"kommentar"}
                    placeholder={kommentar}
                    ref={register}
                  />
                </div>
                <div css={styles.fcWrapper} style={{ display: "block" }}>
                <FriendlyCaptcha />
                </div>
              </div>
              <div css={styles.submitWrapper}>
                <button type="submit">
                  {universalTexts?.Submit || "Absenden"}
                </button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    required={true}
                    type="checkbox"
                    placeholder="Ich akzeptiere die Datenschutzerklärung"
                    name="checkbox"
                    id="checkbox"
                    ref={register({ required: true })}
                  />
                  <label htmlFor={"checkbox"}>
                    Ich akzeptiere die{" "}
                    <a target="_blank" href={`/imprint-datenschutz#Datenschutz`}>
                      {" "}
                      Datenschutzerklärung
                    </a>
                  </label>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanningDateModal;
