// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const HaderContainer: any = styled.div<{isDeployed: boolean }>`
  display: flex;
  width: 100%;
  height: -webkit-fill-available;
  position: absolute;
  z-index: 999;
  overflow-y: auto;

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: column;
    ${({ isDeployed }) => isDeployed ?
    `
            right: 0;
            width: 377px;
            height: 600px;
          `
    :
    `
          right: 0;
          top: 20px;
          width: 255px;
          height: -webkit-fill-available;
          `
}
  }
`;
export const Flex: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;

`;

export const HaderContent: any = styled.div<{isDeployed: boolean}>`
  display: flex;
  flex-direction: ${({ isDeployed }) => isDeployed ? "column" : "row"};
  width: 100%;
  background-color: rgba(0,0,0,.8);
  height: fit-content;
  position: absolute;
  z-index: 999;
  overflow-y: auto;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: column;
    ${({ isDeployed }) => isDeployed ?
    `
          right: 0;
          width: 377px;
          height: 604.797px;
        `
    :
    `
        right: 0;
        width: 255px;
        height: fit-content;
        `
}
  }
`;

export const Logo = styled.img`
  display: block;
  max-width: 128px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: unset;
    max-width: 220px;
    max-height: 306px;
  }
`;

export const LogoWrapper: any = styled.div<{isDeployed: boolean}>`
  z-index: 9999;
  max-width: 45%;
  ${({ isDeployed }) => isDeployed ?
    `
    margin: 19px 0  0;
    padding-left: 19px;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 100%;
      max-width: 100%;
      margin: 44px 0 25px;
      padding-left: 64px;
    }
  `
    :
    `
    width: 100%;
    margin: 19px 0  0;
    padding-left: 19px;
  `
}

`;

export const ContactOptionWrapper: any = styled.div<{isDeployed: boolean}>`
  background: ${({ isDeployed }) => isDeployed ? "#94C03E" : "transparent"};
  width: 100%;
  margin-top: 21px;
  right: 0;
  z-index: 9999;
  padding-top: 3px;
  padding-bottom: 4px;
  padding-left: 16px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding-left: 0;
  }

  div.icon_menu {
    width: 17px;
    display: flex;
    justify-content: center;
    font-size: 15px;
    line-height: 17.0625px;
    font-weight: 300;
    color: #fff;
    margin: 0 15px;
  }

  div.icon {
    ${({ isDeployed }) => isDeployed ?
    `
      font-size: 28px;
      line-height: 28.875px;
      font-weight: 300;
      color: #fff;
      margin: 0 11px 0 13px;
      width: 37px;
    `
    :
    ` width: 17px;
      display: flex;
      justify-content: center;
      font-size: 15px;
      line-height: 17.0625px;
      font-weight: 300;
      color: #94C03E;
      margin: 0 15px;`
}

  }
  div.title {
    display: flex;
    align-items: center;
    margin: 5px 0;
    > h1 {
      ${({ isDeployed }) => isDeployed ?
    `
        font-size: 22px;
        line-height: 28.875px;
        font-weight: 300;
        color: #fff;
        margin: 0;
      `
    :
    `
        font-size: 13px;
        line-height: 17.0625px;
        font-weight: 300;
        color: #fff;
        margin: 0;
      `
}
  }
`;

export const ToogleMenu: any = styled.div`
  display: none;
  @media (${BREAKPOINTS_DM.silver_768}){
    display: flex;
    align-items: center;
    margin: 10px 0;
    > h1 {
      cursor: pointer;
      ${({ isDeployed }) => isDeployed ?
    `
        font-size: 22px;
        line-height: 28.875px;
        font-weight: 300;
        color: #fff;
        margin: 0;
      `
    :
    `
        font-size: 13px;
        line-height: 17.0625px;
        font-weight: 300;
        color: #fff;
        margin: 0;
      `
}}
`;

export const TogleMenuMobile: any = styled.div`
  height: 30px;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin: 5%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;
