import React, { FunctionComponent, useContext, useState } from "react";
import { BurgerMenu } from "../burgerMenu/BurgerMenu";
import NavItem, { eNavItemType } from "../navItem/NavItem";
import * as styles from "./ResponsiveSidebar.style";
import { IContext, Context, getHeaderNavItemProps } from "src/common";
import { LineLogo } from "../../../../../../common/components/LineLogo";

const ResponsiveSidebar: FunctionComponent = () => {
  const caisyProps = useContext<IContext>(Context);
  const initialHeight = 72;
  const navItemHeight = 53;

  const [height, setHeight] = useState<number>(initialHeight);
  if (!caisyProps.PrimaryNavigation?.items) {
    return null;
  }
  const navItems = caisyProps.PrimaryNavigation.items.map((item, i) => {
    const itemProps = getHeaderNavItemProps(item);
    if (!itemProps) {
      return null;
    }

    return (
      <NavItem
        key={i}
        type={eNavItemType.sidebar}
        link={itemProps.link}
        headline={itemProps.headline}
        title={itemProps.title}
        subItems={item.subitems as any}
        itemHeight={navItemHeight}
        expandItem={(shrink: boolean) => {
          if (shrink) {
            setHeight(height - item.subitems?.length * navItemHeight);
          } else {
            setHeight(height + item.subitems?.length * navItemHeight);
          }
        }}
      />
    );
  });

  return (
    <div style={{ height }} css={styles.wrapper}>
      <div css={styles.burgerMenuWrapper}>
        <div css={styles.logoWrapper}>
          {caisyProps?.CompanyInformationPublic?.logo && (
            <a href="/">
              <img
                src={caisyProps?.CompanyInformationPublic?.logo?.[0]?.src}
                alt="logo"
              />
            </a>
          )}
        </div>
        <BurgerMenu
          isActive={height > initialHeight}
          onClick={() => {
            if (height > initialHeight) {
              setHeight(initialHeight);
            } else {
              setHeight(initialHeight + navItems?.length * navItemHeight);
            }
          }}
        />
      </div>
      <div>{navItems}</div>
    </div>
  );
};

export default ResponsiveSidebar;
