import React from "react";
import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const PhotoGalleryWrapper = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-right: -15px;
    margin-left: -15px;
  }
`;

export const PhotoGalleryItem = styled.div`
  padding: 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
  }

  img {
    cursor: pointer;
  }
`;

