import { hexIsLight } from "src/common/utils/colorHelper";

import { createGlobalStyle, css } from "styled-components";

import TextStyles from "./textStyles";
import { lightenDarkenColor } from "../../utils/lightenDarkenColor";

const DarkTheme = css`
    --back-1: #505050;
    --back-2: #404040;
    --back-3: #605950;
    --front-1: #000000;
    --front-2: #ffffff;
    --front-3: #efefef;
    --special-logobg: #fff;
`;

const LightTheme = css`
    --back-1: #ffffff;
    --back-2: #f9f9f9;
    --back-3: #eeeeee;
    --front-1: #000000;
    --front-2: #505050;
    --front-3: #797979;
    --special-logobg: #fff;
`;

export const CSSWithOpacityHoverAnimation = css`
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &:hover,
    &:focus {
        opacity: 0.8;
    }
`;

const base = css<{
    primaryColor?: string;
    backgroundColor?: string;
    brandAreaMaxWidth?: number;
    applyBodyBackground?: boolean;
}>`
    ${({ applyBodyBackground, backgroundColor }) =>
        applyBodyBackground &&
        backgroundColor &&
        css`
            body {
                background-color: ${backgroundColor};
            }
        `};
        
    div.brand-area {
        --primary-100: ${({ primaryColor }) => primaryColor || "#FF0001"};
        ${({ brandAreaMaxWidth }) => css`
            --brand-area-max-width: ${brandAreaMaxWidth || 1366}px;
        `};

        ${({ backgroundColor }) => (backgroundColor && hexIsLight(backgroundColor) ? LightTheme : DarkTheme)};
        ${({ backgroundColor }) =>
            backgroundColor &&
            `${backgroundColor}`.length == 7 &&
            hexIsLight(backgroundColor) &&
            css`
                --back-1: ${backgroundColor};
                --back-2: ${lightenDarkenColor(backgroundColor, -6)};
                --back-3: ${lightenDarkenColor(backgroundColor, -17)};
            `};
        ${({ backgroundColor }) =>
            backgroundColor &&
            `${backgroundColor}`.length == 7 &&
            !hexIsLight(backgroundColor) &&
            css`
                --back-1: ${backgroundColor};
                --back-2: ${lightenDarkenColor(backgroundColor, 6)};
                --back-3: ${lightenDarkenColor(backgroundColor, 17)};
            `};

        .richText {
            ${TextStyles}
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a,
        li,
        ul,
        input,
        textarea,
        span,
        button,
        div {
            font-family: Arial, "sans-serif";
            hyphens: none;
        }
        input::placeholder,
        textarea::placeholder {
            color: var(--front-3);
        }
    }
`;

export const BrandAreaGlobalStyle = createGlobalStyle`
  ${base};
`;
