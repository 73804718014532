import React, { useContext } from "react";
import { IContext, Context } from "../..";
import SGoogleStars from "./Styles/SGoogleStars";

const GoogleStars = (props): any => {
  const p = useContext<IContext>(Context);
  const totalReviewsCount = p?.dealer?.totalReviewsCount;
  const averageRating = p?.dealer?.averageRating;

  if (!averageRating || !totalReviewsCount || totalReviewsCount === 0) {
    return null;
  }

  const starSize = props.starSize || 16;
  /* changeable indicates whether the amount of full 
        and half stars should change when clicking on it and whether stars should be hoverable.
        amountOfStars are the selected stars.
    */
  let fullStarsToRender: number;

  fullStarsToRender = Math.ceil(averageRating);

  const halfStarsToRender = Math.ceil(5 - fullStarsToRender);
  const stars: React.ReactElement[] = [];

  for (let i = 0; i < fullStarsToRender; i++) {
    stars.push(
      <svg
        key={i}
        xmlns="http://www.w3.org/2000/svg"
        width={starSize}
        height={starSize}
        viewBox="0 0 24 24"
      >
        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
      </svg>
    );
  }
  for (let i = 0; i < halfStarsToRender; i++) {
    stars.push(
      <svg
        key={fullStarsToRender + i}
        width={starSize}
        height={starSize}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z" />
      </svg>
    );
  }

  return (
    <SGoogleStars overwriteStyle={props.overwriteStyle}>
      <div>
        <strong>Kundenstimmen:</strong>
        <br></br>
      </div>
      {`${averageRating} Sterne aus ${totalReviewsCount} Bewertungen auf Google`}
      <br></br>
      {stars}
    </SGoogleStars>
  );
};

GoogleStars.displayName = "GoogleStars";
export { GoogleStars };
