
import React, { FC, FunctionComponent, useContext, useState } from "react";
import { Context, IContext } from "src/common";

import PlanningdateModal from "../PlanningDateModaL/PlanningDateModaL";
import { HaderContainer, Logo, LogoWrapper, ContactOptionWrapper, ToogleMenu, TogleMenuMobile, HaderContent, Flex } from "./Header.styles";
import Navigation from "./Navigation";

const ContactOption: FunctionComponent<{ phone: string; isDeployed: boolean; onToogleDeployed: any }> = ({ phone, isDeployed, onToogleDeployed }) => 
{
  const props = useContext<IContext>(Context);
  const contactSlug = props?.PrimaryNavigation?.items?.find(item => item.label === "Kontakt");
  const [isShowForm, toogleShowForm] = useState(false);
  return (
    <>
      {isShowForm && <PlanningdateModal onSetShowModal={toogleShowForm}/>}
      <ContactOptionWrapper isDeployed={isDeployed}>
        <div className="title">
          {isDeployed ?
            <div className="icon"><img src="/images/icon-dialog-bubble_t10.svg"/></div> :
            <div className="icon"><img src="/images/icon-dialog-bubble-green_t10.svg"/></div> 
          }
          <h1>{phone}</h1>
        </div>
        <div className="title" onClick={() => toogleShowForm(true)}>
          {isDeployed ?
            <div className="icon"><img src="/images/icon-kalender_t10.svg"/></div> :
            <div className="icon"><img src="/images/icon-kalender-green_t10.svg"/></div>
          }
          <h1>Terminanfrage</h1>
        </div>
        {!isDeployed &&
          <>
            <a href={`/${contactSlug?.link[0].slug}`}>
              <div className="title">
                <div className="icon"><img src="/images/icon-kontakt-green_t10.svg"/></div>
                <h1>Kontakt</h1>
              </div>
            </a>
            <ToogleMenu onClick={() => onToogleDeployed(!isDeployed)}>
              <div className="icon_menu"><i className="fa fa-bars"/></div>
              <h1>Menü anzeigen</h1>
            </ToogleMenu>
          </>
        }
      </ContactOptionWrapper>
    </>
  );
};

const Header: FC<{ pageNews?: boolean }> = ({ pageNews }) => 
{
  const props = useContext<IContext>(Context);
  const [isDeployed, toogleDeployed] = useState(true);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  const phone = props.CompanyInformationPublic?.phone;

  return (
    <HaderContainer isDeployed={isDeployed} pageNews={pageNews}>
      <HaderContent isDeployed={isDeployed} pageNews={pageNews}>
        <LogoWrapper isDeployed={isDeployed}>
          {logo && <a className="logo-container" href="/"><Logo src={logo.src}/></a>}
        </LogoWrapper>
        <ContactOption phone={phone} isDeployed={isDeployed} onToogleDeployed={toogleDeployed}/>
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} isDeployed={isDeployed} onToogleDeployed={toogleDeployed}/>
        {!isDeployed &&
        <TogleMenuMobile onClick={() => toogleDeployed(!isDeployed)}>
          <i className="fa fa-chevron-down"/>
        </TogleMenuMobile>
        }
      </HaderContent>
    </HaderContainer>
  );
};

export default Header;
