import styled from "styled-components";

export const CompanyInfoWrapper: any = styled.div`
  display: flex;
`;

export const InfoTitle: any = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  cursor: pointer;
  span.title {
    padding: 13px 11px;
    font-size: 11px;
    font-weight: 300;
    &:hover {
      color: #E67C19;
    }
  }

  p {
    font-size: 13px;
    font-weight: 300;
    line-height: 19.5px;
  }

  p.button {
    color: #e67c19;
    font-size: 13px;
    font-weight: 300;
    line-height: 19.5px;
    text-decoration: underline;
  }
  
  div.item-data {
    display: none;
    position: absolute;
    top: 42px;
    min-width: 160px;
    margin-top: 0!important;
    text-align: center;
    background: rgb(26, 26, 26);
    border-top: solid 2px #e67c19;
    padding: 30px;
    z-index: 9999999;
    div > table {
      margin: 0 0 15px;
      width: 100%;
    }
    span {
    font-size: 13px;
    font-weight: 300;
    line-height: 18.2px;
    }
  }

  &:hover {
    div.item-data {
      display: flex;
      justify-content: center;
    }
  }
`;

export const InfoLink: any = styled.a`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  cursor: pointer;
  padding: 13px 11px;
  font-size: 11px;
  font-weight: 300;
  &:hover {
    color: #E67C19;
  }
`;

export const MapWrapper: any = styled.a`
  #map {
    height: 130px;
    width: 190px;
    margin: 0;
  }
  input, button, p {
    display: none;
  }
  #routePanel {
    display: none;
  }
`;

export const Info: any = styled.div<{textAlign: string; margin: boolean}>`
  margin: ${({ margin }) => margin ? "0px 50px 0px 20px" : "0"};
  text-align: ${({ textAlign }) => textAlign};
`;
