import styled from "styled-components";

export const SSliderWrapper = styled.div`
    margin-bottom: 27px;
    padding: 27px;
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: 1;
        border-bottom: 1px solid ${props => props.theme.secondary};
        background: white;
        width: 40px;
        height: 40px;
        transform: rotate(-45deg);
    }
    padding: 0 !important;
    width: 100%;
    &:before {
        border-bottom-color: ${props => props.theme.secondary};
    }
    > div {
        border: solid 1px ${props => props.theme.secondary};
    }
`;
