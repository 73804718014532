import styled from "styled-components";
import { cutCorner } from "../../../../constants/layout";

export const SNavWrapper: any = styled.div`
    ${cutCorner};
    background-color: ${props => props.theme.primary};
    padding: 0 0 30px !important;
    svg {
        fill: white;
    }
`;
