export const ANIMATIONS = {
  fade: "fade",
  fadeeffect: "fade-up",
  "fade-down": "fade-down",
  "fade-left": "fade-left",
  "fade-right": "fade-right",
  "fade-up-right": "fade-up-right",
  "fade-up-left": "fade-up-left",
  "fade-down-right": "fade-down-right",
  "fade-down-left": "fade-down-left",
  flipeffect: "flip-up",
  "flip-down": "flip-down",
  "flip-left": "flip-left",
  "flip-right": "flip-right",
  "slide-up": "slide-up",
  "slide-down": "slide-down",
  "slide-left": "slide-left",
  "slide-right": "slide-right",
  zoomeffect: "zoom-in",
  "zoom-in-up": "zoom-in-up",
  "zoom-in-down": "zoom-in-down",
  "zoom-in-left": "zoom-in-left",
  "zoom-in-right": "zoom-in-right",
  "zoom-out": "zoom-out",
  "zoom-out-up": "zoom-out-up",
  "zoom-out-down": "zoom-out-down",
  "zoom-out-left": "zoom-out-left",
  "zoom-out-right": "zoom-out-right",
};

export const getAnimation = (animation: string): string => ANIMATIONS[animation];
