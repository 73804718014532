import React from 'react';

/**
 * Create a single particle Object.
 *
 * @private
 *
 * @param  {array}  profile - particle profile that contains type and velocity
 * @param  {object} bounds  - canvas width and height
 *
 * @return {object} particle Object
 */
function _createParticle(profile, { width, height }) {
	const { random } = Math;
	const {
		deltaX,
		deltaY,
		deltaOpacity,
		radius,
		color,
		opacity
	} = getParticleValues(profile);

	return {
		init() {
			this.x = random() * width;
			this.y = random() * -height;
			this.deltaX = deltaX;
			this.deltaY = deltaY;
			this.color = color;
			this.radius = radius;
			this.opacity = opacity;
			this.deltaOpacity = deltaOpacity;

			return this;
		}
	};
}

/**
 * Generates a specific amount of particles to be rendered
 * on the canvas based on the specified particle profile.
 *
 * @param  {array}  profile - particle profile that contains type and velocity
 * @param  {number} amount  - the amount of particles to be rendered
 * @param  {object} bounds  - canvas width and height
 *
 * @return {array} particle Objects to be rendered
 */
export function generateParticles(profile, amount, bounds) {
	const particles = [];

	while (amount--) {
		let particle = _createParticle(profile, bounds);

		particle.init();
		particles.push(particle);
	}

	return particles;
}

const PROFILE = [ 'snow', 'steady' ];
const AMOUNT = 800;
const WIDTH = 600;
const HEIGHT = 300;
const STYLES = {
	backgroundColor: '#0A2933',
	position: 'absolute',
	top: '0',
	left: '0'
};


export default class Snowparticles extends React.Component {
	draw (particles) {

		/**
		 * Note that angles are measured in radians:
		 *
		 * radians = (Math.PI / 180) * degrees
		 */
		const startAngle = 0;
		const endAngle = 2 * Math.PI; // 360 degrees in radians
		const antiClockwise = true;
		const { ctx, dynamicX } = this;
		const {
			width = WIDTH,
			height = HEIGHT,
			profile = PROFILE
		} = this.props;
		const [ type ] = profile;

		// Clear the canvas context before updating and animating the particles.
		(ctx).clearRect(0, 0, width, height);

		// Updates the particle values before (re) drawing to create an animation on the canvas.
		particles.forEach(particle => {
			const {
				deltaX,
				deltaY,
				color,
				radius,
				opacity,
			} = particle;

			// Update particle values before animating.
			particle.x += deltaX + 1.33 * dynamicX;
			particle.y += deltaY;

			// Update particle opacity based on particle type.
			switch (type) {
				case 'snow': {
					particle.opacity = opacity;

					break;
				}

			}

			// Style the particles.
			ctx.fillStyle = color;
			ctx.globalAlpha = particle.opacity;

			// Animate the particles.
			ctx.beginPath();
			ctx.arc(particle.x, particle.y, radius, startAngle, endAngle, antiClockwise);
			ctx.fill();
			ctx.closePath();

			// Re initialize the particle when it falls out of the view port.
			if (particle.y > height) {
				particle.init();
			}
		});

		this.animate(particles);
	}

	/**
	 * Animate by drawing all particles.
	 *
	 * @param  {array} particles - particle Objects to be rendered
	 */
	animate (particles) {
		window.requestAnimationFrame( this.draw.bind(this, particles) );
	}

	/**
	 * Calculates the dynamic particle x coordinate based on
	 * the mouse cursor position.
	 *
	 * @param  {object} event - the event Object
	 */
	handleMouseMove (event) {
		const { width = WIDTH } = this.props;

		this.dynamicX = event.pageX / width;
	}

	componentDidMount () {
		const {
			profile = PROFILE,
			amount = AMOUNT,
			width = WIDTH,
			height = HEIGHT
		} = this.props;

		this.dynamicX = 1;
		
		const particles = generateParticles(profile, amount, { width, height });

		this.animate(particles);
	}

	render () {
		const {
			width = WIDTH,
			height = HEIGHT,
			styles = STYLES
		} = this.props;

		return (
			<canvas
				width={ width }
				height={ height }
				style={ styles }
				ref={ canvas => this.ctx = canvas.getContext('2d') }
				onMouseMove={ this.handleMouseMove }
			>
			</canvas>
		);
	}
};