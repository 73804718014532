import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import Img from "../../../BaseComponents/Img";
import {
  FurtherAwardsWrapper,
  FurtherAwardsContet,
  ImageWrapper,
  FurtherSpan,
} from "./FurtherAwards.style";
const FurtherAwards: FunctionComponent = () => 
{
  const props = useContext(Context);
  const furtherAwards = props?.CompanyInformationPrivate?.furtherAwards;

  return (
    <FurtherAwardsWrapper>
      <FurtherSpan>Ausgezeichnet von</FurtherSpan>
      <FurtherAwardsContet>
        {furtherAwards?.map((item, i) => (
          <ImageWrapper key={i}>
            <Img quality={1600} key={i} src={[{ src: item?.src }]} alt={item?.title ?? "image"}/>
          </ImageWrapper>
        ))}
      </FurtherAwardsContet>

    </FurtherAwardsWrapper>
  );
};

export default FurtherAwards;
