import styled from "styled-components";

const SLink = styled.div<{isScrolling: boolean}>`
    display: inline;
    color: white;
    padding: 15px 27px;
    font-family: ahb-icons;
    font-size: ${({ isScrolling }) => isScrolling ? "32px" : "42.6667px"};
    font-style: italic;
`;

export default SLink;
