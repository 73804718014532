import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

const getSize = (lg: boolean): number => lg ? 454 : 232;

export const HeroContainer: any = styled.div<{ lg: boolean }>`
  max-width: calc(100vw);
  ${({ theme }) => theme.box};

  [data-slider-wrapper] > div {
    border: none;
    .slick-slider {
      height: ${({ lg }) => getSize(lg)}px;
      img {
        height: ${({ lg }) => getSize(lg)}px;
      }
    }
  }

  > div {
    img {
      height: ${({ lg }) => getSize(lg)}px;
    }
    button {
      display: none !important;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    max-width: ${({ theme }) => theme.maxWidth};
  }
`;

