import React, { useContext, Fragment, useState } from "react";
import { Context, Img, IAsset } from "src/common";
import SProductShowcase from "./Styles/SProductShowcase";
import Col from "src/common/BaseComponents/Col/Col";
import Row from "src/common/BaseComponents/Row/Row";
import ImageWithAspectRatio from "src/common/BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import { TheOnlySlider } from "../TheOnlySlider";
import { RichText } from "../RichText";
import Gallery from "src/common/BaseComponents/Gallery/Gallery";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import { TabBar, TabBarElement } from "./TabBar";
import IconDownload from "../ProductLandingBanner/Icons/IconDownload";
import { NonBindingRequestFrom } from "../NonBindingRequestFrom/NonBindingRequestFrom";
import useCollapse from "react-collapsed";
import Slider from "src/templates/t6/components/elements/Slider/Slider";
import { getProductFieldTitle } from "../../utils/getProductFieldTitle";

const ProductShowcase = () => {
    const props = useContext(Context);

    const [isExpanded, setExpanded] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse({
        isExpanded,
        duration: 600,
        collapsedHeight: 0,
    });

    if (!props.AllProduct) {
        return null;
    }

    const currIndex = props.AllProduct.findIndex((p) => {
        return p.slug === props.slug;
    });
    if (currIndex === -1) {
        return <h1>Das Produkt wurde nicht gefunden</h1>;
    }
    const currentProduct = props.AllProduct[currIndex];

    const advancedTypeDetails: any[] = [];

    Object.keys(currentProduct).map((key) => {
        if (currentProduct[key] !== undefined && currentProduct[key] !== null) {
            const title = getProductFieldTitle({ productTypename: currentProduct.__typename, fieldName: key });
            if (title != "") {
                advancedTypeDetails.push({
                    title: title,
                    value: Array.isArray(currentProduct[key]) ? currentProduct[key].join(", ") : currentProduct[key],
                });
            }
        }
    });

    let ImageOrGallery;
    const imgArray = currentProduct?.images?.filter((e) => e?.__typename === "Asset") as IAsset[];
    if (imgArray?.[0]) {
        if (imgArray.length > 1) {
            ImageOrGallery = (
                <TheOnlySlider>
                    {imgArray.map((image, i) => {
                        return (
                            <Fragment key={`image${i}`}>
                                <Img alt={image?.description} src={image?.src} placeholderHeight={"208px"} />
                            </Fragment>
                        );
                    })}
                </TheOnlySlider>
            );
        } else {
            ImageOrGallery = (
                <ImageWithAspectRatio
                    alt={imgArray?.[0]?.description}
                    src={imgArray?.[0]?.src}
                    height={342}
                    ratio={50}
                    quality={1080}
                />
            );
        }
    }

    return (
        <SProductShowcase>
            <Row fullWidth>
                <Col bronze={[1, 4, 1]} silver={[1, 12, 1]}>
                    <h1 className="ps-title">
                        {currentProduct?.title}{" "}
                        <span className="ps-title-price">
                            {" für "}
                            {currentProduct?.ourPrice} {" €"}{" "}
                        </span>
                    </h1>
                </Col>
                <Col bronze={[1, 4, 2]} silver={[1, 6, 2]} platinum={[1, 8, 2]}>
                    {imgArray?.[0] && (
                        <>
                            {imgArray.length > 1 ? (
                                <>
                                    <div className="ps-image">
                                        <Slider elements={imgArray} landscapeFormat></Slider>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="ps-image">
                                        <ImageWithAspectRatio
                                            alt={imgArray?.[0]?.description}
                                            src={imgArray?.[0]?.src}
                                            height={342}
                                            ratio={50}
                                            contain={true}
                                            quality={1080}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Col>
                <Col bronze={[1, 4, 3]} silver={[7, 12, 2]} platinum={[9, 12, 2]}>
                    <Spacer bronze={20} silver={1} />
                    <div className="ps-text">
                        <RichText textContent={currentProduct.text}></RichText>
                    </div>
                </Col>
                {!!currentProduct.services?.length && (
                    <>
                        <Col bronze={[1, 4, 4]} silver={[7, 12, 3]} platinum={[9, 12, 3]}>
                            <Spacer bronze={20} />
                            <h3>Unser Preis inklusive:</h3>
                            <div className="ps-service-gallery">
                                <Gallery fullWidth bronze={2} silver={2}>
                                    {currentProduct.services?.map((s, i) => {
                                        return (
                                            <div className="ps-service-icon" key={`plb2` + i}>
                                                <Img src={s.icon} />
                                                {s.title}
                                            </div>
                                        );
                                    })}
                                </Gallery>
                            </div>
                        </Col>
                    </>
                )}
                <Col bronze={[1, 4, 5]} silver={[7, 12, 4]} platinum={[9, 12, 4]}>
                    <Spacer bronze={20} />
                    {currentProduct.normalPrice && currentProduct.ourPrice && (
                        <div className="ps-our-price-before">
                            <span className="ps-price-before-text">Ehemaliger eigener Verkaufspreis</span>
                            <div>
                                <span className="ps-price-before-number">{currentProduct.normalPrice} &euro;</span>
                            </div>
                            <Spacer bronze={16}> </Spacer>
                        </div>
                    )}
                    <div className="ps-our-price">
                        <span className="ps-our-price-label-text">Jetzt für nur</span>
                        <span className="ps-our-price-label-number">
                            {" "}
                            {currentProduct.ourPrice}
                            {" €"}{" "}
                        </span>
                    </div>
                </Col>
                <Col bronze={[1, 4, 6]} silver={[1, 12, 5]}>
                    <Spacer bronze={40} />
                    <TabBar>
                        {currentProduct.productDescription &&
                            currentProduct.productDescription?.content?.length >= 1 && (
                                <TabBarElement label="Beschreibung">
                                    <RichText fragment textContent={currentProduct.productDescription}></RichText>
                                </TabBarElement>
                            )}
                        {((currentProduct.productTechnicalDetails &&
                            currentProduct.productTechnicalDetails?.content?.length >= 1) ||
                            (advancedTypeDetails && advancedTypeDetails?.length >= 1)) && (
                            <TabBarElement label="Technische Details">
                                <div className={"ps-product-details"}>
                                    <RichText fragment textContent={currentProduct.productTechnicalDetails}></RichText>
                                    {advancedTypeDetails && advancedTypeDetails?.length >= 1 && (
                                        <table>
                                            <tbody>
                                                {advancedTypeDetails.map((d, index) => {
                                                    return (
                                                        <Fragment key={`advancedTypeDetails${index}index`}>
                                                            {" "}
                                                            <tr>
                                                                {" "}
                                                                <td>
                                                                    {" "}
                                                                    <p>
                                                                        <span>{d?.title}</span>
                                                                    </p>{" "}
                                                                </td>{" "}
                                                                <td>
                                                                    <p>
                                                                        <span>
                                                                            {typeof d?.value === "boolean"
                                                                                ? !!d?.value
                                                                                    ? "Ja"
                                                                                    : "Nein"
                                                                                : d?.value}
                                                                        </span>
                                                                    </p>{" "}
                                                                </td>{" "}
                                                            </tr>{" "}
                                                        </Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </TabBarElement>
                        )}
                        {currentProduct?.productDownloads && currentProduct?.productDownloads?.length > 0 && (
                            <TabBarElement label="Downloads">
                                {currentProduct?.productDownloads?.map((d, i) => {
                                    return d?.src ? (
                                        <a
                                            href={d?.src}
                                            key={`download${i}`}
                                            className="ps-download"
                                            type="download"
                                            target="_blank"
                                        >
                                            {d?.title || "Bitte title setzten..." + " "} <IconDownload />
                                        </a>
                                    ) : null;
                                })}
                            </TabBarElement>
                        )}
                    </TabBar>
                </Col>
                <Col bronze={[1, 4, 7]} silver={[7, 9, 7]}>
                    <Spacer bronze={40} />
                </Col>
                {currIndex !== 0 && (
                    <Col bronze={[1, 2, 8]} silver={[7, 9, 8]}>
                        <a href={`/produkt/${props.AllProduct[currIndex - 1].slug}`}>
                            <button className="ps-button ps-nav ps-prev">vorheriges Angebot</button>
                        </a>
                    </Col>
                )}
                {currIndex !== props.AllProduct.length - 1 && (
                    <Col bronze={[3, 4, 8]} silver={[10, 12, 8]}>
                        <a href={`/produkt/${props.AllProduct[currIndex + 1].slug}`}>
                            <button className="ps-button ps-nav ps-next">nächstes Angebot</button>
                        </a>
                    </Col>
                )}
                <Col bronze={[1, 2, 9]} silver={[1, 3, 8]}>
                    <button
                        {...getToggleProps({
                            onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                        })}
                        className="ps-button ps-offer"
                    >
                        Unverbindliche Anfrage
                    </button>
                </Col>
                <Col {...getCollapseProps()} bronze={[1, 4, 10]} silver={[1, 12, 10]}>
                    <NonBindingRequestFrom productTitle={currentProduct?.title} />
                </Col>
            </Row>
        </SProductShowcase>
    );
};

export { ProductShowcase };
