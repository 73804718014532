import { Context } from "src/common";
import React, { FunctionComponent, useContext } from "react";

import Img from "../../../../BaseComponents/Img/Img";
import RichText from "../../../../BaseComponents/RichText/RichText";
import * as layout from "../../../../constants/layout";
import * as styles from "./HeaderContactRow.style";
import { getContactCompanyNameShort } from "../../../../../../common/components/ImprintStart";
import { getOpeningHoursContent } from "../../../../../../common/utils/getOpeningHoursContent";

export const HeaderContactRow: FunctionComponent = () => {
  const companyInformation = useContext(Context)?.CompanyInformationPublic;
  const openingHoursContent = getOpeningHoursContent();

  return (
    <div className="serviceNumberField" css={styles.wrapper}>
      <div
        css={layout.flexItem50Early}
      >
        <p className="serviceNumber" css={styles.phone}>
          {!!companyInformation?.serviceNumber
            ? companyInformation?.serviceNumber
            : companyInformation?.phone}
        </p>
        <div css={styles.serviceNumberText}>
          <Img src="/images/phone.svg" alt="phone icon" />
          <p>WIR SIND FÜR SIE DA — UNSERE SERVICENUMMER</p>
        </div>
      </div>
      <div className="companyInformationField" css={layout.flexItem25Early}>
        <div css={styles.richText}>
          {companyInformation?.companyName && (
            <h4>{getContactCompanyNameShort()}</h4>
          )}
          {companyInformation?.street && <p>{companyInformation?.street}</p>}
          {companyInformation?.postcode && companyInformation?.place && (
            <p>
              {companyInformation?.postcode} {companyInformation?.place}
            </p>
          )}
          <br />
          {companyInformation?.phone && <p>Tel: {companyInformation?.phone}</p>}
          {companyInformation?.fax && <p>Fax: {companyInformation?.fax}</p>}
          <br />
          {companyInformation?.email && (
            <a href={`mailto:${companyInformation?.email}`}>
              {companyInformation?.email}
            </a>
          )}
        </div>
      </div>
      <div className="openingHoursField" css={layout.flexItem25Early}>
        <div css={styles.richText}>
          <RichText
            textContent={openingHoursContent}
            overwriteStyle={styles.disableListStyle}
          ></RichText>
        </div>
      </div>
    </div>
  );
};
