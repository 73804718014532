
import React, { FC, useContext } from "react";
import { Context, getHeaderNavItemProps, IContext } from "src/common";

import CompanyInfo from "../CompanyInfo";
import { HeaderContainer, Logo, NavigationContainer, Head, HeadContent } from "./Header.styles";
import Navigation from "./Navigation/Navigation";

const Header: FC = () => 
{
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  const isContact = props?.PageContact?.slug === props?.slug;
  return (
    <HeaderContainer>
      <Head>
        <HeadContent>
          <div className="contact-data">
            <a href={`tel:${props?.CompanyInformationPublic?.phone}`}><i className="fas fa-phone"/><span>{props?.CompanyInformationPublic?.phone}</span></a>
            <a href={`mailto:?subject=${props?.CompanyInformationPublic?.email}`}><i className="far fa-envelope" /><span>{props?.CompanyInformationPublic?.email}</span></a>
          </div>
          <CompanyInfo isContact={isContact}/>
        </HeadContent>
      </Head>
      <NavigationContainer>
        {logo && <a href="/"><Logo src={logo.src} /></a>}
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} />
      </NavigationContainer>
    </HeaderContainer>
  );
};

export default Header;
