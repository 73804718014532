import styled, { css } from "styled-components";
import { MIN_DIAMOND, MIN_GOLD, MIN_MASTER, MIN_PLATINUM, MIN_SILVER } from "../../../constants/mediaquerys";

export interface ISFancyImageAspectContainerProps {
    brozeAspectRatio: number;
    silverAspectRatio: number;
    goldAspectRatio: number;
    platinumAspectRatio: number;
    diamondAspectRatio: number;
    masterAspectRatio: number;
    hasLoaded: boolean;
}

const Default = css`
    position: relative;
    z-index: 0;
    width: 100%;
    > .absolute-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    > .layer-z1 {
        z-index: 1;

        transition: opacity 0.3s ease-in-out;
        ${({ hasLoaded }) => hasLoaded && css`opacity: 0;`}
    }
    > .layer-z2 {
        z-index: 2;
    }

    :before {
        pointer-events: none;
        content: "";
        width: 100%;
        position: relative;
        display: block;
        padding-bottom: ${(props: ISFancyImageAspectContainerProps) => (1 / props.brozeAspectRatio) * 100}%;
    }
`;

const Silver = css`
    :before {
        padding-bottom: ${(props: ISFancyImageAspectContainerProps) => (1 / props.silverAspectRatio) * 100}%;
    }
`;

const Gold = css`
    :before {
        padding-bottom: ${(props: ISFancyImageAspectContainerProps) => (1 / props.goldAspectRatio) * 100}%;
    }
`;

const Platinum = css`
    :before {
        padding-bottom: ${(props: ISFancyImageAspectContainerProps) => (1 / props.platinumAspectRatio) * 100}%;
    }
`;

const Diamond = css`
    :before {
        padding-bottom: ${(props: ISFancyImageAspectContainerProps) => (1 / props.diamondAspectRatio) * 100}%;
    }
`;
const Master = css`
    :before {
        padding-bottom: ${(props: ISFancyImageAspectContainerProps) => (1 / props.masterAspectRatio) * 100}%;
    }
`;

const SFancyImageAspectContainer = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
    ${MIN_MASTER`${Master}`};
`;

export default SFancyImageAspectContainer;
