import React, { FunctionComponent, useContext } from "react";
import { Context, LogoBannerHorizontal, ShareLinks } from "src/common";
import { LineLogo } from "src/common/components/LineLogo";
import { GoogleStars } from "../../../../../common/components/GoogleStars/GoogleStars";
import {
  getContactCompanyNamePrefix,
} from "../../../../../common/components/ImprintStart";

import { hexIsLight } from "../../../../../common/utils/colorHelper";
import {
  LogoBannerHorizontalWrapper,
  Wrapper,
  CenteredContent,
  Columns,
  LineLogoWrapper,
  NavLinks,
  ShareOnSocialMediaWrapper,
  Address,
  LinksInAddress,
  NavLinkInAddress,
} from "./Footer.style";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";

export const FooterT3: FunctionComponent = () => {
  const props = useContext(Context);
  const palette = props?.Settings;
  const logoBannerCompanies = props?.AllBrand;
  const navItems = props?.Footer?.Navigationspunkte;
  const companyInformation = props?.CompanyInformationPublic;

  let bgColor = palette.themeBackgroundColourIsland || "#666";
  let textColor = palette.themeTextColor || "#000";
  try {
    const lightBg = hexIsLight(bgColor);
    const lightText = hexIsLight(textColor);
    if (!lightBg && !lightText) {
      textColor = "#fff";
    }
    if (lightBg && lightText) {
      textColor = "#000";
    }
  } catch {}

  return (
    <Wrapper
      background={palette.themeBackgroundColourIsland}
      textColor={textColor}
    >
      <CenteredContent>
        {!props?.slug && logoBannerCompanies?.length > 1 && (
          <LogoBannerHorizontalWrapper background={"#ffffff"}>
            <LogoBannerHorizontal
              whiteBackground={true}
              shadowOverlayVisible={false}
            />
          </LogoBannerHorizontalWrapper>
        )}

        <Columns>
          <LineLogoWrapper id="linelogow1">
            <LineLogo />
            {(props?.Footer as any)?.customHtml && (
              <div
                id="customHtmlFooter"
                dangerouslySetInnerHTML={{
                  __html: (props.Footer as any).customHtml,
                }}
              />
            )}
          </LineLogoWrapper>
          {navItems?.length > 0 && (
            <NavLinks>
              {navItems?.map((item, i) => {
                if (!item?.slug || !item?.nameInNavigation) {
                  return null;
                }

                return (
                  <li key={i}>
                    <a href={item?.slug}>{item?.nameInNavigation}</a>
                  </li>
                );
              })}
            </NavLinks>
          )}
          <div>
            <h4>Website teilen</h4>
            <ShareOnSocialMediaWrapper>
              <ShareLinks></ShareLinks>
              {/* {companyInformation?.facebook && (
                <a
                  className="facebook"
                  href={addHttpsToLinks(companyInformation?.facebook)}
                  title={"Bei Facebook teilen"}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                  <span>teilen</span>
                </a>
              )}
              {companyInformation?.twitter && (
                <a
                  className="twitter"
                  href={addHttpsToLinks(companyInformation?.twitter)}
                  title={"Bei Twitter teilen"}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                  <span>tweet</span>
                </a>
              )}
              {companyInformation?.pintrest && (
                <a
                  className="pintrest"
                  href={addHttpsToLinks(companyInformation?.pintrest)}
                  title={"Bei Pinterest teilen"}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>pin it</span>
                </a>
              )}
              {companyInformation?.email && (
                <a
                  className="mail"
                  href={`mailto:${companyInformation?.email}`}
                  title={"Per E-Mail teilen"}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
                  </svg>
                  <span>mail</span>
                </a>
              )} */}
            </ShareOnSocialMediaWrapper>
          </div>
          <Address>
            {companyInformation?.companyFormOfLaw &&
              companyInformation?.companyName && (
                <p>
                  <strong>{getContactCompanyNamePrefix()}</strong>
                </p>
              )}
            {companyInformation?.street && <p>{companyInformation?.street}</p>}
            {companyInformation?.postcode && companyInformation?.place && (
              <p>
                {companyInformation?.postcode} {companyInformation?.place}
              </p>
            )}
            <LinksInAddress>
              {companyInformation?.phone && (
                <span>Telefon: {companyInformation?.phone}</span>
              )}
              {companyInformation?.email && (
                <NavLinkInAddress href={`mailto:${companyInformation?.email}`}>
                  E&#8209;Mail
                </NavLinkInAddress>
              )}
              <span id="footeraddressnav1">
              {navItems?.map((item, i) => {
                if (!item?.slug || !item?.nameInNavigation) {
                  return null;
                }
                return (
                  <NavLinkInAddress key={i} href={item?.slug}>
                    {item.nameInNavigation}
                  </NavLinkInAddress>
                );
              })}
              </span>
            </LinksInAddress>
            <GoogleStars />
          </Address>
        </Columns>
        <div style={{ display: "flex", flexWrap: "wrap", paddingTop: "8px", fontSize: "12px" }}>
          <CopyrightNotice />
        </div>
      </CenteredContent>
    </Wrapper>
  );
};
