import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
  width: 300px;
  margin-right: 40px;
  * {
    color: white;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: none;
  }
`;

export const logoWrapper: any = css`
  padding: 27px 27px 0 27px;
`;

export const logo: any = css`
  margin-bottom: 40px;
  width: 100%;
  height: auto;
`;

export const gelectraInfo: any = css`
  padding: 0 27px;

  ul {
    margin-bottom: 13.5px;
  }

  p {
    margin-bottom: 13.5px;
  }
`;

export const navItem_IconRow: any = css`
  padding: 10px 27px;
  border-top: 1px solid white;
  > a:first-child {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    img {
      height: 52px;
      width: 52px;
      margin-right: 15px;
    }
  }
  a {
    display: block;
  }
`;

export const subItem: any = css`
  padding: 8px 8px 8px 85px;
  text-transform: uppercase;
  font-size: 13px;
  &:last-child {
    padding-bottom: 0;
  }
`;

export const itemWrapper: any = css`
  background-color: ${(props) => props.theme.secondary};
`;

export const richText: any = css`
  ol,
  ul {
    padding: 0;
  }
`;

export const blackFooterBox: any = css`
  background-color: black;
  img {
    margin-bottom: 120px;
    width: 100%;
    height: auto;
    max-width: 300px;
    max-height: 300px;
  }
  nav a {
    display: block;
  }
`;

export const facebookIcon: any = css`
  height: 40px;
  width: auto;
  fill: white;
`;
