import styled, { css } from "styled-components";

import { PADDING } from "../../../constants/grid";
import {
    MIN_SILVER,
    MIN_GOLD,
    MIN_PLATINUM,
    MIN_DIAMOND
} from "../../../constants/mediaquerys";

const Default = css`
  padding: 0 ${PADDING.BRONZE}px;
`;

const Silver = css`
  padding: 0 ${PADDING.SILVER}px;
`;

const Gold = css`
  padding: 0 ${PADDING.GOLD}px;
`;

const Platinum = css`
  padding: 0 ${PADDING.PLATINUM}px;
`;

const Diamond = css`
  padding: 0 ${PADDING.DIAMOND}px;
`;

const SRowOuter = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SRowOuter;
