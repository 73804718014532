import React, { FunctionComponent, useContext, useState } from "react";
import { Context } from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import textStyles from "../../../constants/textStyles";
import { PlanningDateModaL } from "../PlanningDateModaL/PlanningDateModaL";
import * as styles from "./Footer.style";
import SFooterWrapper,
{
  SectionFooterWrapper,
  AreasWrapper,
  AdressInfoWrapper,
  ContactWrapper,
  Separator,
}
  from "./Styles/SFooterWrapper";

interface IFooterLinkInColumnList 
{
  title: string;
  link: string;
  isBold: boolean;
}

export const Footer: FunctionComponent = () => 
{
  const props = useContext(Context);
  const companyInformation = props.CompanyInformationPublic;
  const IPrimaryNavigation = props.PrimaryNavigation.items || [];
  const [isShowPlanningDateModaL, toogleShowPlanningDateModaL] = useState(false);
  console.log(IPrimaryNavigation);
  return (
    <SFooterWrapper>
      {isShowPlanningDateModaL && <PlanningDateModaL onSetShowModal={toogleShowPlanningDateModaL}/>}
      <SectionFooterWrapper>
       
        <AreasWrapper>
          <h4 css={textStyles.h4Black}>Bereiche</h4>
          {IPrimaryNavigation.map((item, i) =>
            <>
              {item?.link && <a css={textStyles.pGray} key={i} href={item?.link[0]?.slug}>{item.label}</a>} 
            </>
          )
          }
          <Separator />
          <div style={{ display: "flex" }}>
            <p css={styles.linksWrapper}>Impressum</p>

            <p css={styles.linksWrapper}>&nbsp;Datenschutzerklärung</p>
          </div>
        </AreasWrapper>
       
        <AdressInfoWrapper> 
          <h4 css={textStyles.h4Black}>Kontakt</h4>
          <p css={textStyles.pGray}>{getContactCompanyNameShort()}</p>
          <p css={textStyles.pGray}>{companyInformation?.street}</p>
          <p css={textStyles.pGray}>{companyInformation?.postcode} {companyInformation?.place}</p>
          <div css={"margin-top: 20px"}></div>
          <p css={textStyles.pGray}>{`Telefon: ${companyInformation?.phone}`}</p>
        </AdressInfoWrapper>
       
        <ContactWrapper>
          <h4 css={textStyles.h4Black}>Beratungstermin</h4>
          <p css={textStyles.pGray}>Wir beraten Sie gerne persönlich. Vereinbaren Sie dafür einfach einen Termin mit uns:</p>
          <div css={styles.linksBoldContent} onClick={() => toogleShowPlanningDateModaL(!isShowPlanningDateModaL)}>
            <a css={styles.linksBoldWrapper}>Beratungstermin vereinbaren</a>
          </div>
         
          <h4 css={textStyles.h4Black}>Bleib auf dem Laufenden</h4>
        </ContactWrapper>
        
      </SectionFooterWrapper>
      
      <Separator />
      
      <SectionFooterWrapper>
        <div css={styles.footerBottom}>
          {props?.CompanyInformationPrivate?.showInKitchenCompass ? (
            <a href={"https://www.kuechenkompass.com/"}>
              <img src={"/images/kompass.png"} alt={"Küchenkompass"} />
            </a>
          ) : null}
          <p css={textStyles.SGray}>© Copyright 2021 - dk Testpartner Layout Ahorn</p>
        </div>
      </SectionFooterWrapper>
    </SFooterWrapper>
  );
};

export default Footer;
