import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const LinkBanner = styled.div``;

export const TextTeaser = styled.div`
  width: 100%;
  height: 89px;
  margin: 30px 0px 30px 0px;
  display: flex;
`;

export const TextTeaserLeft = styled.div`
  background: url(/images/kw_text_teaser_bg_left.png);
  width: 10px;
  height: 89px;
`;

export const TextTeaserMiddle: any = styled.div`
  position: relative;
  background: url(/images/kw_text_teaser_bg_middle.png);
  width: calc(100% - 110px);
  height: 89px;
  float: left;
  h2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0px 25px;
    color: #f9b000;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;

    @media (${BREAKPOINTS_DM.silver_768}) {
      font-size: 29px;
      line-height: 35px;
    }
  }
`;

export const TextTeaserRight = styled.div`
  background: url(/images/kw_text_teaser_bg_right.png);
  width: 50px;
  height: 89px;
`;

export const TextTeaserLink = styled.div`
  font-weight: 700;
  width: 50px;
  height: 89px;
  color: #f9b000;
  padding-left: 10px;
  font-size: 15px;
  line-height: 85px;
`;
