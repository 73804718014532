import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
  align-items: center;
  background-image: url("/images/bg_award_t11.gif");
  display: flex;
  flex-direction: column;
  
  padding: 50px 0 80px;
  width: 100%;

  h3 {
    color: #ccc !important;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    padding: 10px 0;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 333px;
    padding: 50px 0 0;
  }
`;

export const FurtherAwardsWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }
`; 
export const ImageWrapper: any = styled.div`
  width: 240px;
  height: 137.14px;
  margin: 10px 30px 0;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 280px;
    height: 160px;
  }
`;
