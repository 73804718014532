import {
  getIfNewsShouldBeDisplayByDate,
  Img,
  IProduct,
  RichText,
} from "src/common";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import IconAngle from "src/common/components/ProductLandingBanner/Icons/IconAngle";
import styled from "styled-components";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

const SItemDefault = styled.div`
  &:not(.isProduct) {
    .cpg-item-head {
      h2 {
        display: inline;
      }
    }
    .cpg-item-head-arrow {
      margin-left: 5px;
      opacity: 0;
      display: inline;
      svg {
        display: inline;
      }
    }
    &:hover {
      .cpg-item-head-arrow {
        opacity: 1;
      }
    }
  }

  &.isProduct {
    .cpg-item-head-arrow {
      display: none;
      svg {
        display: none;
      }
    }
    div.imageWrapper {
      height: 400px;
      background: white;

      img {
        object-fit: contain !important;
      }
    }
  }

  a.cpg-item > .cpg-item-wrapper {
    .cpg-item-img {
      @media (${BREAKPOINTS_DM.silver_768}) {
        height: 272px;
        .imageWrapper {
          height: 272px;
        }
      }
    }
    .cpg-price {
      padding: 10px 0;
      .cpg-price-text {
        color: #e4007e;
      }
      .cpg-price-number {
        color: #e4007e;
        margin-bottom: 28px;
      }
    }
    .cpg-price-next {
      background-color: #e4007e;
      color: white;
      display: block;
      width: 130px;
      text-align: center;
      svg {
        display: none;
      }
    }
  }
  a.cpg-item > .cpg-item-wrapper {
    ul {
      list-style-position: inside;
      * {
        list-style-position: inside;
      }
      li {
        display: list-item;
        list-style: disc;
      }
    }
    ol {
      list-style-position: inside;
      * {
        list-style-position: inside;
      }
      li {
        display: list-item;
        list-style: decimal;
      }
    }
  }
  a.cpg-item > .cpg-item-wrapper.with-img {
    @media (${BREAKPOINTS_DM.silver_768}) {
      display: flex;
      .cpg-item-b1 {
        flex-basis: 50%;
      }
      .cpg-item-b2 {
        flex-basis: 50%;
        padding-left: 28px;
      }
    }
  }
`;

export const ContinungGalleryProductItemT3 = ({ item, i }) => {
  if (!item) {
    return item;
  }
  const product = item?.link?.[0] as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const normalPrice = link?.normalPrice;
  const price = link?.ourPrice;
  const slug = link?.slug;

  const externLink =
    link?.__typename == "ExternalLink" ? link?.externalLinkAddress : null;

  if (
    isProduct &&
    product?.acceptAGB &&
    !getIfNewsShouldBeDisplayByDate({
      startDate: product?.startDate,
      endDate: product?.endDate,
    })
  ) {
    return null;
  }

  return (
    <SItemDefault className={isProduct && "isProduct"}>
      <a
        className="cpg-item"
        href={
          externLink
            ? "https://" +
              `${externLink}`.replace("http:", "https:").replace("https://", "")
            : slug
            ? `/${isProduct ? "produkt/" : ""}${slug}`
            : undefined
        }
        target={externLink ? "_blank" : undefined}
      >
        <div
          className={`cpg-item-wrapper${
            item.thumbnail?.[0] ? " with-img" : " no-img"
          }`}
        >
          <div className="cpg-item-b1">
            <div
              className={`cpg-item-img${
                item.thumbnail?.[0] ? " with-img" : " no-img"
              }`}
            >
              {item.thumbnail?.[0] && (
                <div className="imageWrapper">
                  <Img src={item.thumbnail}></Img>
                </div>
              )}
            </div>
          </div>
          <div className="cpg-item-b2">
            <div className="cpg-item-head">
              <h2>{item.title}</h2>
              <div className="cpg-item-head-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="9"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                </svg>
              </div>
              <Spacer bronze={20} />
              <div className="cpg-item-richt-text">
                {item?.text?.content && (
                  <RichText fragment={true} textContent={item?.text}></RichText>
                )}
              </div>
            </div>

            {isProduct && (
              <div className="cpg-price">
                {normalPrice && price && (
                  <>
                    <span className="cpg-price-before-text">
                      Ehemaliger eigener Verkaufspreis
                    </span>
                    <div>
                      <span className="cpg-price-before-number">
                        {normalPrice} &euro;
                      </span>
                    </div>
                    <Spacer bronze={16}> </Spacer>
                  </>
                )}

                <span className="cpg-price-text">Jetzt für nur</span>
                <div>
                  {price && (
                    <span className="cpg-price-number">{price} &euro;</span>
                  )}
                  <span className="cpg-price-next">
                    zum Angebot <IconAngle />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </a>
      <Spacer bronze={20} />
    </SItemDefault>
  );
};
