import styled, { css } from "styled-components";

import { BREAKPOINTS_DM, BREAKPOINTS } from "../../../constants/mediaquerys";

import { centeredContent } from "../../../constants/layout";
import { textStyleDefaults } from "../../../constants/textStyleDefaults";

export const Wrapper: any = styled.div``;

export const TwoColumnLayout = styled.div<{ twoColumnLayout: boolean }>`
  ${({ twoColumnLayout }) =>
    twoColumnLayout &&
    `display: flex;
    flex-direction: row;
    > div {
        width: 50%;
    }
    > div:not(:first-child) {
        padding-left: 28px;
    }`}
`;

export const PageContentWrapper = styled.div<{
  textColor: string;
  backgroundColor: string;
}>`
  min-height: calc(100vh - 331px);
  position: relative;
  padding: 30px 0 60px;
  z-index: 1;
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  color: ${({ textColor }) => (textColor ? textColor : "#222")};
  display: flex;
  flex-direction: column;
  > * {
    width: 100%;
  }
  h3,
  h4,
  h2,
  h1 {
    font-weight: 600;
  }
  .mobileHeading {
    &, h1, h1>span, p, p>span{
      font-size: 24px;
    }
    p, p>span{
      font-weight: 200;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    > * {
      width: 48%;
    }
    .mobileHeading {
      display: none;
    }
  }
`;

export const FlexWrapper: any = styled.div<{ twoColumnLayout: boolean }>`
  ${({ twoColumnLayout }) =>
    twoColumnLayout &&
    `
    display: flex;
    flex-direction: column;
    > *:nth-child(2) {
        margin-top: 40px;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
        justify-content: space-between;
        > * {
            width: 48%;
        }
        > *:nth-child(2) {
            margin-top: 0;
        }
    }`}
`;

export const headingNoneDesktop: any = css`
  font-size: 24px;
  display: block;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const Heading: any = styled.h1`
  font-size: 24px;
  line-height: 1.46667;
  margin: 1rem 0;
  display: block;
`;

export const HeadingSec: any = styled.h3`
  /* font-size: 24px; */
  font-size: 1.07143rem;
  line-height: 1.46667;
  margin: 1rem 0;
  display: block;
`;

export const InlineImage: any = styled.div<{
  background: string;
  color: string;
}>`
  display: flex;
  margin-bottom: 28px;
  margin-top: 28px;
  div:first-child {
    width: 33%;
    margin-right: 28px;
    > p {
      padding: 14px;
      color: ${({ color }) => (color ? color : "#fff")};
      background-color: ${({ background }) =>
        background ? background : "#666"};
    }
  }
  div:not(:first-child) {
    width: 66%;
    > img {
      width: 100%;
      height: auto;
    }
  }
`;

export const mainText: any = css`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  > div {
    float: left;
  }
  strong p {
    margin-top: 20px;
  }
  h2 {
    a {
      font-size: 1.14286rem;
      font-weight: 600;
      span {
        font-size: 24px;
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
    > span {
      font-weight: 600;
    }
  }
  p {
    margin-bottom: 0.5rem;
  }
  ${textStyleDefaults}
`;

// image not fixed //

export const mainImageNotFixed: any = css`
  margin-bottom: 25px;
  width: 100%;
`;

export const headingImageNotFixed: any = css`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 13px;
`;

export const Article: any = styled.article`
  margin-top: 50px;
  /* &:first-child {
      margin-top: 50px;
    } */
  h3,
  h4,
  h2,
  h1 {
    font-weight: 600;
    margin: 1.5em 0 1em;
    &:first-child {
      margin-top: 0;
    }
  }
  div > img {
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 228px;
    }
  }
  /* div:not(:first-of-type) {
      margin: 15px 15px 0 0;
      width: 90%;
      max-width: 300px;
    } */
  > div > div:last-child  {
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 68%;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 73%;
    }
  }
  > div {
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const ArticleImageWrapper: any = styled.div`
  min-width: 100%;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    min-width: 27%;
    width: 27%;
  }
  > div {
    margin-bottom: 14px;
  }
`;

export const UniversalComponentsRendererWrapper: any = styled.div<{
  colorText: string;
  colorPrice: string;
}>`
  a.cpg-item {
    h2 {
      color: ${({ colorText }) => (colorText ? colorText : "#222")};
    }
  }
  .cpg-price {
    color: ${({ colorPrice }) => (colorPrice ? colorPrice : "#222")};
    span {
      color: ${({ colorPrice }) => (colorPrice ? colorPrice : "#222")};
    }
  }
`;

export const TopComponentWrapper: any = styled.div<{
  priceColor: string;
  textColor: string;
}>`
  margin: auto;
  .ps-text {
    color: ${({ textColor }) => (textColor ? textColor : "#222")};
  }
  .ps-title-price,
  .ps-our-price {
    span {
      color: ${({ priceColor }) => (priceColor ? priceColor : "#D40046")};
    }
  }
  .ps-button.ps-offer {
    color: #fff;
  }
`;

export const CenteredContent: any = styled.div<{
  mt: number;
  textColor: string;
}>`
  ${centeredContent};
  margin-top: ${({ mt }) => mt}px;
`;

export const ImagesWrapper: any = styled.div<any>`
  display: flex;
  flex-direction: column;
  div:first-child {
    width: 100%;
    ${({ withCaption }) => (withCaption ? `` : `margin: 0 0 10px;`)}
    > img {
      width: 100%;
      height: auto;
    }
  }

  div:not(:first-child) {
    width: 100%;
    margin: 10px 0;
    > img {
      width: 100%;
      height: auto;
    }
    > p {
      padding: 14px;
      color: #fff;
      background-color: #666;
    }
  }
`;
