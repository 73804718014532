import React from "react";
import { getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";

import { SProductItemT8, Title, TextWrapper, TextPrice, TextSecondary, PriceWrapper, Wrapper } from "./Styles/SProductItemT8";

export const ProductItemT8 = ({ item, i }) => 
{
  if(!item) 
  {
    return item;
  }
  const product = (item?.link?.[0]) as IProduct;
  const isProduct = product?.__typename.includes("Product");
  const link = item?.link?.[0] as any;
  const price = link?.ourPrice;
  const slug = link?.slug;
  if(isProduct && product?.acceptAGB && !getIfNewsShouldBeDisplayByDate({ startDate: product?.startDate, endDate: product?.endDate })) 
  {
    return null;
  }
  return (
    <Wrapper>
      <SProductItemT8>
        <Img src={item.thumbnail}/>
        <TextWrapper>
          <a href={`/${isProduct ? "produkt/" : ""}${slug}`}>
            <Title>{item?.title}</Title>
            <PriceWrapper>
              {price && <TextPrice>{price} Euro</TextPrice>}
              <TextSecondary>
                    zum Angebot
              </TextSecondary>
            </PriceWrapper>
          </a>
        </TextWrapper> 
      </SProductItemT8>
    </Wrapper>
    
  );
};
