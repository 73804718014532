// @ts-nocheck
import styled, { css } from "styled-components";

const Default = css`
    svg {
        fill: rgb(255, 204, 102);
        margin-right: 4px;
        margin-top: 4px;
        transform-origin: top left;
    }

    &.changeable svg {
      cursor: pointer;
    }
    >div{
      margin-top: 2rem;
    }
    strong{
      font-weight: 700;
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SGoogleStars = styled.div<{overwriteStyle: any}>`
  ${Default}
  ${({overwriteStyle}) =>  overwriteStyle ? overwriteStyle : ""}
`;
export default SGoogleStars;
