import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const BurguerIcon: any = styled.div`
  height: 34px;
  width: 57px;
  background-image: url("/images/sprite-sa3ca3ca63e.png");
  background-position: 0px -305px;
  background-repeat: no-repeat;
  position: absolute;
  top: calc(64px / 2 - 34px / 2);
  left: 10px;
  cursor: pointer;
  z-index: 999;
`;

export const NavigationLink: FC<any> = styled.li<{ isActive: boolean }>`
  display: flex;
  margin-bottom: 1px;
  > a {
    position: relative;
    color: #EFEFEF;
    display: block;
    font-weight: 300;
    padding: 8px 0 8px 48px;;
    line-height: 18.375px!important;
    width: 100%;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;

    @media (${BREAKPOINTS_DM.silver_768}) {
      color: #F8F8F8;
      line-height: unset;
      padding-left: 48px;
      padding-right: 11.5px;
      padding-top: 2.8px 0;
      font-size: 14px;
      background-image: none;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const NavigationIcon: FC<any> = styled.img<{ src?: string }>`
  ${({ src }) => !src && "display: none;"}
  position: absolute;
  left: 6px;
  bottom: 4px;
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const SubItem: any = styled.div`
  color: #EFEFEF;
  display: block;
  font-weight: 300;
  padding: 8px 0 8px 48px;;
  line-height: 18.375px;
  width: 100%;
  font-family: sofia_pro_lightregular,Helvetica,Arial,sans-serif;
  font-size: 14px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    color: #F8F8F8;
    line-height: unset;
    padding-left: 48px;
    padding-right: 11.5px;
    padding-top: 2.8px 0;
    font-size: 14px;
    background-image: none;
  }
`;

export const IconWrapper: any = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  background-color: ${({ theme }) => theme.palette.light_blue};
  width: 100%;
  padding-left: 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const Icon: any = styled.div<{isShowSubItems: boolean}>`
  transform: ${({ isShowSubItems }) => isShowSubItems && "rotate(90deg)"};
`;

export const NavigationLinks = styled.div<{isDeployed: boolean}>`
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  width: 100%;
  box-shadow: -2px -2px 5px rgba(50,50,50,.5);
  height: 100%;

  ${BurguerIcon} {
    display: none;
    right: 11.5px;
  }

  ul {
    width: 100%;
    list-style: none;
    height: 100%;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    width: unset;
    box-shadow: none;
    height: 100%;
    padding: ${({ isDeployed }) => isDeployed ? "16px 0 0 10px" : "0 0 0 10px"};

    ul {
      height: 100%;
    }
  }
`;

export const NavigationContainer: FC<any> = styled.div<{ active: boolean; isDeployed: boolean }>`
  display: flex;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  ${BurguerIcon} {
    display: none;
  }
    a.home {
      color: #EFEFEF;
      display: flex;
      font-weight: 300;
      padding: 8px 0 8px 48px;;
      line-height: 18.375px;
      width: 100%;
      font-family: sofia_pro_lightregular,Helvetica,Arial,sans-serif;
      font-size: 14px;
    }
    div.icon {
      font-size: 17px;
      margin-left: -38px;
      margin-right: 19px;
    }
`;

export const SubItemWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToogleMenu: any = styled.div`
  color: #777878;
  display: flex;
  font-weight: 300;
  line-height: 40px;
  width: 100%;
  font-family: sofia_pro_lightregular,Helvetica,Arial,sans-serif;
  padding-left: 48px;
  padding-right: 11.5px;
  padding-top: 2.8px 0;
  font-size: 14px;
  background-image: none;
  cursor: pointer;

  div.icon_mobile {
    color: #fff;
    font-size: 24px;
    margin: 0 auto;
    padding-right: 36.5px;
  }

  div.icon {
    display: none;
    font-size: 17px;
    margin-left: -38px;
    margin-right: 19px;
  }
  span {
    display: none;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: fit-content;
    div.icon {
      display: initial;
    }
    span {
      display: initial;
    }
    div.icon_mobile {
      display: none;
    }
  }
`;
