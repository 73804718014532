import { css } from "styled-components";

import { BREAKPOINTS_DM } from "./mediaquerys";
import { theme } from "./themes";

export const centeredContent: any = css`
    max-width: ${theme.maxWidth}px;
    width: 90%;
    margin: 0 auto;
`;

export const contentWrapper: any = css`
    padding: 60px 0;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        padding: 30px 0;
    }
`;

export const flexItem50: any = css`
    width: calc(50% - 13.5px);
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        width: 100%;
    }
`;