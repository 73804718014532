// @ts-nocheck
import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";
import { Container } from '../../../BaseComponents/Grid';
import { Grid } from '../../../BaseComponents/Grid';

export const BurguerIcon: any = styled.div`
  height: 34px;
  width: 57px;
  background-image: url("/images/sprite-sa3ca3ca63e.png");
  background-position: 0px -305px;
  background-repeat: no-repeat;
  position: absolute;
  top: calc(64px / 2 - 34px / 2);
  left: 10px;
  cursor: pointer;
  z-index: 999;
`;

export const HaderContainer = styled(Container)`
  background-color: rgba(0,0,0,.3);
  height: 96px;
  position: fixed;
  z-index: 999;
  box-shadow: 2px -2px 5px rgb(0 0 0 / 60%);
  ${Grid} {
    height: 100%;
    position: relative;
    z-index: 9999;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;
    max-width: ${({ theme }) => theme.maxWidth}px;
    margin: auto;
  }
`;

export const Logo = styled.img`
  position: absolute;
  display: block;
  margin: 0 20px;
  width: calc(100% - 97px);
  max-width: 480px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: unset;
  }
`;

export const NavigationLink: FC<any> = styled.li<{ isActive: boolean }>`
  display: flex;
  :last-child {
    border-right: none;
  }
  background-color: ${({ theme }) => theme.palette.light_blue};
  border-bottom: 1px solid;
  margin-bottom: 1px;
  a {
    color: #EFEFEF;
    display: block;
    font-weight: 300;
    padding: 0 7px;
    background-color: ${({ theme }) => theme.palette.light_blue};
    line-height: 40px;
    width: fit-content;
    font-family: sofia_pro_lightregular,Helvetica,Arial,sans-serif;
    font-size: 22px;
    font-weught: 400;
    font-stretch: 100%;
    &:hover {
      color: #008288; 
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      color: ${({ isActive }) => isActive ? "#008288" : "#F8F8F8"};
      line-height: 20px;
      padding: 0 7px;
      font-size: 14px;
      background-image: none;
      width: unset;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    border-bottom: none;
    margin-bottom: 0;
  }
`;
export const SubItem: any = styled.div`
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.palette.light_blue};
  padding: 2px 5px 2px 0;
  height: auto;
  margin-bottom: 2px;
`;

export const IconWrapper: any = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  background-color: ${({ theme }) => theme.palette.light_blue};
  width: 100%;
  padding-left: 10px;
  
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const Icon: any = styled.div<{isShowSubItems: boolean}>`
  transform: ${({ isShowSubItems }) => isShowSubItems && "rotate(90deg)"};   
`;

export const NavigationLinks = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,.8);
  display: block;
  align-items: center;
  padding: 96px 0 0 0;

  transform: translate3d(70vw,0,0);
  transition: transform .8s ease;
  width: 80vw;
  height: 96px;
  box-shadow: -2px -2px 5px rgba(50,50,50,.5);

  ${BurguerIcon} {
    display: none;
  }

  ul {
    background-color: rgba(0,0,0,.8);
    list-style: none;
    overflow-y: auto;
    height: calc(100vh - 64px);
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    background-color: #80C7DC;
    display: flex;
    transform: translate3d(0,0,0);;
    right: 0;
    width: 100%;
    height: unset;
    padding: .5em 0;
    box-shadow: none;
    top: 16px;

    ${NavigationLink} {
      display: flex;
    }

    ${BurguerIcon} {
      display: none;
    }

    ul {
      background-color: #80C7DC;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: unset;
    }
  }
`;

export const NavigationContainer: FC<any> = styled.div<{ active: boolean }>`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  background-color: #000;

  ${({ active }) => active
    ? `
      width: 80vw;
      ${NavigationLinks} {
        ${BurguerIcon} {
          display: block;
        }
        transform: translate3d(0,0,0);
      }

      ${BurguerIcon} {
        display: none;
      }
    `
    : `
      width: 77px;
    `
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
    ${BurguerIcon} {
      display: none;
    }
  }
`;

export const SubItemWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
`;
