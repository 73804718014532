import { BREAKPOINTS_DM } from "src/templates/t8/constants/mediaquerys";
import styled from "styled-components";

// export const UniversalElementWrapper: any = styled.div`
// 		width: 100%;
// 		padding: 0px 10px;
		
// 		@media (${BREAKPOINTS_DM.silver_768}) {
// 			width: 84%;
// 			margin: 0 auto;
// 			div {
// 				display: flex;
// 				flex-wrap: wrap;
// 				img {
// 					width: 212px;
// 					padding: 0 10px;
// 				}
// 			}
// 		}
// 		@media (${BREAKPOINTS_DM.gold_1024}) {
// 			width: 83%;
// 		}
// 		@media (${BREAKPOINTS_DM.platinum_1366}) {
// 			width: 82%;
// 		}
// `;
export const Wrapper: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0 10px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {}
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    padding: 0 10px;
  }
`;
export const SProductItemT8 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  margin: 10px 0;
  div {
    flex-wrap: unset;
    height: auto;
    img {
      width: 100%;
      height: 173px;
      padding: 0;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: fit-content;
    div {
      flex-wrap: inherit;
      height: auto;
      img {
        width: 275px;
        height: 173px;
        padding: 0;
      }
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: fit-content;
    div {
      flex-wrap: inherit;
      height: auto;
      img {
        width: 355px;
        height: 173px;
        padding: 0;
      }
    }
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    div {
      height: auto;
      img {
        width: 355px;
        height: 173px;
        padding: 0;
      }
    }
  }
`;

export const Title: any = styled.div`
    width: 100%;
    color: #70c13e;
    font-family: Roboto,sans-serif;
    line-height: 1.4;
    padding: 15px 0px 25px;
    font-weight: 600;
    font-size: 18px;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 250px;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: 355px;
    }
}
`;

export const TextWrapper: any = styled.div`
  width: 100%;
  padding: 0px 20px 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 250px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 355px;
  }
`;

export const TextPrice: any = styled.span`
  font-family: Roboto,sans-serif;
  font-size: 20px;
`;

export const TextSecondary: any = styled.span`
  font-family: Roboto,sans-serif;
  margin: 0 5px 1px;
  font-size: 15px;
  color: #AAAAAA;
`;

export const PriceWrapper: any = styled.div`
  display: flex;
  align-items: flex-end;
`;

