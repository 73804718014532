import {
  MIN_DIAMOND,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_SILVER,
} from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

const Default = css<any>`
  margin-top: 50px;
  .imageWrapper {
    display: table-cell;
    width: 40%;
  }

  .textWrapper {
    display: table-cell;
    vertical-align: top;

    .textWrapper__content {
      margin-left: ${(props) =>
        props.hasImage && !props.threeOrMorePerRow ? "60px" : "0"};
      overflow: hidden;

      h2 {
        font-size: 1.5rem;
        ${({ threeOrMorePerRow }) =>
          threeOrMorePerRow
            ? css`
                hypens: auto;
                line-height: 1em !important;
                font-size: 16px !important;
                font-weight: 600 !important;
                height: 50px;
                margin-bottom: 0;
                overflow: hidden;
                text-overflow: ellipsis;
              `
            : ""};
      }

      .padding__text {
        padding: 30px 0 50px 0;
        ${(props) =>
          !props.hasImage && props.threeOrMorePerRow ? "padding-top: 0" : ""};
      }
    }

    button {
      font-size: 14px;
      padding: 5px 24px;
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SPageItemT3 = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
