import { ISubNavigationItem, getHeaderNavItemProps, Context, IContext } from "src/common";
import React, { FunctionComponent, useContext, useState } from "react";

import * as styles from "./NavItem.style";

export enum eNavItemType {
    header,
    sidebar
}

interface IProps {
    link: string;
    headline?: string;
    title: string;
    subItems: (ISubNavigationItem | null)[] | null;
    type: eNavItemType;
    active?: boolean;
    expandItem?: (shrink: boolean) => void;
    itemHeight?: number;
}

const NavItem: FunctionComponent<IProps> = props => {
    const commonData = useContext<IContext>(Context);
    if (!props) {
        return null
    }
    const isInSidebar = props.type === eNavItemType.sidebar;

    const [isExpanded, setIsExpanded] = useState(false);

    const expandSubItems = (e): void => {
        e.preventDefault();

        if (props.expandItem) {
            props.expandItem(isExpanded);
        }
        setIsExpanded(!isExpanded);
    };

    return (
        <div
            style={
                isInSidebar
                    ? isExpanded
                        ? { height: (props.subItems.length + 1) * props.itemHeight }
                        : { height: props.itemHeight }
                    : null
            }
            css={isInSidebar ? styles.inSidebar_wrapper : styles.wrapper}
        >
            <a href={"/" + props.link} title={props.headline} css={props.active && styles.activeLink}>
                <span>{props.title}</span>
                {isInSidebar && props.subItems?.length > 0 && (
                    <div onClick={expandSubItems} css={styles.sidebarExpandWrapper}>
                        {isExpanded ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M0 9h24v6h-24z" />
                            </svg>
                        ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
                                </svg>
                            )}
                    </div>
                )}
            </a>
            {props.subItems && props.subItems?.length >= 1  && (
                <div css={isInSidebar ? styles.subItemsWrapper : styles.dropdownWrapper}>
                    {props.subItems.map((subItem, i) => {
                        const subItemProps = getHeaderNavItemProps(subItem);

                        if (!subItemProps) {
                            return null;
                        }
                        const isSubItemActive = subItem?.link?.[0]?.slug === commonData.slug;

                        return (
                            <a
                                key={i}
                                className={"test"}
                                css={isInSidebar ? styles.sidebar_SubItem : styles.dropDrownItem}
                                href={`/${subItemProps.link}`}
                                style={!isInSidebar && isSubItemActive ? { color: "#008bc5" } : {}}
                            >
                                {subItemProps.title}
                            </a>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default NavItem;
