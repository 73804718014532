import React, { FC, useContext } from "react";
import { Context, ICompanyInformationPublic } from "src/common";

import { AddressWrapper } from "./Footer.style";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

const fixUrl = (url: string): string =>
  /^w{3}\./.test(url) ? `https://${url}` : url;

const Address: FC<{
  companyInformation: ICompanyInformationPublic;
  items: any[];
  impressum: Record<string, any>;
}> = ({ impressum }) => {
  const props = useContext(Context);
  const facebook = props?.CompanyInformationPublic?.facebook;
  const instagram = props?.CompanyInformationPublic?.instagram;
  const pinterest = props?.CompanyInformationPublic?.pintrest;
  const twitter = props?.CompanyInformationPublic?.twitter;
  const youtube = props?.CompanyInformationPublic?.youtube;

  return (
    <AddressWrapper>
      <div className="managingDirectorWrapper">
        <p>
          <CopyrightNotice />
        </p>
        <a href={`/${impressum?.slug}`}>Impressum</a>
        <a href="/imprint-datenschutz#dataprivacy">Datenschutzerklärung</a>
      </div>
      <div className="social-links">
        {facebook && <a href={fixUrl(facebook)} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"/></a>}
        {instagram && <a href={fixUrl(instagram)} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"/></a>}
        {pinterest && <a href={fixUrl(pinterest)} target="_blank" rel="noopener noreferrer"><i className="fab fa-pinterest"/></a>}
        {twitter && <a href={fixUrl(twitter)} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></a>}
        {youtube && <a href={fixUrl(youtube)} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"/></a>}
      </div>
    </AddressWrapper>
  );
};

export default Address;
