import { FullscreenImage, FullscreenSlider } from "src/common";
import AOS from "aos";
import React, { FC, Fragment, useEffect } from "react";
import { IAsset, IPhotoGallery } from "src/common/childTypes";
import { getAnimation } from "src/common/constants/animations";
import { ModalImageHide } from "src/common/components/ModalImageHide";


import {
  PhotoGalleryWrapper,
  PhotoGalleryRow,
  PhotoGalleryItem,
} from "./EffectPhotoGallery.style";

const MODAL_MAP = {
  slider: FullscreenSlider,
  image: FullscreenImage,
  hide: ModalImageHide,
};

const getChunks = (item: IPhotoGallery, cols: number): IAsset[][] => 
{
  const chunks = [];
  const items = [...item?.elements];
  while(items.length) 
  {
    chunks.push(items.splice(0, cols));
  }

  return chunks;
};

const getImageProps = (item: IPhotoGallery): any =>
  item?.modal === "slider" ? { elements: item.elements } : null;

const EffectPhotoGallery: FC<{ item: IPhotoGallery; columns?: number }> = ({
  item,
  columns,
}) => 
{
  useEffect(() => 
  {
    AOS.init();
    AOS.refresh();
  }, []);

  const cols = columns || item.maxCountPerRow || 1;
  const animation = getAnimation(item.visualization);
  const Image = MODAL_MAP[item.modal || "image"];
  const chunks = getChunks(item, cols);
  
  return (
    <PhotoGalleryWrapper data-aos-offset="200" data-gallery>
      {cols == 2 ? (
        <>
          {chunks.map((assets, i) => (
            <Fragment key={`gallery-row-${i}`}>
              <PhotoGalleryRow cols={cols}>
                {assets[0] && (
                  <Fragment key={`gallery-row-1-${i}`}>
                    <PhotoGalleryItem
                      key={`gallery-image-${assets[0].src}`}
                      data-aos={animation}
                    >
                      <div data-caption>{assets[0].description ?? ""}</div>
                      <div data-image>
                        <Image
                          img={[assets[0]]}
                          subtitle={assets[0].description}
                          lazyload={false}
                          {...getImageProps(item)}
                        />
                      </div>
                    </PhotoGalleryItem>
                    <PhotoGalleryItem
                      key={`gallery-row-1-2-${i}`}
                      data-aos={animation}
                    >
                      <div data-caption></div>
                      <div data-image></div>
                    </PhotoGalleryItem>
                  </Fragment>
                )}
              </PhotoGalleryRow>
              <PhotoGalleryRow cols={cols}>
                {assets[1] && (
                  <Fragment key={`gallery-row-1-${i}`}>
                    <PhotoGalleryItem
                      key={`gallery-row-1-2-${i}`}
                      data-aos={animation}
                    >
                      <div data-caption></div>
                      <div data-image></div>
                    </PhotoGalleryItem>
                    <PhotoGalleryItem
                      key={`gallery-image-${assets[1].src}`}
                      data-aos={animation}
                    >
                      <div data-caption>{assets[1].description ?? ""}</div>
                      <div data-image>
                        <Image
                          img={[assets[1]]}
                          subtitle={assets[1].description}
                          lazyload={false}
                          {...getImageProps(item)}
                        />
                      </div>
                    </PhotoGalleryItem>
                  </Fragment>
                )}
              </PhotoGalleryRow>
            </Fragment>
          ))}
        </>
      ) : (
        <>
          {chunks.map((assets, chunkIndex) => (
            <PhotoGalleryRow key={`gallery-row-${chunkIndex}`} cols={cols}>
              {assets.map((asset) => {
                if(!asset || !asset.src) return null;

                return (
                  <PhotoGalleryItem
                    key={`gallery-image-${asset.src}`}
                    data-aos={animation}
                    chunkIndex={chunkIndex}
                    hideModal={item.modal === "hide"}
                  >
                    <div data-caption>{asset.description ?? ""}</div>
                    <div data-image>
                      <Image
                        img={[asset]}
                        subtitle={asset.description ?? ""}
                        lazyload={false}
                        {...getImageProps(item)}
                      />
                    </div>
                  </PhotoGalleryItem>
                )
              })}
            </PhotoGalleryRow>
          ))}
        </>
      )}
    </PhotoGalleryWrapper>
  );
};

export default EffectPhotoGallery;
