import React, { FunctionComponent } from "react";

import * as styles from "./BurgerMenu.style";

interface IProps 
{
  onClick: () => void;
}

export const BurgerMenu: FunctionComponent<IProps> = props => (
  <div css={styles.menuContainer}>
    <div css={styles.menu} onClick={props?.onClick}>
      <div css={styles.line} />
    </div>
  </div>

);
