import * as React from "react";
import SImageWithAspectRatio from "./Styles/SImageWithAspectRatio";
import Img from "../Img/Img";
import { IImgProps } from "../../../../common/components/Img";
import { IAsset } from "../../../../common";

export interface IAspectDefinition {
    ratio?: number;
    width?: number;
    height?: number;
}

export interface IImageWithAspectRatioProps extends IImgProps {
    width?: number;
    alt?: string;
    src: IAsset[];
    height?: number;
    ratio?: number;
    bronze?: IAspectDefinition | number;
    silver?: IAspectDefinition | number;
    gold?: IAspectDefinition | number;
    platinum?: IAspectDefinition | number;
    diamond?: IAspectDefinition | number;
}

const ImageWithAspectRatio = (props: IImageWithAspectRatioProps) => {
    const {
        src,
        alt,
        height = 100,
        width = 100,
        ratio,
        bronze = {
            height,
            width,
            ratio
        },
        silver = bronze,
        gold = silver,
        platinum = gold,
        diamond = platinum
    } = props;
    // @ts-ignore
    const ratioCalcBronze = !isNaN(bronze) ? bronze : bronze.ratio || bronze.height / bronze.width;
    // @ts-ignore
    const ratioCalcSilver = !isNaN(silver) ? silver : silver.ratio || silver.height / silver.width;
    // @ts-ignore
    const ratioCalcGold = !isNaN(gold) ? gold : gold.ratio || gold.height / gold.width;
    // @ts-ignore
    const ratioCalcPlatinum = !isNaN(platinum) ? platinum : platinum.ratio || platinum.height / platinum.width;
    // @ts-ignore
    const ratioCalcDiamond = !isNaN(diamond) ? diamond : silver.ratio || diamond.height / diamond.width;
    return (
        <SImageWithAspectRatio
            ratioCalcBronze={ratioCalcBronze <= 2 ? ratioCalcBronze * 100 : ratioCalcBronze}
            ratioCalcSilver={ratioCalcSilver <= 2 ? ratioCalcSilver * 100 : ratioCalcSilver}
            ratioCalcGold={ratioCalcGold <= 2 ? ratioCalcGold * 100 : ratioCalcGold}
            ratioCalcPlatinum={ratioCalcPlatinum <= 2 ? ratioCalcPlatinum * 100 : ratioCalcPlatinum}
            ratioCalcDiamond={ratioCalcDiamond <= 2 ? ratioCalcDiamond * 100 : ratioCalcDiamond}
        >
            <Img src={src} alt={alt} {...props} />
        </SImageWithAspectRatio>
    );
};

export default ImageWithAspectRatio;
