import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

const Default = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 60px 0;
  margin: 0 auto;
  position: relative;
  background: ${(props: any) => props.color};

  .wrapper {
    display: flex;
    width: 90%;
    max-width: 1600px;
  }
  
  .content {
    width: 50%; 
  }
  .content:last-child{
    a{
      width: 100%;
      height: 100%;
    }
  }
  .content:first-child{
    display: flex;
    a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    }
  }
  text-align: center !important;
  h2 { 
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    width: 170px;
    word-wrap: break-word;
  }
  .cpg-item{
    .cpg-price{
      padding: 13px;
      padding: 0;
      position: relative;
      .cpg-price-number{
        font-size: 28px;
        font-weight: 400;
        color: #d60e47;
        width: auto;
      }
    }
    .cpg-price-next{
      color: #fff !important;
      background: #918574 !important;
      line-height: 48px !important;
        margin-top: 14px;
        font-size: 18px;
        width: 100%;
        height: 48px;
        width: 150px;
        cursor: pointer;
        &:hover{
          color: black;
        }
        display: block;
        line-height: 2em;
        text-align: center;
    }
  }
`;

const Silver = css`
  h2{
    font-size: 24px;
    line-height: 20px;
    font-weight: 400;
    width: unset;
    word-wrap: normal;
  }
  .cpg-item{
    .cpg-price{
      .cpg-price-number{
        font-size: 64px;
      }
    }
    .cpg-price-next{
        font-size: 24px;
        width: 220px;
    }
  }
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SProductItemT6 = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

