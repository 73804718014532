import React, { Fragment } from "react";
import { getIfNewsShouldBeDisplayByDate, Img, IProduct } from "src/common";
import { RichText } from "src/common/components/RichText";
import { SProductItemT6 } from "./Styles/SProductItemT6";

export const ProductItemT6 = ({ item, i }) => {
    if (!item) {
        return item;
    }
    const product = (item?.link?.[0]) as IProduct;
    const isProduct = product?.__typename.includes("Product");
    const link = item?.link?.[0] as any;
    const price = link?.ourPrice;
    const slug = link?.slug;
    if (isProduct && product?.acceptAGB && !getIfNewsShouldBeDisplayByDate({ startDate: product?.startDate, endDate: product?.endDate })) {
        return null;
    }
    const index = i % 1;
    const colors = ["##f1f1f1", "#aad4cf",];

    return (
        <>
            <SProductItemT6 color={colors[index] || "#f1f1f1"}>
                <div>
                    <a className="cpg-item" href={`/${isProduct ? "produkt/" : ""}${slug}`}>
                        <h2>{item?.title}</h2>
                        {item?.link?.[0]?.text?.content &&
                            <RichText textContent={item?.link?.[0]?.text.content}></RichText>
                        }
                        <div className="cpg-price">
                            <div>
                                <span className="cpg-price-text">Jetzt für nur</span>
                                {price && <span className="cpg-price-number">{`${price}€`}</span>}
                            </div>
                            <div>
                                <span className="cpg-price-next">
                                    zum Angebot
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
                <div>
                    <a className="cpg-item" href={`/${isProduct ? "produkt/" : ""}${slug}`}>
                        <Img src={item.thumbnail}></Img>
                    </a>
                </div>
            </SProductItemT6>
        </>
    );
}