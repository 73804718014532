import styled, { keyframes, css } from "styled-components";

export const SScrollIndicatorAngle = styled.div<any>`
    width: 20px;
    height: 20px;
    background: transparent;
    text-indent: -9999px;
    border-top: 3px solid #fff;
    transform: rotate(-135deg);
    right: 175px;
    border-left: 3px solid #fff;
    text-decoration: none;
    color: transparent;
`;
