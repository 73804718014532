import React, { useContext } from "react";
import { Context } from "../..";
import { ImprintCompanyName } from "../ImprintStart";

export const ImprintNameAndAddressOfThePersonInCharge = () => {
  const c = useContext(Context);
  if (!c?.CompanyInformationPublic) {
    return null;
  }
  const { street, place, country, postcode, phone, email } =
    c?.CompanyInformationPublic;

  if (!c?.slug || c?.PageImprint?.slug !== c?.slug) {
    return null;
  }

  return (
    <>
      <p>
        <ImprintCompanyName />
        <br />
        {street ? `${street}` : ""}
        <br />
        {postcode ? `${postcode}` : ""}
        {place ? ` ${place}` : ""}
        <br />
        <br />
        {phone && `Telefon: ${phone} `} <br />
        {email && `E-Mail : ${email}`}
        <br />
      </p>
    </>
  );
};
