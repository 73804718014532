import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import BrandBannerHorizontal from "../BrandBannerHorizontal";
import { Section, Container } from "../Grid";
import Address from "./Address";
import { FooterWrapper, Award } from "./Footer.style";
interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent<{ brands?: boolean }> = ({ brands }) => {
  const props = useContext(Context);
  const companyInformation = props.CompanyInformationPublic;

  return (
    <Section>
      <FooterWrapper>
        {brands && (
          <Container>
            <BrandBannerHorizontal />
          </Container>
        )}
        <Container>
          <Address companyInformation={companyInformation}/>
          <Award><img src="/images/kw_linebadge.png"/></Award>
        </Container>
      </FooterWrapper>
    </Section>
  );
};

export default Footer;
