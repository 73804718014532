import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    width: 100%;
`;

export const twoColumnsWrapper: any = css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > * {
        display: none;
    }
    > div:first-child {
        display: block;
        width: calc(100% - 310px);
    }
    > div:nth-child(2) {
        display: block;
        width: 270px;
        margin-left: 40px;
    }
`;

export const pageContent: any = css`
    padding: 0 !important;
    @media (${BREAKPOINTS_DM.platinum_1366}) {
        width: 100% !important;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
        width: 92% !important;
        margin: 0 auto;
    }
`;

export const blackFooterBox2: any = css`
    margin: auto;
    margin-bottom: 24px;
    background-color: black;
    width: 92% !important;
    color: white;
    img {
        margin-bottom: 120px;
        width: 100%;
        height: auto;
        max-width: 300px;
        max-height: 300px;
    }
    nav a {
        color: white;
        display: block;
    }
    display: none;
    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: block;
    }
`;

export const sliderWrapper: any = css`
    width: 100%;
    padding: 0 !important;
    &:before {
        border-bottom-color: ${(props) => props.theme.secondary};
    }
    > div {
        padding: 27px;
        border: solid 1px ${(props) => props.theme.secondary};
    }
`;

export const connectedArticle: any = css`
    margin: 10px 0 50px;
    width: 100%;
    > div > div {
        float: left;
    }
    > div {
        display: flow-root;
    }
    p {
        margin-bottom: 0.5rem;
    }
    h2 {
        color: ${(props) => props.theme.primary};
        font-size: 30px;
        text-transform: uppercase;
    }
    h2 {
        margin-bottom: 1rem;
    }
    h3 {
        color: ${(props) => props.theme.primary};
        font-size: 20px;
        margin-bottom: 13.5px;
    }
    iframe {
        max-width: 100%;
    }
`;

export const connectedArticleWithBorder: any = css`
    padding: 0 !important;
    &:before {
        border-bottom-color: ${(props) => props.theme.secondary};
    }
    > div {
        padding: 27px;
        width: 100%;
        border: solid 1px ${(props) => props.theme.secondary};
    }
`;

export const connectedArticleImg: any = css`
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 0 20px 20px 0;
`;

export const connectedArticleImgThreeInARow: any = css`
    width: calc(33.3% - 20px);
    height: auto;
    display: inline-block;
    @media (max-width: 768px) {
        width: 100%;
        max-width: none;
        margin-right: 0;
    }
`;

export const connectedArticleRichtext: any = css`
    /* margin: 10px 0 20px; */
    em {
        font-style: italic;
    }
    /* float: left; */
    > div:first-child {
        float: left;
        margin: 0 28px 28px 0;
    }
`;

export const footnote: any = css`
    margin-bottom: 13px;
    a,
    a * {
        color: ${(props) => props.theme.primary};
    }
`;
