import React, { FC } from "react";
import { Img, IProduct, RichText } from "src/common";

import {
  Container,
  TitleWrapper,
  ImageWrapper,
  RichTextWrapper,
} from "./Styles/SProductItemT23";

export const ProductItemT23: FC<{ item: any; i: number }> = ({ item, i }) => {
  if (!item) {
    return null;
  }

  return (
    <Container>
      {item.thumbnail?.length > 0 && (
        <ImageWrapper>
          {item.thumbnail.map((thumbnail, i) => (
            <Img src={thumbnail.src} key={`image-${i}`} />
          ))}
        </ImageWrapper>
      )}
      {item.title && (
        <TitleWrapper>
          <h5>{item.title}</h5>
        </TitleWrapper>
      )}
      {item.text?.content && (
        <RichTextWrapper>
          <RichText fragment={true} textContent={item.text} />
        </RichTextWrapper>
      )}
    </Container>
  );
};
