import React, { FC } from "react";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import { AddressWrapper } from "./Footer.style";

const Address: FC<{
  companyInformation: any;
  items: any[];
  impressum: Record<string, any>;
}> = ({ impressum }) => {
  return (
    <AddressWrapper>
      <div className="managingDirectorWrapper">
        <p>{`Copyright © ${new Date().getFullYear()} - ${getContactCompanyNameShort()}`}</p>
        <a href={`/${impressum?.slug}`}>Impressum</a>
        <a href="/imprint-datenschutz#dataprivacy">Datenschutzerklärung</a>
      </div>
    </AddressWrapper>
  );
};

export default Address;
