import React, { FC, useContext, useState, FunctionComponent } from "react";
import { Context } from "src/common";
import { css } from "styled-components";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import { RichTextGeneral } from "../RichText";
import {
  InfoWrapper,
  DataWrapper,
  H3,
  H1,
  RichTextWrapper,
  AddressWrapper,
  Wrapper,
} from "./Banner.style";
import BannerLink from "./BannerLink";

const BannerItem: FunctionComponent<{
  icon: string;
  data: Record<string, any>;
  isScrolling: boolean;
}> = ({ icon, data, isScrolling }) => {
  const [isActive, toogleActive] = useState(false);
  return (
    <>
      <BannerLink
        icon={icon}
        onToogleActive={toogleActive}
        isActive={isActive}
        isScrolling={isScrolling}
      />
      {isActive && (
        <InfoWrapper isScrolling={isScrolling}>
          {icon === "phone-alt" && (
            <DataWrapper>
              <H3>Telefon:</H3>
              <H1>{data}</H1>
            </DataWrapper>
          )}
          {icon === "clock" && (
            <DataWrapper>
              <H3>Öffnungszeiten:</H3>
              <RichTextWrapper>
                {data?.type == "doc" ? (
                  <RichTextGeneral
                    overwriteStyle={css`
                      table > tbody > tr:not(:last-child) {
                        border-bottom: 1px solid white;
                        margin-bottom: 16px;
                        padding-bottom: 16px;
                      }
                    `}
                    textContent={data}
                  />
                ) : null}
              </RichTextWrapper>
            </DataWrapper>
          )}
          {icon === "map-marker-alt" && (
            <DataWrapper>
              <H3>Adresse:</H3>
              <AddressWrapper>
                <p>{getContactCompanyNameShort()}</p>
                <p>{data.street}</p>
                <p>
                  {data.postcode} {data.place}
                </p>
              </AddressWrapper>
            </DataWrapper>
          )}
        </InfoWrapper>
      )}
    </>
  );
};

const Banner: FunctionComponent<{ isScrolling: boolean }> = ({
  isScrolling,
}) => {
  const props = useContext(Context);

  const phone = {
    data: props?.CompanyInformationPublic?.phone,
    icon: "phone-alt",
  };
  const address = {
    data: {
      place: props.CompanyInformationPublic?.place,
      postcode: props.CompanyInformationPublic?.postcode,
      companyName: getContactCompanyNameShort(),
      street: props.CompanyInformationPublic?.street,
    },
    icon: "map-marker-alt",
  };
  const openingHours = {
    data: props.CompanyInformationPublic?.openingHours,
    icon: "clock",
  };

  const linksList = [phone, openingHours, address];
  return (
    <Wrapper isScrolling={isScrolling}>
      {linksList?.map((link, index) => (
        <BannerItem
          icon={link.icon}
          key={`link-${index}`}
          data={link.data}
          isScrolling={isScrolling}
        />
      ))}
    </Wrapper>
  );
};

export default Banner;
