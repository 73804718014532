import styled from "styled-components";

export const AffixWrapper: any = styled.div<{ absolute?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
`;

