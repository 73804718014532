import { css } from "styled-components";

import colors from "../../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../../constants/mediaquerys";

export const wrapper: any = css`
  display: flex;
  padding: 6px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768})
  {
      flex-direction: column-reverse;
      justify-content: flex-start;
  }
`;

export const contentWrapper: any = css`
    padding: 6px 0;
    margin-bottom: 10px;
    width: 50%;
    h4 {
      color: ${props => props.theme.primary} !important;
      font-weight: 400;
      font-size: 19px;
      line-height: 110% !important;
      margin-bottom: 12px;
    }
    a {
      color: ${props => props.theme.primary} !important;
      display: block;
      margin: 18px 0 2px;
      &:hover {
        text-decoration: underline;
      }
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        width: 100%;
    }
`;

export const imgWrapper: any = css`
    width: 50%;
    margin-left: 25px;
    img {
      width: 100%;
      height: 220px !important;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-bottom: 15px;
        margin-left: 0;
        width: 100%;
        img {
          height: 50vw !important;
        }
    }
`;

export const richText: any = css`
    overflow: hidden;
    max-height: 110px;
    span {
      color: #000000;
    }
`;

