import parse from "html-react-parser";

export const renderCustomHtmlElements = (html) => {
    try {
        return parse(html);
    } catch {
        return null;
    }
};

export const HtmlEmbeddingWithPackage = (props) => {
    return (
        <>
            <div>{props.htmlCode && renderCustomHtmlElements(props.htmlCode)}</div>
        </>
    );
};

export const HtmlEmbeddingWithNative = (props) => {
    return <div dangerouslySetInnerHTML={{ __html: props.htmlCode }} />;
    // const divRef = useRef();

    // useEffect(() => {
    //     if (divRef.current) {
    //         // @ts-ignore
    //         divRef.current.innerHTML = props.htmlCode;
    //     }
    // }, []);
    // return (
    //     <>
    //         <div ref={divRef}></div>
    //     </>
    // );
};

export const HtmlEmbedding = (props) => {
    if (props?.htmlCode?.includes("script")) {
        return <HtmlEmbeddingWithNative htmlCode={props.htmlCode} />;
    }
    return <HtmlEmbeddingWithPackage htmlCode={props.htmlCode} />;
};
