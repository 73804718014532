import React, { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { FancyImage, IFancyImage } from "../FancyImage/FancyImage";
import { SModalSubtitleWrapper } from "./styles/SModalSubtitleWrapper";
import SModalImageWrapper from "./styles/SModalImageWrapper";
import { SModalCloseWrapper } from "./styles/SModalCloseWrapper";
import { SModalFlexContainer } from "./styles/SModalFlexContainer";
import { SModalContent } from "./styles/SModalContent";
import { SModalBackground } from "./styles/SModalBackground";
import { SClickWrapper } from "./styles/SClickWrapper";
import { useWindowDimensions } from "../../utils/useWindowDimensions";
import { BREAKPOINTS } from "../../constants/mediaquerys";
import { CloseIcon } from "src/common/icons/CloseIcon";

const Modal: FC = (props) => {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let el;

        if (typeof window !== "undefined") {
            el = document.createElement("div");
            const rootContainer = document.querySelector("#__next");
            rootContainer.appendChild(el);
            container.current = el;
        }

        return () => el?.remove();
    }, []);

    return container.current ? createPortal(props.children, container.current) : null;
};

const heightOfCrossAndSubtitle = 80;

/**
This image component support focal point, lazyload and responsive images.
@img: the image object
@responsiveFactor: The responsiveFactor is the amount of space used on the screen in percent - default is 1 for full width image
@responsiveAspectRatio: overwrite the aspect ratio for each breakpoint (width/height)  - default is to use the aspect ratio of the image
@responsiveMaxWidth: The responsiveMaxWidth is the max width of the image in px - default the is no limit and will scale to max of current breakpoint 
@lazyload: lazyload the image or not - default is true
*/

export const FancyImageWithModal: FC<IFancyImage> = (props) => {
    const [isActive, setIsActive] = useState(false);
    const { windowWidth, windowHeight } = useWindowDimensions();
    const aspectRatioImage = props.img?.width && props.img?.height ? props.img.width / props.img.height : 1;
    const aspectRatioContainer = props.img?.width && props.img?.height ? props.img.width / (props.img.height + 80) : 1;
    const windowWidthWithoutPadding = windowWidth < BREAKPOINTS.PLATINUM ? windowWidth - 24 * 2 : windowWidth - 76 * 2;
    const windowHeightWithoutPadding =
        windowWidth < BREAKPOINTS.PLATINUM ? windowHeight - 24 * 2 : windowHeight - 76 * 2;

    let adjustedWidth = 0;

    // this is if the aspect ratio of the image is higher than the aspect ratio of the container possible inside the window;
    if (windowWidthWithoutPadding * aspectRatioImage > windowHeightWithoutPadding - heightOfCrossAndSubtitle) {
        adjustedWidth = +((windowHeightWithoutPadding - heightOfCrossAndSubtitle) * aspectRatioImage).toFixed(0);
    } else if (
        windowWidthWithoutPadding / aspectRatioContainer >
        windowHeightWithoutPadding - heightOfCrossAndSubtitle
    ) {
        adjustedWidth = +((windowHeightWithoutPadding - heightOfCrossAndSubtitle) * aspectRatioContainer).toFixed(0);
    }

    if (!props.img) {
        return null;
    }

    return (
        <>
            <SClickWrapper>
                <FancyImage {...props} onClick={() => setIsActive(true)} />
            </SClickWrapper>
            <Modal>
                <SModalBackground isActive={isActive} onClick={() => setIsActive(false)}>
                    <SModalContent
                        style={isActive ? { display: "flex" } : { display: "none" }}
                        data-fullscreen-image-wrapper
                    >
                        <>
                            {isActive && (
                                <SModalFlexContainer
                                    className="fullscreen-image-container"
                                    adjustedWidth={adjustedWidth}
                                >
                                    <SModalCloseWrapper className="fullscreen-close">
                                        <CloseIcon />
                                    </SModalCloseWrapper>
                                    <SModalImageWrapper className="fullscreen-image-inner">
                                        <FancyImage img={props.img} />
                                    </SModalImageWrapper>
                                    <SModalSubtitleWrapper>
                                        {props.img.description && props.img.description != "<invalid Value>" && (
                                            <div className="fullscreen-caption">
                                                <p data-title>{props.img.description}</p>
                                            </div>
                                        )}
                                    </SModalSubtitleWrapper>
                                </SModalFlexContainer>
                            )}
                        </>
                    </SModalContent>
                </SModalBackground>
            </Modal>
        </>
    );
};
