import styled, { css } from "styled-components";

import {
  COL_AMOUNT,
  GUTTER,
  MAX_WIDTH,
  PADDING,
  SCREEN_TYPES,
} from "../../../constants/grid";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/mediaquerys";

const generateColums = (type) => {

  const cols = `1fr `
  .repeat(COL_AMOUNT[type])
;
  return css`
    grid-gap: ${GUTTER[type]}px;
    grid-template-columns: ${cols};

  `;
};

const Default = css`
  display: grid;
  grid-template-rows: auto;
  width: 100%;
`;

const Silver = css`
  ${generateColums(SCREEN_TYPES.SILVER)};
`;

const Gold = css`
  ${generateColums(SCREEN_TYPES.GOLD)};
`;

const Platinum = css`
  ${generateColums(SCREEN_TYPES.PLATINUM)};
`;

const Diamond = css`
  ${generateColums(SCREEN_TYPES.DIAMOND)};
  margin: auto;
  max-width: ${MAX_WIDTH - PADDING.DIAMOND * 2}px;
`;

const SRow = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SRow;
