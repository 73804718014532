import React, { FunctionComponent, useContext } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { IContext, Context } from "../../index";
import Row from "src/common/BaseComponents/Row/Row";
import Col from "src/common/BaseComponents/Col/Col";
import SNonBindingRequestFrom from "./Styles/SNonBindingRequestFrom";
import Spacer from "src/common/BaseComponents/Spacer/Spacer";
import { handleFormSubmit } from "../../utils/formSubmit";
import { css } from "styled-components";
import { FriendlyCaptcha } from "../friendly-capture";

export const NonBindingRequestFrom: FunctionComponent<any> = ({ productTitle }) => {
    const pageProps = useContext<IContext>(Context);

    const { register, handleSubmit, errors, reset } = useForm();
    const [success, setSuccess] = React.useState(false);
    const onSubmit = (data): void => {
        //Kontaktformular: d-26922068a30343d98a725c3fdaa663da
        //Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
        //Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441
        //Produktanfrage: d-ee2129a87d2d4e70bbcf21f1bff6491e

        const fcValue = (document.querySelector(".frc-captcha-solution") as HTMLInputElement)?.value;

        let title: string | undefined = undefined;
        if (typeof window !== "undefined") {
            title = document.title;
        }
        data.productTitle = productTitle;
        handleFormSubmit({
            ...data,
            fcValue: !!fcValue ? fcValue : sessionStorage.getItem("fcaptcha"),
            line: pageProps?.CompanyInformationPrivate?.line,
            subject: `Angebotsanfrage ${data?.Name ? `von ${data?.Name} ` : ""}${productTitle ? `zu ${productTitle} ` : ""}${title ? `- ${title}` : ""}`,
            dealerMail: pageProps?.CompanyInformationPublic?.email,
            templateId: "d-ee2129a87d2d4e70bbcf21f1bff6491e",
        }).then((r) => {
            reset();
            setSuccess(true);
        });
    };

    const name = "Name";
    const email = "E-Mail";
    const tel = "Telefon (optional)";
    const msg = "Nachricht";
    const submitText = "Unverbindliche Anfrage";

    return (
        <SNonBindingRequestFrom className={"nbrf-o"}>
            <div className={"nbrf"}>
                {success ? (
                    <form>
                        <h1>DANKE!</h1>
                        Wir werden uns sobald wie möglich bei Ihnen melden.
                    </form>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row fullWidth>
                            <Col bronze={[1, 4, 1]} silver={[1, 6, 1]}>
                                <Spacer bronze={20} />
                                <label htmlFor={"name"}>{name}</label>
                                <input
                                    required={true}
                                    type="text"
                                    name="name"
                                    id={"name"}
                                    ref={register({ required: true })}
                                />
                            </Col>
                            <Col bronze={[1, 4, 2]} silver={[1, 6, 2]}>
                                <Spacer bronze={20} />
                                <label htmlFor={"email"}>{email}</label>
                                <input
                                    required={true}
                                    type="email"
                                    id={"email"}
                                    name="email"
                                    ref={register({ required: true, min: 4 })}
                                />
                            </Col>
                            <Col bronze={[1, 4, 3]} silver={[7, 12, 2]}>
                                <Spacer bronze={20} />
                                <label htmlFor={"tel"}>{tel}</label>
                                <input type="phone" name="tel" id={"tel"} ref={register({ required: false })} />
                            </Col>
                            <Col bronze={[1, 4, 4]} silver={[1, 12, 3]}>
                                <Spacer bronze={20} />
                                <label htmlFor={"msg"}>{msg}</label>
                                <textarea rows={8} name="msg" id={"msg"} ref={register()} />
                            </Col>
                            <Col bronze={[1, 4, 5]} silver={[1, 6, 4]}>
                                <Spacer bronze={20} />
                                <input
                                    css={css`
                                        position: absolute;
                                        opacity: 0;
                                        pointer-events: none;
                                    `}
                                    type="privacy"
                                    placeholder="Ich akzeptiere die Datenschutzerklärung"
                                    name="privacy"
                                />
                                <input
                                    required={true}
                                    type="checkbox"
                                    name="checkbox"
                                    id={"checkbox"}
                                    ref={register({ required: true })}
                                />
                                <label htmlFor={"checkbox"} className="datenschutzAkzeptieren">
                                    Ich akzeptiere die{" "}
                                    <a href={`/${pageProps?.PageImprint?.slug}`}> Datenschutzerklärung</a>
                                </label>
                            </Col>
                            <Col bronze={[1, 4, 6]} silver={[1, 6, 5]}>
                                <Spacer bronze={12} />
                                <FriendlyCaptcha />
                            </Col>
                            <Col bronze={[1, 4, 7]} silver={[7, 12, 6]}>
                                <button type="submit" className="ps-button ps-offer">
                                    {submitText}
                                </button>
                            </Col>
                        </Row>
                    </form>
                )}
            </div>
            <div></div>
        </SNonBindingRequestFrom>
    );
};

export default NonBindingRequestFrom;
