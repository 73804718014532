import React, { FC } from "react";
import styled, { StyledFunction } from "styled-components";
import { BREAKPOINTS_DM } from "../../constants/mediaquerys";

export const Container = styled.div`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 0px;
}
`;

export const Grid = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
`;
export const Section = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(50,50,50,.5);
`;

interface IGridProps {
  sm?: number;
  md?: number;
  expand?: boolean;
}

export const Col = styled.div<IGridProps>`
  width: ${({sm}) => sm ? 100 / 12 * sm : 100}%;

  @media (${BREAKPOINTS_DM.silver_768}){
    ${({ md }) => md && `width: ${100 / 12 * md}%;`}
  }
`;

export const Row = styled.div<IGridProps>`
  display: flex;
  flex-wrap: wrap;

  ${({ expand }) => expand && `
    margin-left: 0px;
    margin-right: 0px;
  `}

  ${Col} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
