import styled from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const FurtherAwardsWrapper: any = styled.div`
  width: 100%;
  height: auto;
  background-color: ${colors.light_green};
  background-image: url("/images/bg_award.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FurtherAwardsContet: any = styled.div`
  margin: 10px auto 70px;
  display: flex;
  justify-content: center; 
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ImageWrapper: any = styled.div`
  width: 30%;
  margin: 10px 15px 0px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 20%;
  }
`;

export const FurtherSpan: any = styled.span`
  margin: 40px auto 20px;
  font-family: Roboto,sans-serif;
  letter-spacing: 0.8px;
  line-height: 1.4;
  font-size: 14px;
  color: ${colors.white};
`;
