import { css } from "styled-components";

import colors from "../../../constants/colors";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    background-color: ${colors.lightGray};

  .slick-prev:before,.slick-next:before{
    color: ${(props: any) => props.theme.primary || "#AAD4CF"} !important;
  }
`;

const Default = css`
  img {
    background-color: white;
    object-fit: contain;
    max-height: calc(100vw - 40px);
  }
`;

const Silver = css`
 img {
  max-height: calc(92vw / 2 * 0.9 * 9/16); // use the width of the surrounding containers and then enforce 16/9 ratio
  }
`;

const Gold = css`

`;

const Platinum = css`
 img {
  max-height: 540px;
  }
`;

const Diamond = css``;

export const imageWrapper = css`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

